import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Newsletter_Data from "./Newsletter_data"; // Import the newsletter data

import banner1 from "../.././asset/images/newsletter/newsletter_banner/mar25banner.jpg"
import banner2 from "../.././asset/images/newsletter/newsletter_banner/feb25banner.jpg"
import banner3 from "../.././asset/images/newsletter/newsletter_banner/jan25banner.png"


const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1025 }, items: 1 },
    tablet: { breakpoint: { max: 778, min: 480 }, items: 1 },
    mobile: { breakpoint: { max: 480, min: 0 }, items: 1 }
};

const data2025 = Newsletter_Data.filter((item) => item.year === 2025);

export default function NewsletterBannerCarousel() {
    const carouselRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    // Handle dot click
    const goToSlide = (index) => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(index);
            setActiveIndex(index);
        }
    };

    // Ensuring smooth infinite loop
    const handleBeforeChange = (nextSlide) => {
        setActiveIndex(nextSlide % data2025.length);
    };

    return (
        <Container maxWidth="lg" className="bdr gradmain">
            <Grid container spacing={2} alignItems="center">
                {/* Left Section */}
                <Grid item lg={3} md={5} sm={12} xs={12}>
                    <Box className="al_left grad1" mb={2}>
                        <Typography variant="h1" className="blue mb0 newh1">
                            Newsletter
                        </Typography>
                        <Typography variant="h6" className="black mb0">
                            Stay up to date about the latest happenings in the Financial Industry.
                        </Typography>
                        {/* Custom Dots */}
                        {/* <CustomDots activeIndex={activeIndex} totalSlides={data2025.length} goToSlide={goToSlide} /> */}
                    </Box>
                </Grid>

                {/* Right Section - Carousel */}
                <Grid item lg={9} md={7} sm={12} xs={12}>
                    <Box className="grad2">
                        <img loading="lazy" 
                            src={banner1}
                            alt="Banner"
                            style={{ maxWidth: "100%", height: "auto" }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

/* Custom Dots Component */
const CustomDots = ({ activeIndex, totalSlides, goToSlide }) => {
    return (
        <Box display="flex" justifyContent="start" mt={2}>
            {Array.from({ length: totalSlides }).map((_, index) => (
                <button
                    key={index}
                    onClick={() => goToSlide(index)}
                    style={{
                        width: "14px",
                        height: "14px",
                        margin: "5px",
                        borderRadius: "50%",
                        backgroundColor: activeIndex === index ? "#1930AB" : "#e0e0e0",
                        border: activeIndex === index ? "3px solid #1930AB" : "1px solid #ccc",
                        cursor: "pointer",
                        transition: "background-color 0.3s, border 0.3s ease-in-out",
                    }}
                />
            ))}
        </Box>
    );
};
