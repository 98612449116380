import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import ModalVideo from "react-modal-video";

import { Link } from "react-router-dom";

// import Web from "../../asset/images/landing_page/web_icon.webp"

// import PDF from "../../asset/images/landing_page/innovations/smartcloud_pdf_analysis.webp";
// import CRM from "../../asset/images/landing_page/innovations/crm_services.webp";
import CMS from "../../asset/images/landing_page/innovations/cat_modeling_services.webp";
import DATA from "../../asset/images/landing_page/innovations/data_solutions.webp";
// import EsgNews from "../../asset/images/landing_page/innovations/esg_news_pulse.webp";
import AI from "../../asset/images/landing_page/innovations/artificial_intelligence.webp";
import Bridge from "../../asset/images/landing_page/innovations/bridge_lending_solutions.webp";
import SBAServices from "../../asset/images/landing_page/innovations/sba_services.webp";
import Investor from "../../asset/images/landing_page/innovations/investor_relations_solutions.webp";

export default function HomeInnovationSecond() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // const [isOpen, setIsOpen] = useState(false);
  // const openModal = () => {
  //   setIsOpen(true);
  // };



  // const [isOpen3, setIsOpen3] = useState(false);
  // const openModal3 = () => {
  //   setIsOpen3(true);
  // };

  const [isOpen4, setIsOpen4] = useState(false);
  const openModal4 = () => {
    setIsOpen4(true);
  };

  // const [isOpen5, setIsOpen5] = useState(false);
  // const openModal5 = () => {
  //   setIsOpen5(true);
  // };

  const [isOpen6, setIsOpen6] = useState(false);

  return (
    <>
      <Box id="new_innovation_design" className="section" component="section">
        <Container maxWidth="lg">
          <Box id="products">
            <Grid
              container
              spacing={1}
              alignItems="top"
              justifyContent="center"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography variant="h2" className="black mob_left">
                    Experience Innovation: Our Technology, Your Future
                  </Typography>
                  <Typography variant="h6" className="black mob_jst">
                    Our solutions are user-centric that not only meet today's
                    needs but anticipate tomorrow's challenges, propelling you
                    towards a smarter, more connected world.{" "}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box pt={2}>
              <Grid
                container
                spacing={3}
                alignItems="top"
                justifyContent="center"
              >

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box
                        style={{ backgroundImage: "url(" + AI + ")" }}
                        className="innovation_bg"
                      >
                        <IconButton
                          className="pulse-button pulse_button_2"
                          onClick={openModal4}
                        ></IconButton>
                      </Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Artificial Intelligence
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Empower your business with cutting-edge AI
                            solutions, including the transformative capabilities
                            of gen AI, for unparalleled innovation & efficiency.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore Our Success Story
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box style={{ backgroundImage: "url(" + Bridge + ")" }} className="innovation_bg d-none"></Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Bridge Lending Solutions
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Streamline bridge lending with efficient, tailored solutions. Simplify underwriting, mitigate risks, and enhance decision-making.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/bridge-lending-solutions">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore it
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box style={{ backgroundImage: "url(" + CMS + ")" }} className="innovation_bg d-none"></Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Catastrophe Modeling Services
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Empower your risk strategy with advanced catastrophe modeling services, enhancing risk assessment, underwriting, and resilience for insurers.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/catastrophe-modeling-services">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore it
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box style={{ backgroundImage: "url(" + DATA + ")" }} className="innovation_bg d-none"></Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Data Solutions
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Optimize research with customized data solutions, real-time updates, advanced analytics, and automation to enhance financial decision-making.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/data-solution-in-us">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore it
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box style={{ backgroundImage: "url(" + Investor + ")" }} className="innovation_bg d-none"></Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Investor Relations Solutions
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Enhance investor communication with simplified reporting and real-time insights for better engagement.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/investor-relations-solutions">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Know more
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box
                        style={{ backgroundImage: "url(" + SBAServices + ")" }}
                        className="innovation_bg  d-none"
                      >
                      </Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            Small Business Administration
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Maximize efficiency and precision with comprehensive SBA loan outsourcing services for NBFCs and SBA lenders.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/small-business-administration">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Discover Now
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box
                        style={{ backgroundImage: "url(" + EsgNews + ")" }}
                        className="innovation_bg"
                      >
                        <IconButton
                          className="pulse-button pulse_button_2"
                          onClick={openModal3}
                        ></IconButton>
                      </Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            ESG News Senti-Meter
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Our AI extracts real-time ESG insights from news,
                            facilitating rapid assessment of issuers in your
                            portfolio or coverage.{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <a
                        href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/esg-key-issue-assessment-report.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Download the Sample Report
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Grid> */}



                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box
                        style={{ backgroundImage: "url(" + PDF + ")" }}
                        className="innovation_bg"
                      >
                        <IconButton
                          className="pulse-button pulse_button_2"
                          onClick={openModal5}
                        ></IconButton>
                      </Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            PDFquery{" "}
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            {" "}
                            PDFquery is reshaping business document management,
                            enabling effortless search, extraction, & analysis
                            from complex PDFs.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/pdfquery" rel="noopener noreferrer">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore it
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid> */}

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="innovation_bx">
                    <Box className="innovation_inner_bx">
                      <Box style={{ backgroundImage: "url(" + CRM + ")" }} className="innovation_bg d-none"></Box>
                      <Box>
                        <Box className="innovation_content_bx">
                          <Typography variant="h5" className="blue">
                            CRM Support Services
                          </Typography>
                          <Typography variant="h6" className="black thr">
                            Unlock Salesforce CRM's full potential with DPA’s expert support - optimize processes, integrate data, and automate tasks for seamless client engagement.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box className="synopsic_bx">
                      <Link to="/crm-solutions">
                        <Button
                          variant="contained"
                          className="dpabtn_sm1"
                          fullWidth
                        >
                          Explore it
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid> */}


              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YANI_bxmNB8"
        onClose={() => setIsOpen(false)}
      /> */}

      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen2}
        videoId="1o7DlE35IS4"
        onClose={() => setIsOpen2(false)}
      /> */}

      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen2}
        videoId="cqkcVeJDKIg"
        onClose={() => setIsOpen2(false)}
      /> */}

      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen3}
        videoId="LaXyyhy8o3s"
        onClose={() => setIsOpen3(false)}
      /> */}

      <ModalVideo
        channel="youtube"
        isOpen={isOpen4}
        videoId="9V08_Li8uO0"
        onClose={() => setIsOpen4(false)}
      />

      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen5}
        videoId="91mtDiCmlgw"
        onClose={() => setIsOpen5(false)}
      /> */}

      <ModalVideo
        channel="youtube"
        isOpen={isOpen6}
        videoId="pLfNSRpdEvE"
        onClose={() => setIsOpen6(false)}
      />
    </>
  );
}
