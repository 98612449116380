import React, { Component } from "react";
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import Aos from "aos";
import "aos/dist/aos.css";
import whoweare from "../../asset/images/aboutus/whoweare_sideimg.webp";


import tech from "../../asset/images/aboutus/tech4.svg";
import valu from "../../asset/images/aboutus/val1.svg";
import team from "../../asset/images/aboutus/team.svg";

import Whoweare from "../../asset/images/new_banner/who_we_are_banner.webp"

export class VideoModal extends Component {


  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    Aos.init({ duration: 1000 });
  }

  render() {
    return (
      <>


        <Helmet>
          <meta charset="UTF-8" />
          <h1>Customized, Optimized And Enhanced Research Operations</h1>
          <title>Team of Experts in Financial Services & Computing </title>
          <meta name="description" content="Leading & Trusted Partner in financial market professionals. A full services consulting company for financial institutions around the world. Contact Us Now!" />
          <meta name="keywords" content="artificial intelligence solutions company
Private companies ESG data provider
Research company
Staff Augmentation
staff outsourcing solutions" />
          <meta name="author" content="Decimal Point Analytics" />
          <link rel="canonical" href="https://www.decimalpointanalytics.com/who-we-are" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

        <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + Whoweare + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={3} md={4} sm={5} xs={8}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">Who We Are</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Customized, Optimized and<br /> Enhanced Research Operations</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className='section2 sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className="al_center synopsic_bx">
                  <Typography variant='h6' className='black jst'>
                    Since its inception in March 2003, Decimal Point Analytics has been a trusted partner to an entire spectrum of financial market professionals. We are thrilled to be able to deliver innovation in research processes through a team of experts in financial services and computing. By utilizing and scaling the values we bring into the engagements, our customers have been able to delight their end-customers and garner competitive advantage in the process. Our team based in India shares a common vision and pedigree. It includes highly experienced professionals with advanced degrees in engineering, math, business and financial domain. The research wing is often complemented by a team of equally capable professionals at client locations with a common objective of delivering value to clients.
                  </Typography>
                </Box>
              </Grid>

            </Grid>
          </Container>
        </Box>


        <Box className='section' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='al_center'>
                  <Typography variant='h2' className='black'>
                    What We Do
                  </Typography>
                </Box>
              </Grid>
            </Grid>


            <Grid container spacing={1} alignItems="center">
              <Grid item lg={7} md={6} sm={6} xs={12} >
                <Box>
                  <Typography variant='h6' className='black jst'>
                    Decimal Point Analytics Private Limited (DPA) is a leading firm specializing in Data Analytics, AI, and technology-driven solutions. We provide customized analytics backed by advanced technology services, and AI-driven insights to clients worldwide. Our expertise spans data processes, financial and statistical modeling, machine learning and GenAI, backed by engineering skillsets needed to create custom analytical solutions and services.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    Our team comprises a unique mix of domain and technology experts, each equipped with a futuristic outlook and a realistic approach to solving complex business problems. We are proud to serve a distinguished list of clients across the USA, UK, Africa, and India. With a rich history of serving BFSI clients including institutional asset managers, insurance firms, banks, and financial data providers, DPA is now diversifying its client base across sectors like CPG, manufacturing and healthcare. With over 850 consultants and engineers, DPA has successfully supported all its clients by building a significant global workforce.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    DPA's commitment to innovation has been recognized by leading industry bodies. Oracle Global Leaders named us the "ISV of the Year 2023," and we have also been awarded the title of "Indian SME of the Year" by the Indo-French Chamber of Commerce and Industry (IFCCI). Additionally, DPA has been acknowledged for excellence in innovation by ET Edge in 2023 and prominently featured in Dun & Bradstreet's "Leading SMEs of India 2024, 2022." CEO Insights magazine has also recognized us as one of the "Top 10 Brands in Finance."
                  </Typography>


                </Box>
              </Grid>
              <Grid item lg={5} md={6} sm={6} xs={12} >
                <Box className='al_right'>
                  <img loading="lazy" src={whoweare} className='topbanner' alt='Who We Are' />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* <Box className='section sec_bg ourstrength' component='section'>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_center'>

                  <Typography className='black' variant='h2'>
                    So, Why Decimal
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className="mob_card" mt={3}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/technologyedge'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img loading="lazy"  src={tech} className='bxicon' alt='TEch' />
                      </Box>
                      <Typography className='black w100' variant='h4'>The Technology Edge</Typography>
                    </Box>
                  </Link>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/valueproposition'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img loading="lazy"  src={valu} className='bxicon' alt='value' />
                      </Box>
                      <Typography className='black w100' variant='h4'>Value Proposition</Typography>
                    </Box>
                  </Link>
                </Grid>



                <Grid item lg={4} md={4} sm={6} xs={12} >
                  <Link to='/management'>
                    <Box className='str_bg'>
                      <Box mb={1}>
                        <img loading="lazy"  src={team} className='bxicon' alt="team" />
                      </Box>
                      <Typography className='black w100' variant='h4'>Management Team</Typography>
                    </Box>
                  </Link>
                </Grid>


              </Grid>
            </Box>


          </Container>
        </Box> */}



        <Box className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <Box>

                  <Typography variant='h2' className='black'>
                    Watch Our Story
                  </Typography>
                  <Typography variant='h6' className='black jst'>
                    This video captures our unique Value Proposition and how and why we are transforming research operations and analytics needs of investment management and financial intermediation businesses.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} >
                <Box className='al_center' style={{ position: 'relative', }}>
                  <Box className='videobg'>
                    <button onClick={this.openModal} class="pulse-button"></button>
                  </Box>
                </Box>

                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="0mXcQHdxIYM"
                  onClose={() => this.setState({ isOpen: false })}
                />

              </Grid>
            </Grid>
          </Container>
        </Box>

      </>
    );
  }
}

export default VideoModal;
