import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://api-betadpa.decimalpointanalytics.com/",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

;

export default client;
