import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Quote from "../../../asset/images/media/press_release/quote.webp"

export default function CybersecurityInFintech() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Advancements in Cybersecurity: AI and Quantum-Resistant Strategies </title>
                <meta name="description" content="Explore how Shailesh Dhuri leads innovations in cybersecurity with AI and quantum-resistant techniques. Enhance cyber threat detection with quantum-safe AI." />
                <meta name="keywords" content="cybersecurity, AI, quantum computing, encryption, cyber threats, digital security." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Cybersecurity in Fintech: Ensuring Data Protection and Privacy in a World of Connectivity
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO,  <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, has recently shared a quote on the title ‘Cybersecurity in fintech: Ensuring data protection and privacy in a world of connectivity’ which has been featured on <a href='https://content.techgig.com/it-security/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity/articleshow/107081925.cms' target='_blank' rel="noopener noreferrer">TechGig</a>. In the quote, he sheds light on the rising cybersecurity challenges in India's fintech sector. He emphasizes the necessity of a zero-trust security architecture with AI monitoring. The move towards Banking as a Service (BaaS) and exploration of quantum communication further underscores the critical need to protect sensitive financial data in a world of increasing connectivity.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <a href='https://content.techgig.com/it-security/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity/articleshow/107081925.cms' target='_blank' rel="noopener noreferrer">Click here</a> to read the full coverage
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={7} md={8} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={Quote} className='fullimg' alt='Quote' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://content.techgig.com/it-security/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity/articleshow/107081925.cms" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TG + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box> */}


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
