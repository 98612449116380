import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function LaunchoftheGCC() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>GCC Policy 2025-30: Driving Global Growth in GIFT City</title>
                <meta name="description" content="Our SVP, Sameer Kulkarni, attended the GCC Policy 2025-30 launch in GIFT City, Gujarat. Explore key discussions on policy vision, strategic initiatives, and GIFT City’s role in driving global financial and tech innovation." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/gcc-policy-2025-launch" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Launch of the Global Capability Center (GCC) Policy 2025-30
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>
                        <Typography variant='h6' className='black jst'>
                            <strong>Sameer Kulkarni, Senior Vice President</strong> of Decimal Point Analytics, attended the <strong>Global Capability Center (GCC) Policy 2025-30 Launch</strong> in <strong>GIFT City, Gujarat.</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event, organized by the <strong>Department of Science & Technology, Government of Gujarat,</strong> marked a significant step in shaping Gujarat as a premier hub for <strong>Global Capability Centers (GCCs).</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Key Discussions and Insights</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The launch event featured discussions on:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Vision and Objectives of the GCC Policy 2025-30</strong> – A roadmap outlining the long-term goals and impact of the policy. 
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Gujarat’s Strategic Initiatives to Attract and Expand GCC Operations</strong> – Policies and incentives designed to enhance the state's appeal as a GCC destination. 
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Role of GIFT City in Fostering a Global Financial and Technology Hub</strong> – The infrastructure and regulatory framework supporting innovation and investment.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Industry and Policymaker Insights on Enabling Business Growth</strong> – Perspectives from key stakeholders on accelerating the GCC ecosystem. 
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Notable Attendees</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                        The launch was graced by <strong>Hon. Chief Minister of Gujarat, Shri Bhupendra Patel,</strong> along with prominent dignitaries including: 
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Shri Tapan Ray</strong> – Managing Director & Group CEO, GIFT City 
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                    <strong>Ms. Debjani Ghosh</strong> – Independent Director, GIFT City & Member, NITI Aayog 
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Looking Ahead</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                        The GCC Policy 2025-30 sets the stage for Gujarat to emerge as a global leader in financial and technological capabilities. With a strong policy framework, world-class infrastructure, and proactive governance, GIFT City is poised to attract major players in the GCC ecosystem. 
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Decimal Point Analytics partners with leading GCCs, providing specialized expertise and strategic insights. To learn more about our capabilities and how we can support your business, connect with our experts by filling out our form</strong> <Link to="/contact-us#contact_form" className='link'>here</Link>.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
