import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn"; 
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from '@material-ui/icons/Facebook';
import ThreadsIcon from '../asset/images/threads.svg';
import TwitterNewIcon from '../asset/images/twitter_new.webp';
 

import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function SBAFooter() {


  return (
    <>
      <Box
        className="footer al_center"
        component="section"
        style={{ backgroundColor: "#fff" }}
      >
        <Container maxWidth="lg"> 


          <Box className="social-icons"   mb={2}>

            <a href="https://www.linkedin.com/company/decimalpointanalytics/" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="linkedin-icon">
                <LinkedInIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://twitter.com/DecimalP" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="twitter-icon">
                <img loading="lazy"  src={TwitterNewIcon} alt="Twitter logo here" />
              </IconButton>
            </a>

            <a href="https://www.instagram.com/decimalpointanalytics/" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="instagram-icon">
                <InstagramIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.youtube.com/c/DecimalPointAnalytics" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="youtube-icon">
                <YouTubeIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.facebook.com/decimalpointanalyticspl" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="facebook-icon">
                <FacebookIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.threads.net/@decimalpointanalytics" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="threads-icon">
                <img loading="lazy"  src={ThreadsIcon} alt="threds logo here" />
              </IconButton>
            </a>

          </Box>



          <Divider />

          <Box className="footer-base">

            <Box className="black al_center">
              <a href="mailto:info@decimalpointanalytics.com">
                <Button variant="text" className="txt_btn w100 flx_center" disableRipple fullWidth startIcon={<MailOutlineIcon />} size="medium">
                  info@decimalpointanalytics.com
                </Button>
              </a>
            </Box>

            <Typography variant="h6" className="black al_center">
              © Copyright {new Date().getFullYear()} Decimal Point Analytics Private Limited. 
              {/* All Rights  Reserved. */} 
            </Typography>

          </Box>
        </Container>
      </Box>


    </>
  );
}
