import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { esgFormApi } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export default function ViewFullBlog(props) {

    const schema = yup.object().shape({
        name: yup.string().typeError("Required field").required("Name is required"),
        email: yup.string().typeError("Required field").required("Email is required").email().max(75),
        company: yup.string().typeError("Required field").required("Company name is required"),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    let report_type = "Blog";
    try {
        report_type = props.report_type;
    } catch (e) {
    }

    const onSubmit = async (data) => {
        const payload = {
            email: data.email,
            report_for: props.reportby,
            report_type: report_type
        };

        if (data.name.trim() !== "") {
            payload.name = data.name;
        }

        if (data.company.trim() !== "") {
            payload.company = data.company;
        }

        const d1 = await esgFormApi(payload) 
        if (d1.data.status === true) {
            window.open(
                props.link.toString(),
                "_blank"
            );
        }
        else {
            toast.error("Please Enter Valid details before Downloading", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };


    return (
        <>
            <Box>
                <Grid container spacing={2}>

                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Typography variant="h4" className="blue jst">
                                Learn More...
                            </Typography>
                        </Box>
                    </Grid> */}

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Name *"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="standard"
                                        fullWidth
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="company"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Company Name *"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        error={!!errors.company}
                                        helperText={errors.company?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="al_center" mt={1}>
                            <Button variant="contained" className="dpabtn_2" type="submit" onClick={handleSubmit(onSubmit)}>
                                Download Now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );

}
