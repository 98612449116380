import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'
import Writemessage3 from '../../../Component/Writemessage3';
import { Link } from 'react-router-dom';

import IMG1 from "../../../asset/images/insight/insight_pages/oracle_article_1.avif";
import IMG2 from "../../../asset/images/insight/insight_pages/oracle_article_2.webp";


export default function DPAOvercomesProjectComplexity() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>



            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                DPA overcomes project complexity, improves performance with Autonomous Database
                            </Typography>
                        </Grid>
                    </Grid>



                    <Box className='whitebx '>


                        <Typography variant='h6' className='black jst ' gutterBottom> <Link to="/" className='link'>Decimal Point Analytics Private Limited (DPA)</Link> is a full-service management consultant in financial research analytics, providing customized solutions to clients across the globe. The company has extensive management consultancy service capabilities in the key areas of financial research, data analytics, valuation models, risk management, and related solutions. </Typography>


                        <Typography variant='h6' className='black jst ' gutterBottom> DPA’s solutions offer a wide range of value additions to client business across qualitative and quantitative metrics, combining deep multi-domain expertise in finance and technology. It provides customized and one-stop solutions, bringing cost and time efficiencies to client businesses through the use of automation and technology.  </Typography>

                        <Typography variant='h6' className='black jst' > DPA’s list of clients includes some of the most prestigious institutional asset managers, risk managers, family offices, and data providers across geographies, supported by a workforce of more than 800 consultants spread across the USA, UK, and India. </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom> DPA took on the project of replacing a complex multi-database architecture on Amazon Web Service (AWS) using MySQL and Mongo Atlas with a single converged Autonomous Database, preserving a large percentage of application code using Oracle Database API for Mongo DB. </Typography>


                        <Typography variant='h6' className='black jst'>
                            The project used the following suite of products:
                            <ul>
                                <li> <a href='https://www.oracle.com/autonomous-database/' target="_blank" rel="noopener noreferrer"> Oracle Autonomous Database </a> </li>
                                <li> <a href='https://www.oracle.com/database/' target='_blank' rel="noopener noreferrer"> Oracle Database API for Mongo DB, SQL/JSON </a> </li>
                                <li> <a href='https://www.oracle.com/cloud/' target='_blank' rel="noopener noreferrer">  Oracle Cloud Infrastructure (OCI) </a> </li>
                                <li> <a href='https://www.oracle.com/cloud/compute/' target='_blank' rel="noopener noreferrer">  OCI Compute  </a> </li>
                                <li> <a href='https://docs.oracle.com/en-us/iaas/Content/Balance/Concepts/balanceoverview.htm' target='_blank' rel="noopener noreferrer">  OCI load balancers  </a> </li>
                                <li> <a href='https://docs.oracle.com/en-us/iaas/Content/Object/Concepts/objectstorageoverview.htm' target='_blank' rel="noopener noreferrer">  Oracle Object Storage  </a> </li>
                            </ul>
                        </Typography>




                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Challenges with the legacy environment  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            One of the key value propositions of DPA’s solution is the capability of custom deliverables and the augmentation and enrichment of information from various sources They present this information in a customer-friendly self-service manner to help their clients make best-informed decisions in near real-time. The old architecture, deployed on AWS, was a complex data-processing workflow consisting of a managed AWS RDS MySQL Database and MongoDB Atlas to address the business requirement of data consistency and accuracy, while providing the information in flexible JSON format to their customers.
                        </Typography>



                        <Box className='al_center' mt={4} mb={4}>
                            <img loading="lazy"  src={IMG1} className='topbanner2' alt='asset tokenization' />
                            <Box pt={2}>
                                <Typography variant='h6' className='black al_center'> Figure 1: A graphic depicting the workflow for the old architecture.  </Typography>
                            </Box>
                        </Box>





                        <Typography variant='h6' className='black jst' gutterBottom> Multiple source systems continuously loaded data in S3 Object Storage buckets in varying formats, which required data pipelines to load the individual data sets in a standardized format into the relational database. Relational structures (primary-foreign key constraints) ensured data integrity and quality across the various data sources. Extracting the data in JSON format from MySQL was not performant and scalable, so another single-purpose database, MongoDB Atlas, was added to the architecture. This required adding an extra processing step to the architecture, extracting the data from MySQL and loading it in MongoDB Atlas for the external APIs for end-user access.  </Typography>



                        <Typography variant='h6' className='black jst'>
                            Multiple fragmented systems were coupled together, increasing the complexity of data load, transformation, and synchronization. This architecture was not only complex, but wasted resources and was error-prone. It had the following complications:
                            <ul>
                                <li> The complexity increased the chances of data processing errors, leading to lower data quality and less than 100% accuracy. </li>
                                <li> The complexity increased the processing times, leading to suboptimal time-to-production. </li>
                                <li>  The complexity led to significant operational overhead, wasting time and resources on problem-solving and debugging. </li>
                            </ul>
                        </Typography>






                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Why Decimal Point Analytics chose Oracle  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            DPA was looking for a more innovative, future-proof architecture and cloud platform to address their existing business challenges and to grow and innovate faster with the future architecture.  </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            With its converged database capabilities to support relational and document-store requirements within a single database, Oracle Autonomous Database running on OCI was the core platform to achieve DPA's goals. Oracle Autonomous Database enabled DPA to store the raw data as-is in its relational format with enforced data integrity while retrieving the data for the end-user applications in the required JSON format in milliseconds. The data was readily available through SQL and JSON and through Oracle Database API for MongoDB, without any further processing. </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>
                            “The Oracle Cloud Lift Services team has exceeded our expectations in assisting us with our workload migration to OCI,” said Shailesh Dhuri, CEO of Decimal Point Analytics. “Their expertise and guidance helped us fully utilize OCI's capabilities, and they made valuable suggestions to improve the architecture of several of our applications. We greatly appreciate their attention to detail and commitment to delivering high-quality work. Throughout the process, they remained open to feedback and demonstrated exceptional skills and patience, saving us valuable time. We highly recommend Oracle Cloud Lift Services to any business seeking to migrate to OCI.” </Typography>



                        <Box className='al_center' mt={4} mb={4}>
                            <img loading="lazy"  src={IMG2} className='topbanner2' alt='asset tokenization' />
                            <Box pt={2}>
                                <Typography variant='h6' className='black al_center'> Figure 2: A graphic depicting the workflow of the new architecture running on OCI.   </Typography>
                            </Box>
                        </Box>



                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> The results  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Consolidating the former complex database architecture into a single Oracle Autonomous Database running on OCI eliminated the need to operate a complex architecture with multiple single-purpose databases and complex, synchronized ETL between these two databases, reducing the processing time while increasing the data quality and accuracy. </Typography>


                        <Typography variant='h6' className='black jst'>
                            DPA saw the following immediate business benefits:
                            <ul>
                                <li>  Improved customer satisfaction through higher quality and 15% faster time-to-production of projects. </li>
                                <li>  Increased application performance through simpler architecture and Oracle Autonomous Database capabilities. </li>
                                <li>   Significant operational cost savings of approximately 10% through less infrastructure, application, and database management.  </li>
                                <li>  Increased developer productivity to focus on innovation rather than on fixing problems. </li>
                            </ul>
                        </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom>
                            With Autonomous Data Guard and automatic scaling of Oracle Autonomous Database, the new architecture provides DPA the fault tolerance and agility needed to operate the environment mission-critical for their customers. This enables them to provide cost-effective technological solutions of high value and quality to their customers as a highly-valued, full-service management consulting company.</Typography>







                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Tokenization of assets a new paradigm for businesses" />
                    </Box>




                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
