import { Container, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';

import Infoback from '../../../Component/Infoback'
import Writemessage3 from '../../../Component/Writemessage3';


import Aos from 'aos'
import chinapdf from '../../../asset/images/insight/pdf/china_auto_finance_market.jpg'
import { Helmet } from 'react-helmet';

export default function Chinaauto() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

<Helmet>
<meta charset="UTF-8"/>
<h1>China Auto Finance Market Infographics - Key Insights and Trends" </h1>
<title>China Auto Finance Market Infographics | Decimal Point Analytics </title>
<meta name="description" content="Discover the latest trends in China's auto finance market with our infographics. Gain insights and make informed business decisions. Visit us now!" />
<meta name="keywords" content="auto financing
auto loans
automotive finance
used car financing
auto loan rates" />
<meta name="author" content="Decimal Point Analytics" />
<link rel="canonical" href="https://www.decimalpointanalytics.com/infographics/china-auto-finance-market" />

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</Helmet>

            <Box className='section sec_bg' component='section'>
                <Typography variant='h3' className='subhead skblue al_center'>
                    Infographic
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center'>
                    China Auto Finance Market
                </Typography>



                <Container maxWidth='lg' className='bdr'>

                    <Writemessage3 pageVisited="China Auto Finance Market" />

                    <Box className='whitebx'>

                        <Box className='al_center'>
                            <img loading="lazy"  src={chinapdf} className='topbanner' alt='China Auto' />
                        </Box>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                    </Box>


                    <Infoback />


                </Container>
            </Box>

        </>
    );
}
