import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import * as yup from "yup";
import React, { useState } from 'react';
import { Formik, Field, Form, } from "formik";
import { esgFormApi } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ESG_download(props) {
    const [NameError, setNameError] = useState(''); 

    const initialValues = {
        name: "",
        email: "",
        company: "",
    };
    const nameValidation = /^[A-Za-z\s]+$/;
    const validationSchema = yup.object().shape({
        name: yup.string()
            .typeError("Required field")
            .required("Name is required")
            .matches(nameValidation, "Enter valid first name"),
        email: yup.string()
            .typeError("Required field")
            .required("Email is required")
            .email("Please enter a valid email address")
            .matches(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                "Please enter a valid email address"
            ),
        company: yup.string()
            .typeError("Required field")
            .required("Company name is required"),
    });



    const handleKeyUp = (event) => {
        const value = event.target.value;
        if (/\d+/.test(value)) {
            setNameError('Name should not contain numbers');
        } else {
            setNameError('');
        }
    };

    let report_type = "ESG Insight"
    try {
        report_type = props.report_type
    } catch (e) { 
    }

    const onSubmit = async (data, { setSubmitting }) => {
        const payload = {
            email: data.email,
            report_for: props.reportby,
            report_type: report_type
        };
        if (data.name.trim() !== "") {
            payload.name = data.name;
        }

        if (data.company.trim() !== "") {
            payload.company = data.company;
        }

        try {
            const response = await esgFormApi(payload);
            if (response.data.status === true) {
                window.open(
                    props.link.toString(),
                    "_blank"
                );
            } else {
                toast.error("Please Enter Valid details before Downloading", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        if (!actions.errors || Object.keys(actions.errors).length === 0) {
                            onSubmit(values, actions);
                        } else {
                            actions.setSubmitting(false);
                        }
                    }}
                >
                    {({ handleSubmit, touched, errors }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Box className="alltxfieldlg" mt={1}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label="Name *"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                            onKeyUp={handleKeyUp}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={NameError || (touched.name && errors.name)}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Box className="alltxfieldlg" mt={1}>
                                        <Field
                                            as={TextField}
                                            name="email"
                                            label="Email *"
                                            variant="standard"
                                            fullWidth
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Box className="alltxfieldlg" mt={1}>
                                        <Field
                                            as={TextField}
                                            name="company"
                                            label="Company Name *"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                            error={touched.company && Boolean(errors.company)}
                                            helperText={touched.company && errors.company}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={12} sm={6} xs={12}>
                                    <Box className="al_center" mt={1}>
                                        <Button
                                            variant="contained"
                                            className="dpabtn_2"
                                            type="submit"
                                        >
                                            Download Now
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
}
