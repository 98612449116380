import { Box, Container, Grid, Typography, Stack } from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Newsletter_Data from "./Newsletter_data";
import calendar from "../../asset/images/newsletter/calendar.webp";


// Owl Carousel Responsive Options
const responsiveOptions = {
    0: { items: 1 },
    480: { items: 2 },
    778: { items: 3.5 },
    1025: { items: 3.5 },
    3000: { items: 5 }
};

export default function NewsletterCarousel2023() {
    // Filter newsletters for 2023
    const data2023 = Newsletter_Data.filter(item => item.year === 2023);

    let owlRef = null;

    return (
        <Box className="newsroom-banner">
            <Box className="owldot2 nav_newsletter reportinfo gradmain carousel-wrapper carousel_op carousel_2023">
                <Container maxWidth="lg" className="reportinfo">
                    <Grid container spacing={2} alignItems="center">
                        {/* Left Section */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="fx_c">
                                <Box>
                                    <img loading="lazy"  src={calendar} alt="calendar" className="calendar" />
                                </Box>
                                <Typography variant="h2" className="black mb0 newh2">
                                    2023 edition 
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Right Section - Carousel */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="carousel-overlay grad2">
                                <OwlCarousel
                                    className="owl-theme reportinfo"
                                    loop
                                    margin={10}
                                    nav={true}
                                    dots={false}
                                    autoplay={false}
                                    autoplayTimeout={4000}
                                    responsive={responsiveOptions}
                                    ref={(el) => (owlRef = el)}
                                >
                                    {/* Dynamically map the 2023 newsletter data */}
                                    {data2023.map((item, index) => (
                                        <Box key={index} m={1}>
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                <Box className="newnewsletter2023bx">
                                                    <Box
                                                        className="reportimg_bg"
                                                        style={{ backgroundImage: `url(${item.imgsrc})` }}
                                                    ></Box>

                                                    <Box mt={2} mb={1}>
                                                        <Typography className="reporttitle one">
                                                            {item.date}
                                                        </Typography>
                                                    </Box>
                                                    <Box className="line"></Box>
                                                </Box>
                                            </a>
                                        </Box>
                                    ))}
                                </OwlCarousel>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
