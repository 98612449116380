import { Container, Typography, Box, Grid, Divider, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_alternative_data_investment.webp'
// import ViewFullWhitepaperNew from '../../../Component/forms/ViewFullWhitepaperNew';

import EmployeeProfile from '../../../Component/EmployeeProfile.js';
import GG from '../../../asset/images/team/gaurav_gupta.webp'
import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper.js';

export default function AlternativeDataInvestment() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Decimal%20Point%20-%20Whitepaper%20on%20Alternative%20Investments%201%20(1).pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Alternative Data and AI in Alternative Investments | Whitepaper by Decimal Point Analytics</title>
                <meta name="description" content="Discover how alternative data and AI are revolutionizing alternative investments. Explore Decimal Point Analytics' advanced solutions for informed decision-making." />
                <meta name="keywords" content="alternative investments, AI in asset management, alternative data, machine learning, investment strategies" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/alternative-data-that-can-power-successful-alternative-investments" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="section sec_bg">
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>




                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h3' className='subhead skblue al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            Alternative Data that can Power Successful Alternative Investments
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                    Traditional investment approaches do not cut it anymore. Alternative investments offer a compelling opportunity for higher returns and portfolio diversification. But, it’s a complex landscape that needs data-driven decision-making.
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Our whitepaper, “Alternative Data that Can Power Successful Alternative Investments,” explores how AI, Machine Learning, and Big Data Analytics can revolutionize the way asset managers and investors approach alternative investments.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                Deep-dive into the exciting world of alternative investments and their growth potential
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                Explore the use of alternative data sources for deeper market insights and the wealth of benefits they offer
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                Understand the real-world applications of alternative data in investment models and the challenges of integrating these technologies
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>




                                            </Box>

                                            <Box mt={1}>
                                                <EmployeeProfile imgSrc={GG} name={"Mr. Gaurav Gupta"} designation={"Managing Partner,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/gaurav-gupta-8a472a/"} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider />

                                <Box mt={2} className='al_center'>
                                    <Typography variant="h4" className="black">
                                        Get Your Full Copy Now – Simply Fill Out the Form
                                    </Typography>
                                </Box>


                                <Box className='graybbx2222' mt={2}>
                                    <ViewFullWhitepaper reportby={"Alternative Data that can Power Successful Alternative Investments"} link={link} />
                                </Box>
                            </Box>





                        </Grid>

                        {/* <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box className='bg_websection' p={4}>
                                <Box className="web_section whitebx" >
                                    <Box mt={2} className='al_center'>
                                        <Typography variant="h4" className="black">
                                            Download the whitepaper
                                        </Typography>
                                    </Box>


                                    <Box className='graybbx2222' mt={2}>
                                        <ViewFullWhitepaperNew reportby={"Alternative Data that can Power Successful Alternative Investments"} link={link} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid> */}

                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box>
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
