import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core';


import FAQ from "../../../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function EcoPulseFaq() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>


      <Container maxWidth='lg' className='bdr'>


        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image'  mt={4}>
              <img loading="lazy"  src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box className='al_left'>
              <Typography variant='h2' className='black'>
                <strong>  FAQ </strong>
              </Typography>
            </Box>

            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> What markets does EcoPulse cover? </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    EcoPulse is a comprehensive macroeconomic indicators’ database of all the leading countries in the world.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>How frequently is the data updated?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    EcoPulse provides real-time updates of critical new data announcements and tracks revisions to previous data. It ensures that you have the latest information to make informed trading decisions. Other non-critical indicators are checked and updated periodically.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Can I customize the data feeds to suit my requirements?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, EcoPulse offers customization options, allowing you to receive feeds only for selected countries or indicators of interest. This customization feature ensures that you receive the information most relevant to your trading strategies.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How can I integrate EcoPulse into my website?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    EcoPulse provides various integration options, including website embedding and live economic calendar integration. Our team will guide you through the integration process and find the best solution for your website's needs.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Is the data provided by EcoPulse reliable and accurate?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, EcoPulse sources its data from trusted and authentic sources such as central banks, statistical commissions, and industrial associations. Our platform follows strict quality control measures to ensure the accuracy and reliability of the information provided.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Can I access historical data through EcoPulse?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, EcoPulse offers historical database access via APIs or SFTPs. This allows you to analyze past trends and patterns to enhance your trading strategies and decision-making.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>How can EcoPulse help me stay informed about market developments?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    EcoPulse provides real-time updates on critical macro-economic indicators. You will receive timely notifications about notable events, economic indicators, and other market developments, ensuring you stay informed and can act quickly.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Can I try EcoPulse before committing to a subscription?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, we offer a demo of EcoPulse to highlight its features and benefits. Contact us to schedule a demo and experience the power of EcoPulse firsthand.</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Is customer support available if I have any questions or issues?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Absolutely! Our dedicated customer support team is available to assist you with any questions, concerns, or technical issues you may have. We are committed to ensuring your experience with EcoPulse is smooth and successful.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

 


              </Box>
            </Box>
          </Grid>
        </Grid>




      </Container>




    </>


  );
}
