import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Newsletter_Data from "./Newsletter_data";
import calendar from "../../asset/images/newsletter/calendar.webp";

const responsiveOptions = {
    0: { items: 1 },
    480: { items: 2 },
    778: { items: 3.5 },
    1025: { items: 3.5 },
    3000: { items: 5 }
};

export default function NewsletterCarousel2024() {
    const data2024 = Newsletter_Data.filter(item => item.year === 2024);

    return (
      <Box className="newsroom-banner">
          <Box className="owldot2 nav_newsletter reportinfo gradmain carousel-wrapper carousel_op carousel_2024">
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="fx_c">
                            <Box>
                                <img loading="lazy"  src={calendar} alt="calendar" className="calendar" />
                            </Box>
                            <Typography variant="h2" className="black mb0 newh2">
                                2024 edition 
                            </Typography> 
                        </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="carousel-overlay">
                            <OwlCarousel
                                className="owl-theme reportinfo"
                                loop
                                margin={10}
                                nav
                                dots={false}
                                autoplay={false}
                                responsive={responsiveOptions}
                            >
                                {data2024.map((item, index) => (
                                    <Box className="carousel-item" key={index}>
                                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                                            <Box className="reportm_bg" mx={1}>
                                                <Typography variant="h6" className="reporttitle">
                                                    {item.date}
                                                </Typography>
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: `url(${item.imgsrc})`,
                                                    }}
                                                ></Box>
                                                <Box className="reportbox" mt={1}>
                                                    <Typography className="reporttitle" title={item.heading}>
                                                        {item.heading}
                                                    </Typography>
                                                </Box>
                                                <Box mt={1}>
                                                    <Typography variant="h6" className="black fw5 one" title={item.subheading}>
                                                        {item.subheading}
                                                    </Typography>
                                                </Box>
                                                <Box mt={1}>
                                                    <Button variant="h6" aria-label={`Read More about ${item.heading}`} className="read_btn">
                                                        Read More
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </a>
                                    </Box>
                                ))}
                            </OwlCarousel>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
      </Box>
    );
}
