import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import sd from "../../asset/images/team/new/shailesh_dhuri.webp";
import ps from "../../asset/images/team/new/paresh_sharma.webp";
import gg from "../../asset/images/team/new/gaurav_gupta.webp";
import narendra from "../../asset/images/team/new/narendra_joshi.webp";
import prasad from "../../asset/images/team/new/prasad.webp";
import rikesh from "../../asset/images/team/new/rikesh_patel.webp";
import shyam from "../../asset/images/team/new/shyam_pardeshi.webp";
import nikesh from "../../asset/images/team/new/nikesh_satra.webp";
import dilip from "../../asset/images/team/new/dilip_sah.webp";
import vaibhav from "../../asset/images/team/new/vaibhav_poonekar.webp";
import durga from "../../asset/images/team/new/durga_dande.webp";
import nilanjan from "../../asset/images/team/new/nilanjan_das.webp";
import ajay from "../../asset/images/team/new/ajay_jindal.webp";
import sameer from "../../asset/images/team/new/sameer_kulkarni.webp";
import pravas from "../../asset/images/team/new/pravas_sahoo.webp";
import arun from "../../asset/images/team/new/arun_pratap_singh.webp";
import ankit from "../../asset/images/team/new/ankit_rana.webp";
import rajat from "../../asset/images/team/new/rajat_batra.webp";
import suyash from "../../asset/images/team/new/suyash_shrivastava.webp";
import viresh from "../../asset/images/team/new/viresh_yadav.webp";
import AmitD from "../../asset/images/team/new/amit_dhalia.webp";
import Prerna from "../../asset/images/team/new/prerna_kharche.webp";
import Neerupa from "../../asset/images/team/new/neerupa_singhvi.webp";

import Aos from 'aos'
import "aos/dist/aos.css"

import team from "../../asset/images/new_banner/team_banner.webp"
import { Helmet } from 'react-helmet';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

// Board members data with details
const BoardMembers = [
    {
        id: 1,
        name: "Shailesh Dhuri",
        title: "Chief Executive Officer",
        image: sd,
        linkedin: "https://www.linkedin.com/in/shailesh-dhuri-68baa/",
        details: [
            "A result driven professional with nearly three decades of experience in Entrepreneurship, Fund Management and Artificial Intelligence for Data Analytics. He is a highly sought-after leader and Innovator in the space of real life applications of artificial intelligent technology that are used in massive data, quantitive, statistical analysis, and algorithms. His passion for innovation and technology driven solutions has been the backbone of Decimal Point Analytics since its inception in 2003.",
            "Shailesh has not only built strong and successful businesses of his own, but he has also supported many notable organizations in creating and sustaining a dynamic environment, even at the beginning of his career. From being instrumental in setting up Unit Trust India’s first Private Sector Bank to launching India’s first Money Market Fund back in 90’s, his vision for future has remained unparalleled to date.",
            "He is an alumnus of some of the most prestigious educational institutes in India and yet considers himself to be a forever student of mathematics and Philosophy. His strong academics include an MBA from IIM Bangalore, FRM from GARP, PRM from PRMIA, CFA from ICFAI, CWA from ICWAI and Bachelor of Commerce from Mumbai University are the key founding pillars of his career as an analyst and strategist of par excellence.",
            "He currently overlooks Growth Strategy, Financial Planning, Corporate Governance and Risk Analysis for Decimal Point Analytics, Algocircle and Advanced Investment Mechanics in the capacity of CEO and as an honorable board member. With his strong business acumen, he is focused on building new industry verticals by delivering excellent Business Strategy, Audit Plans and Risk Analysis for his current as well future ventures."
        ]
    },
    {
        id: 2,
        name: "Paresh Sharma",
        title: "Managing Partner",
        image: ps,
        linkedin: "https://www.linkedin.com/in/paresh-sharma-b615ba5/",
        details: [
            "Paresh Sharma is the Managing Partner at Decimal Point Analytics and a driving force behind the company's Technology and Data Science. With years of experience under his belt, Paresh has a strong background in technology, equities, and research, having previously served as the Head of Equities and the Head of Research at a prominent fund house in India.",
            "A highly accomplished scholar, having completed his engineering from IIT-BHU and management from IIM-Bangalore. He is a passionate advocate of long-term investing and the transformative power of technology, always seeking new and innovative ways to combine the two.",
            "At Decimal Point Analytics, Paresh leads a team dedicated to providing clients with cutting-edge technology and solutions to help them navigate the ever-changing landscape of the financial industry. His visionary leadership and strategic vision have been instrumental in driving the company's growth and success."
        ]
    },
    {
        id: 3,
        name: "Gaurav Gupta",
        title: "Managing Partner",
        image: gg,
        linkedin: "https://www.linkedin.com/in/gaurav-gupta-8a472a/",
        details: [
            "As one of the co-founders and the managing director at Decimal Point Analytics, Gaurav has been a key player in the growth and success of the company. A hands-on leader, he is closely involved in building the company's offerings and overseeing business development. Gaurav firmly believes in the power of creativity and innovation to transform financial markets, and he brings over two decades of experience as an active participant in global financial and capital markets to his role.",
            "Before founding Decimal Point Analytics, Gaurav managed assets for India's pioneering and largest asset manager. He leverages this extensive experience and deep expertise to help his team navigate the complexities of financial markets and deliver exceptional solutions to clients.",
            "One of Gaurav's greatest strengths is his ability to build meaningful relationships with financial services leaders. He relishes collaborating with these individuals and tackling their most pressing challenges using cutting-edge solutions provided by Decimal Point Analytics. Gaurav's passion for excellence and his unwavering commitment to his team and clients are essential drivers of the company's growth and success."
        ]
    },
    {
        id: 4,
        name: "Narendra Joshi",
        title: "Director",
        image: narendra,
        linkedin: "https://www.linkedin.com/in/narendrajoshi/",
        details: [
            "Narendra Joshi specializes in business development, strategic partnerships, and revenue growth. At Decimal Point Analytics, he drives client acquisition, market expansion, and relationship management to enhance business opportunities.",
            "With expertise in financial services and consultative sales, he focuses on building long-term partnerships and leveraging data-driven insights to align solutions with client needs, ensuring sustainable business growth."
        ]
    },
    {
        id: 5,
        name: "Rikesh Patel",
        title: "President",
        department: "Head of Operations",
        image: rikesh,
        linkedin: "https://www.linkedin.com/in/rikeshspatel/",
        details: [
            "Rikesh Patel is a seasoned operations leader with over 18 years of experience in driving business transformation, operational excellence, and AI-driven solutions. At Decimal Point Analytics, he oversees a team of 480+ professionals, managing research, data analytics, and technology-driven initiatives.",
            "With expertise in data science, FinTech, and business intelligence, he leads large-scale projects, fosters innovation, and optimizes business processes to accelerate revenue growth and enhance client success globally."
        ]
    },
    // {
    //     id: 6,
    //     name: "Dilip Kumar Sah",
    //     title: "Chief Technology Officer",
    //     image: dilip,
    //     linkedin: "https://www.linkedin.com/in/dilipksah/",
    //     details: [
    //         "An experienced technology leader, Dilip Kumar Sah specializes in AI, innovation, and startup scaling. At Decimal Point Analytics, he leads technology strategy, product innovation, and platform development.",
    //         "With expertise in Generative AI and cross-functional leadership, he focuses on developing AI-driven financial solutions, enhancing product roadmaps, and ensuring seamless project execution to drive revenue growth and client satisfaction."
    //     ]
    // },
    {
        id: 7,
        name: "Arun Pratap Singh",
        title: "Chief People Officer",
        image: arun,
        linkedin: "https://www.linkedin.com/in/arunpsingh/",
        details: [
            "Arun Pratap Singh is a dynamic HR leader with over 15 years of experience in shaping people strategies and driving organizational transformation. A postgraduate in Human Resource Management from TISS, he has successfully led HR functions across large organizations, fostering high-performance cultures through strategic talent management, acquisition, rewards, and performance management.",
            "With expertise spanning Talent Management, Employee Engagement, HR Digitalization, and Business Transformation, Arun’s people-first approach has been instrumental in optimizing workforce strategies and enhancing organizational efficiency. He holds multiple industry-recognized certifications, including HR Excellence Assessor (CII), Reward Professional (Aon Hewitt), Psychometric Tools Practitioner (DISC – Thomas International), and HR Analytics & Data Mining (IIT Roorkee).",
            "Passionate about HR innovation, he continuously integrates technology and strategy to build agile, future-ready teams that drive business success."
        ]
    },
    {
        id: 8,
        name: "Neerupa Singhvi",
        title: "Chief Financial Officer",
        image: Neerupa,
        linkedin: "https://www.linkedin.com/in/ca-neerupa-bafna-25845a1ab/",
        details: [
            "Neerupa Singhvi is a Chartered Accountant with expertise in financial strategy, cash flow management, investor relations, and compliance. As CFO at Decimal Point Analytics, she leads financial planning, risk mitigation, and reporting accuracy to drive operational efficiency.",
            "With a strong focus on optimizing investment structures and ensuring regulatory compliance, she plays a key role in aligning financial strategy with business growth objectives."
        ]
    },
    {
        id: 9,
        name: "Durga Dande",
        title: "Executive Vice President",
        department: "Research & Customer Value Impact",
        image: durga,
        linkedin: "https://www.linkedin.com/in/durgadande/",
        details: [
            "With over a decade of dedicated service at Decimal Point Analytics, Durga Dande has established herself as a cornerstone of our organization's success. As a senior leader in our client engagement practice, she has built a dynamic think tank team, transforming high-value opportunity pursuits.",
            "Durga's expertise spans across research, ESG analytics, and comprehensive financial management—insights she applies daily to anticipate client needs and develop innovative solutions that deliver tangible business results. Durga advances our services while staying committed to delivering client success."
        ]
    },
    {
        id: 10,
        name: "Nilanjan Das",
        title: "Executive Vice President",
        department: "Business Development",
        image: nilanjan,
        linkedin: "https://www.linkedin.com/in/nilanjan-das-cfa-284ab64/",
        details: [
            "With over 30 years of experience in the financial industry, Nilanjan specializes in asset management, capital markets, and derivatives trading. At Decimal Point Analytics, he leads business development, driving global client engagement and delivering innovative financial solutions for alternative asset managers.",
            "His expertise spans commercial lending, equity & credit research, portfolio management, and quantitative trading, ensuring impactful strategies that enhance client value across the US and India."
        ]
    },
    {
        id: 11,
        name: "Ajay Jindal",
        title: "Executive Vice President",
        image: ajay,
        linkedin: "https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/",
        details: [
            "Ajay Jindal is a seasoned finance professional with nearly 30 years of experience. After early roles as a research analyst in investment banking and institutional equities, he worked in various leadership roles in India’s largest print media firm, The Times of India Group, and spearheaded entrepreneurial ventures.",
            "At Decimal Point Analytics, he leads strategic partnerships and drives business growth, leveraging his deep industry expertise and CXO-level engagement skills."
        ]
    },
    {
        id: 12,
        name: "Prasad Nawathe",
        title: "Senior Vice President",
        department: "Investor Relations & Client Success",
        image: prasad,
        linkedin: "https://www.linkedin.com/in/prasad-nawathe-009/",
        details: [
            "Prasad Nawathe leads investor relations and client success at Decimal Point Analytics, driving enhanced investor and client experiences, stronger relationships, and revenue growth. He focuses on maximizing client value through a comprehensive service approach, ensuring high CSAT scores, and fostering long-term engagement.",
            "By spearheading initiatives to boost recurring revenue, he strengthens customer confidence and conducts Value Lab sessions to generate actionable business insights for sustainable growth."
        ]
    },
    {
        id: 13,
        name: "Pravas Kumar Sahoo",
        title: "Senior Vice President",
        department: "Valuation & Advisory",
        image: pravas,
        linkedin: "https://www.linkedin.com/in/pravas-kumar-sahoo-22b62378/",
        details: [
            "Leading a team of 60+ professionals across projects in Equity Research, Quants, Analytics, and Consulting, Pravas Kumar Sahoo specializes in valuations and strategic advisory. At Decimal Point Analytics, he drives data-driven insights, financial modeling, and risk assessment to deliver precise valuations and impactful advisory solutions that support informed investment decisions.",
            "He holds an MBA in Finance from IIM Visakhapatnam, further strengthening his expertise in financial analysis and investment strategies."
        ]
    },
    {
        id: 14,
        name: "Shyam Pardeshi",
        title: "Senior Vice President",
        department: "Research & Data Operations",
        image: shyam,
        linkedin: "https://www.linkedin.com/in/shyam-pardeshi-1a149623/",
        details: [
            "With 18+ years of experience, Shyam Pardeshi specializes in research operations, project management, process automation and client management. At Decimal Point Analytics, he leads a team of 70+ research professionals driving research and data operation initiatives, ensuring efficiency through innovation and automation.",
            "With expertise in financial data tools, Python, SQL, and VBA, he streamlines workflows, enhances decision-making, and drives operational excellence across research and data functions."
        ]
    },
    {
        id: 15,
        name: "Suyash Shrivastava",
        title: "Senior Vice President",
        department: "Database Architect",
        image: suyash,
        // linkedin: "#",
        details: [
            "Suyash Shrivastava is a seasoned expert in BI, data analytics, data management, and architecture with over 17 years of experience. At Decimal Point Analytics, he leads the Database and ETL Development department, ensuring seamless data integration, warehousing, and business intelligence solutions.",
            "With expertise in SQL, NoSQL, cloud platforms, and dimensional modeling, he drives efficient data strategies, optimizes infrastructure, and enhances system performance to support enterprise-wide analytics initiatives."
        ]
    },
    {
        id: 16,
        name: "Sameer Kulkarni",
        title: "Senior Vice President",
        department: "IT Infra, Cloud, Security",
        image: sameer,
        linkedin: "https://www.linkedin.com/in/sameer-vasant-kulkarni/",
        details: [
            "Leading IT infrastructure, cloud, and security initiatives, Sameer Kulkarni brings deep expertise in technology management, networking, and cybersecurity. As Air Force veteran with 28 years in the ICT domain, he brings in disciplined and strategic approach in ensuring cyber resilient information systems, robust data center operations, seamless wired/wireless communications, and SOC/NOC operations.",
            "At Decimal Point Analytics, he drives governance, risk and compliance (GRC) management leveraging his experience in ITILv4-compliant service desk management, security policy consolidation, and robust data governance to strengthen network security and operational excellence."
        ]
    },
    {
        id: 17,
        name: "Vaibhav Poonekar",
        title: "Senior Vice President",
        department: "Automation & Development Lead",
        image: vaibhav,
        linkedin: "https://www.linkedin.com/in/vaibhavpoonekar/",
        details: [
            "Vaibhav Poonekar specializes in automation, workflow optimization, and scalable product engineering. At Decimal Point Analytics, he leads the development of SaaS-based financial technology solutions, leveraging No-Code/Low-Code tools to empower business users with AI-driven data management capabilities.",
            "With expertise in strategic planning, project execution, and innovation, he drives digital transformation by enhancing operational efficiency, optimizing workflows, and delivering high-impact technology solutions."
        ]
    },
    {
        id: 18,
        name: "Viresh Yadav",
        title: "Senior Vice President",
        department: "APAC",
        image: viresh,
        linkedin: "https://www.linkedin.com/in/viresh-yadav-14495423/",
        details: [
            "Viresh Yadav is a seasoned sales leader with 13+ years of experience in managing enterprise relationships and driving digital transformation sales. At Decimal Point Analytics, he focuses on strategic account planning, consultative selling, and pipeline generation to expand market presence.",
            "With expertise in articulating value propositions and fostering client partnerships, he ensures revenue growth, customer satisfaction, and long-term business success in India and the Gulf region."
        ]
    },
    {
        id: 19,
        name: "Nikesh Satra",
        title: "Senior Vice President",
        department: "Research & Operations",
        image: nikesh,
        linkedin: "https://www.linkedin.com/in/nikesh-satra-54409111/",
        details: [
            "Nikesh Satra spearheads research and operations at Decimal Point Analytics, playing a pivotal role in advancing data-driven decision-making through strategic initiatives. With a proven track record in project execution, client engagement, and operational excellence, he ensures the seamless delivery of high-quality research solutions.",
            "Committed to driving revenue growth and enhancing customer satisfaction, Nikesh oversees project management, process optimization, and talent development, fostering a robust and innovative research and analytics function."
        ]
    },
    {
        id: 20,
        name: "Ankit Rana",
        title: "Vice President",
        department: "Research & Data Operations",
        image: ankit,
        linkedin: "https://www.linkedin.com/in/ankit-rana-052b8a93/",
        details: [
            "Ankit Rana has 12+ years of expertise in operational excellence, process optimization, and client management. At Decimal Point Analytics, he oversees a team of 85 analysts handling pre-qualification, underwriting, closing, and loan servicing for U.S.-based clients in CRE and SBA lending.",
            "His role involves strategic client management, ensuring high-quality service delivery, optimizing workflows, and fostering strong client partnerships to drive business success."
        ]
    },
    {
        id: 21,
        name: "Rajat Batra",
        title: "Vice President",
        department: "Data Science & AI",
        image: rajat,
        linkedin: "https://www.linkedin.com/in/rajatbatra91/",
        details: [
            "Rajat leads the Data Science & AI function at Decimal Point Analytics. His primary focus is to oversee all AI and data science related aspects across our projects and to drive strategy for developing analytical and Gen-AI solutions to enhance our consulting capabilities. Rajat's team is responsible for developing and deploying AI & ML models to solve complex business challenges.",
            "Rajat completed his B.Tech from IIT Delhi and has cleared CFA Level-2 and FRM certifications. In a career spanning over 12 years, he has previously worked across quant, consulting, banking and product-focused roles within the fin-tech space in leading organizations including Blackrock, Deloitte, Natwest Group and Morningstar. He joins us from Nasdaq where he was responsible for setting up and leading the Regulatory Tech function as their Director of Technology."
        ]
    },
    {
        id: 22,
        name: "Amit Dhalia",
        title: "Vice President",
        department: "Business Intelligence & Automation",
        image: AmitD,
        linkedin: "https://www.linkedin.com/in/amit-dhalia-49805547/",
        details: [
            "Amit Dhalia specializes in business intelligence, automation, and AI-driven data solutions. With expertise in data science, ML, and Python, he has led automation initiatives, including financial data extraction using OCR and LLM-powered data transformation.",
            "His strategic leadership in ETL automation and Power BI analytics drives efficiency and innovation. At Decimal Point Analytics, he focuses on expanding AI projects, enhancing BI credibility, and delivering intelligent automation solutions."
        ]
    },
    {
        id: 23,
        name: "Prerna Kharche",
        title: "Senior Manager",
        department: "Finance",
        image: Prerna,
        linkedin: "https://www.linkedin.com/in/prerna-kharche/",
        details: [
            "Prerna Kharche is a finance professional with expertise in financial control, compliance, and process optimization. A Certified Professional from the Association of Chartered Certified Accountants (ACCA) UK, she works closely with the CFO and CEO at Decimal Point Analytics to strengthen financial oversight and streamline cash flow management.",
            "She has played a key role in accelerating MIS delivery timelines, reducing reporting cycles from the 15th to the 5th working day, enhancing decision-making agility. Additionally, she has led the automation of MIS dashboards in Excel, significantly improving accuracy and turnaround time."
        ]
    },

];

// Dialog component to show member details
const MemberDialog = ({ member, open, onClose }) => (
    <Dialog open={open} onClose={onClose} maxWidth="md" className="b_modal teammodal">
        <DialogTitle className="feedname" id="customized-dialog-title" onClose={onClose}>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box mt={4}>
                        <Box className='teama' style={{ backgroundImage: `url(${member.image})` }}></Box>
                    </Box>
                </Grid>
                <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box mt={3}>
                        <Typography className='teaminfo blue al_left'>
                            {member.name}
                        </Typography>
                    </Box>
                    <Box className='line'></Box>

                    {/* <Box mt={1}>
                        <Typography variant='h6' className='black al_left'>
                            {member.title} - {member.department}, <br />
                            Decimal Point Analytics Pvt. Ltd.
                        </Typography>
                    </Box> */}

                    <Box mt={1}>
                        <Typography variant="h6" className="black al_left">
                            {member.title}
                            {member.department ? ` - ${member.department}` : ""}
                            {", "} <br />
                            Decimal Point Analytics Pvt. Ltd.
                        </Typography>
                    </Box>


                    <Box style={{ marginTop: '-10px', marginLeft: '-4px' }}>
                        <a href={member.linkedin} target='_blank' rel="noopener noreferrer">
                            <IconButton aria-label="delete" size="small">
                                <LinkedInIcon fontSize="small" color="primary" />
                            </IconButton>
                        </a>
                    </Box>
                </Grid>
            </Grid>


            <Grid container spacing={2} alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box mt={3}>

                        {member.details.map((paragraph, index) => (
                            <Typography variant='h6' className='black jst' key={index}>
                                {paragraph}
                            </Typography>
                        ))}

                    </Box>
                </Grid>
            </Grid>

        </DialogContent>
    </Dialog>
);

// Main component that renders the board members
const Management = () => {
    const [selectedMember, setSelectedMember] = useState(null);

    const handleOpenDialog = (member) => {
        setSelectedMember(member);
    };

    const handleCloseDialog = () => {
        setSelectedMember(null);
    };

    const firstThree = BoardMembers.slice(0, 3);
    const remainingMembers = BoardMembers.slice(3);


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <title>Decimal Point Analytics Management Team </title>
                <meta name="description" content="Discover our management team at Decimal Point Analytics, offering a unique blend of financial services domain expertise and advanced computational skills." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/management" />
            </Helmet>



            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + team + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Our Team</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Unique Combination of Financial Services Domain Expertise and Computational skills</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section sec_bg' component='section'>
                <Box mt={2} mb={2}>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Executive Board
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="center" alignItems='center'>
                            {firstThree.map((member) => (
                                <Grid item key={member.id} lg={4} md={4} sm={6} xs={12} className='dpateam'>
                                    <Box
                                        onClick={() => handleOpenDialog(member)}
                                        className='tmlink'
                                    >
                                        <Box mt={4}>
                                            <Box className='team' style={{ backgroundImage: `url(${member.image})` }}></Box>
                                        </Box>

                                        <Box mt={3}>
                                            <Typography className='teaminfo blue al_center'>
                                                {member.name}
                                            </Typography>
                                        </Box>
                                        <Box className='line' style={{ margin: '0 auto' }}></Box>

                                        <Box mt={1}>
                                            <Typography variant='h6' className='black al_center'>
                                                {member.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            </Box>

            <Box className='section' component='section'>
                <Box mt={3} className='other-members'>
                    <Container maxWidth='xl' className='bdr'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Leadership Team
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="center" alignItems='flex-start'>
                            {remainingMembers.map((member) => (
                                <Grid item key={member.id} lg={3} md={4} sm={6} xs={12} className='dpateam'>
                                    <Box
                                        onClick={() => handleOpenDialog(member)}
                                        className='tmlink'
                                    >
                                        <Box mt={4}>
                                            <Box className='team' style={{ backgroundImage: `url(${member.image})` }}></Box>
                                        </Box>

                                        <Box mt={3}>
                                            <Typography className='teaminfo blue al_center'>
                                                {member.name}
                                            </Typography>
                                        </Box>
                                        <Box className='line' style={{ margin: '0 auto' }}></Box>


                                        <Box mt={1}>
                                            <Typography variant="h6" className="black al_center">
                                                {member.title}
                                                {member.department && ", "}
                                                {member.department && <br />}
                                                <span className="member-dept">{member.department}</span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>

            </Box>


            {selectedMember && (
                <MemberDialog
                    member={selectedMember}
                    open={Boolean(selectedMember)}
                    onClose={handleCloseDialog}
                />
            )}

        </>
    );
};

export default Management;
