import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_doubling_productivity.webp"
import Infographics from "../../../../asset/images/insight/case_studies/enhancing_sba.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function DoublingProductivity() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Boost Loan Processing Efficiency with Automation</title>
                <meta name="description" content="Discover how automation boosted loan processing speed, doubled productivity, and improved client satisfaction through automated loan underwriting and loan processing outsourcing." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/doubling-productivity-achieving-2x-faster-loan-processing-with-50-fewer-errors" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Doubling Productivity Achieving 2x Faster Loan Processing  <span className='lower'>with</span> 50% Fewer Errors
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Operational bottlenecks, slow loan processing, and inconsistent quality control were key challenges for a multi-strategy real estate finance company. These issues impacted client satisfaction and market competitiveness. Through the adoption of automated loan underwriting, loan processing outsourcing, and loan due diligence, enhanced quality control measures, and scalable operations, productivity doubled, processing speed improved by 40%, and error rates dropped by 50%, resulting in a more efficient and consistent lending environment.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The company faced multiple obstacles in its SBA loan operations:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Bottlenecks: </strong> Complex loan processes led to slow approvals and frequent delays in <strong>loan origination outsourcing</strong>.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High Error Rates: </strong>   Inaccurate data entry and inconsistent quality measures affected compliance and slowed down the underwriting process.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inefficient Workflows: </strong>  Existing workflows were not optimized for handling high volumes of applications, causing delays in <strong>loan processing outsourcing</strong> and impacting client trust.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Low Turnaround Times: </strong>  Prolonged loan processing times diminished borrower satisfaction and limited market competitiveness.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Enhancing SBA Loan Processing
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="w100" alt="Solutions: Enhancing SBA Loan Processing" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 40% Faster Processing Times: </strong>  Loan processing times were reduced from 20 hours to 13 hours per application. This was achieved by optimizing <strong>loan processing outsourcing</strong> and enhancing <strong>loan due diligence</strong>.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 2x Increase in Applications: </strong>  Enhanced scalability allowed for double the number of applications to be handled effectively. Leveraging <strong>loan origination outsourcing</strong> ensured scalability without compromising quality.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 50% Error Reduction: </strong>  Improved quality checks reduced processing errors and ensured compliance with regulations.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Efficiency: Automated loan underwriting</strong>  doubled productivity and reduced processing times by 40%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Compliance: </strong>   Enhanced quality measures increased data accuracy and compliance standards.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Client Satisfaction: </strong>  Faster loan processing improved client engagement and trust. A focus on <strong>loan due diligence</strong> further reinforced reliability and transparency.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Achieving a 2x increase in productivity with fewer errors is not just about speed—it's about reliability and client trust. Leverage intelligent automation and robust quality controls to create a faster, more accurate, and client-focused lending operation.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Ready to double your productivity and enhance compliance? Explore tailored solutions to streamline your SBA loan processing and boost client satisfaction today.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}