import { Container, Grid, Typography, Box, Button, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Link } from 'react-router-dom';

import blogimg from "../../asset/images/insight/blog_thumbnails/led-focus-areas.webp"
import case_studiesimg from "../../asset/images/insight/case_studies/case_studies_thumbnails/investor_engagement.webp"
import whitepaperimg from "../../asset/images/insight/whitepaper_thumbnails/transformative_potential.webp"


export default function NewsletterResourecs() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Box className=" section">
                <Container maxWidth="lg" className="bdr">

                    <Typography variant="h2" className="black newh2 al_center" >
                    Explore Our Thought Leadership Hub
                    </Typography>


                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Link to="/blog/tech-led-focus-areas-for-asset-managers-2025">
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + blogimg + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Blog
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                Tech-led focus areas for asset managers preparing for opportunities and challenges in 2025
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Link to='/case-study/crm-optimization-investor-data-automation' >
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case_studiesimg + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                Optimizing CRM & Investor Engagement for a $27B Asset Manager
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Link to='/whitepaper/agentic-ai-in-financial-services' >
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + whitepaperimg + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                            Whitepaper
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                            The Transformative Potential of Agentic AI for the Financial Services Industry
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Grid>

                    </Grid>

                </Container>
            </Box>

        </>
    );
}
