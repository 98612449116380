import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function DpaOracalEvent() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Insights from Oracle CloudWorld Tour India: AI, Data, and Cloud Innovations</title>
                <meta name="description" content="Decimal Point Analytics attended the Oracle CloudWorld Tour in India, exploring AI, data management, and cloud advancements. Discover key takeaways on digital transformation, enterprise optimization, and AI-powered analytics." />
                {/* <meta name="author" content="Decimal Point Analytics" /> */}
                <link rel="canonical" href="https://www.decimalpointanalytics.com/oracle-cloudworld-tour-insights" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at Oracle CloudWorld Tour India
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics' Suyash Srivastava and Viresh Yadav recently attended the prestigious <strong>Oracle CloudWorld Tour</strong> in India. This event gathered leading cloud technology experts, industry professionals, and innovators to explore the latest advancements in AI, data management, and cloud infrastructure.
                        </Typography>

                        <Typography variant='h3' className='black jst h3h6 mb0'>
                            Key Highlights from the Event
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The Oracle CloudWorld Tour focused on cutting-edge solutions and strategies that are shaping the future of business and technology. Some of the key topics discussed included:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Driving Digital Transformation:</strong>  Exploring innovative approaches to enhance business agility and streamline operations through cloud-based solutions.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Optimizing Enterprise Operations:</strong>  Leveraging Oracle's robust cloud infrastructure to improve efficiency, scalability, and security.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>AI-Powered Analytics for Smarter Decision-Making:</strong>  Harnessing artificial intelligence and advanced analytics to drive data-driven financial and strategic decisions.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h3' className='black jst h3h6 mb0'>
                            Insights and Takeaways
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event provided valuable insights into how organizations can maximize the potential of Oracle Cloud technologies to stay ahead in a rapidly evolving digital landscape. Industry leaders shared real-world applications, best practices, and future trends that are set to redefine enterprise operations.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics remains committed to leveraging these advancements to drive innovation and provide cutting-edge solutions for our clients.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                           <strong>     
                           Want to learn how these advancements can benefit your organization? Fill out our form <Link to="/contact-us#contact_form" className='link'>here</Link>  to get in touch with our experts today!
                        </strong>
                        </Typography>


                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
