import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


import salesforce_banner from "../../../asset/images/salesforce/salesforce_banner.webp";
import keybenefits from "../../../asset/images/salesforce/keybenefits.webp";
import whowe from "../../../asset/images/salesforce/whowe.svg";
import SalesforceLogo from "../../../asset/images/salesforce/salesforce-logo.webp";

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import about_enhance from "../../../asset/images/salesforce/about_enhance.webp"

import solution1 from "../../../asset/images/salesforce/solutions/solution1.png";
import solution2 from "../../../asset/images/salesforce/solutions/solution4.png";
import solution3 from "../../../asset/images/salesforce/solutions/solution3.png";
import solution4 from "../../../asset/images/salesforce/solutions/solution2.png";

import whychoose1 from "../../../asset/images/salesforce/whychoose/whychoose1.png";
import whychoose2 from "../../../asset/images/salesforce/whychoose/whychoose2.png";
import whychoose3 from "../../../asset/images/salesforce/whychoose/whychoose3.png";
import whychoose4 from "../../../asset/images/salesforce/whychoose/whychoose4.png";
import whychoose5 from "../../../asset/images/salesforce/whychoose/whychoose5.png";

import SalesforceContactUS from '../../../Component/forms/SalesforceContactUS';

import case1 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/auto_and_custom.webp"
import case2 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/salesforce.webp"
import case3 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/achieving_nearzero.webp"
import case4 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/assetManagergained.webp"
import case5 from "../../../asset/images/insight/case_studies/case_studies_thumbnails/investor_engagement.webp"

export default function SalesforcePart() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    // Scroll to form started
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 30;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 50;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);
    // Scroll to form ended 

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Salesforce CRM Solutions | AI-Powered Automation & Expert Support | DPA</title>
                <meta name="description" content="Unlock Salesforce’s full potential with AI-driven automation, seamless CRM integration, and 24/7 expert support. Enhance customer engagement, boost efficiency, and scale smarter with data-driven insights. Talk to our Salesforce-certified experts today!" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/salesforce-crm-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="salesforce">
                <Box id="new_landing_carousel" className=''>
                    <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + salesforce_banner + ")" }} pb={2}>
                        <Container maxWidth="lg">
                            <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                <Grid item lg={9} md={9} sm={11} xs={10}>
                                    <Box className="text_animated mb0">
                                        <Box className='salesimg' mt={3}>
                                            <img loading="lazy"  src={SalesforceLogo} alt="Salesforce logo" />
                                        </Box>
                                        <Typography variant="h1" className="white fw6 mb0 h1h1" gutterBottom>
                                            Elevate Customer Experience with Intelligent<span className='white'><span className=''> Salesforce CRM Solutions</span> </span>
                                        </Typography>

                                        <Box>
                                            <Typography variant="h2" className="fw4 white" gutterBottom>
                                                Drive engagement and retention with a customer-first approach.
                                            </Typography>
                                        </Box>

                                        <Box mt={2}>
                                            <Link to="/salesforce-crm-solutions#salesforce_contactus" onClick={() => scrollToElement('salesforce_contactus')}>
                                                <Button variant="contained" className='dpabtn_sm1'>
                                                    Get Expert Support
                                                </Button>
                                            </Link>
                                        </Box>

                                    </Box>
                                </Grid>

                            </Grid>
                        </Container>
                    </Box>
                </Box>

                <Box id='' className='section sec_bg mob_card' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={2} alignItems="flex-start" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">


                                </Box>
                            </Grid>

                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={about_enhance} className='w100' alt='ClickUp Solutions: Driving Results, Delivering Performance' />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box mt={2}>

                                    <Typography variant="h2" className="fw5 mb0" gutterBottom>
                                        Enhance Your Salesforce Experience with DPA
                                    </Typography>

                                    <Box>
                                        <Typography variant="h4" className="black fw5" gutterBottom>
                                            Seamless CRM Integration | Intelligent Automation | Scalable Growth
                                        </Typography>
                                    </Box>

                                    <Typography variant='h6' className='black mb0 al_left' >
                                        Salesforce is a powerful platform, but without expert support, seamless integration, and intelligent automation, its full potential remains untapped.
                                    </Typography>

                                    <Typography variant='h6' className='black al_left' gutterBottom>
                                        At Decimal Point Analytics (DPA), we provide structured, data-driven solutions that improve operational efficiency, streamline workflows, and automate key processes—helping your business <strong>make informed decisions and scale effectively</strong>.
                                    </Typography>

                                    <Typography variant='h6' className='black al_left' gutterBottom>
                                        <strong>Let’s discuss how we can support your Salesforce goals.  <Link className="link" to="/contact-us">Schedule a Consultation</Link></strong>.
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box id='' className='section mob_card ' component='section'>
                    <Container maxWidth='lg' className='bdr'>


                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center' mb={1}>
                                    <Typography variant='h2' className='fw5 black al_center mb0'>
                                        Our Expertise
                                    </Typography>

                                    <Typography variant='h2' className='black al_center'>
                                        Comprehensive  <span className='dblack'>Salesforce CRM Solutions</span>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg3'>
                                    <Box className='al_center'>
                                        <img loading="lazy"  src={solution1} className='allic' alt='Certified ClickUp Expertise' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                            <Grid item lg={12} md={7} sm={12} xs={12}>
                                                <Box className='cat_m'>
                                                    <Typography variant='h3' className='blue al_center fw6'>
                                                        Salesforce CRM Integration & Data Management
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>
                                                We streamline CRM data with seamless integration, structured architecture, and high accuracy. Our expertise covers system integrations, automated processing, and KPI-driven insights for better decisions. Optimize your CRM for better decision-making.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg3'>
                                    <Box className='al_center'>
                                        <img loading="lazy"  src={solution2} className='allic' alt='Tailored to Your Business Needs' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                            <Grid item lg={12} md={9} sm={12} xs={12}>
                                                <Box className='cat_m'>
                                                    <Typography variant='h3' className='blue al_center fw6'>
                                                        Process Automation & AI-Powered Enhancements
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>
                                                We automate workflows using Salesforce Flows, Apex Triggers, and AI-powered analytics to reduce manual work and improve efficiency. Our solutions include pre-populated compliance forms and intelligent CRM automation for faster processing.  Boost productivity with smart automation.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg3'>
                                    <Box className='al_center'>
                                        <img loading="lazy"  src={solution3} className='allic' alt='A Trusted, Experienced Partner' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                            <Grid item lg={12} md={9} sm={12} xs={12}>
                                                <Box className='cat_m'>
                                                    <Typography variant='h3' className='blue al_center fw6'>
                                                        Customization & Value-Added Services
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>
                                                We tailor Salesforce with custom dashboards, automated alerts, and secure access controls to enhance efficiency. Our solutions also enable instant PDF and document generation within Salesforce. Get a CRM setup that works for you.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg3'>
                                    <Box className='al_center'>
                                        <img loading="lazy"  src={solution4} className='allic' alt='Innovative Solutions' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                            <Grid item lg={12} md={9} sm={12} xs={12}>
                                                <Box className='cat_m'>
                                                    <Typography variant='h3' className='blue al_center fw6'>
                                                        Ongoing Maintenance & Continuous Support
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>
                                                We ensure seamless Salesforce performance with deployment management, security updates, and continuous optimization. Our team also provides training and adoption support to help businesses maximize Salesforce capabilities. Stay ahead with expert Salesforce support.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>



                    </Container>
                </Box>



                <Box className="section sec_bg ourstrength" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Choose DPA as Your Salesforce Partner?
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={2} mb={4}>
                            <Grid container spacing={2} alignItems="top" justifyContent='center'>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className="str_bg">
                                        <Box className='ora_app_iconbx' mr={1}>
                                            <img loading="lazy"  src={whychoose1} className='allic' alt='Optimized Performance' />
                                        </Box>
                                        <Typography className="blue fw6" variant="h6" style={{ textAlign: 'center' }}>
                                            Salesforce-Certified Experts
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className="str_bg">
                                        <Box className='ora_app_iconbx' mr={1}>
                                            <img loading="lazy"  src={whychoose2} className='allic' alt='Optimized Performance' />
                                        </Box>
                                        <Typography className="blue fw6" variant="h6" style={{ textAlign: 'center' }}>
                                            End-to-End CRM Solutions
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className="str_bg">
                                        <Box className='ora_app_iconbx' mr={1}>
                                            <img loading="lazy"  src={whychoose3} className='allic' alt='Optimized Performance' />
                                        </Box>
                                        <Typography className="blue fw6" variant="h6" style={{ textAlign: 'center' }}>
                                            Data-Driven Decision Making
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className="str_bg">
                                        <Box className='ora_app_iconbx' mr={1}>
                                            <img loading="lazy"  src={whychoose4} className='allic' alt='Optimized Performance' />
                                        </Box>
                                        <Typography className="blue fw6" variant="h6" style={{ textAlign: 'center' }}>
                                            Seamless Automation & Process Optimization
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className="str_bg">
                                        <Box className='ora_app_iconbx' mr={1}>
                                            <img loading="lazy"  src={whychoose5} className='allic' alt='Optimized Performance' />
                                        </Box>
                                        <Typography className="blue fw6" variant="h6" style={{ textAlign: 'center' }}>
                                            24/7 Offshore Salesforce Support
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Typography variant='h6' className='black al_center' gutterBottom>
                            <strong>Ensure your Salesforce environment runs smoothly and efficiently. <Link className="link" to="/contact-us">Talk to Our Experts</Link></strong>.
                        </Typography>
                    </Container>

                </Box>


                <Box id='' className='section  clickup_features' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                <Box className="al_center className='ibm_textbx'">
                                    <Typography variant='h2' className='black'>
                                        Key Benefits of Partnering with DPA for Salesforce
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center desktop-only">
                                    <img loading="lazy"  src={keybenefits} className="w100" alt="Features of ClickUp" />
                                </Box>

                                <Typography className="blue mobile-only" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Increased Efficiency</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                Automating workflows reduces manual tasks by 42%, improving productivity and cutting costs. Streamlined processes eliminate bottlenecks for seamless operations.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Enhanced Customer Experience</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                Customer retention improves by 35%, while satisfaction scores rise 33% through personalized interactions and faster service.
                                            </Typography>
                                        </li>


                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Smarter Decision-Making</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                With 38% more accurate data and a 45% boost in analytics, businesses gain better insights for strategic planning.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Accelerated Growth</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                Revenue grows by 37%, while faster workflows cut policy issuance time by 50%, improving market responsiveness.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Seamless Collaboration & Integration</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                A unified Customer 360 view enhances customer focus, while low-code solutions like OmniStudio enable faster implementations.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Cost Savings & Resource Efficiency</strong>
                                            </Typography>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                Automation reduces costs and manual errors while optimizing human resources for strategic priorities.
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>

                            </Grid>

                            <Grid item lg={9} md={9} sm={11} xs={11}>
                                <Box className="al_left ibm_textbx">
                                    <Typography variant='h5' className='black' gutterBottom>
                                        DPA helps businesses scale efficiently, improve customer engagement, and drive long-term growth.
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>

                    </Container>
                </Box>


                <Box id='sba_solutions' className="section sec_bg" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Explore Our Success Stories
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case5 + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                Optimizing CRM & Investor Engagement for a $27B Asset Manager
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-study/crm-optimization-investor-data-automation">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case4 + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                How a $76B Asset Manager Used AI & CRM Insights to Secure 24 New Investors
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-study/data-driven-investor-acquisition-asset-manager">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case3 + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                How a $27B Asset Manager Achieved 70% Faster Data Processing & Near-Zero Errors
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-study/global-asset-manager-crm-automation-efficiency">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case2 + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + case1 + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle two">
                                                Salesforce Automation to Simplify Your Complex Processes
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-studies/salesforce-automation-to-simplify-your-complex-processes">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box >

                <Box id='' className='section  clickup_features' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                <Box className="al_center className='ibm_textbx'" >
                                    <Typography variant='h2' className='black mb0'>
                                        Who We Work With
                                    </Typography>

                                    <Typography variant='h6' className='black'>
                                        We support a diverse range of industries, including:
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center desktop-only" mt={5}>
                                    <img loading="lazy"  src={whowe} className="w100" alt="Features of ClickUp" />
                                </Box>

                                <Typography className="blue mobile-only" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Financial Services & Investment Firms</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Private Equity & Asset Management Companies</strong>
                                            </Typography>
                                        </li>


                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>Lending Institutions & FinTech Enterprises</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst mb0" >
                                                <strong>B2B SaaS & Enterprise Businesses</strong>
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>

                            </Grid>
                        </Grid>

                    </Container>
                </Box>

                <Box id='' className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems="stretch">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center' mb={1}>
                                    <Typography variant='h2' className='black'>
                                        Specialized Solutions for Alternative Asset Managers
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="stretch">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center className='ibm_textbx'">



                                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box className='ibm_experties al_left'>
                                                <Typography variant='h4' className='blue' gutterBottom>
                                                    Efficiency & Automation
                                                </Typography>

                                                <Box mt={2}>
                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Automated Investor Operations:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Automate investor onboarding, communications, and reporting to reduce manual work, minimize errors, and ensure consistent stakeholder engagement.
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Optimized Deal & Fundraising Workflows:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Centralize deal pipeline tracking and automate LP interaction follow-ups to accelerate fundraising, enhance decision-making, and improve overall operational efficiency.
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box className='ibm_experties al_left'>
                                                <Typography variant='h4' className='blue' gutterbottom>
                                                    Customization & Integration
                                                </Typography>

                                                <Box mt={2}>
                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Seamless System Integration:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Integrate Salesforce with key platforms like FactSet, Preqin, PitchBook, and fund accounting systems for unified data access and operational continuity.
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Tailored Salesforce Enhancements:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Customize dashboards, workflows, and user controls to align CRM functionalities with your business model and compliance needs.
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box className='ibm_experties al_left'>
                                                <Typography variant='h4' className='blue' gutterbottom>
                                                    Investor & LP Engagement
                                                </Typography>

                                                <Box mt={2}>
                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Real-Time LP Reporting:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Leverage real-time dashboards and automated reporting to ensure transparent, timely updates that build investor confidence.
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>Personalized Engagement Tools:</strong>
                                                    </Typography>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Deliver tailored investor experiences through self-service portals, dynamic communications, and email automation within Salesforce.
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box className='ibm_experties al_left'>
                                                <Typography variant='h4' className='blue' gutterbottom>
                                                    Data-Driven Decision Making
                                                </Typography>

                                                <Box mt={2}>
                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>AI-Powered Insights:</strong>
                                                    </Typography>
                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Use predictive analytics and intelligent dashboards to refine investment strategies, optimize fundraising, and drive smarter decision-making across operations.
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst mb0" >
                                                        <strong>KPI Monitoring & Performance Tracking:</strong>
                                                    </Typography>

                                                    <Typography variant="h6" className="black jst" gutterbottom>
                                                        Track critical KPIs in real time to measure fund performance, investor engagement, and operational efficiency—enabling proactive, data-backed actions.
                                                    </Typography>


                                                </Box>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className='al_center' mt={4}>
                                    <Typography variant="h5" className="black" gutterbottom>
                                        Power your Alternative Asset Management with intelligent CRM solutions.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box className='section2  ibm_sucessbgimg' component='section'>
                    <Container maxWidth className='bdr'>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={10} md={10} sm={9} xs={9}>
                                <Box className="al_center className='ibm_textbx'">
                                    <Typography variant='h2' className='black ibmrelative' gutterBottom>
                                        Let’s Build a Smarter CRM for Your Business
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box className="al_center className='ibm_textbx'">
                                    <Typography variant='h6' className='black al_center ibmrelative mb0'>
                                        A well-optimized Salesforce setup leads to better efficiency, improved customer engagement, and faster decision-making.
                                    </Typography>

                                    <Typography variant='h6' className='black al_center ibmrelative' gutterBottom>
                                        Whether you need seamless integration, intelligent automation, or expert CRM management, we are here to help.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box className='section2 sec_bg' component='section'>

                    <Box id='salesforce_contactus' pt={3}>
                        <SalesforceContactUS />
                    </Box>

                </Box>
            </Box>



        </>
    );
}
