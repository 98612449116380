import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";
import { getCurrentOpenings } from "../../Component/api/api";
import NoJobs from "../../asset/images/no-vacancy.png";
import "aos/dist/aos.css"; 
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
  { label: 'Life as a Decimal', to: '/life-as-a-decimal' },
  { label: 'Our Culture', to: '/our-culture' },
  { label: 'Journey of a Decimal', to: '/journey-of-a-decimal' },
  { label: 'ज्ञानोदपि धनोदपि', to: '/dnyanodapi-dhanodapi' },
  { label: 'Current Openings', to: '/current-openings' },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function CurrentOpenings() {
  const [value, setValue] = React.useState(4);
  const location = useLocation();
  const [openings, setOpenings] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // current openings
  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab"); // Get the tab query parameter
    if (tab === "current-openings") {
      setValue(4); // Set the value to 4, which is the index for "Current Openings" tab
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    const fetchOpenings = async () => {
      try {
        const response = await getCurrentOpenings();
        console.log(".......", response.data);
        if (response.data.success) {
          setOpenings(response.data.data);
          setLoading(false);
        } else {
          console.error("Failed to fetch data");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching the openings:", error);
        setLoading(false);
      }
    };
    fetchOpenings();
  }, []);

  const [value1, setValue1] = React.useState(options[4]);

  return (
    <>
      <Helmet>
        <title>Careers Opportunities at Decimal Point Analytics </title>
        <meta
          name="description"
          content="Join Decimal Point Analytics for a rewarding career in finance. Explore opportunities with an innovative team dedicated to excellence."
        />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/current-openings"
        />
      </Helmet>

      <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(0)} component={Link} to="/life-as-a-decimal" />
                  <Tab label="Our Culture" {...a11yProps(1)} component={Link} to="/our-culture" />
                  <Tab label="Journey of a Decimal" {...a11yProps(2)} component={Link} to="/journey-of-a-decimal" />

                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(3)} className="fw6" component={Link} to="/dnyanodapi-dhanodapi" />
                  <Tab label="Current Openings" {...a11yProps(4)} component={Link} to="/current-openings" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value1}
                            onChange={(event, newValue1) => setValue1(newValue1)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>

      <TabPanel value={value} index={4} pt={5} className="current_openings">
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">

            {openings.length === 0 && !loading ? (
              <>
                <Box className="designation_box empty">
                  <Box>
                    <img loading="lazy"  src={NoJobs} className="no-jobs" alt="No Jobs Found" draggable={false} />
                    <Box pt={2}>
                      <Typography variant="h5" className="fw6 black">
                        No Openings Available at the Moment.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="al_center">
                      <Typography variant="h2" className="black">
                        Join our team!
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="top">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box mt={2}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                          <Box pl={"20px"}>
                            <Typography
                              variant="h6"
                              className="mb0 blue sm_text2 desktop-only"
                            >
                              Designation
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={2} xs={12}>
                          <Box pl={"3px"}>
                            <Typography
                              variant="h6"
                              className="mb0 al_left blue sm_text2 desktop-only"
                            >
                              Location
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid container spacing={3} alignItems="top">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="skeleton-box">
                        <Skeleton variant="rect" width="100%" height={80} />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="skeleton-box">
                        <Skeleton variant="rect" width="100%" height={80} />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="skeleton-box">
                        <Skeleton variant="rect" width="100%" height={80} />
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Box className="designation_box">
                    <Grid container spacing={3} alignItems="top">
                      {openings.map((opening, id) => (
                        <Grid item lg={12} md={12} sm={12} xs={12} key={id}>
                          <Box className="job_details">
                            <Grid container spacing={1} alignItems="center">
                              <Grid item lg={5} md={5} sm={5} xs={12}>
                                <Box>
                                  <Typography variant="h5" className="mb0 fw6">
                                    {opening.designation}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item lg={3} md={3} sm={2} xs={12}>
                                <Box>
                                  <Typography variant="h6" className="mb0 al_left black2 fw5">
                                    {opening.job_location}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item lg={4} md={4} sm={5} xs={12}>
                                <Box className="al_right">
                                  <Link to={{
                                    pathname: `/current-openings/${opening.designation.toLowerCase().replace(/\s+/g, '-')}`,
                                    state: { jobData: opening }
                                  }}>
                                    <Button variant="contained" className="dpabtn_1 mobbtn">
                                      Apply Now
                                    </Button>
                                  </Link>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </>
            )}

          </Container>
        </Box>
      </TabPanel>
    </>
  );
}
