import { Container, Grid, Typography, Box, Tab, Tabs, AppBar, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet'; 

import ProductCTAA from '../../Component/ProductCTAA';
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import assessment1 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment1.webp";
import assessment2 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment2.webp";
import assessment3 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment3.webp";
import assessment4 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment4.webp";
import assessment5 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment5.webp";
import assessment6 from "../../asset/images/esg/esg_news_senti_meter/assessment/assessment6.webp";

import rating1 from "../../asset/images/esg/esg_news_senti_meter/ratings/rating1.webp";
import rating2 from "../../asset/images/esg/esg_news_senti_meter/ratings/rating2.webp";
import rating3 from "../../asset/images/esg/esg_news_senti_meter/ratings/rating3.webp";
import rating4 from "../../asset/images/esg/esg_news_senti_meter/ratings/rating4.webp";
import rating5 from "../../asset/images/esg/esg_news_senti_meter/ratings/rating5.webp";

import banenr2 from "../../asset/images/esg/esg_news_senti_meter/assessment.webp";
import { Link } from 'react-router-dom';

import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'ESG', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' },
    { label: 'ESG Insights', to: '/esg-insights/esg-whitepaper' },
    { label: 'ESG Roundtable', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' },
    { label: 'ESG Products', to: '/empowering-esg-investment-with-real-time-analytics' },
];

const options2 = [
    { label: 'News Senti-Meter', to: '/empowering-esg-investment-with-real-time-analytics' },
    { label: 'Private Equity', to: '/elevating-private-equity-performance-with-strategic-esg-solutions' },
    { label: 'Greenlnvest Pulse', to: '/align-your-investments-with-global-sustainability-goals' },
];


export default function ESGNewsSentiMeter() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    // main tabs
    const [valueESG, setValueESG] = React.useState(3);

    const handleChangeESG = (event, newValue) => {
        window.scrollTo(0, 0);
        setValueESG(newValue);
    };
    // main tabs

    const [valueSub, setValueSub] = React.useState(0);

    const handleChangeSub = (event, newValue) => {
        window.scrollTo(0, 0);
        setValueSub(newValue);
    };

    const [value1, setValue1] = React.useState(options[3]);

    const [value2, setValue2] = React.useState(options2[0]);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> ESG Senti-Meter Insights </title>
                <meta name="description" content="Leverage DPA's ESG News Senti-Meter for informed, real-time investment decisions." />
                <meta name="keywords" content="ESG Analysis, ESG News Analytics, AI-ML Technology, Investment Decisions, Real-Time Insights, Environmental, Social, and Governance, Decision-Making Support, Competitive Edge, Technological Edge, ML Engine, PowerBI Integration, Sustainability, Investment Strategy, Data Analytics, Financial Services, Asset Management, Private Equity, Investment Management" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/empowering-esg-investment-with-real-time-analytics" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet> 


            <Box id="esg" className="stiky3">
                <Box id="investor_relations_tabs" className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar dgrid_center">
                                    <Tabs value={valueESG} onChange={handleChangeESG} variant="scrollable">
                                        <Tab
                                            label="ESG"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
                                            value={0}
                                        />
                                        <Tab
                                            label="ESG Insights"
                                            component={Link}
                                            to="/esg-insights/esg-whitepaper"
                                            value={1}
                                        />
                                        <Tab
                                            label="ESG Roundtable"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable"
                                            value={2}
                                        />
                                        <Tab
                                            label="ESG Products"
                                            component={Link}
                                            to="/empowering-esg-investment-with-real-time-analytics"
                                            value={3}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5} >
                    <Container maxWidth="lg" className="bdr">
                        <Box className="alltxfieldlg">
                            <Autocomplete
                                disablePortal
                                options={options}
                                value={value1}
                                onChange={(event, newValue1) => setValue1(newValue1)}
                                fullWidth
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} />}
                                renderOption={(props, option) => (
                                    <Link to={option.to}>
                                        <ListItemButton {...props}>
                                            <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                        </ListItemButton>
                                    </Link>
                                )}
                            />
                        </Box>
                    </Container>
                </Box>
            </Box>


            <Box id="investor_relations_tabs" className="maintabbedsection stiky4 linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top" >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={valueSub} onChange={handleChangeSub} variant="scrollable">
                                    <Tab
                                        label="News Senti-Meter"
                                        component={Link}
                                        to="/empowering-esg-investment-with-real-time-analytics"
                                        value={0}
                                    />
                                    <Tab
                                        label="Private Equity"
                                        component={Link}
                                        to="/elevating-private-equity-performance-with-strategic-esg-solutions"
                                        value={1}
                                    />
                                    <Tab
                                        label="Greenlnvest Pulse"
                                        component={Link}
                                        to="/align-your-investments-with-global-sustainability-goals"
                                        value={2}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky4 esg_tabs">
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options2}
                            value={value2}
                            onChange={(event, newValue2) => setValue2(newValue2)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>



            <Box className='section2 sec_bg' component='section' mt={2}>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        A New Era <span className='lower'>of</span> ESG Analysis
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Decimal Point Analytics (DPA) stands as an award-winning beacon in the realm of tech-enabled research and data analytics, with over two decades of expertise. With a global presence spanning the US, EU, Asia, and South Africa, DPA is committed to innovation, leveraging advanced technologies such as AI-ML, database management, and automation to empower clients. Our ESG News Analytics platform powering our ESG Senti-Meter epitomizes this commitment, transforming the vast landscape of news data into near real-time, actionable ESG insights to guide informed investment decisions.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Schedule a Demo" pageVisited="ESG News Senti-Meter" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    ESG News Senti-Meter
                                    <Typography variant='h2' className='black h2v2'>
                                        Real-Time ESG News Tracking <span className='lower'>and</span> Sentiment Assessment
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment1} className='allic' alt='SA1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Real-Time ESG News Tracking
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Uncover the complex web of news affecting ESG ratings, directly influencing strategic investment decisions.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment2} className='allic' alt='SA2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Connect News to ESG Key Issues
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Our AI model connects ESG relevant news to ESG Pillars and Key Issues, using DPA’s proprietary ESG Framework.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment3} className='allic' alt='SA3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Comprehensive ESG Metrics
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Gain an unparalleled view of fund-level ESG News Sentiment for informed, strategic planning.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img loading="lazy"  src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment4} className='allic' alt='SA4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Insightful Reports
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Benefit from our platform's text reports offering periodic and on-demand commentary for a deeper understanding of ESG.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment5} className='allic' alt='SA5' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Advanced AI-ML Integration
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Interactive dashboards, Python scripts, and BI layer for instant data access.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img loading="lazy"  src={assessment6} className='allic' alt='SA6' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Actionable Insights
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Empower your decision-making with comprehensive evaluations and insights.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Request a Demo" pageVisited="ESG News Senti-Meter" />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Beyond Ratings
                                        <Typography variant='h2' className='black h2v2'>
                                            A New Standard <span className='lower'>for</span> ESG Assessment
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={rating1} className='allic' alt='OP1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Redefining ESG Assessment
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        ESG News Senti-Meter introduces a dynamic, news-based approach that transcends traditional, static ratings.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={rating2} className='allic' alt='OP2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Live Insights
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Offering a nuanced, real-time perspective on ESG developments, ensuring relevance and timeliness.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={rating3} className='allic' alt='OP3' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Advanced Analysis Process
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Our methodology meticulously extracts, tags, scales, and assesses news to provide a comprehensive view of the ESG landscape.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={rating4} className='allic' alt='OP4' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        In-depth ESG Understanding
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Equips you with detailed insights on the ESG impact of current events, enhancing your strategic investment decisions.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={rating5} className='allic' alt='OP3' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Navigational Clarity in ESG Investment
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Empowers investors to confidently navigate the complexities of ESG investment with actionable intelligence.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Schedule a Demo" pageVisited="ESG News Senti-Meter" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className="section" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Why DPA ESG News Senti-Meter?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={0}>
                                <Typography variant='h6' className='black al_center'>
                                    Near real-time updates across all <strong>ESG</strong> pillars: <strong>E</strong>nvironment, <strong>S</strong>ocial, and <strong>G</strong>overnance.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img loading="lazy"  src={whyCP} className='topbanner5' alt='Why Choose ESG News Senti-Meter?' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> A holistic coverage, offering actionable insights through an intuitive online dashboard and detailed on-demand reports.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>  Enhanced decision-making support, powered by our AI-ML driven sentiment and impact analysis.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> This blend of technology and expertise is designed to elevate your investment strategy, providing a competitive edge in the fast-evolving ESG landscape.
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>
                            </Box>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center mt16'>
                                    <ProductCTAA allignment="flx_fs" cta_btn_name="Request a Demo" pageVisited="ESG News Senti-Meter" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='insight_edge sec_bg ourstrength mob_card py3' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={0}>
                                <Typography className="black al_center mb0" variant="h2">
                                    ESG Insights
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        ESG News Exploration
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Key Issue Categorization
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Polarity
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Impact
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            <Box id='esg_news' className='insight_edge ourstrength mob_card py3' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography className="black al_center mb0" variant="h2">
                                    Technological Edge
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7 sec_bg'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        ML Engine
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7 sec_bg'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Continuous Scan
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7 sec_bg'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        PowerBI Integration
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg7 sec_bg'>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Unparalleled Insight
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            <Box id='esg_news' className="sec_bg py3" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='flex-start'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="synopsic_bx">
                                <Typography variant='h6' className='black'>
                                    DPA understands that each investor's needs are unique. Our suite of offerings, ranging from online dashboards to periodic reports, is crafted to cater to these varied requirements. Whether you're looking to track fund-level ESG metrics or seeking in-depth on-demand text reports, DPA provides the essential tools for a comprehensive and effective ESG strategy.
                                </Typography>

                                <Typography variant='h6' className='black'>
                                    Discover how Decimal Point Analytics can transform your ESG investment strategy and how we can drive your investment journey towards sustainability and success.
                                </Typography>

                                <Typography variant='h6' className='black'>
                                    Ready to elevate your ESG investment strategy? <Link to="/contact-us" className='link'>Contact us</Link> to learn more about DPA ESG News Analytics and how we can support your sustainable investment goals.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


        </>
    );
}
