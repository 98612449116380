import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Aos from "aos";
import Carousel from "react-elastic-carousel";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import dd from "../../asset/images/team/durga_dande.webp";
import PN from "../../asset/images/team/prasad.webp";
import sp from "../../asset/images/team/shyam_pardeshi.webp";
import comma from "../../asset/images/aboutus/comma.svg";
import enjoy from "../../asset/images/career/enjoylearning.webp";
import trust from "../../asset/images/career/mutualtrust.webp";
import fun from "../../asset/images/career/funatwork.webp";
import Atmaparikshan from "../../asset/images/career/atmaparikshan.webp";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Autocomplete, ListItemButton } from "@mui/material";


const options = [
  { label: 'Life as a Decimal', to: '/life-as-a-decimal' },
  { label: 'Our Culture', to: '/our-culture' },
  { label: 'Journey of a Decimal', to: '/journey-of-a-decimal' },
  { label: 'ज्ञानोदपि धनोदपि', to: '/dnyanodapi-dhanodapi' },
  { label: 'Current Openings', to: '/current-openings' },
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function LifeAsADecimal() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };

  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  const handleVideoPlay = (url) => {
    setCurrentVideoUrl(url);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [value1, setValue1] = React.useState(options[0]);

  return (
    <>
      <Helmet>
        <title>Embrace Growth and Fun at Decimal: A Thriving Workplace Culture </title>
        <meta
          name="description"
          content="Join Decimal for an exciting journey of growth, collaboration, and fun! Experience a culture of trust and celebration where innovation thrives."
        />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/life-as-a-decimal"
        />
      </Helmet>

      <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(0)} component={Link} to="/life-as-a-decimal" />
                  <Tab label="Our Culture" {...a11yProps(1)} component={Link} to="/our-culture" />
                  <Tab label="Journey of a Decimal" {...a11yProps(2)} component={Link} to="/journey-of-a-decimal" />

                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(3)} className="fw6" component={Link} to="/dnyanodapi-dhanodapi" />
                  <Tab label="Current Openings" {...a11yProps(4)} component={Link} to="/current-openings" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
        <Container maxWidth="lg" className="bdr">
          <Box className="alltxfieldlg">
            <Autocomplete
              disablePortal
              options={options}
              value={value1}
              onChange={(event, newValue1) => setValue1(newValue1)}
              fullWidth
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <Link to={option.to}>
                  <ListItemButton {...props}>
                    <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                  </ListItemButton>
                </Link>
              )}
            />
          </Box>
        </Container>
      </Box>

      <TabPanel value={value} index={0} pt={5}>
        <Box>
          <Box className="section2" component="section">
            <Container maxWidth="lg" className="bdr">
              <Box>
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box className="al_center" mb={2}>
                      <Typography variant="h5" className="blue al_left">
                        Enjoying while learning
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" className="black jst listdetails">
                        The life of a Decimal (Yes, Decimals what we fondly call
                        our people here!!) is an exciting journey where he/she is
                        continuously challenged and exposed to opportunities to
                        grow at a very fast pace. The opportunities are immense
                        for people who are thirsty for growth and who want to
                        create a difference to the world by their unique
                        contributions to the clients we serve and to the society
                        in general. Our culture is open and invigorating for minds
                        who dream of achieving the impossible.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="al_center">
                      <img loading="lazy"  src={enjoy} className="topbanner4 brd8" alt="Enjoy" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box mt={1}>
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box className="al_center" mb={2}>
                      <Typography variant="h5" className="blue al_left">
                        Mutual trust, care and respect
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" className="black jst listdetails">
                        We are happy that over these years we have been able to
                        foster positive and collaborative working relationships
                        which are based on care, trust and respect for each other.
                        You will feel the warmth and a sense of belongingness to
                        this open, friendly and transparent culture that exists
                        here.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="al_center">
                      <img loading="lazy"  src={trust} className="topbanner4 brd8" alt="Trust" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box mt={1}>
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box className="al_center" mb={2}>
                      <Typography variant="h5" className="blue al_left">
                        Fun at work
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" className="black jst listdetails">
                        We carry out the serious business of research with
                        ever-smiling-face and culture of performance,
                        encouragement, and innovation. We work hard and party even
                        harder!! We do not miss any festive occasion or any reason
                        to celebrate, making it a workspace filled with bonhomie.
                        Picnics, team outings, dinners, birthdays, games all add
                        to the fun. Most importantly, we celebrate and enjoy those
                        together the way a big happy family does.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="al_center">
                      <img loading="lazy"  src={fun} className="topbanner4 brd8" alt="Fun" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Divider />
              <Box mt={1}>
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box className="al_center" mb={2}>
                      <Typography variant="h5" className="blue al_left">
                        Atmaparikshan: <span className="black">Leadership Development Program</span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" className="black jst listdetails" gutterBottom>
                        DPA launched a leadership development program named "Atmaparikshan" (AP) in July 2020. The term, derived from Sanskrit, translates to "self-examination" or "soul-searching," emphasizing introspection as a cornerstone for growth. To power this initiative, DPA entered a long-term collaboration with V-Reflect Coaching & Mentoring (V-Reflect). This partnership aims to equip leaders with the skills needed to adapt to rapid changes, navigate new complexities, and drive innovation across the organization.The program emphasizes self-awareness, composure, and a positive mindset—key qualities for effective leadership, problem-solving, and building resilient teams.
                      </Typography>

                      <Typography variant="h6" className="black jst listdetails">
                        Discover more about Atmaparikshan  i.e. our leadership journey. <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/case_study_atmaparikshan_aps_v0.02_ma.pdf" target='_blank' rel="noopener noreferrer">[Read the full case study here]</a>.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="al_center">
                      <img loading="lazy"  src={Atmaparikshan} className="topbanner4 brd8" alt="Atmaparikshan" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </Container>
          </Box>

          <Box
            className="section"
            component="section"
            style={{ paddingTop: "0px" }}
          >
            <Container maxWidth="lg" className="bdr">
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center">
                    <Typography variant="h2" className="black">
                      Meet the Decimal
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Carousel
                    itemsToShow={1}
                    animateOut="slideOutDown"
                    animateIn="flipInX"
                  >
                    <Box className="boxcontent">
                      <Box
                        className="team_testi"
                        style={{ backgroundImage: "url(" + dd + ")" }}
                      ></Box>
                      <Box className="test_text" mt={4}>
                        <Typography variant="h6" className="black al_center">
                          I have been associated with Decimal Point Analytics
                          (DPA) for the past 12 years and not for a single day did
                          I feel that I am not learning new things. It is a
                          pleasure to work for a company that is growing in the
                          right direction. On personal front, my organization has
                          allowed me to work flexi timings, which has helped me to
                          pursue a career which otherwise would not have been
                          possible. I feel proud to be a part of the DPA family.
                        </Typography>
                      </Box>
                      <Box
                        className="line"
                        style={{ margin: "0 auto" }}
                        mt={2}
                        mb={2}
                      ></Box>
                      <Box mt={4}>
                        <img loading="lazy"  src={comma} style={{ width: "50px" }} alt="Comma" />
                      </Box>
                      <Box mt={4}>
                        <Typography variant="h4" className="blue">
                          Durga Dande
                        </Typography>
                        <Typography variant="h6" className="blue">
                          Executive Vice President - Research and Account
                          Management | Mumbai
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="boxcontent">
                      <Box
                        className="team_testi"
                        style={{ backgroundImage: "url(" + PN + ")" }}
                      ></Box>
                      <Box className="test_text" mt={4}>
                        <Typography variant="h6" className="black al_center">
                          Mentoring, trust, and freedom to innovate have been
                          major aspects of my experience at DPA. The seniors and
                          management have always mentored me at every step. At
                          DPA, I was given a challenging opportunity early in my
                          career to build a team of financial analysts at the
                          Nashik location, along with a few other colleagues. I am
                          proud to say that we have already established a robust
                          team in Nashik, and it is growing rapidly, providing
                          numerous career growth opportunities. DPA's work culture
                          promotes a healthy work-life balance, allowing me to
                          take time off when needed the most. Moreover, the
                          organization has supported me during my medical
                          emergency, and I truly feel that DPA is like a family to
                          me. I look forward to many successful years of
                          association with DPA.
                        </Typography>
                      </Box>
                      <Box
                        className="line"
                        style={{ margin: "0 auto" }}
                        mt={2}
                        mb={2}
                      ></Box>
                      <Box mt={4}>
                        <img loading="lazy"  src={comma} style={{ width: "50px" }} alt="Comma" />
                      </Box>
                      <Box mt={4}>
                        <Typography variant="h4" className="blue">
                          Prasad Nawathe
                        </Typography>
                        <Typography variant="h6" className="blue">
                          Senior Vice President - Research & Data Operations |
                          Nashik
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="boxcontent">
                      <Box
                        className="team_testi"
                        style={{ backgroundImage: "url(" + sp + ")" }}
                      ></Box>
                      <Box className="test_text" mt={4}>
                        <Typography variant="h6" className="black al_center">
                          It’s been a year working with Decimal point and the
                          journey has been full of learnings. The first thing that
                          pleasantly surprised me was the next gen analytics the
                          company provides to its clientele and the industry
                          expertise of the leadership team. The visionary
                          leadership has envisaged the futuristic technology and
                          has successfully implemented it as way of life in the
                          organisation. The organisation strongly believes in
                          learning & development and is instrumental in converting
                          talent from non-technical background into experts in
                          various technical domains. The procedures and policies
                          are employee friendly and nurtures a value based culture
                          which is critical for everyone’s success. I am delighted
                          to be a part of such a wonderful organisation and look
                          forward for a long tenure with full of learnings!
                        </Typography>
                      </Box>
                      <Box
                        className="line"
                        style={{ margin: "0 auto" }}
                        mt={2}
                        mb={2}
                      ></Box>
                      <Box mt={4}>
                        <img loading="lazy"  src={comma} style={{ width: "50px" }} alt="Comma" />
                      </Box>
                      <Box mt={4}>
                        <Typography variant="h4" className="blue">
                          Shyam Pardeshi
                        </Typography>
                        <Typography variant="h6" className="blue">
                          Senior Vice President - Research and Data Operations |
                          Nashik
                        </Typography>
                      </Box>
                    </Box>
                  </Carousel>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="top">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className="videback">
                    <ReactPlayer
                      controls
                      url="https://youtu.be/rOInKidIIJ8"
                      className="player"
                      width="100%"
                      height="100%"
                      playing={currentVideoUrl === "https://youtu.be/rOInKidIIJ8"}
                      onPlay={() =>
                        handleVideoPlay("https://youtu.be/rOInKidIIJ8")
                      }
                      onPause={() => setCurrentVideoUrl("")}
                    />
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className="videback">
                    <ReactPlayer
                      controls
                      url="https://youtu.be/AHfdGfE5Mgg"
                      className="player"
                      width="100%"
                      height="100%"
                      playing={currentVideoUrl === "https://youtu.be/AHfdGfE5Mgg"}
                      onPlay={() =>
                        handleVideoPlay("https://youtu.be/AHfdGfE5Mgg")
                      }
                      onPause={() => setCurrentVideoUrl("")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </TabPanel>
    </>
  );
}
