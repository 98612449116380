import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import indexbanner from "../../asset/images/animation/decimalnews.webp";
import { Helmet } from 'react-helmet';


export default function Decimalnews() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Decimal Point Analytics News and Insights</title>
                <meta name="description" content="Discover the latest news and insights in finance, technology, and analytics with Decimal Point Analytics. Stay informed and ahead of the curve." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimalnews" />
            </Helmet>

            <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Decimal News
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Read what we have to say to all of you.
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img loading="lazy"  src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>




            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Decimal News
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='reportinfo'>
                        <Grid container spacing={1} alignItems="top">


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://yourstory.com/smbstory/info-french-business-awards-nitin-gadkari?utm_pageloadtype=scroll' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                13 SMEs and corporates emerge winners at Indo-French Business Awards 2021.
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 26, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst two'>
                                                The event gathered business leaders from prominent Indo-French companies across sectors such as aerospace and defence, mobility, energy and consumer goods, etc. Union Minister for MSME Nitin Gadkari was the chief guest at the event.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.businessinsider.in/business/news/sebi-bets-big-on-tech-shortlists-ibm-india-infy-wipro-others-for-data-analytics-project/articleshow/74065713.cms' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Sebi bets big on tech; shortlists IBM India, Infy, Wipro, others for data analytics project
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 10, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst two'>
                                                New Delhi, Feb 10 () Sebi has shortlisted as many as eight companies, including Infosys, Wipro and IBM India, to implement a "data analytics project"
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>


                        </Grid>
                    </Box>

                </Container>
            </Box>

        </>
    );
}
