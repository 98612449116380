import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_cost_effective_excellence.webp"
import Infographics from "../../../../asset/images/insight/case_studies/cost_effective_excellence_saving.webp"
import { Link } from 'react-router-dom';
import GetInTouch from '../../../../Component/Get_in_touch';

import CostEffectiveFAQ from "../../../../Component/CostEffectiveFAQ"

export default function CostEffectiveExcellence() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Cost-Effective SBA Loan Processing: Enhance Loan Quality & Save 60%</title>
                <meta name="description" content="Discover how Decimal Point Analytics helps lenders save 60% in overheads while enhancing SBA loan quality with cost-effective solutions." />
                <meta name="keywords" content="SBA loan automation, loan processing cost reduction, real estate finance solutions, outsourcing loan processing, improving SBA loan quality, financial process automation, efficiency in loan approvals, error reduction in finance, Decimal Point Analytics." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Cost-Effective Excellence – Saving Lenders 60%  <span className='lower'>in</span> Overheads While Enhancing SBA Loan Quality
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Cost-Effective Excellence Banner - Decimal Point Analytics' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Our client, a multi-strategy real estate finance company, specializes in originating, acquiring, financing, and servicing <Link to="/small-business-administration" className="link">SBA loans</Link> for small and medium-sized businesses. With a mission to empower businesses nationwide, they focus on delivering fast and flexible funding solutions that adhere to SBA compliance.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client faced significant challenges in their SBA lending operations, impacting both cost and quality:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High Operational Costs: </strong>  Onshore underwriting teams were driving up expenses.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Cost-Quality Balance: </strong>   Managing compliance and processing accuracy while controlling costs was a struggle.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Resource Strain and Growth Constraints: </strong>  Rising overheads hindered expansion and strained resources.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>Tailored Solutions <span className='lower'>for </span>SBA Lending Support   </Typography>
                        </Box>

                        <Box>
                            <Typography variant='h6' className='black jst'>
                                Decimal Point Analytics implemented a set of targeted strategies to address these issues effectively:
                                <ol>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>End-to-End SBA Lending Support Services:</strong> Streamlined loan processing workflows, reducing reliance on the onshore team.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost-Effective Outsourcing Model:</strong> Scalable outsourcing allowed the client to adapt capacity to fluctuating demand.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Quality Control:</strong> Stringent checks at each stage ensured compliance, minimized errors, and improved loan quality.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automation for SBA Compliance:</strong> Leveraged automation to maintain precise documentation and adhere to SBA guidelines efficiently.
                                        </Typography>
                                    </li>
                                </ol>
                            </Typography>
                        </Box>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>Results <span className='lower'>and </span>Key Metrics  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            These strategies delivered transformative outcomes:
                        </Typography>

                        <Box mt={1}>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Cost-Effective Excellence and Savings Banner - Decimal Point Analytics" />
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>How Decimal Point Analytics Can Help You Optimize SBA Loan Processing </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            At Decimal Point Analytics, we specialize in offering <strong>SBA Lending Support Services</strong> that help lenders streamline operations, enhance compliance, and improve overall efficiency. By implementing tailored solutions such as <strong>cost-effective outsourcing models</strong> and <strong>automation in SBA compliance</strong>, we empower lenders to achieve significant operational improvements.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>FAQ</Typography>
                        </Box>

                        <CostEffectiveFAQ />

                        {/* <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Success Metrics
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Reduction: </strong> Approximately 60%
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Error Rate Reduction: </strong> Approximately 50%, leading to minimized rework
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Efficiency Improvement: </strong>  Approximately 40% faster processing times
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Approval Throughput: </strong> Doubled capacity in handling loan applications
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                By implementing a comprehensive automation and outsourcing strategy, our client was able to cut costs by 60%, improve efficiency by 40%, and halve error rates, setting a new standard in SBA loan processing. These improvements have not only enhanced operational efficiency but have also bolstered client satisfaction and market competitiveness.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <strong>Ready to Transform Your SBA Loan Processing? </strong>
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Discover how <Link to="/" className='link'>Decimal Point Analytics</Link> can help optimize your loan processing with cost-effective, high-quality solutions tailored to the unique demands of SBA loans.  <Link to="/contact-us#contact_form" className="link">Connect with us</Link> today to streamline your operations and improve client satisfaction.
                            </Typography>

                        </Box> */}

                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}