import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import indexbanner from "../asset/images/animation/terms.webp";

export default function Disclaimer() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Disclaimer
                                </Typography>
 
                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img loading="lazy"  src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Disclaimer
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={4}>

                                <Typography variant='h6' className='black jst'>
                                    This document has been prepared exclusively for the benefit and internal use of the Recipient by Decimal Point Analytics Private Limited (DPA), and its contents may not be used for any other purpose without the prior written consent of DPA. DPA is not making any recommendation, solicitation, or offer for any securities mentioned in the report, and is also not responsible for suitability of such securities for any purpose, investment or otherwise.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The information in this document reflects our opinion as of the date of the document, and may change with changes in market conditions. All data included in the report have been obtained from sources considered reliable, without independent verification of the accuracy or completeness of the information. DPA as such cannot and does not guarantee the accuracy or completeness of the data and information. The returns indicated, including future projections, in the investment report are not guaranteed in any manner and may not be achieved. It is the sole responsibility of the recipient, as a professional organization, to exercise professional due diligence in ensuring suitability of investment. Decimal Point Analytics Pvt. Ltd. shall not be responsible for any loss suffered by the user.
                                </Typography>

                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>





        </>
    );
}
