import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core'; 



import FAQ from "../../../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function CreditpulseFaq() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>


      <Container maxWidth='lg' className='bdr'>


        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image' mt={4}>
              <img loading="lazy"  src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box className='al_left'>
              <Typography variant='h2' className='black'>
                <strong>  FAQ </strong>
              </Typography>
            </Box>

            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>What does CreditPulse offer?  </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      CreditPulse offers a range of features including credit risk assessment, portfolio monitoring, early warning signals, scenario analysis, reporting, and data visualization tools.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>How does CreditPulse assess credit risk?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      CreditPulse analyzes various financial and non-financial data points using advanced analytics and machine learning algorithms to evaluate the creditworthiness of borrowers.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Who can benefit from using CreditPulse? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      CreditPulse is beneficial for financial institutions, credit managers, and organizations seeking to manage credit risk effectively and maintain healthy credit portfolios.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Is CreditPulse constantly updated? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      Yes, CreditPulse is continually evolving to incorporate the latest advancements in technology and analytics to deliver actionable insights in response to the changing landscape of credit risk management.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How can I learn more about CreditPulse? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      For more detailed information about CreditPulse, including its features, capabilities, and how it can benefit your organization,<a href="/contact-us#contact_form"> Contact Us </a> to learn more.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


              </Box>
            </Box>


          </Grid>
        </Grid>




      </Container>




    </>


  );
}
