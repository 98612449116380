import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_accurate_data_mngt.webp"
import { Link } from 'react-router-dom';



export default function DrivingFundraisingSuccess() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Fundraising Success with Accurate Investor Data | Decimal Point</title>
                <meta name="description" content="Discover how automated data updates, quality assurance, and seamless CRM integration drive success." />
                <meta name="keywords" content="Investor data management, Institutional investor intelligence" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/driving-fundraising-success-with-accurate-data-management" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Driving Fundraising Success with Accurate Data Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Our client is a leading alternative investor intelligence platform, managing a global network of institutional investors with a staggering AUM of over EUR 100 trillion. They provide a mobile Customer Relationship Management (CRM) application designed to simplify investor relations and fundraising activities. This CRM empowers their teams with tools to manage investor interactions, monitor fundraising progress, and maintain strong client relationships. However, the client faced challenges in keeping their investor data accurate and up-to-date, which directly impacted the efficiency of their operations and the quality of their outreach efforts.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges Faced
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client encountered:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Outdated financial data for asset allocation, managed in static Excel templates.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Missing or inaccurate contact information for key decision-makers in Limited Partnerships (LPs).
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Limited visibility into investor positions, hindering fundraising efforts.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Our Data-Driven Solution
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Decimal Point Analytics delivered a tailored approach to solve these challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ol>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Comprehensive Data Updates</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Leveraged multiple sources to validate and update financial and contact data.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated Quality Assurance</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Deployed macros for faster, error-free processing.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Adopted a maker-checker system to ensure data accuracy.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Seamless Process Integration</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Created standard operating procedures (SOPs) to streamline updates.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Clear Client Communication</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Delivered accurate and organized insights for fundraising teams.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>

                                </ol>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Outcomes
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The impact of DPA’s intervention was transformational:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Turnaround:</strong> Streamlined processes for quick data updates.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Accuracy:</strong>  Reduced errors with automated validation
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Engagement:</strong>   Clear visibility into investor positions and contacts
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Optimized Resources:</strong>  Freed up client teams for core activities.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Strengthening Investor Relations <span className='lower'>with</span> Data Accuracy
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Effective investor data management is critical for fundraising success. This case study highlights how <strong>Decimal Point Analytics</strong> enabled our client to improve operational efficiency, strengthen investor relationships, and maintain a <strong>data-driven approach</strong> to fundraising.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Unlock  <span className='lower'>the </span> Potential <span className='lower'>of</span> Your Investor Relations Strategy
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Are outdated investor records slowing down your fundraising efforts?  <strong>Partner with Decimal Point Analytics </strong> to leverage automated data management and drive fundraising success.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Connect us today!</Link>
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}