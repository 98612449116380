import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';



import fork_war1 from "../../../asset/images/insight/insight_pages/fork_wars/fork_wars_1.webp";
import fork_war2 from "../../../asset/images/insight/insight_pages/fork_wars/fork_wars_2.webp";
import fork_war3 from "../../../asset/images/insight/insight_pages/fork_wars/fork_wars_3.webp";



export default function Fork_wars() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Our article delves into the fascinating world of cryptocurrency forks, exploring the history, controversies, and implications of this phenomenon. Discover the story of two cryptocurrencies and the challenges and opportunities they present for investors and enthusiasts alike." </h1>
                <title>Fork Wars: A Tale of Two Cryptocurrencies</title>
                <meta name="description" content="Explore the tale of two cryptocurrencies in the Fork Wars. Get insights from Decimal Point Analytics." />
                <meta name="keywords" content="bit coin crypto currency
fork in cryptocurrency
blockchain network
blockchain in cryptocurrency
blockchain and cryptocurrency
investing in cryptocurrency" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/fork-wars" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Fork Wars A Tale of two cryptocurrencies
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'> “Bitcoin” made its debut in 2009, with its creation credited to a pseudonymous coder known as Satoshi Nakamoto. Unlike most currencies, Bitcoin does not involve any bank or third parties. The coins are created by users who “mine” them by lending computing power to verify other users’ transactions and are then rewarded in Bitcoins. Amid much controversy over size and scale, Bitcoin split off in two directions recently, forming a new branch of currency called “Bitcoin Cash”.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Why the Split?</Typography>

                        <Typography variant='h6' className='black jst'> For years, a so-called “scaling debate” had been going on within the Bitcoin community. Bitcoin’s existing technology was struggling to cope with the surge in popularity, that has seen its price jump from about $580 in 2016 to over $4,000 now. </Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={fork_war1} className='topbanner2' alt='Fork Wars 1' />
                        </Box>

                        <Typography variant='h6' className='black jst'> <em>Source: CoinMarketCap</em></Typography>

                        <Typography variant='h6' className='black jst'> In fact, there is a big backlog of transactions on the Bitcoin blockchain i.e. the technology that underpins the cryptocurrency. Only 1 megabyte of transactions could be processed at one time, thus leading to delays. Many alternative solutions were proposed for resolving the scaling situation and rejected. SegWit2x, short for segregated witness, involved moving some of the data outside the main Bitcoin network to increase its capacity and is likely to increase the size of Bitcoin blocks to 2 megabytes. However, some miners were unhappy with the proposal and it ultimately resulted in a fork. </Typography>

                        <Typography variant='h4' className='blue'>The Birth of Bitcoin Cash</Typography>

                        <Typography variant='h6' className='black jst'> On 1st August 2017, a user activated hard fork took place and Bitcoin Cash was created. The new digital currency has swung dramatically in price in the initial few days. On 4th August, Bitcoin Cash plunged 30% to below $300, and has recovered since the middle of August to reach a level of $755.  </Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={fork_war2} className='topbanner2' alt='Fork Wars 2' />
                        </Box>

                        <Typography variant='h6' className='black jst'> <em>Source: CoinMarketCap</em></Typography>

                        <Typography variant='h6' className='black jst' s> Meanwhile, the original cryptocurrency held up well against its offshoot rival during the volatile early August period. </Typography>

                        <Typography variant='h4' className='blue'>The Segwit Lock-in</Typography>

                        <Typography variant='h6' className='black jst'> Segwit was officially locked-into the blockchain on 8th August 2017. This now sets the timeline for implementation of the SegWit2x, which will be triggered about three months after the SegWit activation. On 8th August, Bitcoin surpassed the $3,500 mark for the first time, hitting a fresh record high as uncertainty over the future of the cryptocurrency subsided. The new technology will free up space for more transactions to take place on the blockchain. </Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={fork_war3} className='topbanner2' alt='Fork Wars 3' />
                        </Box>

                        <Typography variant='h6' className='black jst'> <em>Source: CoinMarketCap</em></Typography>


                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>What does the future of Bitcoin look like? </Typography>

                        <Typography variant='h6' className='black jst'> The new strategy to optimise transactions per chain size will facilitate the trading of more Bitcoins on the current infrastructure. The Bitcoin community has lauded this move as a big step in the cryptocurrency world. Moreover, as regulators start sounding positive about Bitcoins and with more institutional investors now considering allocation of funds to Bitcoin, going ahead, the popularity of Bitcoin is likely to increase. For instance, in Switzerland, the financial market regulator has given approval to Zurich-based Falcon Private Bank on managing assets based on the blockchain technology behind Bitcoin and other digital currencies. Separately, the Chicago Board Options Exchange has revealed its plan to offer Bitcoin futures by early next year and the US Commodity Futures Trading Commission has approved a digital currency trading firm called LedgerX to clear derivatives. However, we cannot turn a blind eye to the challenges the cryptocurrency market faces. For instance, its use is limited currently to a small group of merchants at present and the value of bitcoin keeps fluctuating sharply. Moreover, the anonymity in exchanging such virtual currencies magnify the risk associated with laundering and counterfeiting. It is also important to note that like everything else in the crypto universe, no one knows what’s about to happen next!</Typography>


                        <Divider />


                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Fork Wars A Tale of two cryptocurrencies" />
                    </Box>


                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
