import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Budget_Fintech from "../../../asset/images/media/press_release/budget_2025_fintech.webp"
import Budget_CNBC from "../../../asset/images/media/press_release/budget_2025_cnbc.webp"

import Banner from '../../../asset/images/media/press_release/banner_budget_2025.webp'

import CNBC from "../../../asset/images/media/cnbc18.webp";
import FinTech from "../../../asset/images/media/fintech_biznews.webp";

export default function BudgetExpectations2025() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Shailesh Dhuri’s Perspective on Budget 2025 Provident Fund Reform</title>
                <meta name="description" content="Shailesh Dhuri highlights the need for Budget 2025 to modernize Provident Fund rules, simplify savings, improve taxation, and boost investor confidence." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/budget-expectation-2025" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Budget Expectation 2025
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO, Shailesh Dhuri, predicts that Budget 2025 will drive much-needed reforms in Provident Fund administration, taxation, and investor confidence.
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={6} md={6} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={Budget_CNBC} className='fullimg' alt='Budget_CNBC' />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={Budget_Fintech} className='fullimg' alt='Budget_Fintech' />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://www.cnbctv18.com/budget/union-budget-2025-proposals-income-tax-clarity-on-itc-pharma-msme-fintech-19549247.htm" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CNBC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://www.fintechbiznews.com/finserv/budget-expectations-of-lenders-financial-service-providers" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + FinTech + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container >
            </Box >





        </>
    );
}
