import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { withStyles } from "@material-ui/core/styles";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useHistory, Link } from "react-router-dom";
import { newsletterApi } from "../Component/api/api";
import "../Style.css";
import * as yup from "yup";
import { Formik, Field, Form, } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert as MuiAlert } from "@material-ui/lab";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);




export default function Subscribe(props) {


 
    const [loader, setLoader] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [checkboxFlag, setCheckboxFlag] = React.useState(false);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [checkboxError, setCheckboxError] = React.useState("");
    const emailreg = /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;

    const nameValidation = /^[A-Za-z\s]+$/;
    const history = useHistory();

    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        tandc: true,
    };


    const validationSchema = yup.object().shape({
        firstname: yup
            .string()
            .typeError("Required feild")
            .required("First name is required")
            .max(100)
            .matches(nameValidation, "Enter valid first name"),
        lastname: yup
            .string()
            .typeError("Required feild")
            .required("Last name is required")
            .max(100)
            .matches(nameValidation, "Enter valid last name"),
        email: yup
            .string()
            .typeError("Required feild")
            .required("Email is required")
            .matches(emailreg, "Email is not valid"),

        tandc: yup.bool().oneOf([true], "You must check the checkbox"),
        tandce: yup.bool(),
    });

    const handleKeyUp = (event, fieldName) => {
        const value = event.target.value;
        if (/\d+/.test(value)) {
            if (fieldName === 'firstname') {
                setFirstNameError('First name should not contain numbers');
            } else if (fieldName === 'lastname') {
                setLastNameError('Last name should not contain numbers');
            }
        } else {
            if (fieldName === 'firstname') {
                setFirstNameError('');
            } else if (fieldName === 'lastname') {
                setLastNameError('');
            }
        }
    };

    const onSubmit = (values) => {
        
        const formData = {
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            obtain_email: values.tandc,
        };
        if (values.tandc === false) {
            setCheckboxError("You must agree before submitting");
            return;
        }
        setCheckboxError("");
        handleFormSubmit(formData);
    };

    const handleFormSubmit = (values) => {
        post(values);
    };

    const post = async (formData) => {
        setLoader(true);
        const message = "Oops, something went wrong, Please try again leter.";
        try {
            const { data, status } = await newsletterApi(formData);
            if (status === 200) {
                if (data.status === true) {
                    //localStorage.setItem("isOpen", 0);

                    setCheckboxFlag(false);
                    history.push("/subscribed-success");
                } else {
                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
            setLoader(false);
        } catch (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setLoader(false);
        }
    };

    const handleClickOpen = () => {
        localStorage.setItem("isOpen", 1);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        //localStorage.setItem("isOpen", 0);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Box className='' mt={2}>
                <Button onClick={handleClickOpen} variant="contained" className='dpabtn_sm1'>
                    Subscribe Now!
                </Button>
            </Box>




            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="b_modal teammodal"
            >
                <DialogTitle
                    className="feedname"
                    id="customized-dialog-title"
                    onClose={handleClose}
                ></DialogTitle>
                <DialogContent>
                    <Box>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            validateOnChange={true}
                        >
                            {({ values, handleChange, handleBlur, handleSubmit, setFieldError, isSubmitting, touched, errors }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box className="alltxfieldlg">
                                                <Field
                                                    as={TextField}
                                                    id="filled-basic"
                                                    label="First Name *"
                                                    variant="standard"
                                                    fullWidth
                                                    inputProps={{ maxLength: 100 }}
                                                    name="firstname"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onKeyUp={(event) => handleKeyUp(event, 'firstname')}
                                                    error={touched.firstname && Boolean(errors.firstname)}
                                                    helperText={
                                                        (touched.firstname && errors.firstname) ? errors.firstname : firstNameError
                                                    }
                                                    FormHelperTextProps={{ style: { color: 'red' } }}

                                                />
                                            </Box>
                                        </Grid>


                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box className="alltxfieldlg">
                                                <Field
                                                    as={TextField}
                                                    id="filled-basic"
                                                    label="Last Name *"
                                                    variant="standard"
                                                    fullWidth
                                                    inputProps={{ maxLength: 100 }}
                                                    name="lastname"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onKeyUp={(event) => handleKeyUp(event, 'lastname')}
                                                    error={touched.lastname && Boolean(errors.lastname)}
                                                    helperText={
                                                        (touched.lastname && errors.lastname) ? errors.lastname : lastNameError
                                                    }
                                                    FormHelperTextProps={{ style: { color: 'red' } }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box className="alltxfieldlg ">
                                                <Field
                                                    as={TextField}
                                                    id="filled-basic"
                                                    label="Email Address *"
                                                    variant="standard"
                                                    name="email"
                                                    inputProps={{ maxLength: 100 }}
                                                    error={touched.email && Boolean(errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Grid>




                                        <Grid item lg={12} md={12} sm={12} xs={12}> 

                                            <Box className="alltxfieldlg chkbx">
                                                <FormControlLabel
                                                    control={
                                                        <>
                                                            <Field
                                                                defaultChecked={true}
                                                                as={Checkbox}
                                                                name="tandc"
                                                                color="primary"
                                                                size="small"
                                                            />
                                                        </>
                                                    }
                                                    label=""

                                                />
                                                <Typography className="label">
                                                    Subscribe to our Newsletter
                                                </Typography>
                                            </Box>
                                            <Box className="alltxfieldlg">
                                                {touched.tandc && errors.tandc && (
                                                    <MuiAlert severity="error">{errors.tandc}</MuiAlert>
                                                )}
                                            </Box>

                                        </Grid>



                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="al_center" mt={3}>
                                                <Link >
                                                    {loader ? (
                                                        <LoadingButton loading={true} variant="outlined" disabled>
                                                            disabled
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button
                                                            onClick={() => {
                                                                handleSubmit(onSubmit)
                                                            }}
                                                            variant="contained"
                                                            className="dpabtn_1"
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}

                                                </Link>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="alltxfieldlg graybbx" p={2}>
                                                <Typography variant="h6" className="jst">
                                                    Decimal Point Analytics (DPA) will process the information in this form to share information as requested. By checking the above box, you confirm your acceptance to receive the communication. You can unsubscribe any time by clicking the ‘Unsubscribe’ link in the footer of any email you receive from us, or by contacting us at <a href="mailto:marketing@decimalpointanalytics.com">marketing@decimalpointanalytics.com</a>.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>

                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
