import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function PIWOT2025() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at PIWOT 2025: Exploring AI, FinTech, and Digital Transformation</title>
                <meta name="description" content="Decimal Point Analytics participated in PIWOT 2025, where our CEO Shailesh Dhuri and CRO Gautam Naidu engaged in discussions on AI-driven decision-making, sustainable investments, and emerging technologies shaping financial ecosystems." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-paniit-world-of-technology-2025" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at PANIIT - World of Technology 2025
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics participated in <strong>PIWOT 2025</strong>, an exclusive gathering of IIT alumni, industry leaders, and technology innovators, focused on advancements in AI, automation, and digital transformation.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our <strong>Chief Executive Officer, Shailesh Dhuri,</strong> and <strong>Chief Revenue Officer, Gautam Naidu</strong>, attended the event and engaged in insightful discussions on
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        AI-driven decision-making
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        The role of financial technology in sustainable investments
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        The intersection of emerging technologies with economic growth.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event provided valuable insights into regulatory shifts, geopolitical impacts on investments, and advancements in automation transforming financial ecosystems.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
