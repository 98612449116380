import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Aos from "aos";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import whocan from "../../asset/images/career/whocanapply.webp";
import pro from "../../asset/images/career/programmefeature.webp";
import degree from "../../asset/images/career/graduatedegree.webp";
import mentorship from "../../asset/images/career/mentorship.webp";
import career from "../../asset/images/career/careerpath.webp";
import CareerForm from "../../Component/forms/CareerForm";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
  { label: 'Life as a Decimal', to: '/life-as-a-decimal' },
  { label: 'Our Culture', to: '/our-culture' },
  { label: 'Journey of a Decimal', to: '/journey-of-a-decimal' },
  { label: 'ज्ञानोदपि धनोदपि', to: '/dnyanodapi-dhanodapi' },
  { label: 'Current Openings', to: '/current-openings' },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function CreatingOpportunity() {
  const [value, setValue] = React.useState(3);
  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [value1, setValue1] = React.useState(options[3]);

  return (
    <>
      <Helmet>
        <title>Transform Lives with ज्ञानोदपि धनोदपि: Graduate Hiring Program</title>
        <meta
          name="description"
          content="Join ज्ञानोदपि धनोदपि to earn while you learn, gaining corporate experience and post-graduate opportunities for talented graduates."
        />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/dnyanodapi-dhanodapi"
        />
      </Helmet>

      <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(0)} component={Link} to="/life-as-a-decimal" />
                  <Tab label="Our Culture" {...a11yProps(1)} component={Link} to="/our-culture" />
                  <Tab label="Journey of a Decimal" {...a11yProps(2)} component={Link} to="/journey-of-a-decimal" />
                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(3)} className="fw6" component={Link} to="/dnyanodapi-dhanodapi" />
                  <Tab label="Current Openings" {...a11yProps(4)} component={Link} to="/current-openings" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
        <Container maxWidth="lg" className="bdr">
          <Box className="alltxfieldlg">
            <Autocomplete
              disablePortal
              options={options}
              value={value1}
              onChange={(event, newValue1) => setValue1(newValue1)}
              fullWidth
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <Link to={option.to}>
                  <ListItemButton {...props}>
                    <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                  </ListItemButton>
                </Link>
              )}
            />
          </Box>
        </Container>
      </Box>

      <TabPanel value={value} index={3} pt={5}>
        <Container maxWidth="lg" className="bdr">
          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Creating Opportunities, Transforming Lives
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant="h6" className="black jst">
                    Earn while they learn with <strong>ज्ञानोदपि धनोदपि</strong>
                    , enabling talented graduates to fulfil their needs while
                    aiming for growth in the business ladder.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    Decimal Point Analytics recognizes the potential of
                    brilliant and motivated graduates who want to advance their
                    education and develop successful professions. However, we
                    recognize that financial constraints and family obligations
                    can frequently provide significant impediments to reaching
                    these objectives. That is why we are pleased to present our
                    Graduate Hiring Program, named as{" "}
                    <strong>ज्ञानोदपि धनोदपि</strong>.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* </Container> */}
          </Box>

          <Divider />

          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Introducing our Program
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant="h6" className="black jst">
                    <strong>ज्ञानोदपि धनोदपि</strong> is not just a program;
                    it's a transformative journey designed to empower
                    academically outstanding graduates from economically
                    disadvantaged families. This programme combines corporate
                    experience with post-graduate academic opportunities, giving
                    students the skills, information, and support they need to
                    succeed in today's competitive market.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* </Container> */}
          </Box>

          <Divider />

          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Details of the Program
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box className="al_center" mb={2}>
                  <Typography variant="h5" className="blue al_left">
                    Who can Apply
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    className="black al_left listdetails"
                  >
                    <ul>
                      <li>
                        <Typography variant="h6" className="black jst">
                          Graduates who have qualification M.Sc. Economics/ M.A.
                          Economics/ M.A. Mathematics/ M.Sc.
                          Statistics/Math/Physics/ B. Com/ M. Com/ B.Sc./ BBA/
                          BAF/ BMS (Accounting & Finance).
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6" className="blue jst ">
                          Shortlist candidates based on following criteria:
                        </Typography>
                      </li>
                    </ul>
                  </Typography>

                  <ul type="">
                    <li>
                      <Typography variant="h6" className="black jst">
                        Average of 10th + 12th - <strong>70%</strong> and above.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6" className="black jst">
                        Graduation - <strong>60%</strong> and above.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6" className="black jst">
                        Ration Card Type.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6" className="black jst">
                        All students who have graduated{" "}
                        <strong>post-2022</strong> are eligible to apply.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6" className="black jst">
                        <strong>Application form</strong> will be online, and
                        details shared later.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6" className="black jst">
                        The <strong>selection of candidates</strong> will be
                        basis tests and evaluations done by <strong>DPA</strong>
                        . The results of the assessments will be shared with the
                        institute and students <strong>post 15 days</strong>.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className="al_center">
                  <img loading="lazy"  src={whocan} className="topbanner4" alt="Who Can" />
                </Box>
              </Grid>
            </Grid>
            {/* </Container> */}
          </Box>

          <Divider />

          <Box
            className="section"
            component="section"
            style={{ paddingTop: "5px" }}
          >
            {/* <Container maxWidth="lg" className="bdr"> */}

            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_left" mt={2}>
                    <Typography variant="h2" className="black">
                      Why Choose ज्ञानोदपि धनोदपि ?
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Earn a Post-graduate degree while working in a Corporate
                      Environment.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Gain practical experience in a corporate setting while
                          furthering your education in your chosen subject.
                        </li>
                        <li>
                          Develop practical and theoretical skills to advance
                          your career.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={degree} className="topbanner4" alt="Who Can" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Mentorship and Training by Industry Experts.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Receive personalised assistance and support from
                          seasoned specialists at Decimal Point Analytics.
                        </li>
                        <li>
                          Join special training sessions and workshops to
                          improve your abilities and broaden your knowledge.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy" 
                      src={mentorship}
                      className="topbanner4"
                      alt="Who Can"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Unconventional Career Path with Exciting Opportunities
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Create your own route to success by combining job
                          experience and higher education.
                        </li>
                        <li>
                          Gain a competitive edge over peers by combining
                          practical experience and academic proficiency.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={career} className="topbanner4" alt="Who Can" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Program Features
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          The candidates will undergo an Internship program for
                          the first six months.
                        </li>
                        <li>
                          Post satisfactory completion of the internship,
                          candidates would be taken on-rolls of DPA.
                        </li>
                        <li>
                          For the next 2 years each candidate will undergo a
                          series of trainings, spanning across all departments
                          at DPA. These trainings will be coupled with job
                          responsibilities for ease of application and
                          understanding.
                        </li>
                        <li>
                          Across the next two years, those who excel in work
                          performance shall be sponsored for postgraduation by
                          DPA.
                        </li>
                        <li>
                          Employees on successful completion of post-graduation
                          shall be eligible for higher roles in the
                          organization.
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="h6" className="black jst">
                      Our goal is to establish a symbiosis between society and
                      our organisation. In our country, we have many outstanding
                      young brains with enormous potential who are hampered by
                      financial restrictions or familial concerns. We wish to
                      help these students develop their skill sets while also
                      offering them a business placement with rewarding job
                      option.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={pro} className="topbanner4" alt="Pro" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box className="al_center" mt={3}>
              <CareerForm />
              <a
                href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/%E0%A4%9C%E0%A5%8D%E0%A4%9E%E0%A4%BE%E0%A4%A8%E0%A5%8B%E0%A4%A6%E0%A4%AA%E0%A4%BF%20%E0%A4%A7%E0%A4%A8%E0%A5%8B%E0%A4%A6%E0%A4%AA%E0%A4%BF.pdf"
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip title="Download PDF" arrow placement="right">
                  <IconButton className="d-icobtn">
                    <DownloadForOfflineIcon
                      fontSize="large"
                      className="skblue"
                    />
                  </IconButton>
                </Tooltip>
              </a>
            </Box>
            {/* </Container> */}
          </Box>
        </Container>
      </TabPanel>
    </>
  );
}
