import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import boarding from "../../asset/images/career/onboarding.webp";
import kick from "../../asset/images/career/learninggrowth.webp";
import take from "../../asset/images/career/professionalgrowth.webp";
import "aos/dist/aos.css";
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
  { label: 'Life as a Decimal', to: '/life-as-a-decimal' },
  { label: 'Our Culture', to: '/our-culture' },
  { label: 'Journey of a Decimal', to: '/journey-of-a-decimal' },
  { label: 'ज्ञानोदपि धनोदपि', to: '/dnyanodapi-dhanodapi' },
  { label: 'Current Openings', to: '/current-openings' },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function JourneyOfADecimal() {
  const [value, setValue] = React.useState(2);
  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [value1, setValue1] = React.useState(options[2]);

  return (
    <>
      <Helmet>
        <title>Onboarding at Decimal Point Analytics: Your Path to Professional Growth</title>
        <meta
          name="description"
          content="Begin your journey at Decimal Point Analytics with supportive onboarding, clear career paths, and a culture that fosters growth and innovation."
        />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/journey-of-a-decimal"
        />
      </Helmet>

      <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(0)} component={Link} to="/life-as-a-decimal" />
                  <Tab label="Our Culture" {...a11yProps(1)} component={Link} to="/our-culture" />
                  <Tab label="Journey of a Decimal" {...a11yProps(2)} component={Link} to="/journey-of-a-decimal" />

                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(3)} className="fw6" component={Link} to="/dnyanodapi-dhanodapi" />
                  <Tab label="Current Openings" {...a11yProps(4)} component={Link} to="/current-openings" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
        <Container maxWidth="lg" className="bdr">
          <Box className="alltxfieldlg">
            <Autocomplete
              disablePortal
              options={options}
              value={value1}
              onChange={(event, newValue1) => setValue1(newValue1)}
              fullWidth
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <Link to={option.to}>
                  <ListItemButton {...props}>
                    <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                  </ListItemButton>
                </Link>
              )}
            />
          </Box>
        </Container>
      </Box>

      <TabPanel value={value} index={2} pt={5}>
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">
            <Box mb={5}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center">
                    <Typography variant="h2" className="black">
                      The Journey a Decimal Undertakes
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      On-boarding
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" className="black jst listdetails">
                      The first step into Decimal Point Analytics, you know that
                      you are at the right place where professional growth has
                      no boundaries for those with desire and passion to
                      continually learn, contribute and grow.
                    </Typography>

                    <Typography variant="h6" className="black jst listdetails">
                      While recruiting from campuses, we empathize with the
                      typical uncertainties that prevail in the minds of
                      newcomers, as the transition from the ‘University’ to the
                      ‘Corporate’ is always a challenging one. Therefore, one of
                      our key emphasis is to make your transition a very
                      comfortable and enjoyable experience. We do this by
                      providing you with a very friendly, transparent and open
                      culture consisting of induction programs, orientation &
                      acclimatization and buddy programs for bonding right at
                      the beginning of your professional journey with us. We
                      ensure that people who join us after gaining some industry
                      experience are equally thrilled by the exposure they get
                      while working on projects and assignments which not only
                      utilize their previous experience but add new dimensions
                      to their overall professional progression and growth.
                    </Typography>

                    <Typography variant="h6" className="black jst listdetails">
                      Our flat organization structure is meant to ensure that
                      the communication chain isn’t too long and you find people
                      at all levels easily accessible and approachable.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={boarding} className="topbanner4" alt="Boarding" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Kick-starting your journey of learning and growth:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" className="black jst listdetails">
                      Our clearly defined career paths, Fast Track career
                      Program, frequent feedback and review sessions, various
                      learning and development interventions and ongoing
                      coaching and mentoring programs are all designed to
                      collaborate with you and drive you towards achieving your
                      professional objectives and aspirations. Here, you will
                      also witness and participate in various discussion
                      platforms, town-hall meetings, suggestion boxes and many
                      more such initiatives, which are a part of an environment
                      that strongly promotes creativity, innovation and
                      initiative among Decimals. Our employee engagement
                      programs are designed to ensure balanced growth. While,
                      you would love being a part of the family of Decimals, we
                      want you to have a proper work-life balance in a way that
                      you never fail to meet your personal commitments and
                      priorities on time. In fact, employee satisfaction is one
                      of the key contributors to Decimal Point Analytics’
                      consistent excellence.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={kick} className="topbanner4" alt="kick" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Taking cruise-control of your professional growth
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" className="black jst listdetails">
                      Our work culture is defined by our Mantra “Generate Alpha,
                      Reduce Risk”. We always strive to ‘achieve the ultimate’
                      for our clients as well as employees within this
                      framework. This gives opportunity to Decimals – to ‘go for
                      the ultimate’, creating value for all stakeholders in the
                      process and to fast track the professional growth.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={take} className="topbanner4" alt="Take" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </TabPanel>
    </>
  );
}
