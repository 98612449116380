import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import Banner from '../../../../../../asset/images/esg/esg_blog_pages/banner_indian_asset_managers.webp'
import Banner2 from '../../../../../../asset/images/esg/esg_blog_pages/net_zero_alignment.webp'
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'

export default function IndianAssetManagers() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);




  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <title>Sustainability in India: Time for Asset Managers to Lead</title>
        <meta name="description" content="Explore the need for Indian asset managers to drive the sustainability agenda and commit to Net Zero targets." />
        <meta name="keywords" content="Sustainability, India, Asset Managers, Net Zero, Climate Risk, ESG, GreenPulse, Emissions Reduction, Corporate Goals, SBTi" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/indian-asset-managers-need-to-take-the-lead-in-net-zero-targeting" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>



      <Box className='section sec_bg' component='section' id='bank_runs'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography className='mainhead blue al_center'>
                  Indian Asset Managers need to take the lead in Net Zero targeting
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Indian Asset Managers need to take the lead in Net Zero targeting" />


          <Box className='whitebx' mt={1}>

            <Typography variant='h6' className='black jst' gutterBottom>
              The progress of sustainability movement in India presents an interesting contrast from the developed markets. While globally, asset owners and asset management companies had taken the leading role in driving focus on sustainability, particularly with respect to Net Zero and climate risk, asset managers in India have not played much role in pushing for sustainability focus. It is time asset managers in India became more active in driving the sustainability agenda.
            </Typography>

            <Typography variant='h6' className='black jst' gutterBottom>
              Most leading global fund houses have announced their own Net Zero targets. The Net Zero Asset Managers initiative (NZAMi) is a platform of asset managers making commitment to achieve Net Zero portfolios. It has over 300 signatories, which manage $57 trillion in assets. All these entities have made public pledges to achieve Net Zero financed emissions for whole or part of their portfolios. These typically involve reducing financed emissions of their assets by 40-50% by 2030, and entirely by 2050. As an example, a leading global asset manager has declared a Net Zero alignment target of 25% of assets under management by 2025 and 80% of assets by 2030.
            </Typography>

            <Box >
              <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
            </Box>

            <Typography variant='h6' className='black jst' gutterBottom>
              No Indian asset manager seems to have declared Net Zero targets. We believe the time is now ripe for Indian asset managers to make public Net Zero commitments, without fear of not achieving the target, since the corporate sector is making good progress in the pursuit of sustainability goals.
            </Typography>

            <Typography variant='h6' className='black jst' gutterBottom>
              Several hundred Indian corporates have disclosed plans for achieving Net Zero. This is typically stated as the targeted reduction in emissions in a certain time period. For example, Tata Motors has committed to achieved Net Zero emissions in its passenger vehicle (PV) business by 2040 and its commercial vehicle business by 2045. Even India’s oil PSUs have set aggressive net zero goals. ONCG, for example, has declared intent to achieve carbon neutrality by 2038.
            </Typography>

            <Typography variant='h6' className='black jst' gutterBottom>
              Just declaring a target does not constitute adequate action, there needs to be a credible plan to achieve Net Zero. Companies across the globe get their Net Zero target programs validated by third party firms. A non-profit platform called the Science Based Targets Initiative (SBTi) has emerged as the most popular platform for Net Zero target validation. Over 300 Indian companies are working with SBTi for target validation, over 170 have already got their targets validated.
            </Typography>

            <Typography variant='h6' className='black jst' gutterBottom>
              Decimal Point Analytics (DPA) has a Net Zero Alignment tracking solution called ESG GreenPulse. We checked the data for Nifty-50 companies in ESG GreenPulse. 37 companies, or 74% of Nifty companies, have announced Net Zero targets. 18% of companies already have validated Net Zero targets. We expect to see rapid progress in validated Net Zero targets in the coming months.
            </Typography>

            <Grid container spacing={3} alignItems="center" justifyContent='center'>
              <Grid item lg={8} md={9} sm={11} xs={12}>
                <Box py={2}>
                  <img loading="lazy"  src={Banner2} className='w100' alt='Banner' />
                </Box>
                <Typography variant='h6' className='black al_center' gutterBottom><em>Source: Company filings, ESG GreenPulse by Decimal Point Analytics </em></Typography>
              </Grid>
            </Grid>

            <Typography variant='h6' className='black jst' gutterBottom>
              The trend suggests that a large portion listed companies will be able to deliver Net Zero alignment by 2030, the cut off date for intermediate Net Zero targets. The corollary of this is – Fund houses should not hold back on declaring Net Zero goals as well.
            </Typography>

            <Typography variant='h6' className='black jst' gutterBottom>
              We recommend Indian asset managers seriously consider increasing their focus on promoting the cause of emission reduction. The DPA team can assist fund houses in devising a plan to set Net Zero targets and measuring portfolio alignment to Net Zero.
            </Typography>

            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}

          </Box>


          <ESGBlogsBack />

        </Container>

      </Box>


    </>
  )
}
