import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_tech_realestate.webp'

import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

export default function FutureofBridgeLending() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/white-papers%2FWhitepaper%20on%20Shaping%20the%20Future%20of%20Bridge%20Lending%20with%20Technology.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>The Future of Tech-Driven Bridge Lending Operations</title>
                <meta name="description" content="Discover how technology is transforming bridge lending. Optimize real estate financing with automation, AI-driven underwriting, and advanced risk assessment." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/technology-driven-bridge-lending" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>


                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h2' className='subhead skblue  al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            The Future of Bridge Lending: How Technology is Reshaping Real Estate Financing
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                Complex origination, demanding underwriting, and resource-intensive due diligence are slowing down your bridge lending operations, but they don’t have to. 
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Discover how <strong>automation, AI-powered risk assessment, and blockchain</strong> can streamline your
                                                    workflows, <strong>cut processing times by up to 60%</strong>, enhance risk evaluation, and <strong>maximize portfolio
                                                    returns.</strong>
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                  <strong>Key takeaways:</strong>
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                            How automation accelerates loan processing and reduces errors   
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                            The role of AI in making sharper, data-driven underwriting decisions
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                            How blockchain enhances security, transparency, and efficiency in lending
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                   <strong>Gain the competitive edge your lending operations need.</strong> 
                                                </Typography>

                                            </Box>

                                            <Box className='graybbx2222' mt={2}>
                                                <ViewFullWhitepaper reportby={"The Future of Bridge Lending: How Technology is Reshaping Real Estate Financing"} link={link} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>


                        </Grid>


                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box >
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
