import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { esgFormApi } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

export default function ViewFullArticles(props) {

    const schema = yup.object().shape({
        name: yup.string().typeError("Required field").required("Name is required"),
        email: yup.string().typeError("Required field").required("Email is required").email().max(75),
        company: yup.string().typeError("Required field").required("Company name is required"),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    let report_type = "Article";
    try {
        report_type = props.report_type;
    } catch (e) {
    }

    const onSubmit = async (data) => {
        const payload = {
            email: data.email,
            report_for: props.reportby,
            report_type: report_type
        };

        if (data.name.trim() !== "") {
            payload.name = data.name;
        }

        if (data.company.trim() !== "") {
            payload.company = data.company;
        }

        const d1 = await esgFormApi(payload)
        if (d1.data.status === true) {
            window.open(
                props.link.toString(),
                "_blank"
            );
        }
        else {
            toast.error("Please Enter Valid details before Downloading", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        localStorage.setItem("isOpen", 1);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        //localStorage.setItem("isOpen", 0);
    };

    return (
        <>

            {/* <Button variant="contained" className='dpabtn_sm1' onClick={handleClickOpen}>
                {props.cta_btn_name}
            </Button>

            <Button variant="contained" className='dpabtn_sm1' onClick={handleClickOpen}>
                {props.cta_btn_name}
            </Button> */}

            <Typography variant="h4" className="black align_c">
                <Button variant="text" className="artical_cta_btn" disableRipple onClick={handleClickOpen}>Click here</Button>to explore the complete methodology
            </Typography>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="b_modal teammodal"
            >
                <DialogTitle
                    className="feedname"
                    id="customized-dialog-title"
                    onClose={handleClose}
                ></DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant="h4" className="black">
                                        Download the Article
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="alltxfieldlg" mt={1}>
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Name *"
                                                variant="standard"
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                error={!!errors.name}
                                                helperText={errors.name?.message}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="alltxfieldlg" mt={1}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Email *"
                                                variant="standard"
                                                fullWidth
                                                error={!!errors.email}
                                                helperText={errors.email?.message}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="alltxfieldlg" mt={1}>
                                    <Controller
                                        name="company"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Company Name *"
                                                variant="standard"
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                error={!!errors.company}
                                                helperText={errors.company?.message}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center" mt={1} mb={2}>
                                    <Button variant="contained" className="dpabtn_2" type="submit" onClick={handleSubmit(onSubmit)}>
                                        Download Now
                                    </Button>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );

}
