import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Casestudyback from '../../../../Component/Casestudyback'
import Banner from "../../../../asset/images/insight/case_studies/banner_achieving_nearzero.webp"
import { Link } from 'react-router-dom';

export default function AchievingNearZero() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Achieving Near-Zero Errors & 70% Faster Data Management for a $27B Asset Manager</title>
                <meta name="description" content="A $27B global asset manager streamlined data management by 70% and achieved near-zero errors with Decimal Point Analytics' CRM optimization and automated data processing." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-study/global-asset-manager-crm-automation-efficiency" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    How <span className='lower'>a</span> $27B Asset Manager Achieved 70% Faster Data Processing & Near-Zero Errors
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A New York-based global alternative asset manager, overseeing $27 billion in assets, sought to enhance its data management efficiency across public and private market segments. With vast volumes of investor and financial data, the firm needed a robust <strong>CRM data management solution</strong> to streamline operations, improve decision-making, and ensure data integrity.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm faced several data management inefficiencies that hindered operational effectiveness:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High manual intervention</strong> – Excessive reliance on manual data entry increased errors and operational workload.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inconsistent and inaccurate data</strong> – Discrepancies in CRM records affected investor engagement and reporting accuracy.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Slow data retrieval</strong> – Delayed access to crucial information impacted decision-making and responsiveness.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reactive error handling</strong> – The lack of proactive data validation led to recurring quality issues.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Recognizing the need for a scalable, automated approach, the firm leveraged advanced solutions to transform its CRM and data management framework.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Solution Delivered
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Designed a <Link to="/customer-relationship-management-solutions" className="link">comprehensive, automation-driven</Link> solution to enhance CRM efficiency and ensure data accuracy.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated Data Processing & Quality Control</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Deployed an <strong>AI-driven validation framework</strong> to eliminate manual data inconsistencies.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Introduced <strong>real-time anomaly detection</strong> and weekly sanity checks to proactively correct errors.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Accelerated Data Retrieval & Unified Access</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Optimized CRM architecture for <strong>faster, structured data access,</strong> reducing retrieval times significantly.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Established a <strong>centralized, unified data view</strong> to enable seamless collaboration across teams.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced CRM Accuracy & Reliability</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Implemented a <strong>self-correcting error-handling mechanism,</strong> reducing database discrepancies to near zero.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Ensured <strong>consistent, automated updates,</strong> increasing data reliability and investor confidence.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Business Impact
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                DPA’s <strong>data automation and CRM optimization strategy</strong> delivered measurable improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>70% faster data processing,</strong> allowing teams to focus on high-value tasks.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Near-zero errors,</strong> significantly improving CRM reliability and investor insights.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Proactive error detection,</strong> reducing reactive fixes and improving long-term data quality.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Accelerated decision-making,</strong> with instant access to accurate, structured data.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced operational efficiency,</strong> cutting down data review time from hours to just minutes.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Takeaway
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                For asset managers handling vast investor datasets, a <strong>structured, automation-driven CRM strategy</strong> is essential. By leveraging <strong>data analytics and process automation,</strong> firms can enhance <strong>data accuracy, eliminate inefficiencies, and make smarter, faster decisions.</strong>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Take Control of Your Data – Unlock Accuracy & Efficiency
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Struggling with <strong>CRM inefficiencies and data accuracy issues?</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            <Link to="/" className="link">Decimal Point Analytics</Link> helps asset managers achieve <strong>faster data processing, near-zero errors, and seamless decision-making. <Link to="/contact-us#contact_form" className="link">Get in touch today</Link></strong> to optimize your <strong>CRM and data management strategy.</strong>
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}