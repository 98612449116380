import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import Blogback from '../../../Component/Blogback'
import DPAProfile from '../../../Component/DPAProfile';
import { Link } from 'react-router-dom';

export default function ImpactofCovidonSBL() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <title>Automated Loan Management & SBA Underwriting | Decimal Point</title>
                <meta name="description" content="Boost SBA loan underwriting and lending operations with automated loan management, customer due diligence, and efficient SBA 7a processing services." />
                <meta name="keywords" content="Automated loan management system, Loan underwriting services, KYC support, Customer due diligence services" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/sba-underwriting-efficiency-erc-sba-loans" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                The Impact of COVID-19 on Small Business Lending
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                March 17, 2020
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            The COVID-19 pandemic has been a game-changer on many fronts. It has led to a transformation in lifestyles across the globe, a shift in mindsets towards health awareness, and a new working style across industries. Work from home (WFH) has become the norm during this period, signaling a strong shift in employer-employee dynamics, along with the intervening period of the ‘Great Resignation’. Small and medium businesses that were dependent on face-to-face customer connections were decimated; the ones that survived had an online presence or were able to create one in a short period of time.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>How ERC Loans  <span className='lower'> and  </span> SBA Loans Helped Small Businesses Survive  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            In the midst of this turmoil, the US government released the ERC (Employee Retention Credit) loans and SBA (Small Business Administration) loans programs, which were formalized under the Coronavirus Aid, Relief, and Economic Security Act or the CARES Act (2020), and later modified under the 2021 and 2022 legislation. This Act aimed to support businesses that were having a hard time due to the pandemic and were unable to meet their payroll costs.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            ERC loans were intended to support businesses that were able to demonstrate an adverse impact on their functioning from the first quarter of 2020 onwards, either due to lockdown restrictions or due to federal or state government orders restraining the normal functioning of their businesses. Companies that continued with their headcount during this period of uncertainty and were meeting their payroll commitments on a quarter-by-quarter basis were eligible for the ERC loan.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>The Role <span className='lower'>of</span> SBA Lenders <span className='lower'>in</span> Supporting Small Businesses</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            <Link to="/small-business-administration" className='link'>SBA loans</Link> are a well-thought out program with a vast reach through their local chapters in the US. Many of these loans are offered with the option of using an empaneled mentor, who has multiple years of business experience and can provide effective advice to the business entrepreneur. A similar concept is well-embedded in Canada’s Future-preneur program, where using a mentor is mandatory in their scheme of things.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Under the CARES Act, SBA loans in the USA have been focused on supporting businesses that provide employment, where the US government is willing to guarantee up to 75% of the loan amount in case of a default or bankruptcy filing by the borrower. This opens up the field for multiple banking and non-banking lenders that have a huge potential market to service, who end up lending under this program with the backup of the US federal government, subject to certain terms and conditions of the underlying loan (size, business sector, past credit history, liquidity profile etc.).
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>Challenges <span className='lower'>for </span> Lenders  <span className='lower'>in</span>  Processing SBA Loans Efficiently</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            Since the ERC loans directly impact employee salaries, lenders need to make the application process as simple and easy as possible, make applications available online, and respond very quickly on the loan approval status. Automation of these processes enables lenders to optimize their reach and speed up TAT (turnaround time), in line with that required by businesses.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>Leveraging Automated Loan Management Systems  <span className='lower'>to  </span> Improve Underwriting </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            <Link to="/case-studies/Transforming-Banking-Operations-with-Advanced-Data-Analytics" className='link'>Decimal Point Analytics</Link> can provide a new technology solution that automates the workflow process defined to capture structured information provided by potential borrowers, evaluate the information based on the internal screening criteria of the lender, and arrive at an in-principle “Go / No Go” decision, then communicate the same to the borrowers. In case of a “Go” decision, the workflow is to be configured in line with the internal requirements of loan underwriting, including online approvals from credit approvers and an audit trail to track all changes being made to the loan documents at various stages in the approval process. The final loan approval letter will be generated online in a secure environment using the most up-to-date tools and technologies.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            With automated systems, lenders can perform detailed analyses of borrower profiles and financial history, ensuring efficient credit assessments. This technology supports <strong>loan underwriting services</strong> by expediting the evaluation of creditworthiness, facilitating faster approval processes, and providing borrowers with real-time updates. Additionally, automated systems can be tailored to integrate regulatory checks such as <strong>KYC support</strong> and <strong>customer due diligence services</strong>, ensuring compliance with industry standards while maintaining accuracy and speed.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h2' className='blue jst h2h4'>Benefits <span className='lower'>of </span> Outsourcing Underwriting Tasks <span className='lower'>to</span> DPA <span className='lower'>for</span> SBA Loans</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            For lenders overwhelmed with SBA and ERC loan applications, outsourcing to Decimal Point Analytics offers several advantages. By leveraging an <strong>automated loan management system</strong>, DPA ensures quick processing times and error-free documentation. Its offshore team delivers cost-effective <strong>loan underwriting services</strong>, maintaining high standards of accuracy and timeliness.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics robust processes also incorporate <strong>KYC support</strong>, ensuring that all borrowers meet identity verification and anti-money laundering compliance requirements. Additionally, DPA excels in <strong>customer due diligence services</strong>, conducting in-depth financial analysis and background checks to safeguard lenders against potential risks.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <Link to="/small-business-administration" className='link'>Click here to optimize your lending process now!</Link>
                        </Typography>


                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="The Impact of COVID-19 on Small Business Lending" />
                    </Box>



                    <Blogback />


                </Container>
            </Box>


        </>
    );
}
