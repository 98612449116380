import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_automating_fund.webp"
import Infographics from "../../../../asset/images/insight/case_studies/accelerating_fund_info.webp"
import { Link } from 'react-router-dom';



export default function AcceleratingFundAccounting() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Fund Accounting Automation for MENA Firm | Operational Efficiency</title>
                <meta name="description" content="Discover how a MENA asset management firm achieved 50% faster reporting with fund accounting automation, improving data accuracy, operational efficiency, and client satisfaction." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/reducing-fund-processing-time-by-for-a-mena-asset-management-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={7} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'> 
                                    Reducing Fund Processing Time <span className='lower'>by </span> 50% <span className='lower'>for a</span> MENA Asset Management Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A prominent asset management firm in the MENA region required a streamlined process to manage diverse data inputs and efficiently create fund accounting reports and automated factsheet creation. Their existing processes were time-consuming, impacting their ability to provide timely financial reporting updates to clients. By implementing fund accounting automation, the firm achieved faster turnaround times (TAT), improved NAV calculation accuracy, and significant gains in operational efficiency.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client faced several operational hurdles:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Time-Consuming Processes: </strong> Manual calculations and data updates resulted in lengthy processing times.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Diverse Data Inputs:  </strong> Handling varied data formats and inputs made integration into a single reporting system complex.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Impact of Corporate Announcements: </strong> Factoring in the effects of corporate actions on fund valuations was labor-intensive and prone to errors.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Automation  <span className='lower'>and </span> Streamlined Reporting
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                    <img loading="lazy"  src={Infographics} className="w100" alt=" Solution: Automation and Streamlined Reporting" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The automated solution delivered substantial improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 50% Reduction in Processing Time: </strong>  Cut down fund accounting and reporting time from 8 hours to 4 hours.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Higher Accuracy: </strong> Automated quality checks ensured consistent and error-free reporting.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Client Experience: </strong> Faster reporting enabled timely communication with clients, increasing satisfaction.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Efficient Fund Accounting Automation: </strong> Streamlined processes reduced manual workload, improving overall efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Custom Financial Reporting Solutions:  </strong> Tailored templates ensured relevance and accuracy in reporting.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Client Communication: </strong> Faster turnaround times strengthened the client’s ability to meet deadlines and expectations.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By automating fund accounting and integrating corporate announcements seamlessly, the MENA-based asset management firm significantly improved operational capabilities. This partnership highlights how financial data automation tools and expertise can transform time-intensive tasks into streamlined processes, delivering both time savings and enhanced accuracy.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Connect with Us</Link>  for Personalized Solutions! Let us tailor our services to meet your unique needs.
                            </Typography> 

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}