import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';
import Trade2 from "../../../asset/images/insight/blog_pages/need_of_trade2.webp";
import DPAProfile from '../../../Component/DPAProfile';

export default function Do_we_really_need_trade_wars() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Do we really need trade wars to shrink international trade-flows in Physical Goods? </h1>
                <title>Trade Wars: Do They Shrink Global Trade? | Decimal Point Analytics</title>
                <meta name="description" content="Explore the potential impact of additive manufacturing on global trade flows. Learn how localized production can reshape international trade and industries." />
                <meta name="keywords" content="Trade wars, global trade, physical goods, technological changes, additive manufacturing, 3D printing, localized production, economic batch size of one, CAD designs, material availability, combinatorial explosion, AI, international trade collapse, e-commerce, retailers, transporters" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/do-we-really-need-trade-wars" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>

            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Do we really need trade wars to shrink  international trade-flows in Physical Goods?
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                March 19, 2018
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>In the recent weeks the headlines are screaming about the impending trade wars, the expected shrinkage in global trade and its impact on the overall welfare of the humankind. </Typography>

                        <Typography variant='h6' className='black jst'>In this write-up, I attempt to show that very soon technological changes will lead to shrinkage in global trade.</Typography>

                        <Typography variant='h6' className='black jst'>The key feature of industrial revolution since early 19th century is lengthening of the supply chains, concentration of manufacturing in specialized workplaces of gigantic proportions – all for the quest of efficiency.</Typography>

                        <br />

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={Trade2} className='topbanner2' alt='Trade2' />
                        </Box>

                        <br />
                        <Typography variant='h6' className='black jst'>However, the new kind of manufacturing is on the horizon, additive manufacturing or 3d printing. It is just now moving from prototyping novelty to serious manufacturing for various applications. As additive manufacturing is at a cutting-edge intersection of computer science, materials science, thermodynamics and very recently optics and chemistry as well, we believe that this technique will enable production of goods at the places where consumers are <strong>converting locally available materials into desired products.</strong> </Typography>

                        <Typography variant='h6' className='black jst'>The elusive goal of economic batch size of one is within the reach of this generation. When we say economic batch size of one, we just do not mean the customization to the needs of individual consumer’s taste, but we also imply use of materials that are locally available in abundance.</Typography>

                        <Typography variant='h6' className='black jst'>So, in the not so distant future, we will not have huge ships carrying containers of goods from low production cost centers to high income centers; but CAD designs with bills of materials will be zipping over cyberspace from designers located in places with high creativity and education to places with low creativity and education. The designers will be receiving the relative cost of all available materials amenable for additive manufacturing across all consumption centers, apart from receiving the customization needs of consumers. As more and more materials are made amenable for additive manufacturing, we can expect combinatorial explosion of possible designs to such a great extent that only AI can assist the designers in making the most appropriate choices of designs.</Typography>

                        <Typography variant='h6' className='black jst'>In other words, in less than two decades, we predict a sharp slowdown in global trade of physical goods as additive manufacturing becomes even more practical and cost efficient. Pretty soon, we do not a Trump to cause a collapse in the international trade. What this means for current e-commerce giants, physical big box retailers, and transporters et al is matter of detailed analysis. Unfortunately, as usual, the stock market has not yet seen beyond the first curve it is facing.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Do we really need trade wars to shrink international trade-flows in Physical Goods" />
                    </Box>





                    <Blogback />



                </Container>
            </Box>


        </>
    );
}
