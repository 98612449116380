import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';
import Podcast_Data from "./Podcast_Data";

import ForwardIcon from '@mui/icons-material/Forward';


export default function Podcast_New() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>Financial Markets Podcasts | Decimal Point Analytics</title>
        <meta name="description" content="With our podcast, Decimal Point Analytics aims to provide you with valuable insights & actionable information to navigate ever-evolving financial markets" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/podcast" />
      </Helmet>

      <Box className='section2 sec_bg ourstrength' component='section'>
        <Container maxWidth='lg' className='bdr'>
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h2" className="black">
                  Podcast
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center synopsic_bx" mb={3}>
                <Typography variant="h6" className="black jst" gutterBottom>
                  At Decimal Point Analytics, we believe in the power of knowledge and the impact it can have on decision-making. With our podcast, we aim to provide you with valuable insights and actionable information that can help you navigate the ever-evolving financial markets.
                </Typography>

                <Typography variant="h6" className="black jst">
                  From discussing emerging technologies like artificial intelligence and blockchain to examining the role of data analytics in investment strategies, our podcast covers a wide range of topics that are relevant to investors, financial professionals, entrepreneurs, or simply interested in understanding the dynamics of the global economy, our engaging conversations with industry experts provide valuable insights to keep you informed and empowered.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box id="podcast" className="reportinfo">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
              <Grid container spacing={2} alignItems="center" justifyContent='center'>

                {Podcast_Data.map((item, index) => (
                  <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                    <Box className='reportinfo'>
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          >
                          </Box>
                          <Box className="reporttitle_box">
                            <Typography className="reporttitle two">
                              {item.heading}
                            </Typography>
                          </Box>
                          <Typography variant='h6' className='skblue'>
                            {item.date}
                          </Typography>
                          <Box className="line"></Box>
                          <Box className="reporttitle_box">
                            <Typography variant="h6" className="black al_left thr">
                              {item.subheading}
                            </Typography>
                          </Box>
                          <Box className='flx_fs al_left'>
                            <Box>
                              <Typography variant='h6' className='skblue lh20' gutterBottom>
                                Watch Now
                              </Typography>
                            </Box>
                            <Box mr={3}>
                              <ForwardIcon className='skblue fast_forward' fontSize='small' />
                            </Box>
                          </Box>
                        </Box>
                      </a>
                    </Box>
                  </Grid>
                ))}

              </Grid>
            </Grid>
          </Container>
        </Box>

      </Box >

      <Writemessage3 pageVisited="Podcast" />
    </>
  );
}
