import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "../asset/images/twitter_new.webp";
import { Link,useHistory } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Subscribed_Successfully({msg = "Thank you for subscribing to our newsletter! We will get back to you at the earliest."}) {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const history = useHistory(); 

  return (
    <>
      <Box className="mainsection" component="section">
        <Container maxWidth="lg">
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box id="submit-message"  mb={6} mt={"50px"}>
                <Typography variant="h4" className="black al_center">
                  {" "}
                  {msg}
                  {" "}
                </Typography>
                <Typography variant="h4" className="blue al_center">
                  {" "}
                  In the meantime, we invite you to follow us and stay connected with our latest updates and news. 
                </Typography>
              </Box>
            </Grid>


            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box className="whitebx al_center sendmsg">
                <a
                  href="https://www.linkedin.com/company/decimalpointanalytics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton className="icbg2">
                    {" "}
                    <LinkedInIcon className="msgicon linkedin" />{" "}
                  </IconButton>
                  <Box mt={3}>
                    <Typography variant="h6" className="blue">
                      {" "}
                      Connect with us On <strong> LinkedIn </strong>
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>


            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box className="whitebx al_center sendmsg">
                <a
                  href="https://twitter.com/DecimalP"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton className="icbg2">
                    {" "}
                    <img loading="lazy"  src={TwitterIcon} alt="twitter" className="msgImg twitter" />
                  </IconButton>
                  <Box mt={3}>
                    <Typography variant="h6" className="blue">
                      {" "}
                      Follow us On <strong> Twitter </strong>
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>


            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box className="whitebx al_center sendmsg ">
                <a
                  href="https://www.instagram.com/decimalpointanalytics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton className="icbg2">
                    {" "}
                    <InstagramIcon className="msgicon insta" />{" "}
                  </IconButton>
                  <Box mt={3}>
                    <Typography variant="h6" className="blue">
                      {" "}
                      Like us On <strong> Instagram </strong>
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>


            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box className="whitebx al_center sendmsg">
                <a
                  href="https://www.facebook.com/decimalpointanalyticspl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton className="icbg2">
                    {" "}
                    <FacebookIcon className="msgicon fb" />{" "}
                  </IconButton>
                  <Box mt={3}>
                    <Typography variant="h6" className="blue">
                      {" "}
                      Connect with us On <strong> Facebook </strong>
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" my={"50px"}>
                <Link >
                  <Button
                    variant="contained"
                    className="dpabtn_2"
                    startIcon={<ArrowBackIcon />}
                    onClick = {()=>history.goBack()}
                  >
                    Back
                  </Button>
                </Link>
              </Box>
            </Grid>

          </Grid>
        </Container>
      </Box>
    </>
  );
}
