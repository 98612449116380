import React from "react";
import { Typography, Box, } from "@mui/material";

function Event_Design(props) {
  const {link, imgsrc, heading, date } = props;

  return (
    <a href={link}>
      <Box className='reportm_bg'>
        <Box
          className="reportimg_bg"
          style={{
            backgroundImage: `url(${imgsrc})`,
          }}
        ></Box>
        <Box className="reporttitle_box" mt={1}>
          <Typography className="reporttitle two" title={heading}>{heading}</Typography>
        </Box>
        <Box className='line'></Box>
        <Typography variant='h4' className='black'>
          {date}
        </Typography>
      </Box>
    </a>
  );
}

export default Event_Design;
