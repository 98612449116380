import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Aos from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom";
import { newsletterApi } from "../../Component/api/api";
import "../../Style.css";
import * as yup from "yup";
import { Formik, Field, Form, } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Container } from "@mui/material";

export default function NewsletterContact() {

    const [loader, setLoader] = React.useState(false);

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const emailreg = /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;

    const nameValidation = /^[A-Za-z\s]+$/;
    const history = useHistory();

    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
    };


    const validationSchema = yup.object().shape({
        firstname: yup
            .string()
            .typeError("Required feild")
            .required("First name is required")
            .max(100)
            .matches(nameValidation, "Enter valid first name"),
        lastname: yup
            .string()
            .typeError("Required feild")
            .required("Last name is required")
            .max(100)
            .matches(nameValidation, "Enter valid last name"),
        email: yup
            .string()
            .typeError("Required feild")
            .required("Email is required")
            .matches(emailreg, "Email is not valid"),

        tandc: yup.bool().oneOf([true], "You must check the checkbox"),
        tandce: yup.bool(),
    });

    const handleKeyUp = (event, fieldName) => {
        const value = event.target.value;
        if (/\d+/.test(value)) {
            if (fieldName === 'firstname') {
                setFirstNameError('First name should not contain numbers');
            } else if (fieldName === 'lastname') {
                setLastNameError('Last name should not contain numbers');
            }
        } else {
            if (fieldName === 'firstname') {
                setFirstNameError('');
            } else if (fieldName === 'lastname') {
                setLastNameError('');
            }
        }
    };

    const onSubmit = (values) => {

        const formData = {
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            obtain_email: values.tandc,
        };
        handleFormSubmit(formData);
    };

    const handleFormSubmit = (values) => {
        post(values);
    };

    const post = async (formData) => {
        setLoader(true);
        const message = "Oops, something went wrong, Please try again leter.";
        try {
            const { data, status } = await newsletterApi(formData);
            if (status === 200) {
                if (data.status === true) {
                    history.push("/subscribed-success");
                } else {
                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
            setLoader(false);
        } catch (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setLoader(false);
        }
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Container maxWidth="lg" className="reportinfo ">
                <Box className="al_center" >
                    <Typography variant="h1" className=" fw5 black mb0 newh1">
                        Subscribe to our newsletter <span className="blue">“Decimal Chronicles”</span>
                    </Typography>
                    <Typography variant="h6" className="fw4 black">
                    Stay ahead with curated intelligence, industry perspectives, and deep-dive reports made for decision makers.
                    </Typography>
                </Box>

                <Box mt={4} id="get_proposal_formbg" className="whitebx">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        validateOnChange={true}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, setFieldError, isSubmitting, touched, errors }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <Box className="alltxfieldlg">
                                            <Field
                                                as={TextField}
                                                id="filled-basic"
                                                label="First Name *"
                                                variant="standard"
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                name="firstname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onKeyUp={(event) => handleKeyUp(event, 'firstname')}
                                                error={touched.firstname && Boolean(errors.firstname)}
                                                helperText={
                                                    (touched.firstname && errors.firstname) ? errors.firstname : firstNameError
                                                }
                                                FormHelperTextProps={{ style: { color: 'red' } }}

                                            />
                                        </Box>
                                    </Grid>


                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <Box className="alltxfieldlg">
                                            <Field
                                                as={TextField}
                                                id="filled-basic"
                                                label="Last Name *"
                                                variant="standard"
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                name="lastname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onKeyUp={(event) => handleKeyUp(event, 'lastname')}
                                                error={touched.lastname && Boolean(errors.lastname)}
                                                helperText={
                                                    (touched.lastname && errors.lastname) ? errors.lastname : lastNameError
                                                }
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box className="alltxfieldlg ">
                                            <Field
                                                as={TextField}
                                                id="filled-basic"
                                                label="Email Address *"
                                                variant="standard"
                                                name="email"
                                                inputProps={{ maxLength: 100 }}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                                fullWidth
                                            />
                                        </Box>
                                    </Grid>




                                    <Grid item lg={2} md={12} sm={12} xs={12}>
                                        {loader ? (
                                            <LoadingButton loading={true} variant="outlined" disabled>
                                                disabled
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    handleSubmit(onSubmit)
                                                }}
                                                fullWidth
                                                variant="contained"
                                                className="dpabtn_1"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>


            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
