import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/article_pages/banner_agile.webp'
import ViewFullArticles from '../../../Component/forms/ViewFullArticles';

import EmployeeProfile from '../../../Component/EmployeeProfile';
import VP from '../../../asset/images/team/vaibhav_poonekar.webp'


export default function LeveragingAgileScrum() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fupload_doc%2Fpdf%2Fagile_scrum_and_cpm_detailed.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Integrating Agile Scrum and Critical Path Method for Enhanced Project Management</title>
                <meta name="description" content="Discover how integrating Agile Scrum and CPM enhances project management, improves risk management, and ensures timely delivery." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/leveraging-agile-scrum-and-critical-path-method-cpm-for-enhanced-software-project-management" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Insight
                                </Typography>
                                <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                    Leveraging Agile Scrum and Critical Path Method (CPM) for Enhanced Software Project Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className="" pt={2}>
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box className='whitebx'>
                        <Box>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                In an era where timely delivery and quality are paramount in software engineering, project managers often grapple with balancing flexibility and precision. Agile Scrum is renowned for its adaptability and iterative approach, while the Critical Path Method (CPM) excels in identifying key tasks critical to a project’s timeline. This white paper explores the synergy between Agile Scrum and CPM, presenting a combined methodology that addresses the limitations of each when used in isolation. By integrating these methodologies, organizations can optimize project outcomes, improve risk management, and deliver superior results.
                            </Typography>

                            <Typography variant='h2' className='blue jst h2h4' gutterBottom>
                                Introduction
                            </Typography>

                            <Box pt={1}>
                                <Typography variant='h6' className='black jst'> <strong>Challenges in Software Engineering Project Management</strong>  </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Modern software projects face dynamic requirements, tight deadlines, and the need for constant iteration. Traditional methods often fail to adapt to rapid changes, while Agile’s flexibility can overlook long-term dependencies. This creates a gap that needs to be bridged.
                                </Typography>
                            </Box>

                            <Box pt={1}>
                                <Typography variant='h6' className='black jst'> <strong>Overview of Agile Scrum</strong>  </Typography>
                                <Typography variant='h6' className='black jst'> Agile Scrum is a framework built on iterative progress through sprints. Key principles include:
                                    <ul>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Incremental delivery of value.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Flexibility to adapt to changing requirements.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Enhanced collaboration between stakeholders
                                            </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Box pt={1}>
                                <Typography variant='h6' className='black jst'> <strong>Overview of Critical Path Method</strong>  </Typography>
                                <Typography variant='h6' className='black jst'> CPM is a deterministic approach used to:
                                    <ul>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Map out all tasks in a project.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Identify dependencies and critical paths that determine the project timeline.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='h6' className='black jst'>
                                                Highlight tasks where delays directly impact project completion.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Box pt={1}>
                                <Typography variant='h6' className='black jst'> <strong>Purpose of Combining Agile Scrum and CPM</strong>  </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The integration aims to combine the adaptability of Agile with CPM’s precise task mapping. This hybrid approach ensures that projects are flexible yet remain aligned with overarching timelines.
                                </Typography>
                            </Box>

                            <Box pt={1}>
                                <Typography variant='h2' className='blue jst h2h4' gutterBottom>Methodology</Typography>
                            </Box>

                            <Box mt={3}>
                                <EmployeeProfile imgSrc={VP} name={"Mr. Vaibhav Poonekar"} designation={"SVP - Automation & Development Lead,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/vaibhavpoonekar/"} />
                            </Box>

                        </Box>

                    </Box>

                    <Box className='pages fx_c' mt={3} mb={2}>
                        <ViewFullArticles allignment="flx_fs" reportby={"Leveraging Agile Scrum and Critical Path Method (CPM) for Enhanced Software Project Management"} link={link} />
                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Leveraging Agile Scrum and Critical Path Method (CPM) for Enhanced Software Project Management" />
                    </Box>

                    <Insightback />

                </Container>
            </Box>

        </>
    );
}
