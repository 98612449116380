import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack.js';
import { Helmet } from 'react-helmet'; 
import CXO from "../../../asset/images/media/bw_security_world.webp";
import Quote from "../../../asset/images/media/press_release/BeyondDeepfakes.webp"



export default function BeyondDeepfakes() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>AI-Driven Cybersecurity: Combating Deepfakes & Emerging Threats</title>
                <meta name="description" content="Our CEO Shailesh Dhuri emphasizes the need for businesses to adopt AI-powered cybersecurity solutions to combat these emerging threats. With cybercrime projected to reach $10.5 trillion annually by 2025, organizations can no longer afford reactive security strategies. From real-time threat detection to autonomous risk mitigation, agentic AI is reshaping cybersecurity, helping businesses stay ahead of cybercriminals. Learn how advanced AI-driven defenses can protect enterprises from deepfake fraud, data breaches, and financial losses." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/beyond-deepfakes-how-ai-powered-cyber-threats-redefining-digital-warfare" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Beyond Deepfakes: How AI-Powered Cyber Threats Redefining Digital Warfare
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> 

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={7} md={8} sm={10} xs={12}>
                                <Box pt={3} pb={3}>
                                    <img loading="lazy"  src={Quote} className='fullimg' alt='Quote' />
                                </Box>

                            </Grid>
                        </Grid>


                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://bwsecurityworld.com/technology/beyond-deepfakes-how-ai-powered-cyber-threats-redefining-digital-warfare/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CXO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>

        </>
    );
}
