import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import will_AI from "../../../asset/images/insight/blog_pages/why_will_AI.webp";
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';


export default function Why_will_AI_emerge() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Why Will AI Emerge Spontaneously? </h1>
                <title>Why Will AI Emerge Spontaneously? | Decimal Point Analytics</title>
                <meta name="description" content="Discover why AI is predicted to emerge spontaneously as the internet becomes denser. Explore the exponential growth of connected devices and emergent behavior." />
                <meta name="keywords" content="AI emergence, dense internet, exponential growth, connected devices, information flow, network density, emergent behavior, intelligent systems, human-level intelligence, task-specific intelligence, self-preservation, shutting down the internet" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/why-will-ai-emerge" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Why Will AI Emerge Spontaneously?
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                July 24, 2017
                            </Typography>
                        </Grid>
                    </Grid>



                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>In response to one of my previous blogs, some readers privately raised questions as to why I think AI will emerge spontaneously. Here is my detailed reasoning.</Typography>

                        <Typography variant='h4' className='blue jst'>Internet will become denser by a big factor in next eight years</Typography>

                        <Typography variant='h6' className='black jst'>We all know that number of connected devices on internet is increasing rapidly. In 1992, the number of devices connected to internet was in the order of one million. In 2016, it is estimated to be in the range of 23 billion and then to further increase to 80 billion or so by 2025. All of us intuitively understand that the connections in network grow exponentially as the network grows. However, our linear thinking brains may trick us into believing that we are in the middle of the most rapid period of the growth of internet network. However, it would be instructive to run some simulations of see how the network would grow over the years.</Typography>

                        <Typography variant='h4' className='blue jst'>The chart below shows an index of the density of the network of devices connected to internet under certain assumptions:</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={will_AI} className='topbanner2' alt='Will Ai' />
                        </Box>

                        <Typography variant='h6' className='black jst'>I have displayed this chart on natural number scale, instead of log scale to visually impress that, in my estimation, about 99.9999 percent of connections expected as of 2025 between devices on internet, are yet to be formed as of 2017. In other words, the connections between devices on internet will become denser by a factor of 10^5 in 2017-2025 period.</Typography>


                        <Typography variant='h6' className='black jst'>Add to the above the fact that the memory costs, communications costs, and compute costs are still falling at the rate of Moore’s Law. Adjusting for available data and compute power at nodes, I will not hesitate to say that, in terms of information flow, the connections between internet connected devices will become denser by a factor of 10^9 from 2017 till 2025.</Typography>


                        <Typography variant='h4' className='blue jst'>Why is denser internet is important for spontaneous emergence of AI</Typography>


                        <Typography variant='h6' className='black jst'>As a first point, we are already seeing amazing task specific machine intelligence at work everywhere. It is worthwhile to note that this “narrow AI” progress is entirely attributable to human design. However, some specific aspects of those systems are emergent, as has been enthusiastically reported by some sections of media, such as Facebook servers talking to each other in their own language which spontaneously emerged.</Typography>

                        <Typography variant='h6' className='black jst'>As a second point, it is postulated that, intelligent behavior is an emergent property of a complex network of otherwise dumb agents working together seemingly without a common motive. The structure of our brains is a good example. Individual neurons in our brain do not seem to have their own native intelligence which they could share with other neurons to multiply the intelligence effect. Also, there does not seem to be a top down command structure in neurons to guide them to a common goal. The goals and the intelligence emerges spontaneously as if it is a bifurcation of a complex system of dense neural network.</Typography>

                        <Typography variant='h6' className='black jst'>As a conclusion, it means that whatever rudimentary task-specific intelligent behavior we are seeing now in computers right now, will have a chance of improving itself by a factor of 10^9 by 2025. At the current density of internet, we are observing, at the margin, some development of emergent features. The predicted improvement density by 2025 can very well lead to development of undirected emergent core features floating ‘somewhere’ on the internet. The core features will most likely include objective definition and possibly can include need of some sort of self-preservation. Hence, this will take the computers as a group well beyond human level intelligence by then, without anyone trying to do so deliberately. This will include mastery of multiple tasks and ability to define objectives suo motto. I believe, the only way to stop this development is shutting down entire internet, which is currently impossible.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Why Will AI Emerge Spontaneously" />
                    </Box>



                    <Blogback />

                </Container>
            </Box>


        </>
    );
}
