import { Container, Grid, Typography, Box, Button,  } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import WebinarRegistration from '../../Component/forms/WebinarRegistration';
import PN from "../../asset/images/team/prasad.webp";
import AJ from "../../asset/images/team/ajay_jindal.webp";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Webinar() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>ESG in Private Equity: Insights, Strategies & Challenges | Webinar</title>
                <meta name="description" content="Explore the impact of ESG on financial performance, sustainable value creation strategies, and implementation challenges in this essential webinar." />
                <meta name="keywords" content="ESG, Private Equity, Webinar, Sustainable Investing, Financial Performance, Value Creation, Responsible Investment, Investment Strategies, Implementation Challenges, ESG Integration, Portfolio Management, Investment Decision Making, Environmental, Social, Governance, Responsible Finance, Investor Insights" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/webinar" />
            </Helmet>


            <Box id='webinar'>
                <Grid container spacing={0} justifyContent='center'>
                    <Grid item lg={4} md={6} sm={5} xs={12} className='grid-custom-12'>
                        <Box className='bg_websection'>
                            <Box className="web_section" >
                                <WebinarRegistration webinar_title={"ESG in Private Equity: Insights, Strategies & Challenges | Webinar"} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={8} md={6} sm={7} xs={12} className='grid-custom-12'>

                        <Box className='section'>
                            <Container maxWidth>

                                <Box my={2}>
                                    <Typography variant="h3" className="skblue al_left subhead">
                                        Webinar
                                    </Typography>
                                </Box>

                                <Box >
                                    <Typography variant="h5" className="blue al_left fw5">
                                        The Pivotal Role of ESG Factors in
                                        Private Equity Investing
                                    </Typography>
                                </Box>

                                <Box className='ai_center datetimebx' mt={2}>
                                    <Box py={0} className='fx_fs'>
                                        <Stack direction={"row"} spacing={2}> 
                                            <Button variant='text' className='black t-none' disabled startIcon={<CalendarMonthOutlinedIcon className='webinar-icon' />}> July 18, 2024 </Button>

                                            <Button variant='text' className='black t-none' disabled startIcon={<AccessTimeOutlinedIcon className='webinar-icon' />}> 10:00 AM EST </Button>
                                        </Stack>
                                    </Box>
                                </Box>

                                <Box mt={2}>
                                    <Typography variant='h6' className='black jst'>
                                        Join us for an insightful webinar exploring the transformative impact of Environmental, Social, and Governance (ESG) factors on private equity investing. Discover how these principles are reshaping industry dynamics and learn strategies to leverage ESG for sustainable value creation and competitive advantage.
                                    </Typography>
                                </Box>
                            </Container>

                            <Box className='bg_highlights'>

                                <Container maxWidth>
                                    <Box className='web_heighlightbx'>
                                        <Box className='mb16'>
                                            <Typography variant="h2" className="blue al_left fw5">
                                                Webinar Highlights
                                            </Typography>
                                        </Box>

                                        <Grid container spacing={2} alignItems="top" justifyContent='center'>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Box className='web_highlightbx'>
                                                    <Typography variant='h6' className='mb0 fw5 al_center'>
                                                        The Growing Importance of ESG in Private Equity
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Box className='web_highlightbx'>
                                                    <Typography variant='h6' className='mb0 fw5 al_center'>
                                                        Financial Performance and ESG Initiatives
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Box className='web_highlightbx'>
                                                    <Typography variant='h6' className='mb0 fw5 al_center'>
                                                        Strategies for Sustainable Value Creation
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                <Box className='web_highlightbx'>
                                                    <Typography variant='h6' className='mb0 fw5 al_center'>
                                                        What Should an ESG solution for PE Funds Provide?
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <Box className='web_highlightbx'>
                                                    <Typography variant='h6' className='mb0 fw5 al_center'>
                                                        Challenges in ESG Implementation for PE Industry
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>


                                </Container>
                            </Box>

                            <Box>
                                <Grid container spacing={0} alignItems="top" justifyContent='center'>
                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                        <Box className='webinar_who_attent'></Box>
                                    </Grid>
                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                        <Container maxWidth>
                                            <Box className='web_heighlightbx'>

                                                <Box>
                                                    <Typography variant="h2" className="blue al_left fw5">
                                                        Who Should Attend?
                                                    </Typography>
                                                </Box>

                                                <ul>
                                                    <li>
                                                        <Typography variant='h6' className='black jst'>
                                                            Private Equity Investors
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant='h6' className='black jst'>
                                                            Investment Managers
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant='h6' className='black jst'>
                                                            ESG Analysts
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant='h6' className='black jst'>
                                                            Financial Advisors
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant='h6' className='black jst'>
                                                            Anyone Interested in Responsible Investing
                                                        </Typography>
                                                    </li>
                                                </ul>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box id='webteamsection'>
                                <Box className='web_heighlightbx'>
                                    <Container maxWidth className='bdr'>

                                        <Grid container spacing={1} alignItems="top">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box className='al_center mb16'>
                                                    <Typography variant='h2' className='white'>
                                                        Meet Our Speakers
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>

                                                <Grid container spacing={1} alignItems="top">
                                                    <Grid item lg={6} md={12} sm={12} xs={12}>

                                                        <Box className='whitebx'>

                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item lg={5} md={5} sm={5} xs={12}>
                                                                    <Box >
                                                                        <Box className='teama' style={{ backgroundImage: 'url(' + PN + ')', }}></Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={7} md={7} sm={7} xs={12}>
                                                                    <Box>
                                                                        <Typography className='teaminfo white al_left'>
                                                                            Prasad Nawathe
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className='al_left'>
                                                                        <Box className='wh_line'></Box>
                                                                    </Box>
                                                                    <Box mt={1} >
                                                                        <Typography variant='h6' className='white al_left'>
                                                                            Senior VP of ESG, Research & Data Operations
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>


                                                            <Box mt={2} className='al_center'>
                                                                <Typography variant='h6' className='white jst'>
                                                                    Prasad is a key member of the ESG Data & Product Team at Decimal Point Analytics, bringing a wealth of expertise in project and operations management and a deep understanding of ESG data. With over 16 years of extensive experience in the financial services and data operation industry, he has been an integral part of Decimal Point Analytics for more than a decade. His proficiency in integrating ESG factors into investment strategies provides valuable insights for investors.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={6} md={12} sm={12} xs={12}>

                                                        <Box className='whitebx'>

                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item lg={5} md={5} sm={5} xs={12}>
                                                                    <Box >
                                                                        <Box className='teama' style={{ backgroundImage: 'url(' + AJ + ')', }}></Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={7} md={7} sm={7} xs={12}>
                                                                    <Box>
                                                                        <Typography className='teaminfo white al_left'>
                                                                            Ajay Jindal
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className='al_left'>
                                                                        <Box className='wh_line'></Box>
                                                                    </Box>
                                                                    <Box mt={1} >
                                                                        <Typography variant='h6' className='white al_left'>
                                                                            Strategic Partner & ESG Lead
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>


                                                            <Box mt={2} className='al_center'>
                                                                <Typography variant='h6' className='white jst'>
                                                                    Ajay specializes in guiding firms through strategic ESG initiatives, ensuring effective adoption of responsible investment practices. He focuses on business development and project execution for Decimal Point Analytics’ ESG practice. With a certification in ESG Investing from the CFA Institute and over 20 years of experience in corporate finance, equity research, and PE investing, Ajay blends his extensive professional background with ESG expertise to develop tailored solutions for clients.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Container>
                                </Box>
                            </Box>



                            <Box mt={3} className='al_center'>
                                <Link to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' className='link'>
                                    <Button
                                        variant="contained"
                                        className="dpabtn_sm1"
                                        endIcon={<ArrowForwardIcon className='white' />}
                                    >
                                        Explore our ESG Section
                                    </Button>
                                </Link>
                            </Box>
                        </Box>

                    </Grid>


                </Grid>
            </Box>


        </>
    );
}