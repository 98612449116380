import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_automation.webp"
import { Link } from 'react-router-dom';



export default function TransformingWorkforce() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Workforce Automation, Engagement & Retention | Decimal Point Analytics</title>
                <meta name="description" content="Workforce management with automation-driven insights. Reduce manual errors, streamline reporting & improve employee engagement using real-time analytics" />
                <meta name="keywords" content="Workforce automation, employee engagement analytics, workforce data automation, KPI tracking system, automated workforce management, real-time employee analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/transforming-workforce-management-automation" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Transforming Workforce Management Through Automation
                                </Typography>
                            </Box> 
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Introduction
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading manufacturer and online retailer, part of a USD 990 billion AUM asset manager’s portfolio, needed to overhaul its employee engagement and retention analysis. Manual processes and limited insights were restricting their ability to optimize <strong>workforce management and decision-making</strong> effectively.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h3' className='black jst h3h6'>
                                <strong>Overcoming Workforce Management Bottlenecks</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Manual Data Handling:</strong>  Time-consuming and error-prone data collation and KPI calculation using Excel.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inefficiency:</strong>  High turnaround times and difficulty standardizing processes.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Limited Analytics:</strong>  Inability to access historical trends and in-depth insights into employee engagement.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                The Solution
                            </Typography>

                            <Typography variant='h3' className='black jst h3h6'>
                                <strong>Data-Driven Workforce Management with Automation</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Decimal Point Analytics provided a <strong>technology-driven transformation</strong>, automating core processes to enhance workforce insights and decision-making:
                                <ol>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>End-to-End Automation:</strong> Automated data collation and standardization using VBA macros.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Interactive Dashboards:</strong> Designed user-friendly Tableau dashboards for real-time monitoring and analysis.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Streamlined Reporting:</strong> Standardized KPI calculations tailored to client requirements.
                                        </Typography>
                                    </li>
                                </ol>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ol>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Efficiency Boost:</strong> Substantially reduced manual errors and turnaround times.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Insights:</strong> Provided real-time analytics and historical trend visibility across KPIs.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data-Driven Decisions:</strong> Supported strategic workforce management with actionable insights.
                                        </Typography>
                                    </li>
                                </ol>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Client Impact
                            </Typography>

                            <Typography variant='h3' className='black jst h3h6'>
                                <strong>Sustainable Workforce Management Powered by Automation</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                With real-time data and <strong>automated analytics</strong>, the client was able to <strong>optimize employee engagement and retention</strong>. The new system laid the foundation for scalable, efficient workforce management across divisions.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Learn More
                            </Typography>

                            <Typography variant='h3' className='black jst h3h6'>
                                <strong>Transform Your Workforce Strategy Today</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Interested in <strong>data-driven workforce automation?</strong> Contact us to discover how automation can <strong>elevate employee engagement and retention</strong> for your business.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Get in touch with us today!</Link>
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}