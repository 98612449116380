import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Casestudyback from '../../../../Component/Casestudyback'
import Banner from "../../../../asset/images/insight/case_studies/banner_assetManagergained.webp"
import { Link } from 'react-router-dom';

export default function AssetManagerGained() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>How a $76B Asset Manager Gained 24 Investors with AI & CRM</title>
                <meta name="description" content="Discover how a $76B alternative asset manager optimized investor acquisition using AI-driven CRM solutions, data analytics, and lead scoring—resulting in 24 new investors in one year." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-study/data-driven-investor-acquisition-asset-manage" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    How <span className='lower'>a</span> $76B Asset Manager Used AI & CRM Insights <span className='lower'>to</span> Secure 24 New Investors
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading <strong>credit-focused alternative asset management firm,</strong> managing <strong>$76 billion in AUM,</strong> sought to <strong>enhance its investor acquisition strategy.</strong> Catering to <strong>institutional and high-net-worth investors,</strong> the firm aimed to <strong>expand its investor base</strong> and improve engagement with potential investors. However, despite its strong market presence, <strong>data limitations</strong> hindered its ability to <strong>optimize outreach efforts and convert leads effectively.</strong>
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm faced <strong>multiple hurdles</strong> that impacted its investor network growth:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Lack of structured investor insights,</strong> making it difficult to identify high-potential investors and personalize engagement.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inefficient sales and outreach processes,</strong> leading to challenges in prioritizing and converting investor leads.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Limited competitive benchmarking,</strong> restricting visibility into how the firm performed against market peers.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inability to scale investor engagement,</strong> requiring automation and <strong>data integration</strong> for expansion while maintaining personalized interactions.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                To address these challenges, the firm collaborated to deploy a comprehensive, data-driven CRM and investor engagement solution.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Solution
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Decimal Point Analytics implemented an AI-powered, data-driven framework to refine investor acquisition strategies and enhance engagement efficiency.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Investor Segmentation & Data Enrichment</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Analyzed <strong>historical investor data</strong> to build detailed investor profiles based on <strong>engagement patterns, investment history, and interests.</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Applied <strong>advanced analytics</strong> to categorize investors into <strong>high-priority, medium-priority, and low-priority</strong> segments for targeted outreach.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>AI-Driven Lead Prioritization & Sales Efficiency</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Integrated <Link to="/artificial-intelligence-solutions" className="link">AI-powered analytics</Link> to evaluate investor behavior, allowing the firm to focus on <strong>high-conversion prospects.</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Automated <strong>lead scoring,</strong> enabling efficient resource allocation and reducing turnaround time on investor engagement.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Personalized Outreach & Engagement Strategies</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Leveraged <Link to="/customer-relationship-management-solutions" className="link">CRM analytics</Link> to craft <strong>customized communication strategies</strong> based on investor preferences.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Enabled <strong>dynamic engagement</strong> using <strong>real-time behavioral data,</strong> ensuring outreach efforts were <strong>timely, relevant, and effective.</strong>
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Competitive Benchmarking & Performance Tracking</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Provided <strong>peer comparison metrics,</strong> helping the firm assess its investor acquisition strategy relative to competitors.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Delivered <strong>granular insights</strong> into fundraising trends, investor activity, and market dynamics to refine future outreach efforts.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>Results: Increased Efficiency & Higher Conversions</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                With DPA’s data-driven investor engagement strategy, the firm achieved:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Acquisition of 24 new investors</strong> within 12 months, accelerating growth.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Higher sales efficiency</strong> by focusing on priority leads and automating manual processes.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced investor engagement</strong> through <strong>personalized, data-backed outreach.</strong>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reduced acquisition costs</strong> by optimizing resource allocation and improving conversion rates.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Stronger market positioning</strong> with structured <strong>competitive benchmarking</strong> and actionable insights.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Takeaway
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A <strong>data-driven approach</strong> to investor acquisition can significantly <strong>improve efficiency, reduce costs, and enhance engagement.</strong> By leveraging <strong>AI-driven analytics, CRM insights, and competitive benchmarking,</strong> asset managers can make <strong>informed, strategic decisions that drive measurable business growth.</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>Unlock the Power of Data-Driven Investor Engagement</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Looking to <strong>optimize your investor acquisition strategy?</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <Link to="/contact-us#contact_form" className="link">Partner with Decimal Point Analytics</Link> to implement intelligent <strong>data automation and CRM solutions</strong> that drive <strong>higher engagement, better conversions, and scalable growth.</strong>
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}