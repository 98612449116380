import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(data) {
  return data;
}

const rows = [
  createData({
    indicator: "Product and Business Activity",
    us: 277,
    uk: 376,
    eu: 460,
    oecd: 1721,
    africa: 4617,
    am: 1235,
    others: 896,
  }),
  createData({
    indicator: "Employment, Unemployment and Wages",
    us: 112,
    uk: 36,
    eu: 35,
    oecd: 769,
    africa: 315,
    am: 49,
    others: 50,
  }),
  createData({
    indicator: "Housing",
    us: 35,
    uk: 16,
    eu: 9,
    oecd: 74,
    africa: 2,
    am: 2,
    others: 15,
  }),
  createData({
    indicator: "Money, Credit, and Security Markets",
    us: 21,
    uk: 12,
    eu: 42,
    oecd: 153,
    africa: 251,
    am: 276,
    others: 1110,
  }),
  createData({
    indicator: "Price, Inflation",
    us: 35,
    uk: 30,
    eu: 179,
    oecd: 896,
    africa: 891,
    am: 232,
    others: 513,
  }),
  createData({
    indicator: "Government Finance",
    us: 531,
    uk: 204,
    eu: 265,
    oecd: 506,
    africa: 236,
    am: 176,
    others: 248,
  }),
  createData({
    indicator: "International Statistics, Trade",
    us: 253,
    uk: 1356,
    eu: 1234,
    oecd: 2116,
    africa: 9686,
    am: 1020,
    others: 1371,
  }),
  createData({
    indicator: "National Output, Income and Spending",
    us: 135,
    uk: 10,
    eu: 84,
    oecd: 302,
    africa: 1524,
    am: 242,
    others: 389,
  }),
  createData({
    indicator: "Options Expiry",
    us: 14,
    uk: 2,
    eu: 0,
    oecd: 4,
    africa: 0,
    am: 0,
    others: 1,
  }),
  createData({
    indicator: "Government Bond Auction",
    us: 39,
    uk: 63,
    eu: 0,
    oecd: 455,
    africa: 0,
    am: 0,
    others: 4,
  }),
  createData({
    indicator: "Banking and Financial",
    us: 53,
    uk: 259,
    eu: 665,
    oecd: 948,
    africa: 866,
    am: 1196,
    others: 412,
  }),
  createData({
    indicator: "Demographics",
    us: 0,
    uk: 0,
    eu: 10,
    oecd: 103,
    africa: 305,
    am: 13,
    others: 34,
  }),
  createData({
    indicator: "Capital Market",
    us: 0,
    uk: 0,
    eu: 3,
    oecd: 9,
    africa: 72,
    am: 1,
    others: 10,
  }),
];

export default function EcopulseTable() {
  const classes = useStyles();

  return (
    <>
      <Box id="mui-table">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            {/* <caption>A basic table example with a caption</caption> */}
            <TableHead>
              <TableRow>
                <TableCell> Indicator/Country </TableCell>
                <TableCell className="centered_data"> US </TableCell>
                <TableCell className="centered_data"> UK </TableCell>
                <TableCell className="centered_data"> Europe </TableCell>
                <TableCell className="centered_data">
                  {" "}
                  Other OECD Countries
                </TableCell>
                <TableCell className="centered_data"> Africa </TableCell>
                <TableCell className="centered_data"> EM </TableCell>
                <TableCell className="centered_data"> Others </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.indicator}>
                  <TableCell component="th" scope="row">
                    {" "}
                    {row.indicator}{" "}
                  </TableCell>
                  <TableCell className="centered_data">{row.us}</TableCell>
                  <TableCell className="centered_data">{row.uk}</TableCell>
                  <TableCell className="centered_data">{row.eu}</TableCell>
                  <TableCell className="centered_data">{row.oecd}</TableCell>
                  <TableCell className="centered_data">{row.africa}</TableCell>
                  <TableCell className="centered_data">{row.am}</TableCell>
                  <TableCell className="centered_data">{row.others}</TableCell>
                </TableRow>
              ))}

              <TableRow className="lstrow">
                <TableCell component="th" scope="row">
                  {" "}
                  Total{" "}
                </TableCell>
                <TableCell className="centered_data"> 1505 </TableCell>
                <TableCell className="centered_data"> 2364 </TableCell>
                <TableCell className="centered_data"> 2986 </TableCell>
                <TableCell className="centered_data"> 8056 </TableCell>
                <TableCell className="centered_data"> 18765 </TableCell>
                <TableCell className="centered_data"> 4442 </TableCell>
                <TableCell className="centered_data"> 5053 </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
