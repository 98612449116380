import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_optimizing_portfolio_investment.webp"
import Infographics from "../../../../asset/images/insight/case_studies/optimizing_portfolio.webp"
import { Link } from 'react-router-dom';



export default function OptimizingPortfolioInvestment() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Middle East Asset Firm Boosts Portfolio | Case Study</title>
                <meta name="description" content="Learn how a Middle East asset firm used data insights and automation to enhance decision-making in our detailed case study." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/optimizing-portfolio-investment-decisions-for-a-leading-middle-eastern-asset-management-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Optimizing Portfolio Investment Decisions <span className='lower'>for a</span> Leading Middle Eastern Asset Management Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A prominent asset management firm in the Middle East, managing over $7 billion in assets, faced significant challenges with data interpretation, earnings call delays, and macroeconomic analysis. By partnering with Decimal Point Analytics, the firm streamlined their investment analysis, reduced delays, and achieved more informed portfolio decisions.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm managed an extensive portfolio of over 200 companies across various sectors, encountering several challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data Complexity:</strong> Constant updates to valuation models were necessary to keep pace with market changes.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Earnings Call Delays: </strong> Processing of earnings call data was too slow, delaying crucial investment decisions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Macroeconomic Factors:  </strong> Incorporating global economic changes added complexity to their analysis.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Streamlined Investment Analysis <span className='lower'>and</span> Macroeconomic Insights
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Solution: Streamlined Investment Analysis and Macroeconomic Insights" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The collaboration with Decimal Point Analytics led to:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Decision-Making:  </strong> Significantly reduced delays in earnings call interpretations.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Portfolio Performance:  </strong> Enhanced accuracy and timeliness of data improved investment returns.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Investor Confidence:  </strong> Data-driven insights increased trust among investors.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data-Driven Decision-Making: </strong> Empowered the firm to make informed decisions through advanced valuation models.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Faster Turnaround Times:  </strong>  Decreased time needed for critical earnings data processing.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Macroeconomic Insights: </strong> Allowed effective risk management and opportunity capitalization.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today to learn how we can help optimize your investment decisions and enhance portfolio performance!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}