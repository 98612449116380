import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import CBDC from "../../../asset/images/media/press_release/cbdc2.webp"

export default function TheNewEraOfMoney() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> CBDCs: Revolutionizing Money - Insights by Shailesh Dhuri </title>
                <meta name="description" content="Explore how CBDCs transform payments and foster collaboration. Join the discussion on efficient, secure transactions with a global network of purpose-driven CBDCs." />
                <meta name="keywords" content="CBDCs, Central Bank Digital Currencies, Shailesh Dhuri, Digital Currency, Smart Contracts, International Collaboration, Economic Efficiency, Purpose-Encoded CBDCs" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/central-bank-digital-currencies-the-new-era-of-money" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Central Bank Digital Currencies (CBDCs): The New Era of Money
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, CEO of Decimal Point Analytics was recently featured in the digital print of <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/pcquest-january-2024.pdf" target='_blank' rel="noopener noreferrer">PCQuest</a> for outlining his vision for a future powered by Central Bank Digital Currencies (CBDCs). In an interview, he highlighted the potential of CBDCs to revolutionize payments, foster transnational collaboration, and even combat illegal activities through the use of smart contracts.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Shailesh envisions a global network where different CBDCs can be seamlessly exchanged and utilized through smart contracts. This, he believes, could unlock greater economic efficiency and international trade. He further emphasizes the potential of 'purpose encoded' CBDCs, where specific transaction rules are programmed into the currency itself. This innovative approach could be used to direct funds towards specific purposes, such as social welfare programs or disaster relief efforts, while simultaneously curbing illegal activities.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Secure your copy of the digital print by <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/pcquest-january-2024.pdf" target='_blank' rel="noopener noreferrer">clicking here</a>.
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={4} md={5} sm={9} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={CBDC} className='fullimg' alt='CBDC' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>

                    </Box>

                    {/* <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/pcquest-january-2024.pdf" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + PCQ + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box> */}


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
