import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';

import data_analytics1 from "../../../asset/images/insight/insight_pages/data_analytics_in_asset/1data_analytics.webp";
import data_analytics2 from "../../../asset/images/insight/insight_pages/data_analytics_in_asset/2data_analytics.webp";
import data_analytics3 from "../../../asset/images/insight/insight_pages/data_analytics_in_asset/3data_analytics.webp";



export default function Data_analytics_in_asset() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Data Analytics in Asset-Backed Securities (ABS) for Informed Decision Making" </h1>
                <title>Data Analytics In Asset-Backed Securities (ABS)</title>
                <meta name="description" content="Discover the power of data analytics in asset-backed securities. Optimize investments and maximize returns with Decimal Point Analytics." />
                <meta name="keywords" content="mortgage backed securities
asset backed securities
residential mortgage backed securities
mbs mortgage backed securities" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/data-analytics-in-asset-backed-securities" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Data Analytics in Asset-Backed Securities (ABS)
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>Asset-backed securities (ABS) and Mortgage-backed securities (MBS) are two important types of asset classes within the fixed-income sector. ABS is created from the pooling of non-mortgage assets while MBS is created from the pooling of mortgages that are sold to interested investors. </Typography>

                        <Typography variant='h6' className='black jst'>MBS can be further bifurcated to Commercial Mortgage-Backed Security (CMBS) and Residential Mortgage-Backed Security (RMBS) </Typography>

                        <Typography variant='h6' className='black jst'>The CMBS is a type of fixed-income security collateralized by commercial real estate loans. These loans are given typically for income-generating real estates such as office buildings, hotels, malls, apartment complexes and factories. A CMBS provides liquidity to real estate investors and commercial lenders alike. CMBS aren't standardized, so there are a lot of details associated with them that make their valuation difficult.  </Typography>

                        <Typography variant='h6' className='black jst'>RMBS is a type of mortgage-backed debt securities where the cash flows are derived from residential mortgages. </Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>The Risks of Mortgage-Backed Security MBS </Typography>

                        <Typography variant='h6' className='black jst'>A mortgage-backed security is created when the bank takes a group of loans on its books, bundles them together, then sells them in securitized form as a series of bonds. Each series is organized in "tranches" from the highest-rated, lowest-risk MBS or "Senior tranche" to the highest-risk, lowest-rated MBS called Equity tranche.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'> Different Risk and Return for Different Investors </Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={data_analytics1} className='topbanner2' alt='Data Analytics 1' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: www.wallstreetmojo.com</Typography>

                        <Typography variant='h6' className='black jst'>As is the case with corporate bonds, commercial mortgage-backed securities are at risk of default. If the underlying borrowers fail to make their principal and interest payments, CMBS investors can experience a loss. The risk of individual issues can vary based on the strength of the property market in the specific area where the loan was originated, as well as by the date of issuance.</Typography>

                        <Typography variant='h6' className='black jst'>Unlike commercial mortgages, apart from the <strong> risk of default on the loans</strong>, residential mortgages also <strong>pose a risk of pre-payment of the loan. </strong></Typography>


                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Risk Management using Data analytics</Typography>

                        <Typography variant='h6' className='black jst'>Mortgage-backed securities (MBS) can play an important role for Investors as a fixed income asset class that offers several benefits. In addition to historically attractive yields compared to Treasuries and low volatility, these highly liquid assets provide diversification, which can lower portfolio risk. </Typography>

                        <Typography variant='h6' className='black jst'>Currently, investors depend on credit ratings and default probabilities to identify the risk associated with these instruments. The investors would be keeping an eye on the various elements of their pool of loans based on various characteristics. They tap on updated information available on these pools, that can be viewed and analyzed for emerging signs of distress.</Typography>

                        <Typography variant='h6' className='black jst'>However, at times they fall short when it comes to risk assessment and mitigation. This has forced investors to seek other means of managing the risk. There is a lot of data available that can be mined to generate insights about the underlying assets. </Typography>

                        <Typography variant='h6' className='black jst'>The technically savvy investors are monitoring the financial health of the tenants of the commercial property to get a better perspective on the default risk of CMBS. The tenant’s financial status has a direct correlation to the borrower’s potential cash flow. These investors are also looking at performance with their peers, the Impact of recent news, search trends, interest rates, etc.</Typography>

                        <Typography variant='h6' className='black jst'>Similarly, for RMBS investors are monitoring the metropolitan areas to understand how the rental markets are performing, looking at the vacancy rates, asking rent, population growth rates, unemployment rates, etc. </Typography>

                        <Typography variant='h6' className='black jst'>These data points help investors get a good idea about how the market is doing and how it will affect the default risk of the borrower.</Typography>



                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={data_analytics2} className='topbanner2' alt='Data Analytics 2' />
                        </Box>

                        <Typography variant='h4' className='blue'>Decimal Point Analytics: Metropolitan Statistical Area Snapshot </Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={data_analytics3} className='topbanner2' alt='Data Analytics 3' />
                        </Box>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'> Decimal Point Analytics: Economic Indicators of Metropolitan Statistical Area</Typography>

                        <Typography variant='h6' className='black jst'>The above analysis will help the investor get a clear picture of the factors affecting the underlying assets and its default possibilities. It not only shows the current status but also shows the trend. </Typography>

                        <Typography variant='h6' className='black jst'>Such analysis will help the investor get unbiased information which will help him/her from being blindsided. The current level of technology and availability of data is allowing investors in this market to be on top of changes in the portfolio risk profile, and helping them make better pricing and risk mitigation strategies.</Typography>


                        <Divider />


                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Data Analytics in Asset-Backed Securities ABS" />
                    </Box>


                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
