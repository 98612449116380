import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Whitepaper_Data from "./Whitepaper_Data";
import LazyLoad from "react-lazyload";

export default function Whitepaper() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // State to control how many whitepapers are visible
  const [visibleWhitepapers, setVisibleWhitepapers] = useState(6);

  // Increase visible count by 6, ensuring it does not exceed the total count
  const handleLoadMore = () => {
    setVisibleWhitepapers((prev) =>
      Math.min(prev + 6, Whitepaper_Data.length)
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Decimal Point Analytics: Insightful Whitepapers for Strategic Decision-Making
        </title>
        <meta
          name="description"
          content="Decimal Point Analytics offers whitepapers covering ESG, data analytics, asset management, and LLMs, providing comprehensive data-driven insights for informed business decisions"
        />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepaper" />
      </Helmet>

      <Box
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h1" className="black newh1">
                  Whitepapers
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="reportinfo">
            <Grid container spacing={1} alignItems="top" justifyContent="center">
              {Whitepaper_Data.slice(0, visibleWhitepapers).map((item) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={item.link}>
                  <LazyLoad height={300} offset={100} once>
                    {item.linking === "external" ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                      >
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                            <Box mt={2}>
                              <Typography variant="h6" className="skblue">
                                {item.date}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="line"></Box>
                        </Box>
                      </a>
                    ) : (
                      <Link to={item.link}>
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                            <Box mt={2}>
                              <Typography variant="h6" className="skblue">
                                {item.date}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="line"></Box>
                        </Box>
                      </Link>
                    )}
                  </LazyLoad>
                </Grid>
              ))}
            </Grid>

            {visibleWhitepapers < Whitepaper_Data.length && (
              <Box mt={4} textAlign="center">
                <Button variant="contained" className="dpabtn_1" onClick={handleLoadMore}>
                  Load More
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
