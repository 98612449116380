import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function AnnualDSConference() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Insights from the 8th Annual Data Science in Finance Conference 2025</title>
                <meta name="description" content="Nilanjan Das represented Decimal Point Analytics at CFA New York's 8th Annual Data Science in Finance Conference, exploring AI, ML, and data-driven strategies shaping financial decision-making." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/8th-annual-data-science-in-finance-conference" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    8th Annual Data Science in Finance Conference
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>
                            Nilanjan Das, Executive VP Business Development at Decimal Point Analytics, attended the 8th Annual Data Science in Finance Conference hosted by <a href='https://cfany.org/' target='_blank' rel="noopener noreferrer">CFA New York</a>.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event brought together industry experts to discuss the evolving role of AI, machine learning, and data-driven strategies in financial decision-making, exploring emerging trends, regulatory challenges, and advancements shaping the future of data science in finance.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            At <Link to="/contact-us#contact_form" className='link'>Decimal Point Analytics</Link>, we remain committed to staying at the forefront of technological advancements, ensuring our solutions deliver transformative value for clients across industries.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
