import case1 from "../../asset/images/insight/case_studies/case_studies_thumbnails/nav_calculations.webp";
import case2 from "../../asset/images/insight/case_studies/case_studies_thumbnails/intelligent_doc_processing.webp";
import case3 from "../../asset/images/insight/case_studies/case_studies_thumbnails/solution_for_crm.webp";
import case4 from "../../asset/images/insight/case_studies/case_studies_thumbnails/response_management.webp";
import case5 from "../../asset/images/insight/case_studies/case_studies_thumbnails/portfolio_monitoring.webp";
import case6 from "../../asset/images/insight/case_studies/case_studies_thumbnails/robotics_process.webp";
import case7 from "../../asset/images/insight/case_studies/case_studies_thumbnails/collatoral_management.webp";
import case8 from "../../asset/images/insight/case_studies/case_studies_thumbnails/intelligent_dashboard.webp";


import case13 from "../../asset/images/insight/case_studies/case_studies_thumbnails/offering_solutions.webp";
import case12 from "../../asset/images/insight/case_studies/case_studies_thumbnails/cutting_edge.webp";
import case11 from "../../asset/images/insight/case_studies/case_studies_thumbnails/auto_and_custom.webp";
import case10 from "../../asset/images/insight/case_studies/case_studies_thumbnails/process_and_cost.webp";
import case9 from "../../asset/images/insight/case_studies/case_studies_thumbnails/fraud_detection.webp";

import case15 from "../../asset/images/insight/case_studies/case_studies_thumbnails/quotemedia.webp";
import case16 from "../../asset/images/insight/case_studies/case_studies_thumbnails/salesforce.webp";


import case17 from "../../asset/images/insight/case_studies/case_studies_thumbnails/extraction_ghg.webp";
import case18 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automateddatawarehouse_thumbnail.webp";


import case19 from "../../asset/images/insight/case_studies/case_studies_thumbnails/security_level_database.webp";
import case20 from "../../asset/images/insight/case_studies/case_studies_thumbnails/technical_support.webp";

import case21 from "../../asset/images/insight/case_studies/case_studies_thumbnails/database_management_for_enhanced.webp";


import case23 from "../../asset/images/insight/case_studies/case_studies_thumbnails/driving_investment_banking.webp";
import case24 from "../../asset/images/insight/case_studies/case_studies_thumbnails/how-ai-driven-automation.webp";
import case25 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhanced_esg.webp";
import case26 from "../../asset/images/insight/case_studies/case_studies_thumbnails/advanced-fund-accounting-solutions.webp";

import case27 from "../../asset/images/insight/case_studies/case_studies_thumbnails/investment.webp";
import case28 from "../../asset/images/insight/case_studies/case_studies_thumbnails/risk_management.webp";
import case29 from "../../asset/images/insight/case_studies/case_studies_thumbnails/revolutionizing_financial_data.webp"
import case30 from "../../asset/images/insight/case_studies/case_studies_thumbnails/revolutionising_machinery.webp"
import case31 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing-business-intelligence.webp"
import case32 from "../../asset/images/insight/case_studies/case_studies_thumbnails/wealth_management.webp"
import case33 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhance_asset.webp"
import case34 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automated_data.webp"
import case35 from "../../asset/images/insight/case_studies/case_studies_thumbnails/lending-company-transforms.webp"
import case36 from "../../asset/images/insight/case_studies/case_studies_thumbnails/overcoming-data-challenges.webp"
import case37 from "../../asset/images/insight/case_studies/case_studies_thumbnails/rent_roll.webp"
import case38 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_portfolio.webp"
import case39 from "../../asset/images/insight/case_studies/case_studies_thumbnails/streamlining.webp"
import case40 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhanced_risk.webp"
import case41 from "../../asset/images/insight/case_studies/case_studies_thumbnails/accelerating_fund.webp"
import case42 from "../../asset/images/insight/case_studies/case_studies_thumbnails/streamlining_loan.webp"
import case43 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_inventory.webp"
import case44 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhances_clickup.webp"
import case45 from "../../asset/images/insight/case_studies/case_studies_thumbnails/leading_indian_bank.webp"
import case46 from "../../asset/images/insight/case_studies/case_studies_thumbnails/optimizing_portfolio_investment.webp"
import case47 from "../../asset/images/insight/case_studies/case_studies_thumbnails/cat_modeling.webp"
import case48 from "../../asset/images/insight/case_studies/case_studies_thumbnails/product_portfolio.webp"
import case49 from "../../asset/images/insight/case_studies/case_studies_thumbnails/leading_data_provider.webp"
import case50 from "../../asset/images/insight/case_studies/case_studies_thumbnails/boosting_forecast.webp"
import case51 from "../../asset/images/insight/case_studies/case_studies_thumbnails/improving_oas_bond.webp"
import case52 from "../../asset/images/insight/case_studies/case_studies_thumbnails/streamlining_operations.webp"
import case53 from "../../asset/images/insight/case_studies/case_studies_thumbnails/us_asset_management.webp"
import case54 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automated_process.webp"
import case55 from "../../asset/images/insight/case_studies/case_studies_thumbnails/delivering_public.webp"
import case56 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automating_fund.webp"
import case57 from "../../asset/images/insight/case_studies/case_studies_thumbnails/reducing_client_query.webp"
import case58 from "../../asset/images/insight/case_studies/case_studies_thumbnails/standardizing_benchmarks.webp"
import case59 from "../../asset/images/insight/case_studies/case_studies_thumbnails/crafting_dynamic.webp"
import case60 from "../../asset/images/insight/case_studies/case_studies_thumbnails/currency_risk_model.webp"
import case61 from "../../asset/images/insight/case_studies/case_studies_thumbnails/reducing_maintenanace.webp"
import case62 from "../../asset/images/insight/case_studies/case_studies_thumbnails/streamlining_invoice.webp"
import case63 from "../../asset/images/insight/case_studies/case_studies_thumbnails/global_alternative_firm.webp"
import case64 from "../../asset/images/insight/case_studies/case_studies_thumbnails/transforming_ipo.webp"
import case65 from "../../asset/images/insight/case_studies/case_studies_thumbnails/real_estate_finance.webp"
import case66 from "../../asset/images/insight/case_studies/case_studies_thumbnails/how_to_prevent_fraud.webp"
import case67 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_sba_loan.webp"
import case68 from "../../asset/images/insight/case_studies/case_studies_thumbnails/enhancing_workflow_efficiency.webp"
import case69 from "../../asset/images/insight/case_studies/case_studies_thumbnails/cost_effective_excellence.webp"
import case70 from "../../asset/images/insight/case_studies/case_studies_thumbnails/doubling_productivity.webp"
import case71 from "../../asset/images/insight/case_studies/case_studies_thumbnails/saving_overheads.webp"
import case72 from "../../asset/images/insight/case_studies/case_studies_thumbnails/boosting_sba.webp"
import case73 from "../../asset/images/insight/case_studies/case_studies_thumbnails/reducing_penalty.png"
import case74 from "../../asset/images/insight/case_studies/case_studies_thumbnails/audit_radiness.webp"
import case75 from "../../asset/images/insight/case_studies/case_studies_thumbnails/improving_customer.webp"
import case76 from "../../asset/images/insight/case_studies/case_studies_thumbnails/achieving_faster_approvals.webp"
import case77 from "../../asset/images/insight/case_studies/case_studies_thumbnails/preventing_fraud.webp"
import case78 from "../../asset/images/insight/case_studies/case_studies_thumbnails/oracle_database_implementation.webp"
import case79 from "../../asset/images/insight/case_studies/case_studies_thumbnails/accurate_data_mngt.webp"
import case80 from "../../asset/images/insight/case_studies/case_studies_thumbnails/index_rebalance.webp"
import case81 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automation.webp"
import case82 from "../../asset/images/insight/case_studies/case_studies_thumbnails/ai_driven_insurance.webp"
import case83 from "../../asset/images/insight/case_studies/case_studies_thumbnails/optimizing_crm.webp"
import case84 from "../../asset/images/insight/case_studies/case_studies_thumbnails/transforming_analytics.webp"
import case85 from "../../asset/images/insight/case_studies/case_studies_thumbnails/achieving_nearzero.webp"
import case86 from "../../asset/images/insight/case_studies/case_studies_thumbnails/assetManagergained.webp"
import case87 from "../../asset/images/insight/case_studies/case_studies_thumbnails/investor_engagement.webp"
import case88 from "../../asset/images/insight/case_studies/case_studies_thumbnails/automating_real_time.webp"
import case89 from "../../asset/images/insight/case_studies/case_studies_thumbnails/optimizing_contact_management.webp"
import case90 from "../../asset/images/insight/case_studies/case_studies_thumbnails/unified_database_for_enhancing.webp"
import case91 from "../../asset/images/insight/case_studies/case_studies_thumbnails/achieving_accuracy_in_index.webp"
import case92 from "../../asset/images/insight/case_studies/case_studies_thumbnails/transforming_data_into_granular_insights.webp"


const Case_studies_Data = [
    {
        categories: ['Salesforce Support'],
        link: '/case-study/transforming-data-insights-investor-engagement',
        imgsrc: case92,
        heading: "Transforming Data into Granular Insights for Enhanced Investor Engagement",
        subheading: "Turning complex data into game-changing strategies that enhance investor engagement, drive revenue growth, and improve decision-making with smarter insights."
    },
    {
        categories: ['Predictive Analytics and Forecasting Solutions'],
        link: '/case-study/predicting-index-rebalancing-with-100-accuracy',
        imgsrc: case91,
        heading: "Achieving 100% Accuracy in Index Rebalancing Predictions, Optimizing Trading Strategies",
        subheading: "100% accurate index rebalancing predictions that empower smarter trading, better liquidity planning, and improved risk management through DPA’s predictive model."
    },
    {
        categories: ['Data Management'],
        link: '/case-study/unified-database-financial-operations',
        imgsrc: case90,
        heading: "Unified Database for Enhancing Financial Operations Efficiency",
        subheading: "Unified database automation helped a leading financial firm reduce query resolution time to 2–3 hours and cut manual errors. By replacing Excel-based workflows, DPA streamlined financial operations, improved data access, and delivered faster, more reliable decision-making."
    },
    {
        categories: ['CRM'],
        link: '/case-study/automated-record-type-matching-salesforce-contact-management',
        imgsrc: case89,
        heading: "Optimizing Contact Management with Automated Record Type Matching in Salesforce CRM",
        subheading: "A financial firm boosted CRM efficiency by automating Salesforce record type assignments for accurate contact classification & seamless workflows."
    },
    {
        categories: ['CRM'],
        link: '/case-study/automated-real-time-email-alerts-salesforce-financial-firms',
        imgsrc: case88,
        heading: "Automating Real-Time Email Alerts For A Financial Services Firm",
        subheading: "A leading financial services firm automated email alerts in Salesforce for instant contact updates & fund tracking to boost efficiency & decision-making."
    },
    {
        categories: ['Salesforce Support'],
        link: '/case-study/crm-optimization-investor-data-automation',
        imgsrc: case87,
        heading: "Optimizing CRM & Investor Engagement for a $27B Asset Manager",
        subheading: "A $27B asset manager eliminated manual CRM inefficiencies, automated investor tracking, and gained real-time fundraising insights with DPA’s data automation solutions. Enhance your CRM strategy."
    },
    {
        categories: ['Salesforce Support'],
        link: '/case-study/data-driven-investor-acquisition-asset-manager',
        imgsrc: case86,
        heading: "How a $76B Asset Manager Used AI & CRM Insights to Secure 24 New Investors",
        subheading: "Optimizing investor engagement, a $76B asset manager secured 24 new investors in one year through data-driven insights."
    },
    {
        categories: ['Salesforce Support'],
        link: '/case-study/global-asset-manager-crm-automation-efficiency',
        imgsrc: case85,
        heading: "How a $27B Asset Manager Achieved 70% Faster Data Processing & Near-Zero Errors",
        subheading: "A global asset manager transformed CRM efficiency with 70% faster data processing and near-zero errors using data automation and CRM optimization."
    },
    {
        categories: ['Process Automation and Data Management'],
        link: '/case-study/accelerating-analytics-with-snowflake',
        imgsrc: case84,
        heading: "Transforming Analytics Efficiency with Snowflake & Snowpark",
        subheading: "A US firm faced slow analytics and high costs. DPA’s Snowflake migration cut processing and costs to $300–$400, boosting scalability."
    },
    {
        categories: ['Database Management'],
        link: '/case-study/optimizing-crm-database-management-for-investor-relations',
        imgsrc: case83,
        heading: "Optimizing CRM Database Extraction & Management for Efficient Investor Relations",
        subheading: "Manual CRM updates slow you down. We automated investor data management with multi-source verification and quality checks—delivering faster, smarter insights for successful fundraising."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/ai-driven-insurance-claims-processing',
        imgsrc: case82,
        heading: "AI-Driven Insurance Claims Processing: Faster, Smarter, and More Accurate",
        subheading: "An Indian insurer faced slow claims and errors. DPA’s AI automation improved accuracy, reduced turnaround time, and enhanced policyholder experience."
    },
    {
        categories: ['Dashboard Solutions'],
        link: '/case-studies/transforming-workforce-management-automation',
        imgsrc: case81,
        heading: "Transforming Workforce Management Through Automation",
        subheading: "Workforce management with automation-driven insights. Reduce manual errors, streamline reporting & improve employee engagement using real-time analytics"
    },
    {
        categories: ['Predictive Analytics and Forecasting Solutions'],
        link: '/case-studies/predicting-index-rebalancing',
        imgsrc: case80,
        heading: "Predicting Index Rebalancing with 100% Accuracy",
        subheading: "Discover how Decimal Point Analytics achieved 100% accuracy in predicting index rebalancing, optimizing portfolio adjustments for financial institutions."
    },
    {
        categories: ['Data Management'],
        link: '/case-studies/driving-fundraising-success-with-accurate-data-management',
        imgsrc: case79,
        heading: "Driving Fundraising Success with Accurate Data Management",
        subheading: "Discover how automated data updates, quality assurance, and seamless CRM integration drive success."
    },
    {
        categories: ['Other'],
        link: '/case-studies/dpas-innovative-approach-enhances-oracle-autonomous-database-implementation',
        imgsrc: case78,
        heading: "DPA's Innovative Approach Enhances Oracle Autonomous Database Implementation",
        subheading: "Decimal Point Analytics (DPA), a global leader in financial research and analytics, leveraged Oracle Autonomous Database to streamline a complex multi-database architecture. As both a long-time Oracle user and implementation expert, DPA was able to introduce innovations that transformed the performance, scalability, and reliability of its data management systems. This case study highlights the key innovations and strategies that DPA brought to enhance Oracle's capabilities for maximum efficiency and customer satisfaction."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/preventing-5-million-in-fraud-and-increasing-efficiency-by-40-with-compliance-excellence',
        imgsrc: case77,
        heading: "Preventing $5 Million in Fraud and Increasing Efficiency by 40% with Compliance Excellence ",
        subheading: "High rejection rates, compliance gaps, and increased exposure to fraud posed serious risks. Proactive fraud detection measures, an adaptive compliance checklist, and meticulous due diligence processes led to the prevention of $5 million in fraud, a 40% increase in operational efficiency, and reduced loan rejections, strengthening compliance and financial integrity."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/achieving-40-faster-approvals-and-doubling-application-throughput',
        imgsrc: case76,
        heading: "Achieving 40% Faster Approvals and Doubling Application Throughput",
        subheading: "Underwriting delays and scalability limitations affected processing speed and client satisfaction. Advanced workflows, optimized loan processing, and refined underwriting strategies led to 40% faster approvals, doubled application throughput, and maintained 99% compliance accuracy, enabling scalable growth and stronger competitiveness."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/improving-customer-acquisition-by-25-faster-screening-and-40-increased-efficiency',
        imgsrc: case75,
        heading: "Improving Customer Acquisition by 25% Faster Screening and 40% Increased Efficiency",
        subheading: "Delays in loan screening slowed decision-making and customer acquisition, limiting competitiveness in the market. By optimizing the screening process, leveraging time-zone advantages, and implementing advanced tools, decision times decreased by 25%, operational efficiency increased by 40%, and customer acquisition accelerated, driving growth and improved market positioning."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/achieving-100-backlog-clearance-in-less-than-2-months-for-sba-audit-readiness',
        imgsrc: case74,
        heading: "Achieving 100% Backlog Clearance in Less Than 2 Months for SBA Audit Readiness",
        subheading: "An urgent need to clear a substantial backlog of exceptions before an impending SBA audit posed compliance risks and strained resources. Rapid team scaling, improved exception handling, and efficient backlog management led to full backlog clearance in under two months. This proactive approach ensured audit readiness, compliance, and a restored focus on strategic growth initiatives."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/reducing-penalty-payments-by-80-90-with-efficient-sba-reporting',
        imgsrc: case73,
        heading: "Reducing Penalty Payments by 80-90% with Efficient SBA Reporting",
        subheading: "Missed SBA deadlines and inefficient processes led to significant late payment penalties, diverting valuable resources away from core operations. By identifying bottlenecks, streamlining workflows, and focusing on compliance tasks, the finance firm achieved an 80-90% reduction in penalties, consistently met reporting deadlines, and improved operational efficiency, allowing resources to be redirected to growth initiatives."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/boosting-sba-loan-approvals-by-30-with-99-documentation-accuracy',
        imgsrc: case72,
        heading: "Boosting SBA Loan Approvals by 30% with 99% Documentation Accuracy",
        subheading: "Frequent documentation errors, compliance gaps, and high rejection rates were impacting SBA loan approvals. A comprehensive documentation checklist, robust quality control measures, and proactive compliance tracking were implemented, resulting in 99% submission accuracy, a 30% reduction in rejections, and faster loan approvals, significantly improving compliance and approval rates."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/saving-60-in-overheads-while-enhancing-sba-loan-quality-and-speed-by-40',
        imgsrc: case71,
        heading: "Saving 60% in Overheads While Enhancing SBA Loan Quality and Speed by 40%",
        subheading: "Escalating costs and compliance challenges demanded a strategic shift for a multi-strategy finance firm relying on onshore underwriting. Balancing costs while maintaining processing accuracy was vital. By adopting cost-effective outsourcing, loan automation, and refined quality measures, overheads were reduced by 60%, accuracy reached 99%, and turnaround times improved by 40%, driving both financial efficiency and growth."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/doubling-productivity-achieving-2x-faster-loan-processing-with-50-fewer-errors',
        imgsrc: case70,
        heading: "Doubling Productivity Achieving 2x Faster Loan Processing with 50% Fewer Errors",
        subheading: "Operational bottlenecks, slow loan processing, and inconsistent quality control were key challenges for a multi-strategy real estate finance company. These issues impacted client satisfaction and market competitiveness. Through the adoption of automated loan underwriting, loan processing outsourcing, and loan due diligence, enhanced quality control measures, and scalable operations, productivity doubled, processing speed improved by 40%, and error rates dropped by 50%, resulting in a more efficient and consistent lending environment."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality',
        imgsrc: case69,
        heading: "Cost-Effective Excellence – Saving Lenders 60% in Overheads While Enhancing SBA Loan Quality",
        subheading: "Our client is a multi-strategy real estate finance company that originates, acquires, finances, and services SBA loans for small to medium-sized businesses. Their goal is to empower businesses nationwide with fast, flexible funding solutions to support growth."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/enhancing-workflow-efficiency-and-reducing-costs-with-precision',
        imgsrc: case68,
        heading: "Enhancing Workflow Efficiency and Reducing Costs with Precision",
        subheading: "Our client, a renowned real estate finance company specializing in SBA loans, struggled with timely and accurate SBA reporting. This led to frequent late payment penalties, inefficient resource allocation, and increased operational costs."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/enhancing-sba-loan-approval-rates-through-precise-documentation-and-ensuring-sba-compliance',
        imgsrc: case67,
        heading: "Enhancing SBA Loan Approval Rates Through Precise Documentation and Ensuring SBA Compliance",
        subheading: "Our client is a multi-strategy real estate finance company that originates, acquires, finances, and services small to medium-sized balance SBA loans. They empower businesses nationwide with fast, flexible funding to fuel their growth."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/how-to-prevent-fraud-in-real-eEstate-finance-a-case-study-on-compliance-excellence',
        imgsrc: case66,
        heading: "How to Prevent Fraud in Real Estate Finance: A Case Study on Compliance Excellence",
        subheading: "In the competitive landscape of real estate finance, maintaining compliance and preventing fraud is paramount. Our client, a leading real estate finance company, specializes in originating, acquiring, financing, and servicing small to medium-sized SBA loans. They are dedicated to empowering businesses nationwide with fast, flexible funding solutions that fuel growth."
    },
    {
        categories: ['SBA'],
        link: '/case-studies/how-automation-doubled-loan-processing-speed-and-cut-errors-by-50-for-real-estate-finance-firms',
        imgsrc: case65,
        heading: "How Automation Doubled Loan Processing Speed and Cut Errors by 50% for Real Estate Finance Firms",
        subheading: "A multi-strategy real estate finance company faced significant challenges, including operational bottlenecks, slow loan processing times, and inconsistent quality control. These issues were limiting client satisfaction and market competitiveness. By implementing advanced automation, stringent quality control measures, and scalable processes, the company achieved a 2x boost in productivity, a 40% improvement in processing speed, and a 50% reduction in error rates—creating a faster, more reliable lending environment."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/transforming-ipo-data-management-with-6-hour-lead-for-stock-market-research',
        imgsrc: case64,
        heading: "Transforming IPO Data Management with 6-Hour Lead for Stock Market Research",
        subheading: "A leading stock market research firm improved its investment strategies by accessing fast, accurate IPO data. With a 6-hour lead over competitors, the firm enabled investors to make timely decisions, boost financial outcomes, and enhance satisfaction. The solution provided comprehensive coverage and real-time updates, helping clients optimize their strategies and capitalize on market opportunities effectively."
    },
    {
        categories: ['Data Management'],
        link: '/case-studies/achieving-99-data-accuracy-in-contact-management-for-global-alternatives-firm',
        imgsrc: case63,
        heading: "Achieving 99% Data Accuracy in Contact Management for Global Alternatives Firm",
        subheading: "A leading global alternative asset manager faced challenges in maintaining accurate and up-to-date investor information. By implementing a rigorous data cleaning and standardization process, the firm significantly improved the quality of its contact management system."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/streamlining-invoice-processing-by-85-for-efficient-transaction-management',
        imgsrc: case62,
        heading: "Streamlining Invoice Processing by 85% for Efficient Transaction Management",
        subheading: "A US-based investment management firm specializing in fixed income alternative investments faced significant challenges with manual invoice processing. By automating data entry and invoice processing, the firm streamlined its workflows, dramatically reducing processing time and errors."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/reducing-maintenance-downtime-by-90-with-ai-driven-solutions',
        imgsrc: case61,
        heading: "Reducing Maintenance Downtime by 90% with AI-Driven Solutions",
        subheading: "An Indian government entity responsible for strategic operations faced challenges with predictive maintenance solutions due to unstructured data and manual tracking systems. By developing AI-ML predictive maintenance models, the organization significantly reduced downtime and enhanced operational efficiency."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence'],
        link: '/case-studies/achieving-50-improvement-in-currency-risk-model-accuracy',
        imgsrc: case60,
        heading: "Achieving 50% Improvement in Currency Risk Model Accuracy",
        subheading: "A US-based investment firm specializing in global asset management faced inefficiencies in their currency risk management process. Manual verification and data extraction led to delays and inaccuracies, affecting investment strategies. By implementing automated data processes and advanced statistical modeling using the FX fair value model, the firm significantly enhanced the accuracy and efficiency of its currency risk model."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/crafting-a-dynamic-industry-classification-system-enabling-real-time-insights-for-investment-strategies',
        imgsrc: case59,
        heading: "Crafting a Dynamic Industry Classification System, Enabling Real-Time Insights for Investment Strategies",
        subheading: "A prominent US-based financial data provider required a robust industry classification system to enhance investment analysis. Challenges included reliance on third-party data, discrepancies in financial data standardization, and delays in classification revisions. A proprietary methodology was developed to deliver timely and accurate classifications, improving investment insights."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/standardizing-benchmarks-across-6700-funds-elevating-investment-precision',
        imgsrc: case58,
        heading: "Standardizing Benchmarks Across 6,700 Funds, Elevating Investment Precision",
        subheading: "A prominent global asset management company managing over 6,700 funds across 26 regions needed to optimize benchmark tracking, standardization, and ETF mapping for improved investment decisions. By addressing inconsistencies in benchmark formats and missing assignments, the organization significantly enhanced its investment precision."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/reducing-client-query-time-by-29-ensuring-100-support-coverage-with-an-offshore-team',
        imgsrc: case57,
        heading: "Reducing Client Query Time by 29%, Ensuring 100% Support Coverage with an Offshore Team",
        subheading: "A leading US firm specializing in global investor and fund manager sentiment insights faced challenges in providing round-the-clock customer support services. Delays in case resolution and inconsistent handling of client issues impacted customer satisfaction. By establishing a dedicated offshore support team and developing an NLP-based chatbot, significant improvements were achieved in response times and support efficiency."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/automating-fund-classification-for-2600-funds-enhancing-insights-and-efficiency',
        imgsrc: case56,
        heading: "Automating Fund Classification for 2,600+ Funds, Enhancing Insights and Efficiency",
        subheading: "A leading fund database provider, managing approximately $37 trillion in AUM, needed precise classifications for various fund types. By implementing financial data automation for fund classifications, Decimal Point Analytics improved efficiency and accuracy in delivering insights to high-net-worth individuals and institutional clients."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/delivering-public-float-data-50-hours-faster-empowering-smarter-investment-decisions',
        imgsrc: case55,
        heading: "Delivering Public Float Data 50 Hours Faster, Empowering Smarter Investment Decisions",
        subheading: "A leading investment firm needed a streamlined process to calculate public float values to keep pace with market shifts. By implementing an efficient calculation system, Decimal Point Analytics provided timely updates on stock splits and market changes, empowering clients with precise insights for smarter, faster decision-making."
    },
    {
        categories: ['Bridge Lending'],
        link: '/case-studies/achieving-60-faster-processing-times-in-bridge-lending-with-automated-processes',
        imgsrc: case54,
        heading: "Achieving 60% Faster Processing Times in Bridge Lending with Automated Processes",
        subheading: "A US-based financial firm specializing in bridge lending faced significant challenges with manual data entry and inefficient processes, resulting in lengthy turnaround times for loan origination. By automating their data tracking, data entry, and document management processes, Decimal Point Analytics enabled the firm to achieve a remarkable 60% reduction in processing time, enhancing operational efficiency and client satisfaction."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/us-asset-management-firm-boosts-security-with-30-faster-operations-and-zero-downtime',
        imgsrc: case53,
        heading: "US Asset Management Firm Boosts Security with 30% Faster Operations and Zero Downtime",
        subheading: "A US-based asset management firm with approximately $12 billion AUM sought to enhance the security and performance of its scheduling tool. With outdated systems posing security risks, Decimal Point Analytics facilitated a seamless cloud infrastructure migration to a more advanced platform. This migration resulted in a 30% increase in job execution speed, zero downtime during the transition, and improved security for sensitive communications."
    },
    {
        categories: ['Web Development'],
        link: '/case-studies/streamlining-operations-with-oracle-apex-and-40-cost-reduction-in-inventory-management',
        imgsrc: case52,
        heading: "Streamlining Operations with Oracle APEX and 40% Cost Reduction in Inventory Management",
        subheading: "A Canada-based innovator specializing in industrial imaging for field machines faced significant challenges in managing their inventory management software and data analysis. By partnering with Decimal Point Analytics, the firm streamlined their front-end development processes, reduced operational costs, and enhanced the scalability of their inventory management system."
    },
    {
        categories: ['Process Automation and RPA'],
        link: '/case-studies/improving-oas-bond-pricing-accuracy-by-25-for-a-global-investment-firm',
        imgsrc: case51,
        heading: "Improving OAS Bond Pricing Accuracy by 25% for a Global Investment Firm",
        subheading: "A global investment firm needed to enhance its OAS bond pricing to provide more accurate analyses for its clients. The firm's existing models lacked precision, leading to inconsistent pricing and reduced client confidence. By developing advanced OAS models, the firm improved bond pricing accuracy, boosted credibility, and delivered more reliable insights to its clients."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/boosting-forecast-accuracy-by-30-with-advanced-inflation-models-for-a-global-hedge-fund',
        imgsrc: case50,
        heading: "Boosting Forecast Accuracy by 30% with Advanced Inflation Models for a Global Hedge Fund ",
        subheading: "A global hedge fund's Chief Economist needed more accurate inflation models to provide better forecasts and guide investment strategies. The existing models failed to capture the complex interplay of global economic variables, leading to inaccurate predictions. By developing enhanced inflation models, the Chief Economist was able to deliver more accurate forecasts, supporting better investment decisions and boosting the fund’s profitability."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/achieving-90-accuracy-in-industry-classification-for-a-leading-us-financial-data-provider',
        imgsrc: case49,
        heading: "Achieving 90% Accuracy in Industry Classification for a Leading US Financial Data Provider",
        subheading: "A leading US financial data provider faced challenges with maintaining an up-to-date and accurate industry classification system. The existing models were outdated, leading to misclassifications and inaccuracies in sector-based analyses. By re-engineering the classification system, the provider achieved more accurate industry categorizations, improved data reliability, and enhanced client satisfaction"
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/driving-1.5m-monthly-profit-increase-with-product-portfolio-optimization-for-a-mena-fmcg',
        imgsrc: case48,
        heading: "Driving $1.5M Monthly Profit Increase with Product Portfolio Optimization for a MENA FMCG",
        subheading: "A major FMCG company in the Middle East and North Africa (MENA) region, managing over 1,200 outlets, sought to optimize their product portfolio and streamline their reporting process. Manual calculations and data handling were time-consuming, impacting their ability to make timely, informed decisions. By implementing an automated framework, the company improved its profitability by $1.5 million per month and reduced processing time by 75%."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/enhancing-catastrophe-modeling-accuracy-for-a-uk-based-reinsurer-with-4000-models',
        imgsrc: case47,
        heading: "Enhancing Catastrophe Modeling Accuracy for a UK-Based Reinsurer with 4,000+ Models",
        subheading: "A leading UK-based reinsurer needed more precise catastrophe models to assess risk, price policies accurately, and manage capital effectively. With outdated risk models and limited scenario analysis, they faced challenges in developing accurate technical pricing. By partnering with Decimal Point Analytics, the firm implemented advanced catastrophe models, improving risk assessment, pricing, and profitability."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/optimizing-portfolio-investment-decisions-for-a-leading-middle-eastern-asset-management-firm',
        imgsrc: case46,
        heading: "Optimizing Portfolio Investment Decisions for a Leading Middle Eastern Asset Management Firm",
        subheading: "A prominent asset management firm in the Middle East, managing over $7 billion in assets, faced significant challenges with data interpretation, earnings call delays, and macroeconomic analysis. By partnering with Decimal Point Analytics, the firm streamlined their investment analysis, reduced delays, and achieved more informed portfolio decisions"
    },
    {
        categories: ['Predictive Analytics and Forecasting Solutions'],
        link: '/case-studies/the-impact-of-predictive-analytics-on-strategic-growth-in-a-leading-indian-bank',
        imgsrc: case45,
        heading: "The Impact of Predictive Analytics on Strategic Growth in a Leading Indian Bank",
        subheading: "A prominent Indian bank, with over 550 branches serving 2.2 million customers, needed to manage vast amounts of daily transactional data to fuel their growth strategy. Utilizing predictive analytics and advanced data visualization, the bank enhanced decision-making, optimized branch expansion, and improved customer segmentation."
    },
    {
        categories: ['Other'],
        link: '/case-studies/dpas-implementation-expertise-enhances-clickups-full-potential',
        imgsrc: case44,
        heading: "DPA’s Implementation Expertise Enhances ClickUp’s Full Potential",
        subheading: "Decimal Point Analytics (DPA) enhances project management using ClickUp with solutions like a custom ticket system and project health monitoring. These tools boost client satisfaction and optimize resource allocation. DPA’s fever chart allows quick risk assessments, while real-time collaboration improves team cohesion. This has reduced project completion times by 35% and increased productivity by 30%, helping organizations unlock ClickUp’s potential."
    },
    {
        categories: ['Predictive Analytics and Forecasting Solutions'],
        link: '/case-studies/delivering-efficiency-gains-through-predictive-analytics-for-a-us-asset-advisory-firm',
        imgsrc: case43,
        heading: "Delivering 8x Efficiency Gains through Predictive Analytics for a US Asset Advisory Firm",
        subheading: "A US-based firm specializing in asset management faced challenges managing over 2 million daily data rows, leading to inefficiencies and slow decision-making. By implementing predictive analytics, the firm enhanced its inventory management, achieving faster turnaround times and improved resource allocation through automated data processing"
    },
    {
        categories: ['Loan and Credit Processing Automation'],
        link: '/case-studies/cutting-loan-pool-acquisition-time-by-for-a-leading-us-asset-management-firm',
        imgsrc: case42,
        heading: "Cutting Loan Pool Acquisition Time by 75% for a Leading US Asset Management Firm",
        subheading: "A US-based asset management firm specializing in real estate loans faced eight-month delays in loan pool acquisitions, hindering investment efficiency. By leveraging Decimal Point analytics for loan management automation and process re-engineering, they reduced acquisition time to just four weeks, accelerating deployment and achieving significant cost savings."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/reducing-fund-processing-time-by-for-a-mena-asset-management-firm',
        imgsrc: case41,
        heading: "Reducing Fund Processing Time by 50% for a MENA Asset Management Firm",
        subheading: "A leading MENA asset management firm sought to streamline its processes for managing diverse data inputs and automating fund accounting reports. By implementing fund accounting automation, the firm achieved faster turnaround times, improved NAV calculation accuracy, and enhanced operational efficiency."
    },
    {
        categories: ['Risk Management Optimization'],
        link: '/case-studies/enhanced-risk-management-capabilities-for-a-leading-us-based-asset-management-firm',
        imgsrc: case40,
        heading: "Enhanced Risk Management Capabilities for a Leading US-based Asset Management Firm",
        subheading: "A leading US-based asset management firm partnered with Decimal Point Analytics (DPA) to enhance its risk management processes. They addressed challenges like unstructured data management and investment categorization, streamlining manual processes and improving data consistency. This collaboration led to more effective risk management and better-informed decision-making."
    },
    {
        categories: ['Fraud Detection and Compliance Optimization'],
        link: '/case-studies/streamlining-consultant-database-management-for-enhanced-efficiency',
        imgsrc: case39,
        heading: "Streamlining Consultant Database Management for Enhanced Efficiency",
        subheading: "A leading asset management company struggled with labor-intensive consultant data management. Decimal Point Analytics deployed a dedicated team to streamline handling, perform accurate calculations, and utilize historical RFPs."
    },
    {
        categories: ['Data Analytics'],
        link: '/case-studies/enhancing-portfolio-management-through-advanced-analytics-and-machine-learning',
        imgsrc: case38,
        heading: "Enhancing Portfolio Management through Advanced Analytics and Machine Learning",
        subheading: "Explore how a US event analytics provider improved portfolio management by tackling manual data processing challenges with an advanced machine learning platform, boosting accuracy, efficiency, and decision-making."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/transforming-rent-roll-data-management-efficiency-and-automation-with-dpas-data-ingestion-platform',
        imgsrc: case37,
        heading: "Transforming Rent Roll Data Management: Efficiency and Automation with DPA’s Data Ingestion Platform",
        subheading: "Discover how a US asset management firm transformed its rent roll data management with an automated platform, enhancing efficiency and enabling more accurate, data-driven decisions. They reduced manual processing errors and high costs."
    },
    {
        categories: ['ESG Data Extraction and Reporting'],
        link: '/case-studies/overcoming-data-challenges-to-build-a-credible-esg-index',
        imgsrc: case36,
        heading: "Overcoming Data Challenges to Build a Credible ESG Index",
        subheading: "Discover how Decimal Point Analytics helped an Indian financial index service provider overcome ESG data collection challenges to build a credible ESG index. Learn about their innovative solutions in data management and quality control, ensuring high-quality, auditable data for informed decision-making."
    },
    {
        categories: ['Process Automation and Data Management'],
        link: '/case-studies/a-lending-company-transforms-data-management-a-case-study-in-efficiency',
        imgsrc: case35,
        heading: "A Lending Company Transforms Data Management: A Case Study in Efficiency",
        subheading: "Discover how a leading US lending company overcame data silos and inefficiencies with Decimal Point Analytics (DPA). By streamlining data management, they reduced errors, cut costs, and enhanced operational efficiency, enabling better decision-making and business success."
    },
    {
        categories: ['Other'],
        link: '/case-studies/automating-data-and-news-updation-for-enhanced-index-management',
        imgsrc: case34,
        heading: "Automating Data and News Updation for Enhanced Index Management",
        subheading: "Discover how Decimal Point Analytics transformed a leading financial institution's data management with advanced automation solutions, enhancing index management, optimizing data retrieval, and seamlessly integrating news updates for informed decision-making"
    },
    {
        categories: ['Other'],
        link: '/case-studies/enhancing-asset-forecasting-capabilities-for-a-leading-financial-institution',
        imgsrc: case33,
        heading: "Enhancing Asset Forecasting Capabilities for a Leading Financial Institution",
        subheading: "Implemented advanced asset forecasting for a leading financial firm, elevating both accuracy and efficiency. Discover unparalleled forecasting capabilities with Decimal Point Analytics"
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/wealth-management-solution',
        imgsrc: case32,
        heading: "Optimizing Asset Allocation: A Tailored Wealth Management Solution",
        subheading: "Decimal Point Analytics delivers tailored software for asset allocation in insurance and investment. Our expertise ensures seamless data integration, empowering staff and enabling swift product launches. Explore innovative wealth management with us."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence'],
        link: '/case-studies/financial-data-extraction-global-index-provider',
        imgsrc: case31,
        heading: "Enhancing Business Intelligence: Transforming Data Extraction for a Leading Financial Institution",
        subheading: "Decimal Point Analytics enhanced data accuracy and efficiency through advanced web scraping, standardizing, and processing 450,000 records monthly."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence'],
        link: '/case-studies/revolutionising-machinery-and-equipment-data-management-with-web-scraping-and-advanced-analytics',
        imgsrc: case30,
        heading: "Revolutionising Machinery And Equipment Data Management With Web Scraping And Advanced Analytics",
        subheading: "Decimal Point Analytics enabled 300,000 monthly records acquisition, ensuring data integrity, accuracy, and uniformity."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/revolutionizing-financial-data-analysis-for-global-indices',
        imgsrc: case29,
        heading: "Revolutionizing Financial Data Analysis for Global Indices",
        subheading: "Decimal Point Analytics transformed financial data analysis for a global indices provider, automating data collection, ensuring multilingual document accuracy, and achieving 99.9% data precision."
    },
    {
        categories: ['Risk Management Optimization'],
        link: '/case-studies/optimizing-supplier-risk-management-unleashing-the-power-of-advanced-analytics-for-seamless-operation',
        imgsrc: case28,
        heading: "Optimizing Supplier Risk Management: Unleashing the Power of Advanced Analytics for Seamless Operation",
        subheading: "A top enterprise transforms supplier risk management using advanced analytics, slashing assessment times, boosting operational efficiency, and gaining unprecedented visibility for proactive risk mitigation."
    },
    {
        categories: ['Loan and Credit Processing Automation'],
        link: '/case-studies/investment-leader-automates-credit-and-loan-operations-achieving-remarkable-efficiency-and-precision',
        imgsrc: case27,
        heading: "Investment Leader Automates Credit and Loan Operations, Achieving Remarkable Efficiency and Precision",
        subheading: "Investment Management Innovator streamlines credit and loan operations with advanced automation, enhancing efficiency and decision-making accuracy, and ensuring robust risk management."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/advanced-fund-accounting-solutions-for-top-financial-services-group-in-mena-region',
        imgsrc: case26,
        heading: "Advanced Fund Accounting Solutions for Top Financial Services Group in MENA Region",
        subheading: "Decimal Point Analytics provided a tailored fund accounting solution for a prominent MENA financial services group with a $2.5 billion portfolio. Their system automated data processing, ensuring accuracy and cost-efficiency while maintaining reliability."
    },
    {
        categories: ['ESG Data Extraction and Reporting'],
        link: '/case-studies/enhanced-esg-reporting-from-voluminous-data-to-clear-insights',
        imgsrc: case25,
        heading: "Enhanced ESG Reporting: From Voluminous Data to Clear Insights",
        subheading: "A global asset manager's complex ESG reports were streamlined into actionable insights, enabling efficient, data-driven decisions and offering forward-looking ESG perspectives."
    },
    {
        categories: ['AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company',
        imgsrc: case24,
        heading: "How AI-Driven Automation Revolutionizes a Leading European Industrial Company",
        subheading: "A Large European Industrial Company faced a critical challenge in transforming its business operations and driving significant value in the longer term. One area requiring improvement was the process of curating news for their newsletter."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/driving-investment-banking-success-decimals-comprehensive-solutions-for-valuation-research-and-trade-recommendations',
        imgsrc: case23,
        heading: "Driving Investment Banking Success: Decimal's Comprehensive Solutions for Valuation, Research, and Trade Recommendations",
        subheading: "In the fast-paced world of investment banking, staying ahead is crucial for success. This case study highlights how Decimal's valuation research and trade recommendations fueled remarkable growth for a top New York investment bank."
    },
    // { 
    //     link: '/case-studies/optimum-data-analytics-solution-for-a-leading-indian-bank',
    //     imgsrc: case22,
    //     heading: "Optimum Data Analytics Solution For A Leading Indian Bank",
    //     subheading:"Powered by Decimal's data analytics solution, a leading Indian bank experienced an unprecedented surge in business growth through optimized cross-selling, customer retention, and branch expansion strategies."
    // },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/improving-database-management-for-enhanced-decision-making-in-the-alternate-fixed-income-industry',
        imgsrc: case21,
        heading: "Improving Database Management for Enhanced Decision-Making",
        subheading: "Pioneering the Alternate Fixed Income industry, a transformative database management solution reshaped decision-making for a US-based firm by automating data input, consolidating details, and analyzing creditworthiness improving bill management efficiency."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/streamlining-technical-support-and-enhancing-efficiency-through-process-automation',
        imgsrc: case20,
        heading: "Streamlining Technical Support and Enhancing Efficiency through Process Automation",
        subheading: "Unleashing the power of automation, Decimal revolutionized data management for a financial ratings firm by addressing Excel-based challenges.  leading to significant time and cost savings and enhanced decision-making capabilities."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence'],
        link: '/case-studies/transforming-banking-operations-with-advanced-data-analytics',
        imgsrc: case19,
        heading: "Transforming Banking Operations with Advanced Data Analytics",
        subheading: "Through the implementation of advanced data analytics, an esteemed Indian bank experienced an empowering transformation, leading to invaluable insights for informed decision-making, precise customer targeting, and the identification of growth opportunities."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits',
        imgsrc: case18,
        heading: "Automated Data Warehouse & Back-Testing Solutions That Helped a Global Index Provider Achieve Financial Benefits",
        subheading: "Discover how Decimal Point Analytics revolutionized a global index provider's operation by implementing automated data warehouse and back-testing solutions. The transformation led to enhanced data quality, increased profitability, and a competitive advantage in the market."
    },
    {
        categories: ['ESG Data Extraction and Reporting'],
        link: '/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports',
        imgsrc: case17,
        heading: "Extraction of GHG Emission Data from ESG Disclosure Reports",
        subheading: "Explore how Decimal Point Analytics revolutionized the extensive extraction of GHG emission data from 5,500 companies, fueled a dynamic climate management platform providing valuable insights for informed decision-making and risk reduction with exceptional support."
    },
    {
        categories: ['Data Management'],
        link: '/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider',
        imgsrc: case15,
        heading: "Delivering Comprehensive Data Solutions for a Leading Financial Data Aggregator and Provider",
        subheading: "A prominent Canadian data aggregator successfully harnessed the power of comprehensive data solutions, capitalizing on advanced analytics expertise to optimize services, streamline operations, and elevate customer satisfaction to new heights."
    },
    {
        categories: ['Salesforce Support'],
        link: '/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm',
        imgsrc: case16,
        heading: "Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM",
        subheading: "A leading financial services provider successfully implemented tailored process automation in Salesforce CRM, ensuring the delivery of high-quality data. This streamlined approach enhanced data accuracy, improved operational efficiency, and empowered the organization to make informed decisions."
    },
    {
        categories: ['Risk Management Optimization'],
        link: '/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer',
        imgsrc: case9,
        heading: "Fraud Detection & Analytics for a Leading German Reinsurer",
        subheading: "Leveraging advanced analytics, Decimal delivered a comprehensive fraud solution to a German reinsurer. Through data extraction, cleansing, and machine learning, suspicious accounts were detected, and future fraud prevention was strengthened using Fidelity Pulse."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence'],
        link: '/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions',
        imgsrc: case10,
        heading: "Process & Cost Efficiency through DPA's Structured Data Management Solutions",
        subheading: "By adopting structured data management solutions, a prominent company achieved remarkable process and cost efficiency, leading to substantial savings of $250,000. The implementation improved data organization, minimized errors, optimized resource allocation, and resulted in enhanced productivity and overall operational efficiency."
    },
    {
        categories: ['Salesforce Support'],
        link: '/case-studies/salesforce-automation-to-simplify-your-complex-processes',
        imgsrc: case11,
        heading: "Salesforce Automation to Simplify Your Complex Processes",
        subheading: "Revolutionizing operations, Salesforce automation enabled a non-bank lending company to achieve remarkable efficiency gains. With automated data entry, refined user interface, and streamlined workflows, they saved time, improved data analysis, and accelerated loan approvals."
    },
    {
        categories: ['Automated Data Extraction and Business Intelligence', 'AI and Machine Learning-Driven Solutions'],
        link: '/case-studies/cutting-edge-data-solutions-for-a-corporate-client',
        imgsrc: case12,
        heading: "Cutting Edge Data Solutions for a Corporate Client",
        subheading: "Empowering a corporate client, a transformative data partnership revolutionized their trading offers and amplified analytical insights. With expertise in data analysis and automation, this collaboration drove informed decision-making and substantial revenue growth."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/offering-solutions-for-every-asset-managers',
        imgsrc: case13,
        heading: "Offering solutions for every Asset Managers",
        subheading: "Decimal Point Analytics provided comprehensive solutions for asset managers, enabling them to optimize operations, enhance investment decisions, and drive superior outcomes. The tailored solutions catered to the unique needs of asset managers, resulting in improved operational efficiency, client satisfaction, and superior outcomes."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/nav-calculations-for-portfolio-management',
        imgsrc: case1,
        heading: "NAV Calculations for Portfolio Management",
        subheading: "Revolutionizing a large insurance company, an automation solution streamlined NAV calculations and enhanced portfolio management efficiency. This reduced calculation time, provided consolidated models for better evaluation, and attracted more investments."
    },
    {
        categories: ['Process Automation'],
        link: '/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager',
        imgsrc: case2,
        heading: "Intelligent document processing of Rent Rolls for an Asset Manager",
        subheading: "Transforming document processing for an asset management firm, an advanced solution streamlined rent roll data extraction. By eliminating manual work and enhancing analysis capabilities, remarkable time and cost efficiencies were achieved."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/data-managment-for-efficient-crm-usage',
        imgsrc: case3,
        heading: "Data Management for Efficient CRM Usage",
        subheading: "Optimizing CRM data management, an advanced solution boosted investor interactions and operational efficiency for an investment management firm. It resolved data challenges, automated processes, and introduced comprehensive check reports for streamlined maintenance."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/response-management-system-for-fund-raising',
        imgsrc: case4,
        heading: "Response Management System for Fund Raising",
        subheading: "Revamping response management, an innovative solution streamlined operations for a leading credit-focused asset management firm, enhancing fund raising. It reduced turnaround time, improved response quality, and achieved impressive accuracy rates."
    },
    {
        categories: ['Investment Decision Support'],
        link: '/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm',
        imgsrc: case5,
        heading: "Automated Portfolio Monitoring System for a Private Equity firm",
        subheading: "Revolutionizing portfolio monitoring, an automated system streamlines performance analysis for a leading private equity firm. With a centralized data repository, standardized templates, and interactive dashboards, it enables swift analysis and peer comparison."
    },
    {
        categories: ['Robotic Process Automation (RPA)'],
        link: '/case-studies/robotic-process-automation-for-credit-funds',
        imgsrc: case6,
        heading: "Robotics Process Automation (RPA) for Credit Funds",
        subheading: "Revolutionizing document and email management, an advanced automation solution empowers a leading credit-focused alternative asset management firm. Leveraging Robotics Process Automation (RPA), it ensures timely and accurate lender approvals for new security purchases."
    },
    {
        categories: ['Loan and Credit Processing Automation'],
        link: '/case-studies/customized-credit-platform-for-financial-institution',
        imgsrc: case7,
        heading: "Customized Credit Platform for Financial Institution",
        subheading: "A financial institution partnered with Decimal Point Analytics to develop a customized credit platform. This platform improved risk assessment, streamlined processes, and facilitated informed lending decisions, resulting in increased operational efficiency, reduced credit risk, and enhanced profitability for the institution."
    },
    {
        categories: ['Dashboard Solutions'],
        link: '/case-studies/intelligent-dashboard-solutions-for-banks',
        imgsrc: case8,
        heading: "Intelligent Dashboard Solution for Banks",
        subheading: "Empowering a leading African bank, Decimal Point Analytics delivered an intelligent dashboard solution offering real-time data visualization, performance tracking, and risk monitoring. The intuitive dashboards provided actionable insights, enhancing operational efficiency, empowering data-driven decision-making, and enabling effective risk management."
    },
]

export default Case_studies_Data;