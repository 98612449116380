import { Container, Grid, Typography, Box, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import banenr2 from "../../asset/images/animation/aimid.webp";
import { Helmet } from 'react-helmet';
import ProductCTAA from '../../Component/ProductCTAA';

import solution1 from '../../asset/images/innovation/solutions/solution1.svg'
import solution2 from '../../asset/images/innovation/solutions/solution2.svg'
import solution3 from '../../asset/images/innovation/solutions/solution3.svg'
import solution4 from '../../asset/images/innovation/solutions/solution4.svg'


import benifit1 from '../../asset/images/innovation/benifits/benifit1.svg'
import benifit2 from '../../asset/images/innovation/benifits/benifit2.svg'
import benifit3 from '../../asset/images/innovation/benifits/benifit3.svg'
import benifit4 from '../../asset/images/innovation/benifits/benifit4.svg'
import benifit5 from '../../asset/images/innovation/benifits/benifit5.svg'
import benifit6 from '../../asset/images/innovation/benifits/benifit6.svg'


import project1 from '../../asset/images/innovation/projects/project1.svg'
import project2 from '../../asset/images/innovation/projects/project2.svg'
import project3 from '../../asset/images/innovation/projects/project3.svg'
import project4 from '../../asset/images/innovation/projects/project4.svg'
import project5 from '../../asset/images/innovation/projects/project5.svg'
import project6 from '../../asset/images/innovation/projects/project6.svg'
import project7 from '../../asset/images/innovation/projects/project7.svg'
import project8 from '../../asset/images/innovation/projects/project8.svg'
import project9 from '../../asset/images/innovation/projects/project9.svg'


import insight1 from '../../asset/images/innovation/insights/insight1.svg'
import insight2 from '../../asset/images/innovation/insights/insight2.svg'
import insight3 from '../../asset/images/innovation/insights/insight3.svg'
import insight4 from '../../asset/images/innovation/insights/insight4.svg'
import insight5 from '../../asset/images/innovation/insights/insight5.svg'

import CS1 from "../../asset/images/insight/case_studies/case_studies_thumbnails/ai_driven_insurance.webp";


import Journey from "../../asset/images/innovation/ai_2.webp";


import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

import AI from "../../asset/images/new_banner/ai_banner.webp"
import Writemessage3 from '../../Component/Writemessage3';


export default function Artificialintelligence() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => { setIsOpen(true); };


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Elevate Your Data Analytics with Leading AI & Generative Insights Solutions</h1>
                <title>Leading AI & Generative Insights Solutions | Elevate Your Data Analytics</title>
                <meta name="description" content="Experience the future of analytics with Decimal Point Analytics' AI solutions. Schedule your demo today and thrive in a data-driven world." />
                <meta name="keywords" content="AI in Data Analytics, Generative Insights Solutions, Data-Driven Business, AI-Powered Analytics, Custom AI Solutions, Advanced Data Analysis, Demo for AI Analytics Future of Analytics, Alternate Data Sources, Data Analytics Expertise" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/artificial-intelligence-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id='artificial-intelligence'>

                <Box className="banner_bx" mb={"2px"}>
                    <Box style={{ backgroundImage: "url(" + AI + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={3} md={4} sm={6} xs={10}>
                                    <Box className="al_center" mt={3}>
                                        <Typography className="white" variant="h1">Artificial Intelligence</Typography>
                                        <Box mt={'10px'}>
                                            <Typography className="white" variant="h4">Leading the Future of Innovation with Generative AI</Typography>
                                            <Box className='banner_btns'>
                                                <Box className='cta_btn'>
                                                    <ProductCTAA cta_btn_name="Schedule a free Demo today!" pageVisited="Artificial Intelligence" />
                                                </Box>
                                                <Box className='banner_pulse'>
                                                    <IconButton className="header_pulse_button" onClick={openModal} >
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Writemessage3 pageVisited="Artificial Intelligence" />


                <Box className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Journey in Generative AI
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mt={0}>
                                    <Typography variant='h6' className='black al_center'>
                                        Even before the rise of the buzzword 'Generative AI', we were deep into its exploration. Here's a glimpse of our evolutionary journey:
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={Journey} className='topbanner' alt='Journey' />
                                </Box>
                            </Grid>


                        </Grid>
                    </Container>
                </Box>




                <Box className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Specialized Solutions
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mt={0}>
                                    <Typography variant='h6' className='black al_center'>
                                        Before ChatGPT became a standard, our deep-rooted expertise in data exploration spanned multiple domains:
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className="cardbx solution_fx_bx">
                                    <Box mb={2}>
                                        <img loading="lazy"  src={solution1} className="bxicon" alt="solution1" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h4" className="blue two">
                                            Our Exposure Before ChatGPT became the "Talk of the Town"
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className='black al_left'>
                                            <ul>
                                                <li> Fraud Detection & Forecasting</li>
                                                <li> Summarizer, Audio transcript, Text classfication</li>
                                                <li> Presentation Design Generation </li>
                                                <li> Audio Embeddings</li>
                                                <li> Audio-based Noise classifier & Fault Detection</li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className="cardbx solution_fx_bx">
                                    <Box mb={2}>
                                        <img loading="lazy"  src={solution2} className="bxicon" alt="solution2" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h4" className="blue two">
                                            Data Exploration
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className='black al_left'>
                                            <ul>
                                                <li> Upload files in format such as CSV/PDF to generate valuable insights </li>
                                                <li> Generate concise and informative summaries of the team meetings </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>



                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <Grid item lg={6} md={6} sm={8} xs={12}>
                                        <Box className="cardbx">
                                            <Box pb={'10px'}>
                                                <Typography variant="h4" className="blue mob_center">
                                                    Retrieval Augmented Generation (RAG)
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className="cardbx solution_fx_bx">
                                    <Box mb={2}>
                                        <img loading="lazy"  src={solution3} className="bxicon" alt="solution3" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h4" className="blue two">
                                            RFP Response Management
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className='black al_left'>
                                            <ul>
                                                <li> Accessing responses from content library and selecting the most relevant and latest answer </li>
                                                <li> Learning of responses articulation style and utilizing it to address similar question </li>
                                                <li> Utilizing latest data points in responses, e.g- latest AUM/NAV numbers automatically </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className="cardbx solution_fx_bx">
                                    <Box mb={2}>
                                        <img loading="lazy"  src={solution4} className="bxicon" alt="solution4" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h4" className="blue two">
                                            Automated News Article Generation
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h6' className='black al_left'>
                                            <ul>
                                                <li> Analyzing news articles to understand the desired article style </li>
                                                <li> Employing both base news and raw information to craft news articles in the specified style </li>
                                                <li> Testing of prompt and the model as part of the process </li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>







                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='mt16'>
                                    <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today!" pageVisited="Artificial Intelligence" />
                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>



                {/* <Writemessage3 pageVisited="Artificial Intelligence" /> */}

                <Box className='section mob_card sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2"> Enhance Your Analytical Approach
                                        <Typography variant="h2" className="black h2v2"> with AI-Driven Benefits </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="center">

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit1} className='allic' alt='benifit1' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'>Efficient Data Management: </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> Facilitate real-time access, manage diverse structures, and ensure seamless scalability. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit2} className='allic' alt='benifit2' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'>Revolutionize Data Processes: </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> Achieve automation in cleaning & standardizing, liberating up to 70% of a data scientist’s time for more strategic tasks. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit3} className='allic' alt='benifit3' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'> Strategic Decision Support: </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> Stay informed and ahead of competitors by leveraging anomaly detection and advanced predictive insights. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>


                                </Grid>

                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={banenr2} className='topbanner2' alt='Banner2' />
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit4} className='allic' alt='benifit4' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'> Maximize Productivity:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> Deploy pattern detection, recommendation systems, and advanced modeling to optimize performance and manage risks effectively. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit5} className='allic' alt='benifit5' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'>Harness Alternate Data:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> Extract valuable insights from many sources, ranging from weather conditions to conversations on social platforms. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg'>
                                            <Box className='al_center'>
                                                <img loading="lazy"  src={benifit6} className='allic' alt='benifit6' />
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Typography variant='h4' className='blue two al_center'>Specialized Generative Solutions:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' className='black al_center'> From fraud detection, audio transcription, and LLM applications to innovative presentation designs and precise financial evaluations. </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='mt16'>
                                    <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today! " pageVisited="Artificial Intelligence" />
                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>





                <Box className="section mob_card" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2"> Highlighted Projects and Successes
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <Grid container spacing={2} alignItems="top" justifyContent='center'>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project1} className="bxicon" alt="project1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Rent Roll Database:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Excel database for 600k US property rent invoices via ML; transformed 50K+ PDFs into Excel.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project2} className="bxicon" alt="project2" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Asset Appraisal:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    ML-driven forecasting for a US Asset Appraisal Firm; enhanced decision-making processes.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project3} className="bxicon" alt="project3" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Deal Market Share Tracking:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Merged 33,000+ PE deals; BI dashboards for a $50B AUM PE firm.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project4} className="bxicon" alt="project4" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Competitive Intelligence:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Analyzed 20+ US bank offerings with ML.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project5} className="bxicon" alt="project5" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    News Management:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    AI for shortlisting/translation of 2,000 daily articles for a German manufacturer.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project6} className="bxicon" alt="project6" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Equipment Watch:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    ML prediction of the US construction market outlook using economic indicators.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project7} className="bxicon" alt="project7" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Fund Reconciliation:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Managed 350+ funds; crafted co-investment templates for a $400B AUM manager.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project8} className="bxicon" alt="project8" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    RoboPulse:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Advanced predictive models for precise financial evaluations.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={project9} className="bxicon" alt="project9" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Credit Risk Analysis:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black al_left'>
                                                    Modernized credit evaluations with 90% accuracy; added early distress detection.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='mt16'>
                                        <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today! " pageVisited="Artificial Intelligence" />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Container>
                </Box>



                <Box className="section mob_card sec_bg" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2"> Elevate Your Business
                                        <Typography variant="h2" className="black h2v2"> with Next-Gen AI Insights </Typography>
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black al_center" variant="h6"> Harness the power of Artificial Intelligence with our comprehensive solutions. From anomaly detection and effective forecasting to automation and complex data interpretation, we pave the way for businesses to leverage AI for optimal outcomes. With our deep expertise in the latest algorithms, technologies, and underlying processes, we are transforming how data is viewed, analyzed, and utilized. </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <Grid container spacing={2} alignItems="top" justifyContent='center'>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center">
                                        <Typography className="h2v2 blue fw5" variant="h2"> Initiatives for Generative AI Advancement </Typography>
                                    </Box>
                                </Grid>


                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={insight1} className="bxicon" alt="insight1" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Collaboration with Oracle:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    We have secured reserved GPU instances to bolster our computational resources.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={insight2} className="bxicon" alt="insight2" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two">
                                                    Model Fine-tuning:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    By ensuring a high-quality data check, we enhance the accuracy and reliability of our models.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={insight3} className="bxicon" alt="insight3" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Data Management:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    We leverage our data management capabilities to improve the quality of the outputs our models generate.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={insight4} className="bxicon" alt="insight4" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Cloud Partnerships:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    By collaborating with multiple cloud service providers, we are pioneering the development of closed-source LLMs.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="cardbx">
                                        <Box mb={2}>
                                            <img loading="lazy"  src={insight5} className="bxicon" alt="insight5" />
                                        </Box>

                                        <Box>
                                            <Box>
                                                <Typography variant="h4" className="blue two" >
                                                    Time-Preference LLM:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant='h6' className='black jst'>
                                                    Our model is uniquely designed to prioritize and fetch the most recent data, ensuring content freshness.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>




                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center">
                                        <Typography className="black al_center" variant="h6"> Our commitment is to continuously push the boundaries and find innovative solutions to overcome challenges in the Generative AI arena.  </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='mt16'>
                                        <ProductCTAA allignment="al_center" cta_content="Navigate the AI revolution with us, where excellence meets innovation. " cta_btn_name="Contact us" pageVisited="Artificial Intelligence" />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Container>
                </Box>

                <Box className="section2 mob_card" component="section">
                            <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                                <Box>
                                    <Grid container spacing={1} alignItems="top">

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="al_center">
                                                <Typography className="blue h2v2 fw5" variant="h2">
                                                    Success Stories
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                                            
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='reportinfo'>
                                                    <Link to='/case-studies/ai-driven-insurance-claims-processing'>
                                                        <Box className="reportm_bg">
                                                            <Box
                                                                className="reportimg_bg"
                                                                style={{
                                                                    backgroundImage: 'url(' + CS1 + ')',
                                                                }}
                                                            >
                                                            </Box>

                                                            <Box className="reporttitle_box">
                                                                <Typography className="reporttitle two">
                                                                AI-Driven Insurance Claims Processing: Faster, Smarter, and More Accurate
                                                                </Typography>
                                                            </Box>
                                                            <Box className="line"></Box>
                                                        </Box>
                                                    </Link>
                                                </Box>
                                            </Grid> 

                                        </Grid>

                                    </Grid>
                                </Box>
                            </Container >
                        </Box>

            </Box>



            <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="9V08_Li8uO0"
                onClose={() => setIsOpen(false)}
            />

        </>
    );
}
