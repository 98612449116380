import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import TCU from "../../../asset/images/media/the_csr_universe.webp";
import CSRT from "../../../asset/images/media/csr_times.webp";
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Banner from '../../../asset/images/media/press_release/banner_empowers_students.webp'

import { Link } from 'react-router-dom';

export default function DPAEmpowersStudents() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics Empowers Underprivileged Students in Financial Analytics</title>
                <meta name="description" content="Decimal Point Analytics empowers underprivileged students in financial analytics with paid internships, mentorship, and career guidance, promoting diversity and talent growth." />
                <meta name="keywords" content="financial analytics, underprivileged students, internship program, mentorship, career guidance, diversity in finance" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-empowers-underprivileged-students-through-dnyanodapi-dhanodapi-program" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h2' className='mainhead blue al_center'>
                                    Decimal Point Analytics Empowers Underprivileged Students Through Dnyanodapi Dhanodapi Program
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Mumbai, India - 24th June 2024</strong> - <Link to="/" className='link'>Decimal Point Analytics</Link>, a leading financial analytics firm, is committed to creating opportunities and transforming lives through its impactful "Dnyanodapi Dhanodapi" program a corporate social responsibility (CSR) initiative aimed at identifying and nurturing talented students from underprivileged backgrounds in the financial analytics sector.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The "Dnyanodapi Dhanodapi" program provides a unique opportunity for deserving students. It offers paid internships, mentorship programs, and career guidance, ensuring a well-rounded learning experience. Upon successful completion of the internship, students are offered full-time positions at Decimal Point Analytics. The company's commitment extends further. After gaining industry experience, eligible employees can receive sponsorship for their MBA programs. This continuous investment empowers them to take on greater responsibility and leadership roles within the organization.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        "At Decimal Point Analytics, we believe that diversity of talent and thought is essential for driving innovation and success," said Shailesh Dhuri, CEO of Decimal Point Analytics. "The 'Dnyanodapi Dhanodapi' initiative reflects our commitment to promoting equal opportunities and fostering a more inclusive financial analytics industry. We are thrilled to see the positive impact this program is having on talented students, helping them reach their full potential."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The "Dnyanodapi Dhanodapi" initiative, named after the Sanskrit phrase meaning "both knowledge and wealth," embodies the philosophy of valuing education and opportunity as the true wealth. It seeks to bridge the gap for underprivileged students by providing them with essential resources and support to thrive in the competitive field of financial analytics. The program's holistic approach ensures that students receive not just technical training, but also the soft skills and professional development necessary to succeed.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The 2024 "Dnyanodapi Dhanodapi" selection process saw an impressive 483 candidates applying, with visits conducted across six campuses. From this pool, 85 candidates were shortlisted for interviews and interactions, culminating in the final selection of 12 students. Notably, the program achieved a female-to-male ratio of 2:1, demonstrating <Link to="/" className='link'>Decimal Point Analytics'</Link> commitment to gender diversity. These statistics highlight a significant increase in applicant interest compared to previous years, demonstrating the growing demand for opportunities in financial analytics among talented students.
                                    </Typography>


                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://thecsruniverse.com/articles/decimal-point-analytics-empowers-underprivileged-students-in-financial-analytics-sector" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TCU + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://csrtimes.org/through-dnyanodapi-dhanodapi-program-decimal-point-analytics-empowers-underprivileged-students/#:~:text=The%20%E2%80%9CDnyanodapi%20Dhanodapi%E2%80%9D%20program%20provides,positions%20at%20Decimal%20Point%20Analytics" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CSRT + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
