import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_leading_indian_bank.webp"
import Infographics from "../../../../asset/images/insight/case_studies/the_impact_of_predictive_analytics.webp"
import { Link } from 'react-router-dom';



export default function LeadingIndianBank() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Predictive Analytics Boosts Indian Bank’s Growth Strategy</title>
                <meta name="description" content="Learn how predictive analytics empowered a major Indian bank to streamline operations and strategically expand their customer base." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/the-impact-of-predictive-analytics-on-strategic-growth-in-a-leading-indian-bank" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    The Impact <span className='lower'>of</span> Predictive Analytics <span className='lower'>on</span> Strategic Growth <span className='lower'>in a</span> Leading Indian Bank
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A prominent Indian bank, with over 550 branches serving 2.2 million customers, needed to manage vast amounts of daily transactional data to fuel their growth strategy. Utilizing predictive analytics and advanced data visualization, the bank enhanced decision-making, optimized branch expansion, and improved customer segmentation.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>	Data Overload:  </strong> Managing over 300,000 data points daily was resource intensive.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Complex Data Integration: </strong> Merging internal and external data sources was challenging.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Growth Strategy Optimization:  </strong> Required predictive insights for effective branch expansion and customer segmentation.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Predictive Analytics<span className='lower'>and</span>  Advanced Data Integration
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Solution: Predictive Analytics and Advanced Data Integration " />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Decision-Making: </strong> Predictive analytics supported rapid, informed decisions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Efficiency Gains:  </strong> Automation reduced the time spent on data management.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Strategic Growth:  </strong> Predictive models identified key opportunities, enhancing customer engagement.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated Efficiency: </strong> Reduced manual workload and increased accuracy.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Predictive Growth Insights:  </strong>  Actionable insights for strategic expansion.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Data-Driven Decisions: </strong> Enabled fast, strategic decisions through visualization tools.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By leveraging predictive analytics and automation, the bank transformed its approach to data management and growth strategy.  <br />
                                Discover how your organization can harness the power of predictive analytics to drive growth and improve decision-making.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today to start your transformation journey!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}