import { Box, Container, Grid, Typography, IconButton } from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Newsletter_Data from "./Newsletter_data"; // Import the newsletter data
import calendar from "../../asset/images/newsletter/calendar.webp";
import Button from "@material-ui/core/Button";

const responsiveOptions = {
    0: { items: 1 },
    480: { items: 2 },
    778: { items: 3.5 },
    1025: { items: 3.5 },
    3000: { items: 5 }
};

export default function NewsletterCarousel2025() {
    // Filter newsletters for 2025
    const data2025 = Newsletter_Data.filter((item) => item.year === 2025);

    return (
        <Box className="newsroom-banner">
            <Box className="owldot2 nav_newsletter reportinfo gradmain carousel-wrapper carousel_op carousel_2025">
                <Container maxWidth="lg" className="reportinfo">
                    <Grid container spacing={2} alignItems="center">
                        {/* Left Section */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="fx_c">
                                <Box>
                                    <img loading="lazy"  src={calendar} alt="calendar" className="calendar" />
                                </Box>
                                <Typography variant="h2" className="black mb0 newh2">
                                    2025 edition 
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Right Section - Owl Carousel */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="carousel-overlay grad2">
                                <OwlCarousel
                                    className="owl-theme reportinfo carousel_opwh25  "
                                    loop
                                    margin={10}
                                    nav={true}
                                    dots={false}
                                    autoplay={false}
                                    responsive={responsiveOptions}
                                >
                                    {data2025.map((item, index) => (
                                        <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">

                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant="h6" className="al_center fw6 mb0">
                                                        {item.heading2025}
                                                    </Typography>
                                                    <Typography variant="h6" className="al_center fw6">
                                                        {item.date}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Box className="nl_whitebx center " component="section">
                                                <img loading="lazy"  src={item.imgsrc} className="book-image" alt="Newsletter" />
                                                <div className="middle">
                                                    {/* <button className="text">Read More</button> */}
                                                    <Button variant="h6" aria-label={`Read More about ${item.heading}`} className="read_btn">
                                                        Read More
                                                    </Button>
                                                </div>
                                            </Box>

                                        </a>
                                    ))}


                                </OwlCarousel>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>

    );
}
