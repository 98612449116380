import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import culture from "../../asset/images/career/onboarding.webp";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
  { label: 'Life as a Decimal', to: '/life-as-a-decimal' },
  { label: 'Our Culture', to: '/our-culture' },
  { label: 'Journey of a Decimal', to: '/journey-of-a-decimal' },
  { label: 'ज्ञानोदपि धनोदपि', to: '/dnyanodapi-dhanodapi' },
  { label: 'Current Openings', to: '/current-openings' },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function OurCulture() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [value1, setValue1] = React.useState(options[1]);

  return (
    <>
      <Helmet>
        <title>Join Decimal Point Analytics: Elevate Your Career and Impact Society </title>
        <meta
          name="description"
          content="Join Decimal Point Analytics to enhance client experiences and drive positive change in society through continuous improvement and innovation."
        />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/our-culture"
        />
      </Helmet>

      <Box id="investor_relations_tabs" className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(0)} component={Link} to="/life-as-a-decimal" />
                  <Tab label="Our Culture" {...a11yProps(1)} component={Link} to="/our-culture" />
                  <Tab label="Journey of a Decimal" {...a11yProps(2)} component={Link} to="/journey-of-a-decimal" />

                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(3)} className="fw6" component={Link} to="/dnyanodapi-dhanodapi" />
                  <Tab label="Current Openings" {...a11yProps(4)} component={Link} to="/current-openings" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
        <Container maxWidth="lg" className="bdr">
          <Box className="alltxfieldlg">
            <Autocomplete
              disablePortal
              options={options}
              value={value1}
              onChange={(event, newValue1) => setValue1(newValue1)}
              fullWidth
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <Link to={option.to}>
                  <ListItemButton {...props}>
                    <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                  </ListItemButton>
                </Link>
              )}
            />
          </Box>
        </Container>
      </Box>


      <TabPanel value={value} index={1} pt={5}>
        <Box>
          <Box className="section2" component="section">
            <Container maxWidth="lg" className="bdr">
              <Grid container spacing={1} alignItems="center">
                <Grid item lg={7} md={7} sm={6} xs={12}>
                  <Box>
                    <Typography variant="h6" className="black jst listdetails">
                      At Decimal Point Analytics, we strongly believe that we are
                      on a mission, where we want to continuously challenge
                      ourselves everyday to try to outperform ourselves and be
                      better than what we were the previous day. While doing this,
                      we realize that we don’t get excited by being part of a
                      routine job or a career, but we thrive to be part of a much
                      bigger aspiration with a focus to achieve the ultimate goal
                      of self-actualization.
                    </Typography>

                    <Typography variant="h6" className="black jst">
                      We believe in continually raising the bar and exceed the
                      benchmarks that we set for ourselves in delivering solutions
                      to our clients. We derive satisfaction when we are able to
                      enhance the experience of our clients, through our ability
                      to use latest cutting-edge technologies by means of
                      Artificial Intelligence and Machine Learning tools. We are
                      passionate about improving our ways of doing things, be it
                      through automation or innovation. We embrace this culture
                      because we want to elevate ourselves into roles that do
                      justice to our capabilities, require us to think freely,
                      solve big problems and want us to innovate at every
                      opportunity. We are a part of a culture which allows us to
                      realize our true and complete potential.
                    </Typography>

                    <Typography variant="h6" className="black jst">
                      We want to be valuable contributors to society with a strong
                      system of ethics and values. We exist with an objective to
                      bring difference to the world and make it a better place to
                      be in. We do this by associating ourselves with institutions
                      and organizations that share similar mindset of working
                      towards upliftment and betterment of the society.
                    </Typography>

                    <Typography variant="h6" className="black jst">
                      If you are someone, who envisions to be on a journey of
                      continual self-improvement and simultaneously contribute
                      towards changing the world for the better, Decimal Point
                      Analytics is the right place for you to realize your
                      dreams!!
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={5} md={5} sm={6} xs={12}>
                  <Box className="al_center">
                    <img loading="lazy"  src={culture} className="topbanner4" alt="Culture" />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </TabPanel>
    </>
  );
}
