import { Container, Grid, Typography, Box, Button, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import EmailImg from "../../asset/images/email-icon.png"
import { Helmet } from 'react-helmet';
import NewsRoomBanner from './NewsRoomBanner';

export default function Pressreleases() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Press Releases | Decimal Point Analytics</title>
                <meta name="description" content="Stay updated with Decimal Point Analytics' latest press releases, highlighting groundbreaking insights and industry innovations." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/pressreleases" />
            </Helmet>

            <NewsRoomBanner />


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Box className='sec_bg' component='section'>
                        <Container maxWidth='lg' className='bdr'>
                            <Grid container spacing={5} alignItems="center" justifyContent='center'>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className='whitebx '>
                                        <Grid container spacing={5} alignItems="top">
                                            <Grid item lg={3} md={4} sm={12} xs={12} className='fx_c'>
                                                <Box>
                                                    <img loading="lazy"  src={EmailImg} alt='email img' />
                                                </Box>
                                            </Grid>

                                            <Grid item lg={9} md={8} sm={12} xs={12}>
                                                <Box>
                                                    <Typography variant='h2' className='blue mb0 mob_c'>
                                                        Media Contact
                                                    </Typography>

                                                    <Box mt={1}>
                                                        <Typography variant='h6' className='black mob_c mb0'>
                                                            Reach out to us for editorial collaborations and media inquiries.
                                                        </Typography>
                                                    </Box>

                                                    <Box my={1} className='mob_c'>
                                                        <Button
                                                            variant="contained"
                                                            className="dpabtn_sm2"
                                                            onClick={() => window.location.href = "mailto:sailaja.das@decimalpointanalytics.com"}
                                                        >
                                                            Contact our PR Team Here
                                                        </Button>

                                                    </Box>

                                                </Box>
                                            </Grid>
                                        </Grid>



                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box className='reportinfo ' mt={5}>
                        <Grid container spacing={1} alignItems="top">

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/scarcitys-symphony-how-limitations-compose-innovation-decimal-point-analytics'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Scarcity's Symphony – How Limitations Compose Innovation: Decimal Point Analytics
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 11, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                <strong>Decimal Point Analytics</strong> explores how businesses can navigate AI regulatory and resource limitations. Our CEO, Shailesh Dhuri highlights the power of efficiency and data-driven insights in driving innovation.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/beyond-deepfakes-how-ai-powered-cyber-threats-redefining-digital-warfare'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Beyond Deepfakes: How AI-Powered Cyber Threats Redefining Digital Warfare
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 13, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO Shailesh Dhuri highlights how agentic AI is revolutionizing cybersecurity autonomously identifying, analyzing, and mitigating threats in real time.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/the-long-wave-ahead-re-examining-kondratieffs-theory-in-a-multipolar-world'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                The Long Wave Ahead: Re-examining Kondratieff’s Theory in a Multipolar World
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 10, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO Shailesh Dhuri’s latest insights on the next Kondratieff wave and how AI, quantum computing, and innovation are transforming the global economy. Explore his perspective on the future of economic and technological evolution.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/the-digital-renaissance-of-americas-small-business-administration-a-potential-tech-revolution'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                The Digital Renaissance of America's Small Business Administration: A Potential Tech Revolution
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 05, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Read our CEO Shailesh Dhuri’s perspective on the future of small business lending and government digital transformation.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/budget-expectation-2025'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Budget Expectation 2025
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 31, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, predicts that Budget 2025 will drive much-needed reforms in Provident Fund administration, taxation, and investor confidence.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/shailesh-dhuri-ceo-of-decimal-point-analytics-discusses-genai-and-sustainability-in-exclusive-interviews-with-cxo-news-and-apac-news-network'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, Discusses GenAI and Sustainability in Exclusive Interviews with CXO News and APAC News Network
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 17, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In an exclusive conversation with <strong>CXO News</strong> and <strong>APAC News Network</strong>, Our <strong>CEO, Shailesh Dhuri,</strong> elaborates about their efforts around GenAI and sustainability and how they are becoming critical for Indian enterprises
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/navigating-the-ai-landscape-key-trends-shaping-2025-and-beyond'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Navigating the AI Landscape: Key Trends Shaping 2025 and Beyond
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 16, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Paresh Sharma highlights the significant impact of AI in 2024, from advancements in generative and multimodal AI to the rise of emerging trends like Agentic AI and human-in-the-loop systems. As we move toward 2025 and beyond, these innovations are poised to transform industries, driving smarter decision-making, enhanced productivity, and more efficient solutions for complex business challenges. Paresh believes that the future of AI holds immense potential to reshape the way businesses operate and create new opportunities.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-hosts-hackathon-2025'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics (DPA) Hosts Hackathon 2025
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 10, 2025
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA) hosted its inaugural Hackathon 2025 to encourage innovation and collaboration among employees. The event focused on six strategic themes aligned with DPA's core areas of financial analytics and technology: AI and Machine Learning for Private Equity Insights, Automated Compliance and Fraud Detection, Sustainable Investment Analysis, Customer Sentiment Analysis in Financial Services, Enhanced Data Visualization for Financial Insights, and Financial Knowledge Graphs Using NLP
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/contemplating-cyberspace-and-beyond'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Contemplating Cyberspace and Beyond
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 26, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, explores the digital realm, questioning whether cyberspace is a true advancement or a fundamental limitation. He advocates for shifting focus from expanding digital constructs to developing technologies that enable direct interaction with the physical world, such as quantum or biological computing, to overcome the inherent constraints of current digital systems.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/the-new-economics-when-ai-meets-multi-agent-networks'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                The new economics: When AI meets multi-agent networks
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 09, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, discusses how AI-driven Agent-Based Models and network analysis are transforming economic analysis. He highlights how these innovations, combined with human judgment, are redefining policy testing, financial risk management, and supply chain resilience, offering a more dynamic and realistic approach to understanding economic systems.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-launches-comprehensive-investor-relationship-management-solutions'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics Launches Comprehensive Investor Relationship Management Solutions
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 15, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, is featured in AP News, where he discusses Decimal Point Analytics’ expanded Investor Relationship Management offerings. He highlights how our innovative solutions, leveraging AI, automation, and a human-in-the-loop approach, are designed to elevate client engagement, streamline investor communications, and help financial firms build long-term investor trust.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/indias-modern-day-arthashastra-a-ministry-of-economic-security'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                India’s Modern Day Arthashastra - A Ministry Of Economic Security
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Oct 16, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, emphasizes that economic security has shifted from simply protecting assets to encompassing the safeguarding of technology, energy independence, and innovation in today’s interconnected world. Drawing on insights from Chanakya’s Artha shastra, he highlights the vital role that economic resilience plays in driving national growth and enhancing global influence.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/reshaping-discovery-ais-transformative-impact-on-scientific-research'} >

                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Reshaping Discovery: AI's Transformative Impact on Scientific Research
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Oct 11, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, shares how artificial intelligence, particularly transformer models, is set to reshape scientific research across disciplines over the next five years. Discover the potential for accelerated discoveries, optimized resource allocation, and enhanced collaboration, alongside the challenges that research institutions must address to thrive in this AI-driven era.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                The Holographic Future of Regulation: Why We Need a Radical New Approach Unravelling the Regulatory Knot
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 27, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, is featured in CXO Today with his article, "The Holographic Future of Regulation." He explores how rapid tech growth challenges current regulations and proposes Holographic Regulation for real-time adaptability.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/global-capability-centers-strategic-catalysts-of-digital-transformation'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Global Capability Centers: Strategic Catalysts of Digital Transformation
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Aug 24, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, emphasizes the transformation of Global Capability Centers (GCCs) into strategic partners for digital transformation. He highlights their role in driving innovation, enhancing customer experiences, and adapting to emerging technologies while focusing on talent development and local collaborations to ensure long-term success
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Global and instant magnification of stupidity as we watch the dream of the Internet failing
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 27, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, reflects on the recent global disruption caused by a design flaw in the Internet’s architecture. On July 19, 2024, a tiny error led to a worldwide halt of essential services, highlighting the shift from the Internet’s original decentralized design to a centralized model. Shailesh points out that this concentration of power, driven by technology corporations and governments, has created vulnerabilities. He questions whether we will ever return to the Internet's foundational principles of resilience and decentralization.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/budget-expectation-2024'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Budget Expectation 2024
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 22, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, expects the upcoming Budget to drive economic growth by cutting red tape, expanding trade, and reforming state enterprises. It will simplify taxes, boost infrastructure, and enhance education and healthcare, while also supporting vulnerable populations and fostering innovation.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/technical-debt-crisis-a-ticking-time-bomb-in-the-digital-age'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Technical debt crisis: A ticking time bomb in the digital age
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 20, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Technical debt is a strategic trade-off wherein teams prioritise quick delivery over perfect code to meet pressing deadlines or respond to market demands. Technical debt poses a severe threat to software innovation and agility. Rapid releases, legacy code, and lack of documentation gradually corrode codebases, making them increasingly difficult to maintain and scale.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/beyond-the-eiffel-tower-building-frances-tech-undersea-wealth'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Beyond the Eiffel Tower: Building France’s Tech Undersea Wealth
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 03, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                CEO Shailesh Dhuri highlights Elon Musk's Tesla vision, emphasizing France's strengths in mathematics and its extensive Exclusive Economic Zone (EEZ). He sees France uniquely positioned to lead in AI, robotics, and big data, particularly in underwater exploration and resource extraction.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-empowers-underprivileged-students-through-dnyanodapi-dhanodapi-program'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics Empowers Underprivileged Students Through Dnyanodapi Dhanodapi Program
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jun 25, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, emphasizes the impact of the "Dnyanodapi Dhanodapi" program, which offers underprivileged students in financial analytics paid internships, mentorship, and career guidance. Named for its focus on knowledge and wealth, the initiative promotes diversity with a notable 2:1 female-to-male applicant ratio in 2024, highlighting its role in nurturing talent and cultivating innovation within the industry.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/pixels-and-bullets-the-case-for-digital-defence-strategies'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Pixels and Bullets: The Case for Digital Defence Strategies
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jun 05, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, explores modern warfare's evolving landscape, emphasizing the role of AI and data analytics in defense. He highlights India's strides towards self-reliance and underscores adaptability and resilience as crucial in navigating today's complex threats.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/dilip-sah-joins-decimal-point-analytics-as-chief-technology-officer'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Dilip Sah Joins Decimal Point Analytics as Chief Technology Officer
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jun 03, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Dilip Sah, appointed as Chief Technology Officer (CTO) at Decimal Point Analytics, brings over 20 years of experience in data, AI, and digital product development to his role. His expertise will drive our company's technological innovation and elevate our financial analytics solutions, marking a significant milestone in our commitment to delivering exceptional value to clients
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/industry-experts-highlights-impact-of-saas-on-business-landscapes'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Industry experts highlights impact of SaaS on business landscapes
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            May 16, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, highlights the efficiency gains from cloud-based delivery models, while highlighting concerns about vendor lock-in and data sovereignty amidst significant investments by tech giants like Amazon, Google, and Microsoft. He points out a growing trend among SaaS providers to integrate managed services, aimed at enhancing delivery amid increasing demand for AI technologies.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/does-adhering-to-green-practices-add-costs-for-businesses'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Does adhering to green practices add costs for businesses?
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 18, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri emphasizes the widespread adoption of sustainability practices in global business, highlighting the integration of environmentally friendly operations. He recognizes the enduring importance of sustainability despite the initial substantial capital investments required for implementing green technologies, such as eco-friendly infrastructure.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/global-vaults-for-a-connected-world-the-promise-of-data-embassies'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Global Vaults for a Connected World: The Promise of Data Embassies
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 11, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, highlights India's embrace of data embassies' strategic potential, as indicated by Finance Minister Nirmala Sitharaman's 2023 budget speech. India's focus on robust cybersecurity, data privacy measures, and secure data backups underscores its commitment to leveraging the benefits of data embassies.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/digital-assets-can-look-forward-to-a-brighter-future-in-2024'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Digital Assets Can Look Forward to A Brighter Future in 2024
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 26, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                After a turbulent 2022, digital assets had a much better year in 2023, and have ended the year with a bunch of positives: Institutional interest is on the upswing, real-world asset tokens are an emerging category that could see considerable action.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/blockchain-beyond-bitcoin-a-journey-towards-true-financial-inclusion'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Blockchain beyond Bitcoin – A journey towards true financial inclusion
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 25, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Rooted in the ancient Indian philosophy of Vasudhaiva Kutumbakam (“The world is one family”), the growing interconnectedness of today’s world demands a global perspective on development and inclusiveness. Distributed ledger technology (DLT) has emerged as a powerful tool to realize this vision of shared prosperity and progress for all.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/rollercoaster-ride-of-bitcoin-what-lies-ahead'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Rollercoaster Ride Of Bitcoin: What Lies Ahead?
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 19, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri of Decimal Point Analytics Pvt Ltd, discusses Bitcoin's turbulent price history, regulatory impacts, and upcoming events such as the supply reduction mechanism and ETF approvals. He emphasizes increasing investor confidence amid market changes but urges caution due to Bitcoin's inherent volatility and speculative nature linked to its restricted supply dynamics and scarcity
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/global-transformations-the-impact-of-ai-trends-across-industries'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Global Transformations: The Impact of AI Trends Across Industries
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 07, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, has recently shared a quote on the title "Global Transformations: The Impact of AI Trends Across Industries." Shailesh emphasizes AI's influence on defense, agriculture, healthcare, and smart cities, accentuating the importance of addressing ethical concerns for its full potential. He highlights the need for affordable, accessible AI adoption and skill development to promote global equity.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/shaping-a-new-growth-mindset-through-innovation-ideas-at-the-growth-summit'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Shaping a New Growth Mindset through Innovation & Ideas at The Growth Summit
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 09, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO, Shailesh Dhuri, was invited to share his insights on driving economic growth at the recent Growth Summit event hosted by Yes Bank and CNBC TV18. His focus on adaptability as a key driver aligns with his commitment to navigating the evolving economic landscape. He shared inspiring insights on how adaptability is key to driving economic growth and gaining a competitive edge in today's rapidly evolving economic landscape. The summit brought together policymakers, industry stalwarts, startup visionaries, and academic luminaries to deliberate on critical aspects pivotal to India's economic advancement.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Unveiling Net Zero: Decoding Decimal Point Analytics' Purposeful Journey Towards Sustainability
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 25, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In a world grappling with environmental challenges, the pursuit of sustainable solutions extends beyond traditional sectors. Today, our focus shifts to Decimal Point Analytics, a leading data analytics firm that is redefining corporate responsibility. Venturing beyond their core expertise, the company is making a noteworthy move by transitioning all their offices in India to green energy and targeting depolymerization to eliminate plastic usage by 2026, while also aligning their operations with the goal of Net Zero Emission. This move is more than just an environmental statement; it signals a strategic shift toward a future powered by renewable energy and guided by responsible business practices.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Cybersecurity in Fintech: Ensuring Data Protection and Privacy in a World of Connectivity
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 23, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics, is leading advancements in cybersecurity, centering on AI's role within zero-trust architectures to enhance security vigilance. He emphasizes the need for quantum-resistant encryption, addressing threats from quantum computing to current cryptographic methods. Shailesh champions a synergy of AI and quantum-resistant approaches to improve cyber threat detection and ensure sustainable cybersecurity in the face of quantum progress. He highlights the integration of quantum-safe AI models as crucial in bolstering defenses against new cyber threats, reflecting the need for forward-thinking strategies in today's complex digital security landscape.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/llms-beyond-chatbots-new-frontiers'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                LLMs Beyond Chatbots - New Frontiers
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 22, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In Shailesh Dhuri's insightful analysis, the potential of Large Language Models (LLMs) transcends the confines of traditional perceptions. From healthcare advancements to strategic military analysis, LLMs offer a spectrum of possibilities reshaping industries worldwide. Shailesh underscores the imperative of collaboration and ethical considerations in harnessing this potential responsibly.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/unlocking-success-key-trends-for-asset-management-in-2024'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Unlocking Success: Key Trends For Asset Management In 2024
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 21, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In Gaurav Gupta's analysis of the 2024 asset management landscape, key trends emerge amidst economic uncertainty and technological advancements. Gaurav highlights the resurgence of value investing, the prominence of ESG principles, and the transformative role of artificial intelligence. Regulatory scrutiny in the crypto space and institutional adoption of digital assets also feature prominently. Amidst these changes, He emphasizes the enduring importance of human expertise alongside technological innovation, underscoring the pivotal role of talent and strategic decision-making in navigating the evolving terrain of asset management.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-plans-overseas-expansion'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics plans overseas expansion
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 16, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA), a full-service management consulting firm specialising in Financial Research, Data Analytics & AI, is planning to expand its base internationally in 2024 to serve its clients better, a top executive said. “We are committed to serving our clients wherever they operate. Beyond our existing offices in India and strategic overseas locations, we will explore further global expansion to bring DPA’s expertise closer to international clients,” Shailesh Dhuri, Chief Executive Officer, Decimal Point Analytics said.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/central-bank-digital-currencies-the-new-era-of-money'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Central Bank Digital Currencies (CBDCs): The New Era of Money
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 10, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics was recently featured in the digital print of PCQuest for outlining his vision for a future powered by Central Bank Digital Currencies (CBDCs). In an interview with PCQuest, he highlighted the potential of CBDCs to revolutionize payments, foster transnational collaboration, and even combat illegal activities through the use of smart contracts.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/gautam-naidu-joins-decimal-point-analytics-as-chief-revenue-officer-in-mumbai'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Gautam Naidu joins Decimal Point Analytics as Chief Revenue Officer in Mumbai
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 10, 2024
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics, a leading provider of data analytics, AI & market research solutions, is thrilled to announce the appointment of Gautam Naidu as its new Chief Revenue Officer (CRO). In his role, Mr. Naidu will spearhead revenue generation strategies, drive business development initiatives, and play a pivotal role in furthering the company's growth trajectory.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/ai-versus-ai-how-ai-can-fight-ai-to-solve-cyberthreat'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                AI versus AI: How AI can fight AI to solve cyberthreat
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 18, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In tackling cybersecurity challenges, AI becomes a key player in zero-trust security architecture, continuously monitoring every interaction. With the emerging threat of quantum computing to current cryptographic systems, the implementation of quantum-resistant encryption is becoming increasingly crucial. Shailesh Dhuri, CEO of Decimal Point Analytics, highlights the synergistic approach of combining AI with quantum-resistant methodologies. This approach not only improves the identification and neutralization of cyber threats but also secures the longevity of cybersecurity strategies against quantum technological developments. Shailesh emphasizes the critical need for incorporating quantum-safe AI models, aiming to robustly defend against cyber threats and strengthen cybersecurity in our rapidly evolving tech world.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/is-it-time-to-write-esg-requiem-not-quite'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Is it time to write ESG’s Requiem? Not quite
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 15, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                The backlash against ESG continues. Several opinion makers have called for an end to ESG in recent week and months.  However, the underlying principles have merit and a place that will live, even if the form evolves. The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles, it is now giving a sensationalist spin. ‘Billions flow out of ESG as investors turn their backs on green’ investing, wrote an article in a major paper.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 02, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                One recent Reuters article hinted at a secret OpenAI project named Q* behind the last weekend’s drama in OpenAI boardroom.
                                                While Q* is deemed a secret as of today, with hints of AGI; I could find one reference to Q* in the labyrinth of openAI documents about DGGP. Again, it is just a mere word association and nothing more. However, my mind raced, and below are my all too human hallucinations about the very near future application of GenAI with DGGP.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics Wins Canadian Reg Tech Award for Best ESG Solution 2023
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 28, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Toronto - Decimal Point Analytics, a leader in innovative financial technology solutions, has been named the winner in the prestigious Canadian RegTech Awards for its exceptional contributions to Environmental, Social, and Governance (ESG) solutions. This recognition underscores the company's unwavering commitment to fostering sustainable and responsible business practices.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/central-bank-digital-currency-enabled-fx-transactions'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Central Bank Digital Currency (CBDC) Enabled FX Transactions: An Opportunity to Facilitate Currency Movement
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 27, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Foreign Exchange (FX) is the largest financial market in the world, an over-the-counter marketplace which determines the exchange rate of currencies. According to a recent report by the BIS, approximately, USD 7.5 trillion of FX trading occurs in a day globally. FX is traded through a network of international dealers and brokers, of which central banks are the biggest entities.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/india-has-an-enviable-place-in-global-esg-disclosure-list'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                India has an enviable place in global ESG disclosure list
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 22, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Environmental, social and governance (ESG) issues are so important today that a company’s management has to publicly declare its performance on these fronts. As the world is fast transitioning towards sustainability, many organisations are becoming transparent on managing ESG risks. Notably, investors take into account ESG factors when evaluating a company’s sustainability standing.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/india-emerging-as-a-global-trendsetter-in-esg-disclosures'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                India emerging as a global trendsetter in ESG disclosures
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Oct 25, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                SEBI’s aggressive stance has ensured India has among the most advanced ESG reporting regulatory regimes in the world, says Shailesh Dhuri, CEO, Decimal Point Analytics, and suggests areas for further action.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Oracle Global Leaders Recognizes Decimal Point Analytics as ISV of the Year 2023
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sept 20, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics, a leading provider of advanced analytics, research, and data solutions, is proud to announce that it has received the prestigious Intel Oracle Global Leaders Award for ISV of the Year in the APAC region for the year 2023. This award recognizes Decimal Point Analytics for its exceptional contributions in leveraging Oracle cloud technologies to drive innovation and deliver outstanding solutions to clients across the globe.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/transformative-impact-of-gpt-on-the-analytics-landscape'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Transformative Impact of GPTs on the Analytics Landscape
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Aug 21, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Generative Pre-trained Transformers or GPTs as we commonly know them, are a powerful family of neural network models, leading the way in generative artificial intelligence. GPTs are already having a fundamental impact across many walks of life – the way we work, the way we think, among others. While scientists have been attempting to develop generative tools in the Artificial Intelligence space for decades, the recent success of GPTs can be attributed to three critical factors:
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/implementing-machine-readable-regulation-for-competitive-advantage'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Implementing Machine Readable Regulation for competitve advantage
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 25, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Machine readable regulation (MRR) is the process of converting regulatory text into a structured format that can be easily understood and processed by machines. In layman’s terms – When an overspeeding vehicle is flouting the traffic rules, government-administered CCTV automatically captures an image and sends a speeding ticket to the concerned person. This is done through MRR.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-pointa-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics and PRMIA Collaborate for Successful "Quantifying ESG Risk" Event
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jun 05, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                [New York] - Decimal Point Analytics, a leading provider of data analytics solutions, partnered with the Professional Risk Managers' International Association (PRMIA) to host the highly successful "Quantifying ESG Risk" event. The event took place on June 1st at New York Bar Association in New York, featuring a keynote session by Shailesh Dhuri, CEO of Decimal Point Analytics, and an esteemed panel of industry experts.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-inaugurated-its-green-energy-office-in-nashik'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics inaugurated its 100% green energy office in Nashik
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 13, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA) inaugurates new green energy office space in Nashik. Speaking on the new office launch Mr. Shailesh Dhuri, CEO of Decimal Point Analytics said, "We believe our green energy office space would help us contribute to our commitment towards Net Zero emissions alongside cultivating a positive and professional work environment for our colleagues."
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics receives grant funding to develop Tokenization solutions
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jan 11, 2023
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics announced to receive an esteemed grant of up to USD 7,50,000 from The Hashgraph Association (THA) to develop tokenization solutions for traditional asset markets.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Link to={'/fintechentity'} >
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                DPA SmartCloud recognized as a “Fintech Entity” by International Financial Services Centres Authority (IFSCA).
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 20, 2022
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                DPA SmartCloud IFSC Private Limited, a wholly owned subsidiary of Decimal Point Analytics (DPA), has become India's first ever fintech start-up to be authorized as a 'Fintech Entity' by the International Financial Services Centres Authority (IFSCA), the omnibus regulator of the financial SEZ at Gift City, Gujarat.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.youtube.com/watch?v=49h8lRYKCis&t=1006s' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Mr. Shailesh Dhuri, CEO of Decimal Point Analytics shared about his entrepreneurial journey and more in an exclusive interview with Startup Story Media
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 21, 2022
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Our CEO Shailesh Dhuri shared about his entrepreneurial journey and more in an exclusive interview with Startup Story™. In this insightful conversation, he shared interesting concepts and trends in the fintech industry.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.business-standard.com/content/press-releases-ani/malware-and-ransomware-attacks-increased-by-358-per-cent-and-435-per-cent-respectively-the-global-risks-report-2022-122090100993_1.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                A “perfect storm” awaiting the financial sector due to deepened global divisions!
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 05, 2022
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                The next financial crisis could arise by repricing of financial risks emanating from developed economies.
                                                This was the key point from our CEO Shailesh Dhuri during a webinar hosted by PRMIA (Professional risk managers international association).
                                                The agenda of discussion among 150 risk managers across the globe were “What risks keep CROs awake"?
                                                The webinar was graced with well-known faces in the industry, including CFOs and CROs of various companies.
                                                Other factors for the emerging financial risk included -Impacts of COVID-19, Technological Changes, etc.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>


                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.thehindu.com/business/Industry/decimal-point-analytics-raises-35-crore/article65467387.ece' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics announces a fund raise of Rs 35 crore (USD 4.7m) by a large global institutional investor
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            May 27, 2022
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA), which provides data analytics products and services to global hedge funds, investment banks, venture capital firms, private equity funds among others, announced fund raise by a large global institutional investor.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prfree.org/@decimalp/decimal-point-analytics-announces-the-launchdate-of-its-asset-allocation-platform-powered-by-quantum-computing-in-a-world-first-implementation-6rm5rnyjbkw4' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics announces the launchdate of its Asset Allocation platform powered by Quantum Computing in a world first implementation
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Oct 27, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Mumbai (October 27, 2021) – Financial industry is always on the lookout for the latest technical innovation to get an edge in the market. Harnessing the power of Quantum computing will be the next big breakthrough for the financial services industry.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prfree.org/@decimalp/prosper-with-knowledge-and-wealth-program-by-decimal-point-analytics-yrkd54qqrm5x' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Prosper With Knowledge and Wealth Program by Decimal Point Analytics
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 23, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA) proudly launched the Dnyanodapi Dhanodapi Program on this World Gratitude Day. The Sanskrit name of this program means “Prosper with Knowledge and Wealth”. This program aims to uplift those graduates who come from a financially weak background but are exceptionally bright in academics.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/2384101/decimal-point-analytics-achieves-zero-carbon-emissions-at-their' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics achieves zero carbon emissions at their analytics centers
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 07, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA) today announced that it has taken steps to ensure zero carbon emissions from its analytics centers in Mumbai and Nashik, India. It has already switched to 100% Green Energy usage at its Mumbai office.
                                                When we think about carbon emissions, we visualize plumes of dark smoke emanating from industrial chimneys, but the airconditioned offices running computers also contribute towards carbon emissions in equal proportions. Hence, DPA took a decision to ensure that its analytics centers run only on green power.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='http://www.prfree.org/@decimalp/decimal-point-analytics-urges-all-its-employees-to-fightback-corona-eqmje54q6kr6' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics urges all its employees to fightback Corona
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 26, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics has commenced an initiative to encourage its employees and their families to get Covid-19 vaccination
                                                With the onset of the 2nd wave of corona pandemic, our country is facing a havoc situation. The rampage in the number of corona cases has made it important for people to go back to the basic rules- Mandatory masks, staying at home unless very important and maintaining social distance.

                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.pr.com/press-release/835244' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Hiring Figures Are Through the Roof at Decimal Point Analytics During the Pandemic Season
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 25, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Mumbai, India, April 25, 2021 --(PR.com)-- The coronavirus pandemic is truly an unprecedented challenge for all of humanity. Since the onset of the pandemic, enterprises declaring Layoffs and Furloughs have become commonplace across the globe. With the entire world economy coming to a grinding halt this was not a surprising response.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.pr.com/press-release/833602' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics Pvt. Ltd. Wins "Indian SME of the Year Award"
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 31, 2021
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Mumbai, India, March 31, 2021 --(PR.com)-- The Indo-French Chamber of Commerce and Industry (IFCCI) organised the Third Edition of the prestigious Indo French Business Awards 2021 on 24th March at New Delhi. The event had more than 120 nominations under multiple award categories. Decimal Point Analytics Pvt. Ltd., a leading technology-enabled financial research consulting company received the "Indian SME of the Year" award.



                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.pr.com/press-release/820383' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics CEO, Mr. Shailesh Dhuri Says India Needs a Rate Cut of 250 BPS in the Next Seven Months
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 04, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                MMumbai, India, September 04, 2020 --(PR.com)-- Recently, Indian GDP for June 2020 Quarter printed -23.9% growth. Interestingly, just a few weeks before this print, RBI Monetary Policy Committee announced that it was holding rates steady because Consumer Price Index (CPI) inflation was at 6.93%, outside the target range of 2% to 6%.





                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.marketpressrelease.com/Decimal-Point-Analytics-employees-contribute-prize-money-to-feed-the-less-privileged-1596541649.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics employees contribute prize money to feed the less privileged
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Aug 04, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Two teams of Decimal Point employees used their prize money to buy food supplies to support Mission30M by the Robin Hood Army.Financial industry is always on the lookout for the latest technical innovation to get an edge in the market. Harnessing the power of Quantum computing will be the next big breakthrough for the financial services industry.








                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.1888pressrelease.com/laplace-s-demon-and-limits-to-central-banks-ability-to-stab-pr-673098.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 12, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In last three weeks, the major central banks in the world have gone in an overdrive to stabilize the markets by actively participating in the market and the market is hoping for a V shaped recovery post Covid19 pandemic curve flattening.





                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prlog.org/12816128-iatrogenesis-of-social-distancing-thoughts-on-flaws-in-the-deeper-structure-of-society.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Iatrogenesis of Social Distancing: Thoughts on flaws in the deeper structure of society
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 26, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                MUMBAI, India - March 26, 2020 - PRLog -- We are in the midst of a global pandemic with a financial crisis staring right at us. Social distancing is being followed as a preventive measure to flatten the curve. Shailesh Dhuri, CEO Decimal Point Analytics, discusses the iatrogenesis of social distancing in his recently published article.





                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='http://www.prfree.org/@decimalp/beginning-of-etf-shock-p7kea7jprkwx' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Beginning of ETF Shock
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 23, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Last week Shailesh Dhuri shared his views in an article about the folly of ETF’s and how the COVID-19 led supply disruption, is leading to a liquidity crunch. The central bankers are finding it difficult to service the liquidity requirements of the real economy leaving precious nothing for the financial markets. This has put about two trillion worth of ETF’s at pricing risk.




                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/1975128/funding-stress-in-dollar-market-at-a-record-high-in-the-last-two' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Funding Stress in Dollar Market at a record high in the last two decade
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 19, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Quantitative easing programs have injected liquidity to the tune of $4000 billion in the markets since the start of 2010. However, if you look closely at the banks today there is a liquidity shortfall to the tune of $500 billion.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.marketpressrelease.com/Shailesh-Dhuri-CEO-of-Decimal-Point-Analytics-discusses-about-COVID-19-and-the-Folly-of-ETFs-1584513466.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Shailesh Dhuri, CEO of Decimal Point Analytics discusses about 'COVID-19 and the Folly of ETFs'
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Mar 18, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In the recently published article Shailesh Dhuri, CEO Decimal Point Analytics discusses some of the apparent similarities between the Great Financial Crisis of 2008 and the emerging situation at present.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/1927571/institute-of-actuaries-of-india-has-announced-weather' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Institute of Actuaries of India has announced "Weather Hackathon January 2020" powered by Decimal Point Analytics
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 11, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Institute of Actuaries of India has announced "Weather Hackathon January 2020."
                                                This is the first-ever hackathon to be organized by the Institute of Actuaries of India (IAI) and it is open to IAI students, Associates, and fellow members. The hackathon is centered around the theme of "Climate change and sustainability."

                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='http://www.prfree.org/@decimalp/decimal-point-analytics-announces-the-top-five-contestants-of-technopulse-its-online-machine-learning-challenge-3rmwydr8amn4' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics announces the top five contestants of ‘TechnoPulse’, its online Machine Learning challenge
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Feb 13, 2020
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                The TechnoPulse final winner will get a brand-new laptop. Top 3 successful contestants will get an opportunity to work with its ML team.
                                                Decimal Point Analytics has published the leaderboard of TechnoPulse, the online Machine Learning coding challenge.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>








                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prlog.org/12653914-weekly-digest-india-heading-towards-deflation.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                India Heading Towards Deflation
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jul 24, 2017
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                In this edition, it has reflected on the Indian CPI which has been declared at 1.54% for June'17. This is the first time inflation has breached RBI's band of 2% to 6% which is an indicator that India is headed towards deflation.

                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>





                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://etradewire.com/news/decimal-point-analytics-launches-singularitypulse-an-ai-focused-newsletter' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics launches SingularityPulse – An AI focused newsletter
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 09, 2017
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics today announced the launch of SingularityPulse, a weekly newsletter focused on Artificial Intelligence. This newsletter will cover the latest usage and application of Machine Learning, Big Data and Natural Language processing in diverse industries across the globe.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prdistribution.com/nbherard.com/decimal-point-analytics-has-launched-a-research-management-portal' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics has launched a ‘Research Management Portal’
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Nov 10, 2017
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics today announced the launch of a ‘Research Management Portal’ which will help financial services firms to comply with the up-coming MiFID II regulatory requirements in UK and Europe. Investor protection and Research unbundling are critical compliance requirement under the MiFID II regulations.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/866247/bitcoin-market-an-apology-for-blockchain.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Bitcoin Market, An Apology for Blockchain
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Dec 19, 2017
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                DDecimal Point Analytics has published its weekly digest on 18 December titled “Bitcoin Market, An Apology for Blockchain”. In this article it has posit a question, if the ongoing frenzy for Bitcoin crashes will that eventually cause users to shy away from the underlying technology of Blockchain?


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/1030797/how-can-india-leverage-rising-crude-oil-prices-to-help-the-domestic-economy.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                How can India leverage rising crude oil prices to help the domestic economy?
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Apr 26, 2018
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                The international crude oil prices are near four-year highs at mid $70s per barrel due to geopolitical reasons and artificial supply constraints. With a five percent ethanol blending norm in India for petrol, oil marketing companies are procuring domestic ethanol which is costlier than imported petrol.


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>







                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prlog.org/12706613-rethinking-corporate-governance-framework-in-the-digital-world.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Rethinking Corporate Governance Framework in the Digital World
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            May 4, 2018
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                We implicitly believe that democracy represents the people's will expressed through the legal institutions and infrastructure in the country. However, the author points out that much of the customs, rules, and regulation are also the product of the technology of the times when it was established.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prlog.org/12716242-decimal-point-analytics-launches-the-beta-of-imagepulse-for-predicting-the-financial-status.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics launches the beta of ImagePulse for predicting the financial status
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Jun 28, 2018
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics (DPA) today announced that it has achieved a major breakthrough in Image analysis using Deep Learning networks. DPA has launched the beta of ImagePulse, its multi-layer convolutional neural network which is able to predict the financial status of a household by processing the images of the household.[:]

                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.prlog.org/12726317-decimal-point-analytics-unveils-new-credit-risk-management-framework-at-the-prmia-event-in-mumbai.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point Analytics unveils new credit risk management framework at the PRMIA event in Mumbai
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Aug 28, 2018
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics(DPA) unveiled a new credit risk management framework at the PRMIA (Professional Risk Managers' International Association) event on "Resiliency through Effective Risk Management in India" at BKC, Mumbai.[:]

                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>




                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/1238419/decimal-point-releases-an-infographic-on-chinese-auto-finance-market.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point releases an Infographic on Chinese Auto Finance Market
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            Sep 14, 2018
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics has released an Infographic about the Chinese Auto Finance Market.
                                                China is the largest automobile market in the world in terms of demand and supply with a market size of RMB 1.0 trillion in 2016. The Chinese auto finance industry is flourishing with auto finance penetration expected to reach ~50% by 2020. [:]


                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>



                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <a href='https://www.openpr.com/news/1743666/decimal-point-releases-the-latest-economic-dashboard-for-india.html' target="_blank" rel="noopener noreferrer">
                                    <Box className='reportm_bg'>
                                        <Box mt={2} mb={2}>
                                            <Typography className='reporttitle two'>
                                                Decimal Point releases the latest Economic Dashboard for India
                                            </Typography>
                                        </Box>

                                        <Typography variant='h6' className='skblue'>
                                            May 17, 2019
                                        </Typography>

                                        <Box className='line'></Box>

                                        <Box mt={2}>
                                            <Typography variant='h6' className='black jst thr'>
                                                Decimal Point Analytics has released a dashboard based on Indian economic indicators generated from data published by EcoPulse.
                                                EcoPulse is a platform which delivers global economic indicators covering currencies, commodities and other markets. [:]
                                            </Typography>
                                        </Box>

                                    </Box>
                                </a>
                            </Grid>


                        </Grid>
                    </Box>


                </Container>
            </Box>


        </>
    );
}
