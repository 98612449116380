
import Nov22 from "../../asset/images/media/event/22nov_event.webp";
import Nov18 from "../../asset/images/media/event/18nov_event2.webp";
import Oct10 from "../../asset/images/media/event/10oct_event.webp";
import Oct5 from "../../asset/images/media/event/5oct_event.webp";
import Sep1 from "../../asset/images/media/event/1sep_event.webp";
import Mar24 from "../../asset/images/media/event/24mar_event.webp";
import Nov21 from "../../asset/images/media/event/21nov_event.webp";
import Feb22 from "../../asset/images/media/event/22feb_event.webp";
import Nov3 from "../../asset/images/media/event/3nov_event.webp";
import Aug21 from "../../asset/images/media/event/21aug_event.webp";
import Jul28 from "../../asset/images/media/event/28jul_event.webp";
import Nov30 from "../../asset/images/media/event/30nov_event.webp";
import RoundTable from "../../asset/images/media/event/round_table.webp";
import PRMIA from "../../asset/images/media/event/prmia_event.webp";
import EvolutionOfESG from '../../asset/images/insight/esg/round_table/evolution_of_esg.webp';
import YinandYang from "../../asset/images/media/event/yin_&_yang.webp";
import InnovationSummit from "../../asset/images/media/event/innovation_summit.webp";
import GenAI from "../../asset/images/media/event/genai_for_enterprises.webp";
import tokenization from "../../asset/images/media/event/power_of_tokenization.webp";
import FinancewithAI from "../../asset/images/media/event/finance_with_ai.webp";
import ExploringAiImpact from "../../asset/images/media/event/exploring_ai_impact.webp";
import ALTSNY from "../../asset/images/media/event/altsny.webp";
import ThakurSchool from "../../asset/images/media/event/thakur_global.webp";
import Oracle from "../../asset/images/media/event/oracle_world.webp";
import Fintech from "../../asset/images/media/event/global_fintech.webp";
import Feinstein from "../../asset/images/media/event/feinstein_concert.webp";
import Picup from "../../asset/images/media/event/picup.webp";
import America_East from "../../asset/images/media/event/america_east.webp";
import Finovate from "../../asset/images/media/event/finovate.webp";
import Aarambh from "../../asset/images/media/event/aarambh.webp";
import Harvesting from "../../asset/images/media/event/harvesting_opportunities.webp";
import IFSC from "../../asset/images/media/event/gift_ifsc.webp";
import IFSCA_SAN from "../../asset/images/media/event/ifsca_san_francisco.webp";
import GenAIConclave from "../../asset/images/media/event/gen_ai.webp";
import Talent_Vista from "../../asset/images/media/event/talent_vista2024.webp";
import Boardroom from "../../asset/images/media/event/boardroom_session.webp";
import SmartStrategies from "../../asset/images/media/event/smart_strategies.webp";
import UnleashInnovation from "../../asset/images/media/event/unleash_innovation.webp";
import AnnualConference from "../../asset/images/media/event/annual_ds_conference.webp";
import EconomyWorks from "../../asset/images/media/event/economy_that_works.webp";
import PrivateCredit from "../../asset/images/media/event/private_credit.webp";
import AnnualSymposium from "../../asset/images/media/event/annual_symposium.webp";
import DPAatIFSCA from "../../asset/images/media/event/IFSCA_CPSE_summit.webp";
import LaunchoftheGCC from "../../asset/images/media/event/launch_of_the_gcc.webp";

import Piwot2025 from "../../asset/images/media/event/piwot2025.webp";
import DpaOracalEvent from "../../asset/images/media/event/dpaoracalevent.webp";


const Newsletter_Data = [

  {
    year: 2025,
    link: "/gcc-policy-2025-launch",
    imgsrc: LaunchoftheGCC,
    heading: "Launch of the Global Capability Center (GCC) Policy 2025-30",
    date: "Feb, 2025",
  },
  {
    year: 2025,
    link: "/ifsca-summit-gift-ifsc-finance",
    imgsrc: DPAatIFSCA,
    heading: "Decimal Point Analytics at IFSCA Public Sector Enterprises Summit 2.0",
    date: "Feb, 2025",
  },
  {
    year: 2025,
    link: "/oracle-cloudworld-tour-insights",
    imgsrc: DpaOracalEvent,
    heading: "Decimal Point Analytics at Oracle CloudWorld Tour India",
    date: "Feb, 2025",
  },
  {
    year: 2025,
    link: "/11th-annual-sustainable-investing-symposium",
    imgsrc: AnnualSymposium,
    heading: "11th Annual Sustainable Investing Symposium",
    date: "Jan, 2025",
  },
  {
    year: 2025,
    link: "/private-credit-and-the-credit-markets-transformation",
    imgsrc: PrivateCredit,
    heading: "Private Credit and the Credit Markets Transformation",
    date: "Jan, 2025",
  },
  {
    year: 2025,
    link: "/decimal-point-analytics-at-paniit-world-of-technology-2025",
    imgsrc: Piwot2025,
    heading: "Decimal Point Analytics at PANIIT - World of Technology 2025",
    date: "Jan, 2025",
  },
  {
    year: 2025,
    link: "/an-economy-that-works-for-all-measurement-matters",
    imgsrc: EconomyWorks,
    heading: "An Economy That Works for All: Measurement Matters",
    date: "Jan, 2025",
  },
  {
    year: 2025,
    link: "/8th-annual-data-science-in-finance-conference",
    imgsrc: AnnualConference,
    heading: "8th Annual Data Science in Finance Conference",
    date: "Jan, 2025",
  },
  {
    year: 2024,
    link: "/roundtable-discussion-on-unleash-innovation-build-the-next-big-thing",
    imgsrc: UnleashInnovation,
    heading: "Roundtable Discussion on Unleash Innovation: Build the Next Big Thing",
    date: "Dec, 2024",
  },
  {
    year: 2024,
    link: "/where-innovation-meets-strategy-an-exclusive-boardroom-session",
    imgsrc: Boardroom,
    heading: "Where Innovation Meets Strategy: An Exclusive Boardroom Session",
    date: "Nov, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-ifsca-official-talent-vista-2024",
    imgsrc: Talent_Vista,
    heading: "Decimal Point Analytics at IFSCA Official Talent Vista 2024",
    date: "Nov, 2024",
  },
  {
    year: 2024,
    link: "/roundtable-discussion-on-smart-strategies-for-multi-cloud-environments-and-data-integration",
    imgsrc: SmartStrategies,
    heading: "Roundtable Discussion on Smart Strategies for Multi-Cloud Environments and Data Integration",
    date: "Nov, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-ifsca-san-francisco-conference-2024",
    imgsrc: IFSCA_SAN,
    heading: "Decimal Point Analytics at IFSCA San Francisco Conference 2024",
    date: "Oct, 2024",
  },

  {
    year: 2024,
    link: "/harvesting-opportunities-funds-in-gift-city-scaling-new-heights",
    imgsrc: Harvesting,
    heading: "Harvesting Opportunities: Funds in GIFT City Scaling New Heights",
    date: "Oct, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-the-gift-ifsc-conference-2024-in-new-york",
    imgsrc: IFSC,
    heading: "Decimal Point Analytics at the GIFT IFSC Conference 2024 in New York",
    date: "Oct, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-gen-ai-conclave-awards-2024",
    imgsrc: GenAIConclave,
    heading: "Decimal Point Analytics at Gen.ai Conclave & Awards 2024",
    date: "Oct, 2024",
  },
  {
    year: 2024,
    link: "/oracle-cloud-world-ai-powered-data-management-with-dilip-sah",
    imgsrc: Oracle,
    heading: "Oracle Cloud World: AI-Powered Data Management with Dilip Sah",
    date: "Sep, 2024",
  },
  {
    year: 2024,
    link: "/finovate-fall-2024-connecting-with-industry-leaders",
    imgsrc: Finovate,
    heading: "Finovate Fall 2024: Connecting with Industry Leaders",
    date: "Sep, 2024",
  },
  {
    year: 2024,
    link: "/executive-roundtable-on-innovating-finance-with-ai",
    imgsrc: FinancewithAI,
    heading: "Executive Roundtable on Innovating Finance with AI",
    date: "Sep, 2024",
  },
  {
    year: 2024,
    link: "/global-fintech-fest-2024-insights-from-the-panel-discussion-on-gift-city",
    imgsrc: Fintech,
    heading: "Global Fintech Fest 2024: Insights from the Panel Discussion on GIFT City",
    date: "Aug, 2024",
  },
  {
    year: 2024,
    link: "/arun-singh-at-avantika-universitys-student-orientation-ceremony-aarambh",
    imgsrc: Aarambh,
    heading: "Arun Singh at Avantika University’s Student Orientation Ceremony 'Aarambh'",
    date: "Aug, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-america-east-meet-nyc-2024-advancing-data-driven-insights",
    imgsrc: America_East,
    heading: "Decimal Point Analytics at America East Meet NYC 2024: Advancing Data-Driven Insights",
    date: "Aug, 2024",
  },
  {
    year: 2024,
    link: "/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference",
    imgsrc: Picup,
    heading: "Paresh Sharma Represents Decimal Point Analytics at the 5th PICUP Fintech Conference",
    date: "Aug, 2024",
  },
  {
    year: 2024,
    link: "/shailesh-dhuris-talk-at-thakur-global-business-school-navigating-emerging-markets",
    imgsrc: ThakurSchool,
    heading: "Shailesh Dhuri's Talk at Thakur Global Business School: Navigating Emerging Markets",
    date: "Aug, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-proudly-supported-the-annual-feinstein-summer-concert-featuring-gwen-stefani",
    imgsrc: Feinstein,
    heading: "Decimal Point Analytics Proudly Supported the 19th Annual Feinstein Summer Concert Featuring Gwen Stefani",
    date: "Jul, 2024",
  },
  {
    year: 2024,
    link: "/decimal-point-analytics-at-altsny-june-2024",
    imgsrc: ALTSNY,
    heading: "Decimal Point Analytics at ALTSNY June 2024",
    date: "Jun, 2024",
  },
  {
    year: 2024,
    link: "/exploring-ais-impact-on-the-insurance-sector-insights-from-the-mena-insurTech-association-summit-2024",
    imgsrc: ExploringAiImpact,
    heading: "Exploring AI's Impact on the Insurance Sector: Insights from the MENA InsurTech Association Summit 2024",
    date: "May, 2024",
  },
  {
    year: 2024,
    link: "/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference",
    imgsrc: tokenization,
    heading: "Unveiling the Transformative Power of Tokenization: Insights from the 6th CFA India Fintech Conference",
    date: "Mar, 2024",
  },
  {
    year: 2023,
    link: "/executive-roundtable-exploring-generative-ai-for-enterprises",
    imgsrc: GenAI,
    heading: "Executive Roundtable: Exploring Generative AI for Enterprises",
    date: "Dec, 2023",
  },
  {
    year: 2023,
    link: "/innovation-summit-2023-at-sda-bocconi-asia-center",
    imgsrc: InnovationSummit,
    heading: "Innovation Summit 2023 at SDA Bocconi Asia Center",
    date: "Oct, 2023",
  },
  {
    year: 2023,
    link: "/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes",
    imgsrc: YinandYang,
    heading: "Yin & Yang of Web3 & AI to Transform Insurance Processes",
    date: "Oct, 2023",
  },
  {
    year: 2023,
    link: "/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers",
    imgsrc: EvolutionOfESG,
    heading: "Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers",
    date: "Jul, 2023",
  },
  {
    year: 2023,
    link: "/quantifying-esg-risk",
    imgsrc: PRMIA,
    heading: "Quantifying ESG Risk",
    date: "Jun, 2023",
  },
  {
    year: 2023,
    link: "/executive-roundtable-on-portfolio-alignment-tools",
    imgsrc: RoundTable,
    heading: "Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook",
    date: "Mar, 2023",
  },
  {
    year: 2022,
    link: "/convergence-of-ai-and-iot",
    imgsrc: Nov30,
    heading: "Convergence of AI & IoT for a safer and equal society",
    date: "Nov, 2022",
  },
  {
    year: 2022,
    link: "/application-of-machine-learning-in-catastrophe-modeling",
    imgsrc: Nov22,
    heading: "Application of Machine Learning in Catastrophe Modeling",
    date: "Nov, 2022",
  },
  {
    year: 2022,
    link: "/data-analytics-making-it-work",
    imgsrc: Nov18,
    heading: "Data Analytics: Making it work in the real world",
    date: "Nov, 2022",
  },
  {
    year: 2022,
    link: "/round-table-by-dpa",
    imgsrc: Oct10,
    heading: "Round Table by DPA - Is Net zero still a myth?",
    date: "Oct, 2022",
  },
  {
    year: 2022,
    link: "/techniche-2022",
    imgsrc: Oct5,
    heading: "Decimal point analytics a sponsorer in “Techniche 2022”",
    date: "Oct, 2022",
  },
  {
    year: 2022,
    link: "/are-developed-markets-riskier",
    imgsrc: Sep1,
    heading: "Are Developed Markets Riskier Than Developing Markets?",
    date: "Sep, 2022",
  },
  {
    year: 2021,
    link: "/indo-french-chamber-of-commerce",
    imgsrc: Mar24,
    heading: "Indo-French Chamber of Commerce and Industry (IFCCI)",
    date: "Mar, 2021",
  },
  {
    year: 2021,
    link: "/chetana-institutes-of-management",
    imgsrc: Nov21,
    heading: "Chetana’s Institutes of Management",
    date: "Nov, 2019",
  },
  {
    year: 2021,
    link: "/mipm-ies-mcrc-convention",
    imgsrc: Feb22,
    heading: "NIPM - IES MCRC Convention",
    date: "Feb, 2019",
  },

  {
    year: 2021,
    link: "/weschool-mumbai",
    imgsrc: Nov3,
    heading: "WeSchool Mumbai",
    date: "Nov, 2018",
  },
  {
    year: 2021,
    link: "/professional-risk-managers-international-association",
    imgsrc: Aug21,
    heading: "Professional Risk Managers' International Association",
    date: "Aug, 2018",
  },
  {
    year: 2021,
    link: "/nl-dalmia-college",
    imgsrc: Jul28,
    heading: "NL Dalmia College",
    date: "Jul, 2018",
  },
];

export default Newsletter_Data;