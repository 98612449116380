import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';

import interest_rates1 from "../../../asset/images/insight/insight_pages/Interest_rates_burden/1interest_r.webp";



export default function Interest_rates_burden() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Real Interest Rates and the Strain on Indian Businesses" </h1>
                <title>Real Interest Rates Burden On Indian Businesses</title>
                <meta name="description" content="Real Interest Rates Impacting Indian Businesses. Discover Insights, Strategies, and Pathways to Overcome Challenges and Drive Growth." />
                <meta name="keywords" content="interest rates
fed rate hike
fed interest rate hike
interest rate rise
interest rate in india" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/real-interest-rates-burder-on-indian-businesses" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Real Interest Rates Burden On Indian Businesses
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>In recent months there has been a spate of news of stress in Indian businesses, both financial and non-financial businesses. Commentators are assigning various causes for the same.</Typography>

                        <Typography variant='h6' className='black jst'>However, in our view, the main cause of this stress is the unbearable burden of astronomically high real interest rates charged by the Indian banking industry, especially private sector banks, to Indian businesses.</Typography>

                        <Typography variant='h6' className='black jst'>The chart below shows real interest rate burden on Indian businesses. The real interest rate for each month is computed as average bank lending rates for the previous twelve months minus average CPI inflation for the same previous twelve months. The numbers are percentages per annum.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Chart 1: Real Interest Rates Burden on Indian businesses</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={interest_rates1} className='topbanner2' alt='interest rate banner' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h6' className='black jst'>The real rates have moved from about 2% pa in 2013 to about 7% pa in 2019. These usurious rates will kill off Indian businesses and in the process destroy any growth potential that the country has unless fast corrective action is taken soon.</Typography>

                        <br />
                        <Divider />



                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Real Interest Rates Burden On Indian Businesses" />
                    </Box>


                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
