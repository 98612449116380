import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_enhancing_inventory.webp"
import Infographics from "../../../../asset/images/insight/case_studies/enhancing_inventory_info.webp"
import { Link } from 'react-router-dom';



export default function EnhancingInventory() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Predictive Analytics for US Asset Firm’s Inventory Management</title>
                <meta name="description" content="See how a US asset advisory firm used predictive analytics to improve inventory management, achieving 8x efficiency and cutting data processing time from 6 days to 2 hours." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/delivering-efficiency-gains-through-predictive-analytics-for-a-us-asset-advisory-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'> 
                                    Delivering 8<span className='lower'>x</span> Efficiency Gains <span className='lower'>through</span> Predictive Analytics <span className='lower'>for a</span> US Asset Advisory Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Managing large volumes of data and maintaining inventory accuracy was a key challenge for a US-based firm providing asset management predictive analytics and valuation services. The complexities of handling over 2 million daily data rows led to inefficiencies and slow decision-making. By leveraging predictive analytics, the firm significantly improved its inventory management capabilities, achieving faster turnaround times and optimized resource allocation through automated data processing solutions.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client faced several critical challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data Overload: </strong> Processing over 2 million data rows daily led to storage and analysis bottlenecks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inconsistent Data Quality: </strong> Variations in data accuracy impacted forecasting and decision-making.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Slow Decision-Making: </strong>  Manual processes slowed down their ability to make informed inventory management decisions.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Predictive Analytics <span className='lower'>and </span> Streamlined Data Processing
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                    <img loading="lazy"  src={Infographics} className="w100" alt="  Solution: Predictive Analytics and Streamlined Data Processing" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The data-driven solution delivered substantial improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 8x Efficiency Improvement:  </strong> Reduced data processing time from 6 days to just 2 hours.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Resource Optimization: </strong> Managed tasks with just 3 resources instead of 19, achieving significant cost savings.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Inventory Management: </strong>  Enabled better demand forecasting and improved decision-making
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Precision in Data Handling: </strong> Automated processes ensured faster and more accurate data management.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Proactive Insights:   </strong> Predictive models provided valuable foresight into inventory needs.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Scalable Solutions: </strong> The streamlined approach allowed the client to adapt quickly to changing market demands.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By implementing asset management predictive analytics and automated data processing solutions, the firm significantly enhanced its inventory management capabilities. This collaboration not only improved efficiency but also positioned the client to stay competitive in a dynamic market with the ability to make faster, more informed decisions.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Reach Out Today!</Link>  Find out how we can help you to attain similar success in your endeavors.
                            </Typography>  
                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}