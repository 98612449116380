import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

import awards1 from "../../asset/images/aboutus/hackathon.webp";
import awards2 from "../../asset/images/aboutus/about_us_award1.webp";
import awards3 from "../../asset/images/aboutus/about_us_award2.webp";
import awards4 from "../../asset/images/aboutus/award4.webp";
import EII from "../../asset/images/aboutus/excellance_in_innovation.webp";
import awards5 from "../../asset/images/aboutus/db_award.webp"
import ISV from "../../asset/images/aboutus/isv-oracle-award.webp";
import RegTech from "../../asset/images/aboutus/canadian-regtech.webp";
  
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



export default function LandingAwards() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



  const responsiveOptions = {
    0: {
      items: 2, // Number of items to show at screen width <= 0px
    },
    600: {
      items: 3, // Number of items to show at screen width <= 600px
    },
    1000: {
      items: 5, // Number of items to show at screen width <= 1000px
    },
  };


  return (
    <>

      <Box className="section ourknowledge owldot2 sec_bg section_mob" component="section" >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" pb={3}>
                <Typography variant="h2" className="black">
                  Recognitions & Latest News
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={0} alignItems="top">

            <Grid item lg={12} md={12} sm={12} xs={12}> 
              <Box mt={2}>
                <OwlCarousel className='owl-theme reportinfo' loop margin={10} nav dots={false} autoplay responsive={responsiveOptions}>


                  <Link to={`/awards#canadian-regtech-award`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + RegTech + ")" }}
                    ></Box>
                  </Link>

                  <Link to={`/awards#isv-of-the-year-2023`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + ISV + ")" }}
                    ></Box>
                  </Link>

                  <Link to={`/awards#excellence-in-innovation`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + EII + ")" }}
                    ></Box>
                  </Link>

                  <Link to={`/awards#featured-in-db`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + awards5 + ")" }}
                    ></Box>
                  </Link>

                  <Link to={`/awards#ceo-insights-magazine`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + awards4 + ")" }}
                    ></Box>
                  </Link>


                  <Link to={`/awards#mark-tech-hackthon`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + awards1 + ")" }}
                    ></Box>
                  </Link>


                  <Link to={`/awards#graham-bell-awards`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + awards2 + ")" }}
                    ></Box>
                  </Link>

                  <Link to={`/awards#indian-sme-of-the-year`}>
                    <Box
                      className="awardsindex"
                      style={{ backgroundImage: "url(" + awards3 + ")" }}
                    ></Box>
                  </Link>

                </OwlCarousel>
              </Box>
            </Grid>


            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box mt={5} >
                <Grid container spacing={1} alignItems="top">
                  <Grid item lg={1} md={1} sm={1} xs={12} >
                    <Typography className="movetext cl1">
                      <span className="cl1"> Latest News: </span>
                    </Typography>
                  </Grid>

                  <Grid item lg={11} md={11} sm={11} xs={12} >
                    <Marquee pauseOnHover speed={50} gradientWidth={0}>
                      <Box className="move">
                        <Typography className="movetext cl1">

                          <a
                            href="https://www.decimalpointanalytics.com/decimal-point-analytics-inaugurated-its-green-energy-office-in-nashik"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              Decimal Point Analytics inaugurates 100% green energy office in Nashik |
                            </span>
                          </a>


                          <a
                            href="https://www.decimalpointanalytics.com/decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              Decimal Point Analytics receives grant funding |
                            </span>
                          </a>

                          <a
                            href="https://www.decimalpointanalytics.com/fintechentity"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              DPA SmartCloud recognized as a “Fintech Entity” by IFSCA |
                            </span>
                          </a>

                          <a
                            href="https://www.youtube.com/watch?v=49h8lRYKCis&t=1006s"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              Mr. Shailesh Dhuri, CEO of Decimal Point Analytics shared about his entrepreneurial journey and more |
                            </span>
                          </a>
                          {/* --------------------------------------3rd item----------------------- */}
                          <a
                            href="https://www.business-standard.com/content/press-releases-ani/malware-and-ransomware-attacks-increased-by-358-per-cent-and-435-per-cent-respectively-the-global-risks-report-2022-122090100993_1.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              PRMIA Event: A “perfect storm” awaiting the financial sector due to deepened global divisions! |
                            </span>
                          </a>
                          {/* -------------------------------------------------------------------------------- */}

                          {/* --------------------------------------4th item----------------------- */}
                          <a
                            href="https://www.thehindu.com/business/Industry/decimal-point-analytics-raises-35-crore/article65467387.ece"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              Decimal Point Analytics raises ₹35 crore |
                            </span>
                          </a>
                          {/* -------------------------------------------------------------------------------- */}

                          {/* --------------------------------------5th item----------------------- */}
                          <a
                            href="https://www.prfree.org/@decimalp/decimal-point-analytics-announces-the-launchdate-of-its-asset-allocation-platform-powered-by-quantum-computing-in-a-world-first-implementation-6rm5rnyjbkw4"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="cl2">
                              {" "}
                              Decimal Point Analytics announces the launch date of its Asset Allocation platform powered by Quantum Computing |
                            </span>
                          </a>
                          {/* -------------------------------------------------------------------------------- */}
                        </Typography>
                      </Box>
                    </Marquee>
                  </Grid>
                </Grid>



              </Box>
            </Grid>


          </Grid>
        </Container>
      </Box>


    </>
  );
}
