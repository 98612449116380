import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { crmFormApi } from "../api/api";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CRMDownloadCS(props) {
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const [checkboxError, setCheckboxError] = React.useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s'-]+$/;
  const organizationReg = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;

  const history = useHistory();

  const initialValues = {
    name: "",
    email: "",
    organization: "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .matches(nameValidation, "Enter valid name"),
    email: yup
      .string()
      .required("Email is required")
      .matches(emailreg, "Email is not valid"),
    
  });

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === "name") {
        setFirstNameError("Name should not contain numbers");
      }
    } else {
      if (fieldName === "name") {
        setFirstNameError("");
      }
    }
  };

  const onSubmit = async (values) => {
    const formData = {
      name: values.name,
      email: values.email,
      organization: values.organization,
      type: "Download Case Study",
    };
    setCheckboxError("");
    await handleFormSubmit(formData);
  };

  const handleFormSubmit = async (formData) => {
    console.log(formData);
    await post(formData);
  };

  const onSuccessCall = () => {
    setCheckboxFlag(false);
    handleClose();
    window.open(
      "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/CRM%20Success%20Storyy%201.pdf",
      "_blank"
    );
  };

  const post = async (formData) => {
    setLoader(true);
    const message = "Oops, something went wrong, Please try again later.";
    try {
      const { data, status } = await crmFormApi(formData);
      if (status === 200) {
        onSuccessCall();
      }
    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  const handleClickOpen = () => {
    localStorage.setItem("isOpen", 1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className={props.className}
        endIcon={props.endIco}
        onClick={handleClickOpen}
      >
        {props.btn_name}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="b_modal teammodal"
      >
        <DialogTitle
          className="feedname"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Box className="al_center">
            <Typography variant="h4" className="blue">
              {" "}
              Download Case Study{" "}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldError,
                isSubmitting,
                touched,
                errors,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic1"
                          label="Name *"
                          variant="standard"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          name="name"
                          onKeyUp={(e) => handleKeyUp(e, "name")}
                          error={touched.name && Boolean(errors.name)}
                          helperText={
                            touched.name && errors.name
                              ? errors.name
                              : firstNameError
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic3"
                          label="Email Address *"
                          variant="standard"
                          name="email"
                          fullWidth
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic4"
                          label="Organization"
                          variant="standard"
                          name="organization"
                          fullWidth
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="al_center" mt={1}>
                        <Link>
                          {loader ? (
                            <LoadingButton
                              loading={true}
                              variant="outlined"
                              disabled
                            >
                              disabled
                            </LoadingButton>
                          ) : (
                            <Button
                              onClick={handleSubmit}
                              variant="contained"
                              className="dpabtn_1 mobbtn"
                              type="submit"
                            >
                              Download
                            </Button>
                          )}
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>

          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
