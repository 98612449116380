import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  // Custom styles for the first column
  featuresColumn: {
    width: "30%", // Adjust the width as needed
  },
});

function createData(data) {
  return data;
}
const planIcon = (isIncluded) => {
  return isIncluded ? (
    <CheckIcon className="check-ico" />
  ) : (
    <HorizontalRuleIcon className="dash-ico" />
  );
};
const rows = [
  createData({
    indicator: "Unlimited Conversations",
    free: true,
    pro: true,
  }),
  createData({
    indicator: "Multiple Uploads",
    free: true,
    pro: true,
  }),
  createData({
    indicator: "Questions",
    free: "50/month",
    pro: "20,000/month",
  }),
  createData({
    indicator: "File Upload",
    free: "5",
    pro: "100",
  }),
  createData({
    indicator: "Library",
    free: "1",
    pro: "10",
  }),
  createData({
    indicator: "Library Files",
    free: "10 Files/library",
    pro: "1000 Files Across All Libraries",
  }),
  createData({
    indicator: "File Size Limit",
    free: "10 MB",
    pro: "50 MB",
  }),
];

export default function PdfQueryTable() {
  const classes = useStyles();

  return (
    <>
      <Box id="mui-table">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            {/* <caption>A basic table example with a caption</caption> */}
            <TableHead>
              <TableRow>
                <TableCell className={classes.featuresColumn}>
                  Features{" "}
                </TableCell>
                <TableCell className="centered_data"> FREE </TableCell>
                <TableCell className="centered_data"> PRO </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.indicator}>
                  <TableCell component="th" scope="row">
                    {" "}
                    {row.indicator}{" "}
                  </TableCell>
                  <TableCell className="centered_data">
                    {typeof row.free !== "boolean"
                      ? row.free
                      : planIcon(row.free)}
                  </TableCell>
                  <TableCell className="centered_data">
                    {typeof row.pro !== "boolean" ? row.pro : planIcon(row.pro)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
