import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function AnnualSymposium() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Exploring ESG and Sustainable Investing at CFA New York’s Symposium</title>
                <meta name="description" content="Nilanjan Das attended CFA New York’s 11th Annual Sustainable Investing Symposium, discussing ESG integration, regulatory developments, and impact-driven investment strategies." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/11th-annual-sustainable-investing-symposium" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    11th Annual Sustainable Investing Symposium
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>
                            Nilanjan Das, Executive VP Business Development at Decimal Point Analytics, attended the 11th Annual Sustainable Investing Conference hosted by <a href='https://cfany.org/' target='_blank' rel="noopener noreferrer">CFA New York</a>.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event convened industry leaders to discuss the latest trends, challenges, and opportunities in sustainable investing, including ESG integration, regulatory developments, and impact-driven investment strategies.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Key discussions focused on:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Balancing financial returns with long-term sustainability goals
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        The evolving role of data in responsible investing.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            At <Link to="/contact-us#contact_form" className='link'>Decimal Point Analytics</Link>,  we remain committed to empowering financial institutions with data-driven insights, enabling them to navigate the complexities of sustainable investing with clarity and confidence.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
