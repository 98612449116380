import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Casestudyback from '../../../../Component/Casestudyback'
import Banner from "../../../../asset/images/insight/case_studies/banner_automating_real_time.webp"
import { Link } from 'react-router-dom';

export default function AutomatedRealTime() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Automate Real-Time Email Alerts in Salesforce for Financial Firms</title>
                <meta name="description" content="Discover how we automated real-time Salesforce alerts for instant contact updates and fund tracking, eliminating manual inefficiencies. Ready to optimize yours?" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-study/automated-real-time-email-alerts-salesforce-financial-firms" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Automating Real-Time Email Alerts For A Financial Services Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h6' className='black jst'>
                                A <strong>leading financial services firm</strong> needed a <strong>real-time email alert system</strong> within <strong>Salesforce CRM</strong> to automate notifications related to <strong>contact updates and fund balance changes.</strong> The firm aimed to eliminate manual tracking, improve response times, and ensure key decision-makers received timely updates.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges Faced
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h6' className='black jst'>
                                <strong>1. Delayed Notifications</strong> – Contact updates in Salesforce were not triggering instant alerts, causing inefficiencies.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>2. Manual Fund Tracking</strong> – Teams had to manually monitor fund balance fluctuations, increasing operational workload.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>3. Lack of Automation</strong> – The absence of an automated workflow resulted in missed updates and slower decision-making.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>4. Ineffective Communication</strong> – No centralized mechanism to notify stakeholders about important financial changes.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                The Solution: Intelligent CRM Automation By Decimal Point Analytics
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h3' className='black jst h3h6' gutterBottom>
                                1. Automated Email Alerts in Salesforce for Contact Updates
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Integrated a <strong>record-triggered flow</strong> in <strong>Salesforce CRM</strong> to generate instant email alerts whenever a <strong>contact was created or modified.</strong>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Implemented <strong>checkbox-based triggers,</strong> ensuring real-time notifications for relevant stakeholders.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h3' className='black jst h3h6' gutterBottom>
                                2. Real-Time Fund Balance Monitoring
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Developed an <strong>automated email notification system</strong> for fund balance updates.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Configured alerts to trigger when the fund balance <strong>exceeded or fell below $100 million,</strong> ensuring proactive financial tracking.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Eliminated manual monitoring efforts by enabling <strong>automatic reporting to key personnel.</strong>
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h3' className='black jst h3h6' gutterBottom>
                                3. Workflow Optimization with Conditional Logic
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Designed a <strong>decision tree workflow</strong> that categorized alerts based on specific conditions.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Ensured different types of email alerts were sent depending on fund balance thresholds, making notifications more relevant.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Outcomes for Financial Services Firm
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Instant notifications</strong> for contact updates, improving response time.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated fund balance tracking,</strong> eliminating manual effort.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reduced errors and operational delays</strong> with automated workflows.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Seamless integration with Salesforce,</strong> enhancing CRM efficiency.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved internal communication,</strong> ensuring stakeholders received accurate and timely information.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h3' className='black jst h3h6' gutterBottom>
                                Why This Matters
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Automating real-time alerts in <strong>Salesforce CRM</strong> can significantly improve <strong>data accuracy, operational efficiency, and decision-making speed.</strong> By implementing <Link to="/customer-relationship-management-solutions/" className="link">CRM workflow automation</Link>, financial firms can streamline key processes, improve internal communication, and drive better business outcomes.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Looking to eliminate manual inefficiencies and improve real-time decision-making? <Link to="/contact-us#contact_form" className="link">Decimal Point Analytics</Link> helps financial firms integrate <strong>intelligent CRM automation</strong> to enhance workflow efficiency, improve communication, and ensure critical updates reach the right stakeholders instantly.
                            </Typography>
                        </Box>
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>
        </>
    );
}