import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Event_Data from "./Event_data";
import PropTypes from "prop-types";
import Design from "./Event_Design";
import Event_banner from "../../asset/images/new_banner/event_banner.webp";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function EventNew() {
    const storedTab = localStorage.getItem("selectedTab");
    const [value, setValue] = React.useState(storedTab ? parseInt(storedTab) : 2025);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
        localStorage.setItem("selectedTab", newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const getEventData = (year) => Event_Data.filter((item) => item.year === year);

    return (
        <>
            <Helmet>
                <title>Events | Decimal Point Analytics</title>
                <meta name="description" content="Explore Decimal Point Analytics' upcoming events, showcasing industry insights and innovative solutions." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/event"
                />
            </Helmet>

            <Box className="banner_bx">
                <Box
                    style={{ backgroundImage: "url(" + Event_banner + ")" }}
                    className="banner_img"
                >
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent="center">
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Event</Typography>
                                    <Box mt={"10px"}>
                                        <Typography className="white" variant="h4">
                                            Join Us In Our Insightful Events.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box id="Event" className="section sec_bg" style={{ paddingTop: '0%' }}>
                <Box className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="relative" className="tabbedbar">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                    >
                                        {[2025, 2024, 2023, 2022].map((year) => (
                                            <Tab
                                                key={year}
                                                label={year.toString()}
                                                component={Link}
                                                to="/Event"
                                                value={year}
                                            />
                                        ))}
                                        <Tab
                                            key={2021}
                                            label="More"
                                            component={Link}
                                            to="/Event"
                                            value={2021}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {[2025, 2024, 2023, 2022, 2021].map((year) => (
                    <TabPanel key={year} value={value} index={year}>
                        <Box id="newsroom" className="sec_bg" component="section">
                            <Container maxWidth="lg" className="bdr">
                                <Box className="reportinfo">
                                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                                        {getEventData(year).map((item, index) => (
                                            <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                                <Design
                                                    year={item.year}
                                                    link={item.link}
                                                    date={item.date}
                                                    imgsrc={item.imgsrc}
                                                    heading={item.heading}
                                                    subheading={item.subheading}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>
                    </TabPanel>
                ))}
            </Box>
        </>
    );
}