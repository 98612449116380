import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_reducing_client_query.webp"
import Infographics from "../../../../asset/images/insight/case_studies/reducing_client_query.webp"
import { Link } from 'react-router-dom';



export default function ReducingClientQueryTime() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>AI in Customer Service | Customer Support Outsourcing Solutions </title>
                <meta name="description" content="Discover AI in customer service and customer support outsourcing solutions. Learn how automation, chatbots, and help desk support improve efficiency and client satisfaction." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/reducing-client-query-time-by-29-ensuring-100-support-coverage-with-an-offshore-team" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Reducing Client Query Time <span className='lower'>by</span> 29%, Ensuring 100% Support Coverage <span className='lower'>with an</span> Offshore Team
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading US firm specializing in global investor and fund manager sentiment insights faced challenges in providing round-the-clock customer support services. Delays in case resolution and inconsistent handling of client issues impacted customer satisfaction. By establishing a dedicated offshore support team and developing an NLP-based chatbot, significant improvements were achieved in response times and support efficiency.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Providing timely client support during Asian business hours was a significant challenge, leading to:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Delayed Resolutions:</strong> Delays in resolving client queries due to insufficient support coverage.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Lack of Comprehensive SOPs: </strong> The absence of standard operating procedures resulted in prolonged turnaround times.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Complex System Navigation: </strong> Difficulty navigating complex system functionalities affected overall efficiency.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Transforming Client Support
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Solution: Transforming Client Support" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Achieved 100% coverage during Asian business hours, ensuring no delays in client support.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Simplified system navigation for a more user-friendly experience.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Boosted customer satisfaction by reducing average query response time from 26 to 18.5 minutes.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Efficient offshore customer support outsourcing enhances client satisfaction and operational efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Implementation of advanced technologies, such as AI in customer service and NLP-based chatbot solutions, streamlines the support process.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Comprehensive SOPs and FAQs are essential for consistent and effective client service.
                                        </Typography>
                                    </li>
                                </ul> 

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Reach out now</Link> to discover how our solutions can enhance your organization’s support and satisfaction levels!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}