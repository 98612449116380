import { AppBar, Grid, Toolbar, Typography, IconButton, MenuItem, Menu, Button, Box, Drawer, withStyles,} from '@material-ui/core';
import React, { useState } from 'react';
import Scroll from './Scroll'
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

import dpalogo from "../asset/images/dpa_main_logo.svg";

import CloseIcon from '@mui/icons-material/Close';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import LetsConnectCTA from './forms/LetsConnectCTA';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WhatWeDo from './MegaMenu/WhatWeDo';
import WhoWeServe from './MegaMenu/WhoWeServe';
import VerticalCarousel from './MegaMenu/VerticalCarousel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);





export default function MegamenuHeader() {

  const [anchorE2, setAnchorE2] = React.useState(null);

  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE2(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';


  const [state, setState] = React.useState({
  });

  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'right' && (event.key === 'right')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  // Insight Dropdown started
  const [anchorEins, setAnchorEins] = React.useState(null);

  const handleClickins = (event) => {
    setAnchorEins(event.currentTarget);
  };

  const handleCloseins = () => {
    setAnchorEins(null);
  };


  // ESG Dropdown started
  const [anchorEESG, setAnchorEESG] = React.useState(null);

  const handleClickESG = (event) => {
    setAnchorEESG(event.currentTarget);
  };

  const handleCloseESG = () => {
    setAnchorEESG(null);
  };




  const [expanded, setExpanded] = React.useState('false');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);

  };
 
  const [subexpandedWhatWeServe, setSubExpandedWhatWeServe] = useState(null); // State for new sub-accordion

  const [subExpanded, setSubExpanded] = useState(null); // Example of initializing state

  const handleSubAccordionChange = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : null);
  };

  const handleSubAccordionChangeWhatWeServe = (panel) => (event, isExpanded) => {
    setSubExpandedWhatWeServe(isExpanded ? panel : null);
  };


  const list = (anchor) => (

    <Box className='mobilemenuview megamenu' sx={{ width: 300 }} role="presentation">


      <Box mb={1} mt={1} mr={2} className='al_right'>
        <IconButton className='Close_btn' variant="contained" onClick={toggleDrawer(anchor, false)}>
          <CloseIcon fontSize='small' color='primary' />
        </IconButton>
      </Box>

      <Box >




        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Button endIcon={<ExpandMoreIcon />} disableRipple className={`menutt ${expanded === 'panel1' ? 'active_menu' : ''}`}>
              What We Do
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={0} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'Research'} onChange={handleSubAccordionChange('Research')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Research-content" id="Research-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'Research' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Research and Consultative Services</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>M&A Advisory</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Risk Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Asset Allocation Strategies</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Equity Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Financial Modelling</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Sector and Company Coverage</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fixed Income Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Credit Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Macroeconomic Research</span>
                            </MenuItem>
                          </Box>
                        </Box>
                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Pre and Post-Deal Assistance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Due Diligence Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Exit Strategy</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'Fund'} onChange={handleSubAccordionChange('Fund')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Fund-content" id="Fund-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'Fund' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Fund Mangement & Administrative Services</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Administration</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Accounting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Performance Monitoring and Attribution Analysis</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Reconciliation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>NAV Calculation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Automated Factsheet Generation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Investor Reporting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>RFP Response Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='#' className={`linkitem ${isActive(['#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Deal Sourcing and Valuation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Back-testing and Calculations</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Performance Reporting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Operations</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Trade Finance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Insurance Mid and Back-office Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'biservices'} onChange={handleSubAccordionChange('biservices')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="biservices-content" id="biservices-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'biservices' ? 'active_menu' : ''}`}>
                      <span className='al_left'>  Bi Services and Data Analytics</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Acquisition</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Web Data Extraction</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Warehousing and Data Lakes</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Quality Management/ Data Governance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Predictive Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Trend Analysis</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>BI Consulting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/csr' className={`linkitem ${isActive(['/csr']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>BI Support and Maintenance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'datascience'} onChange={handleSubAccordionChange('datascience')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="datascience-content" id="datascience-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'datascience' ? 'active_menu' : ''}`}>
                      <span className='al_left'>  Data Science</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Generative AI</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>NLP for Textual Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Sentiment Analysis</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Social Media Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fraud Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Early Warning Signals</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Text Classification and Summarizer</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Automated News Generation and Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Noise Classification</span>
                            </MenuItem>
                          </Box>
                        </Box>
                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Facial Recognition</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'Technology'} onChange={handleSubAccordionChange('Technology')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Technology-content" id="Technology-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'Technology' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Technology And Automation</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Front-end Development</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>API Development</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Website and Mobile App Development</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>DevOps</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Cloud Infra</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Software Quality Assurance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Intelligent Document Processing (IDP)</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Robotic Process Automation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Distributed Ledger Technology</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'esgproduct'} onChange={handleSubAccordionChange('esgproduct')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="esgproduct-content" id="esgproduct-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'esgproduct' ? 'active_menu' : ''}`}>
                      <span className='al_left'> ESG Product and Services</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Typography className="menuheading">ESG Products</Typography>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Key Issue Assessment</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG News Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Reporting for PE Funds</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/technologyedge' className={`linkitem ${isActive(['/technologyedge']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Portfolio Alignment Tool for TCFD and SFDR Reporting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Typography className="menuheading">ESG Services</Typography>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Research Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Data Provision Services</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Policy and Process Consulting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Training</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'crmsupport'} onChange={handleSubAccordionChange('crmsupport')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="crmsupport-content" id="crmsupport-header">
                    <Button disableRipple className={`menutt ${subExpanded === 'crmsupport' ? 'active_menu' : ''}`}>
                      <span className='al_left'> CRM Support Services</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Workflow Automation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Contact Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Email Integration and Automation​</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>RFP (DDQ) Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Analysis via Dashboards​</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Maintenance Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>

                {/* Start of Sub-Accordion */}
                <Accordion expanded={subExpanded === 'productdevelopment'} onChange={handleSubAccordionChange('productdevelopment')} className='bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="productdevelopment-content" id="productdevelopment-header"  >
                    <Button disableRipple className={`menutt ${subExpanded === 'productdevelopment' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Product Development and Design</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Typography className="menuheading">Product Development</Typography>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Product Ideation and Conceptualization</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Product Documentation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Platform Development </span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>User Acceptance Testing</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Typography className="menuheading">UI/UX</Typography>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Website and Mobile App Design</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Interactive Dashboards</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>PowerPoint Presentation Templates</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Brochure and Report Design</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Infographic Design</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}

              </Grid>


            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Button endIcon={<ExpandMoreIcon />} disableRipple className={`menutt ${expanded === 'panel2' ? 'active_menu' : ''}`}>
              What We Serve
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={0} alignItems="top">

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Asset'} onChange={handleSubAccordionChangeWhatWeServe('Asset')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Asset-content" id="Asset-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Asset' ? 'active_menu' : ''}`}>
                      <span className='al_left'>Asset Managers</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>M&A Advisory</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Risk Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Asset Allocation Strategies</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund and Portfolio Monitoring</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Accounting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Administration</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Reconciliation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>NAV Calculation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Factsheet Generation</span>
                            </MenuItem>
                          </Box>
                        </Box>
                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Performance Monitoring and Attribution Analysis</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Private'} onChange={handleSubAccordionChangeWhatWeServe('Private')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Private-content" id="Private-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Private' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Private Equity and Venture Capital</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Deal Sourcing and Valuation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Pre and Post-Deal Assistance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Due Diligence Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Exit Strategy</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Administration</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund and Portfolio Monitoring</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund Factsheet Generation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Investor Reporting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fund of Funds Support</span>
                            </MenuItem>
                          </Box>
                        </Box>
                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Investment'} onChange={handleSubAccordionChangeWhatWeServe('Investment')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Investment-content" id="Investment-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Investment' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Investment Managers and Brokerage Firms</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Equity Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Financial Modelling</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Sector and Company Coverage</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fixed Income Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Credit Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Macroeconomic Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Lending'} onChange={handleSubAccordionChangeWhatWeServe('Lending')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Lending-content" id="Lending-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Lending' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Lending Operations</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Loan Underwriting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Loan Processing</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Quality and Operations </span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Loan Screening and Due Diligence</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Loan Document Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Transition Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Risk Assessment</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ERC Tax Eligibility Check</span>
                            </MenuItem>
                          </Box>
                        </Box>
                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Digital Transformation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Early Warning Signals</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>BI Consulting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Insurance'} onChange={handleSubAccordionChangeWhatWeServe('Insurance')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Insurance-content" id="Insurance-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Insurance' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Insurance Mid and Back-office Support</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Catastrophe Modelling</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Bordereaux Processing</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>IFRS 17 Implementation Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Investment Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'DataProviders'} onChange={handleSubAccordionChangeWhatWeServe('DataProviders')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="DataProviders-content" id="DataProviders-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'DataProviders' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Data Providers/Aggregators</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Acquisition</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Web Data Extraction</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Warehousing and Data Lakes</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Data Quality Management</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>ESG Data Provision Services</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>API Development</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'FMCG'} onChange={handleSubAccordionChangeWhatWeServe('FMCG')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="FMCG-content" id="FMCG-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'FMCG' ? 'active_menu' : ''}`}>
                      <span className='al_left'> FMCG</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>FP&A Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>MIS Automation and Dashboards</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Product Portfolio Optimization</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Procurement Dashboards</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'Corporates'} onChange={handleSubAccordionChangeWhatWeServe('Corporates')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="Corporates-content" id="Corporates-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'Corporates' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Corporates</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Equity Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Fixed Income Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Credit Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>CRM Support</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Trade Finance</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'RealEstate'} onChange={handleSubAccordionChangeWhatWeServe('RealEstate')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="RealEstate-content" id="RealEstate-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'RealEstate' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Real Estate</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Sector Coverage</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Tenant Credit Note</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Alternative Data Analytics</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Macroeconomic Research</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* Start of Sub-Accordion */}
                <Accordion expanded={subexpandedWhatWeServe === 'IndexProviders'} onChange={handleSubAccordionChangeWhatWeServe('IndexProviders')} className='megamenu bn'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon disableRipple />} aria-controls="IndexProviders-content" id="IndexProviders-header">
                    <Button disableRipple className={`menutt ${subexpandedWhatWeServe === 'IndexProviders' ? 'active_menu' : ''}`}>
                      <span className='al_left'> Index Providers</span>
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} alignItems="top">
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Ideation and Conceptualization</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Back-testing and Calculation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Operations</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Performance Reporting</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Automated Factsheet Generation</span>
                            </MenuItem>
                          </Box>
                        </Box>

                        <Box className='bdd'>
                          <Box component={Link} to='/#' className={`linkitem ${isActive(['/#']) ? 'active_drop_menu' : ''}`}>
                            <MenuItem onClick={toggleDrawer(anchor, false)}><span>Index Development & Benchmarking</span>
                            </MenuItem>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* End of Sub-Accordion */}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='bddbddp0'>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='bn'>
                  <AccordionSummary component={Link} to='/#' aria-controls="panel2d-content" id="panel2d-header" onClick={toggleDrawer(anchor, false)}>
                    <Button disableRipple className={`menutt ${isActive(['/#']) ? 'active_menu' : ''}`}> Data Science & Data Mgnt.</Button>
                  </AccordionSummary>
                </Accordion>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='bn'>
                  <AccordionSummary component={Link} to='/#' aria-controls="panel3d-content" id="panel3d-header" onClick={toggleDrawer(anchor, false)}>
                    <Button disableRipple className={`menutt ${isActive(['/#']) ? 'active_menu' : ''}`}>BI Services & Data Analytics</Button>
                  </AccordionSummary>
                </Accordion>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='bn'>
                  <AccordionSummary component={Link} to='/#' aria-controls="panel4d-content" id="panel4d-header" onClick={toggleDrawer(anchor, false)}>
                    <Button disableRipple className={`menutt ${isActive(['/#']) ? 'active_menu' : ''}`}> Technology & Automation</Button>
                  </AccordionSummary>
                </Accordion>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className='bn'>
                  <AccordionSummary component={Link} to='/#' aria-controls="panel5d-content" id="panel5d-header" onClick={toggleDrawer(anchor, false)}>
                    <Button disableRipple className={`menutt ${isActive(['/#']) ? 'active_menu' : ''}`}>  ESG Services</Button>
                  </AccordionSummary>
                </Accordion>
              </Grid>

            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header"  >
            <Button endIcon={<ExpandMoreIcon />} disableRipple className={`menutt ${isActive(['/case-studies', '/articles', '/blog', '/newsletter', '/podcast', '/infographics']) ? 'active_menu' : ''}`}>Insights</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/case-studies' className={`linkitem ${isActive(['/case-studies']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Success Stories </span>
                    </MenuItem>
                  </Box>
                </Box>


                <Box className='bdd'>
                  <Box component={Link} to='/articles' className={`linkitem ${isActive(['/articles']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Articles </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/blog' className={`linkitem ${isActive(['/blog']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Blogs </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/newsletter' className={`linkitem ${isActive(['/newsletter']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Newsletter </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/podcast' className={`linkitem ${isActive(['/podcast']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Podcast </span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/infographics' className={`linkitem ${isActive(['/infographics']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span> Infographic </span>
                    </MenuItem>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary component={Link} to='/careers' aria-controls="panel7d-content" id="panel7d-header" onClick={toggleDrawer(anchor, false)}>
            <Button disableRipple className={`menutt ${isActive(['/careers']) ? 'active_menu' : ''}`}>Careers</Button>
          </AccordionSummary>
        </Accordion>


        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" >
            <Button endIcon={<ExpandMoreIcon />} disableRipple className={`menutt ${isActive(['/who-we-are', '/decimal-sutras', '/awards', '/technologyedge', '/valueproposition', '/management', '/structure-innovation', '/csr', '/energy-efficiency', '/event', '/pressreleases']) ? 'active_menu' : ''}`}>About Us</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <Box className='bdd'>
                  <Box component={Link} to='/who-we-are' className={`linkitem ${isActive(['/who-we-are']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Our Story & Offerings</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/decimal-sutras' className={`linkitem ${isActive(['/decimal-sutras']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Decimal Sutras</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/awards' className={`linkitem ${isActive(['/awards']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Awards and Accolades</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/technologyedge' className={`linkitem ${isActive(['/technologyedge']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>The Technology Edge</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/valueproposition' className={`linkitem ${isActive(['/valueproposition']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Value Proposition</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/management' className={`linkitem ${isActive(['/management']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Management Team</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/structure-innovation' className={`linkitem ${isActive(['/structure-innovation']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Structure of Innovation</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/csr' className={`linkitem ${isActive(['/csr']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>CSR</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/energy-efficiency' className={`linkitem ${isActive(['/energy-efficiency']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Energy Efficiency</span>
                    </MenuItem>
                  </Box>
                </Box>
                <Box className='bdd'>
                  <Box component={Link} to='/event' className={`linkitem ${isActive(['/event']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>Events</span>
                    </MenuItem>
                  </Box>
                </Box>

                <Box className='bdd'>
                  <Box component={Link} to='/pressreleases' className={`linkitem ${isActive(['/pressreleases']) ? 'active_drop_menu' : ''}`}>
                    <MenuItem onClick={toggleDrawer(anchor, false)}><span>News Room</span>
                    </MenuItem>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary component={Link} to='/webinar' aria-controls="panel9d-content" id="panel9d-header" onClick={toggleDrawer(anchor, false)}>
            <Button disableRipple className={`menutt ${isActive(['/webinar']) ? 'active_menu' : ''}`}>Webinar</Button>
          </AccordionSummary>
        </Accordion>


        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
          <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" >
            <LetsConnectCTA className="contact_sales_btn" btn_name="Contact Sales" />
          </AccordionSummary>
        </Accordion>


      </Box>
    </Box >
  );



  const location = useLocation();

  const isActive = (paths) => {
    return paths.some(path => location.pathname === (path));
  };


  return (
    <div className="grow">
      <Scroll showBelow={250} />
      <AppBar position="fixed" className="appnavbar" alignItems="center" data-aos="fade-down">
        <Toolbar>

          <Box className='loggo0'>
            <a href='/' onClick={() => { window.location.href = "/" }}><img loading="lazy"  src={dpalogo} className='dpalogo' alt='Decimal Point Analytics' /></a>
          </Box>

          <div className="grow" />
          <div className="sectionDesktop desktopviewmenu">


            <Box variant="h6" className="logintitle" aria-controls="menuId" aria-haspopup="true" onClick={handleProfileMenuOpen}>
              <Button endIcon={isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} disableRipple className={`menutt ${isMenuOpen ? 'active_menu' : ''}`}>What we do</Button>
            </Box>



            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              id={menuId}
              keepMounted
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={isMenuOpen}
              onClose={handleMenuClose} className="megamenu whatwedo"
            >

              <Box>
                <WhatWeDo onClose={handleMenuClose} />
                <VerticalCarousel onClick={handleMenuClose} />
              </Box>

            </Menu>


            <Box variant="h6" className="logintitle" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickins}>
              <Button endIcon={anchorEins ? <ExpandLessIcon /> : <ExpandMoreIcon />} disableRipple className={`menutt ${anchorEins ? 'active_menu' : ''}`}>Who We Serve</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorEins}
              keepMounted
              open={anchorEins}
              onClose={handleCloseins} className="megamenu whatwedo"

            >
              <Box>
                <WhoWeServe onClose={handleCloseins} />
                <VerticalCarousel onClick={handleCloseins} />
              </Box>


            </Menu>


            <Box variant="h6" className="logintitle" aria-controls="menuId" aria-haspopup="true" onClick={handleClickESG}>
              <Button endIcon={Boolean(anchorEESG) ? <ExpandLessIcon /> : <ExpandMoreIcon />} disableRipple className={`menutt ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses', '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable', '/esg-insights/esg-whitepaper', '/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_menu' : ''}`}>Insight</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorEESG}
              keepMounted
              open={Boolean(anchorEESG)}
              onClose={handleCloseESG} className="insight_menu esg_menu"
            >

              <Box className='bdd'>
                <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span>  ESG </span>
                  </MenuItem>
                </Box>
              </Box>


              <Box className='bdd no_br'>
                <Box className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics', '/elevating-private-equity-performance-with-strategic-esg-solutions', '/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem>
                    <span> ESG Products</span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/empowering-esg-investment-with-real-time-analytics' className={`linkitem ${isActive(['/empowering-esg-investment-with-real-time-analytics']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG News Senti-Meter </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/elevating-private-equity-performance-with-strategic-esg-solutions' className={`linkitem ${isActive(['/elevating-private-equity-performance-with-strategic-esg-solutions']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG for Private Equity </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd' ml={1}>
                <Box component={Link} to='/align-your-investments-with-global-sustainability-goals' className={`linkitem ${isActive(['/align-your-investments-with-global-sustainability-goals']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} className='nest_list'><span className='skblue nested align_c'><ArrowRightIcon fontSize='small' />  ESG GreenInvest Pulse </span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/esg-insights/esg-whitepaper' className={`linkitem ${isActive(['/esg-insights/esg-whitepaper']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span> ESG Insights</span>
                  </MenuItem>
                </Box>
              </Box>

              <Box className='bdd'>
                <Box component={Link} to='/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' className={`linkitem ${isActive(['/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable']) ? 'active_drop_menu' : ''}`}>
                  <MenuItem onClick={handleCloseESG} ><span> ESG Roundtable</span>
                  </MenuItem>
                </Box>
              </Box>

            </Menu>



            {/* <Link to='/careers' variant="h6" className="logintitle" > */}
            <Link to='/careers' className="logintitle">
              <Button disableRipple className={`menutt ${isActive(['/careers']) ? 'active_menu' : ''}`}>Careers</Button>
            </Link>






            <Box variant="h6" className="logintitle" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Button endIcon={Boolean(anchorE2) ? <ExpandLessIcon /> : <ExpandMoreIcon />} disableRipple className={`menutt ${isActive(['/who-we-are', '/decimal-sutras', '/awards', '/technologyedge', '/valueproposition', '/management', '/structure-innovation', '/csr', '/energy-efficiency', '/event', '/pressreleases']) ? 'active_menu' : ''}`}>About Us</Button>
            </Box>

            <Menu
              id="menuId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              anchorEl={anchorE2}
              keepMounted
              open={Boolean(anchorE2)}
              onClose={handleClose} className="menuitem"
            >


              <Grid container spacing={4} alignItems="top">
                <Grid item xs={12} sm={6}>

                  <Box className='bdd'>
                    <Box component={Link} to='/who-we-are' className={`linkitem ${isActive(['/who-we-are']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Our Story & Offerings</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/decimal-sutras' className={`linkitem ${isActive(['/decimal-sutras']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Decimal Sutras</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/awards' className={`linkitem ${isActive(['/awards']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Awards and Accolades</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/technologyedge' className={`linkitem ${isActive(['/technologyedge']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>The Technology Edge</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/valueproposition' className={`linkitem ${isActive(['/valueproposition']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Value Proposition</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/management' className={`linkitem ${isActive(['/management']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Management Team</span>
                      </MenuItem>
                    </Box>
                  </Box>

                </Grid>


                <Grid item xs={12} sm={6}> 

                  <Box className='bdd'>
                    <Box component={Link} to='/structure-innovation' className={`linkitem ${isActive(['/structure-innovation']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Structure of Innovation</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/csr' className={`linkitem ${isActive(['/csr']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>CSR</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/energy-efficiency' className={`linkitem ${isActive(['/energy-efficiency']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Energy Efficiency</span>
                      </MenuItem>
                    </Box>
                  </Box>
                  <Box className='bdd'>
                    <Box component={Link} to='/event' className={`linkitem ${isActive(['/event']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>Events</span>
                      </MenuItem>
                    </Box>
                  </Box>

                  <Box className='bdd'>
                    <Box component={Link} to='/pressreleases' className={`linkitem ${isActive(['/pressreleases']) ? 'active_drop_menu' : ''}`}>
                      <MenuItem onClick={handleClose} ><span>News Room</span>
                      </MenuItem>
                    </Box>
                  </Box>


                </Grid>
              </Grid>

            </Menu> 
            <LetsConnectCTA className="contact_sales_btn" btn_name="Contact Sales" />
            {/* </Link> */}


          </div>


          <Box className='mobileviewmenu'>
            {['right'].map((anchor) => (
              <React.Fragment> 

                <IconButton aria-label="delete" onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon fontSize="small" color='primary' />
                </IconButton>
                <Drawer
                  className='drawer'
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>

        </Toolbar>
      </AppBar>

    </div >



  );
}
