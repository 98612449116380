import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Writemessage3 from '../../../../../../Component/Writemessage3';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';



export default function Sebastian_vettel() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>Sebastian Vettel's Challenge to F1's Carbon Emissions | DPA </title>
        <meta name="description" content="Explore Sebastian Vettel's perspective on F1's carbon emissions and the path to net zero. Delve into the debate on F1's environmental impact." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/sebastian-vettel-and-net-zero" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Sebastian Vettel and Net Zero
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Sebastian Vettel and Net Zero" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                The four-time Formula One (F1) motor racing champion Sebastian Vettel has questioned F1’s commitment to reducing its carbon emissions. Vettel could have done more: he could have questioned the very need for this so-called ‘sport’ to exist anymore
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                The four-time Formula One (F1) motor racing champion Sebastian Vettel, while announcing his decision to retire last week, once again alluded to the dark side of F1: its carbon footprint.
              </Typography>



              <Typography variant='h6' className='black jst'>
                Writing on his own <a href='https://sebastianvettel.de/en/news-en/' target="_blank" rel="noopener noreferrer"> website, </a> Vettel said “(there are) certain aspects that I have learned to dislike. They might be solved in the future but the will to apply that change has to grow much, much stronger and has to be leading to action today. Talk is not enough and we cannot afford to wait”.  </Typography>

              <Typography variant='h6' className='black jst'>
                Vettel has earlier talked about the lack of action on the part of the ‘gas-guzzling’ Formula One to tackle its impact on the climate.
              </Typography>


              <Typography variant='h6' className='black jst'>
                F1 officials announced in 2019 that it intends to be Net Zero Carbon by 2030. Articles about F1’s intent to reduce emissions have been appearing for over a decade. It appears Vettel doesn’t believe the progress is good enough.   </Typography>

            </Box>



            <Box pt={1} pb={2}>

              <Typography variant='h4' className='blue jst'>
                F1 carbon numbers need vast improvement
              </Typography>


              <Typography variant='h6' className='black jst'>
                Vettel could be correct, F1’s emission numbers don’t look good.  </Typography>


              <Typography variant='h6' className='black jst'>
                We tried to find some info on what the carbon footprint of F1 is. Here are some stats from press articles:  </Typography>


              <Typography variant='h6' className='black jst'>

                <ul>
                  <li> <strong> 1.4 km per litre in 2010: </strong>  A <a href='https://www.mykhel.com/motorsport/formulaone-embarks-on-carbon-cuttingdrive-023123.html' target="_blank" rel="noopener noreferrer"> 2010 article  </a>  says The Formula One Teams Association (FOTA) was unveiling a program would cut emissions cut of 15 percent in three years. At that time, each car appeared to burn about 160kg of petrol or about 210 litres in an average race in its 2.4 litre V8 engine. An average race is around 300km, so the fuel efficiency, it appears in 2010 was less than 1.5 km per litre!   </li>

                  <li> <strong>  Fuel limit was increased 4% in 2019? </strong>    An astonishing bit of information we found says: The amount of fuel an F1 car can use per race was increased to 110 kilograms in 2019 (an increase of 5 kilograms) in order to allow the drivers to push more of the time (for source, < a href="https://www.autosport.com/f1/news/how-much-fuel-does-a-formula-1-car-use-f1-nascar-more-compared-4980266/4980266/" target="_blank" rel="noopener noreferrer"> click here </a>). In other words, the F1 management allowed nearly 4% more burning of fuel – to increase race speeds we presume – rather than trying to control.   </li>


                  <li> <strong>  2.2km per litre now? </strong>   Currently, it appears the fuel efficiency is at best 2.2km per litre. 110 kilograms works out to around 145 litres. Now let’s assume that the car burns only 105 kg or 140 litres (some cars must be burning that much warranting increase to 110 kg mentioned above). This brings fuel efficiency to maybe 2.2km per litre or about 55% improvement in over a decade. While 55% improvement sounds promising, 2.2km per litre is still terrible.   </li>
                </ul>
              </Typography>



              <Typography variant='h6' className='black jst'>
                No wonder Vettel says that all the noble talk of reducing emissions is more words than action.
              </Typography>
            </Box>

            <Box pt={1} pb={2}>

              <Typography variant='h4' className='blue jst'>
                Fuel burnt is a minor part of emissions
              </Typography>


              <Typography variant='h6' className='black jst'>
                The fuel F1 car burns is a tiny portion of its emissions. According to <a href="https://www.autoweek.com/racing/formula-1/a40436597/f1-reveals-new-branding-for-net-zero-carbon-initiative/" target="_blank" rel="noopener noreferrer"> an article </a> in the industry mag Autoweek: ‘In its 2019 report, the (F1) series revealed that 45% of its carbon emissions came through logistics, 27% through business travel, and almost 20% through facilities and factories, <strong> 1% came through on-track running,</strong> with 7% coming through event operations.’  </Typography>

            </Box>

            <Box pt={1} pb={2}>

              <Typography variant='h4' className='blue jst'>
                Will F1 get to Net Zero by 2030?
              </Typography>


              <Typography variant='h6' className='black jst'>
                The track record does not inspire confidence in F1’s ability to do much about its carbon footprint.  </Typography>


              <Typography variant='h6' className='black jst'>
                In June this year, the Formula One announced that it has developed a synthetic sustainable fuel to be introduced in 2026 as part of its programme to be net-zero carbon by 2030. It is not clear presently what this fuel is; apparently it is being developed in partnership with Saudi Arabia’s oil giant ARAMCO.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Even if this is carbon neutral, this addresses maybe 1% of the problem.
              </Typography>

            </Box>

            <Box pt={1} pb={2}>

              <Typography variant='h4' className='blue jst'>
                Or should it even exist?
              </Typography>


              <Typography variant='h6' className='black jst'>
                Achieving carbon goals is going to be tall task for humanity. Over the next few years, some hard decisions will have to be made.   </Typography>


              <Typography variant='h6' className='black jst'>
                The time has come for governments to question the very existence of this so-called ‘sport’. It appears to be just as anachronistic as the gladiatorial contests of ancient Rome, or bull-fighting, which is now banned in most places. Mankind can do without frivolous activities that create wholly avoidable pollution.
              </Typography>


            </Box>
            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}

          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Net Zero, Environment
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b>  Sebastian Vettel, Formula One, F1, ARAMCO, synthetic sustainable fuel, The Formula One Teams Association (FOTA)
                  </Typography>


                </Box>
              </Grid>
            </Grid>
          </Box>


          <ESGBlogsBack />
        </Container>
      </Box>


    </>
  );
}

