import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";


export default function HomeOurStrength() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Box className="section sec_bg ourstrength" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className="al_center">
                <Typography className="black" variant="h2">
                  Our Strength
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    350 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Happy Customers
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    700 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Projects Completed
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    05
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Offices Worldwide
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    80 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Expert Advisors
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
