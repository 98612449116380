import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_transforming_analytics.webp"
import { Link } from 'react-router-dom';



export default function TransformingAnalytics() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Accelerating Analytics with Snowflake Analytics | Decimal Point Analytics</title>
                <meta name="description" content="Discover how Decimal Point Analytics revolutionized a US firm's analytics with Snowflake and Snowpark, reducing processing time by 90% and cutting costs from $1,400 to $300–$400. Unlock scalable, cost-efficient cloud solutions today!" />
                <meta name="keywords" content="Workforce automation, employee engagement analytics, workforce data automation, KPI tracking system, automated workforce management, real-time employee analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-study/accelerating-analytics-with-snowflake" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Transforming Analytics Efficiency <span className='lower'>with</span> Snowflake & Snowpark
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                About the Client
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading US-based firm specializing in advisory, disposition, restructuring, valuation, and auction services serves a wide range of industries, including retail, wholesale, and industrial sectors. Renowned for delivering data-driven insights, the company excels in asset appraisal and liquidation solutions tailored to client needs.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges Faced
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm encountered operational hurdles that slowed growth:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Processing Delays: Python-based analytics on AWS resulted in processing times of 1–24 hours.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Costly Infrastructure: Memory limitations triggered frequent, expensive upgrades, locking the firm into high fixed costs.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Limited Flexibility: The system struggled to handle ad hoc requests and adapt to new requirements, reducing agility.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                These inefficiencies hindered the firm’s ability to scale and respond to market demands effectively.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                            DPA Solution
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics (DPA) transformed the firm’s operations by migrating its analytics from Python on AWS to Snowpark on Snowflake. Key enhancements included: 
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Script Optimization: Restructured Python scripts for seamless integration with Snowflake.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Dynamic Scaling: Leveraged Snowflake’s warehouse capabilities to eliminate memory constraints.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Workflow Automation: Streamlined processes for faster, more reliable outputs.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            This modern analytics solution tackled inefficiencies head-on, boosting performance and adaptability. 
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            Want to transform your operations with cloud solutions? <Link to="/cloud-services" className="link">Explore DPA’s Cloud Solutions today!</Link> 
                            </Typography>

                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                            Results Achieved
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            The migration yielded impressive outcomes: 
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Processing:</strong> Reduced analytics runtime to under 2 hours, a 90%+ improvement. 
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Savings:</strong> Slashed monthly expenses from $1,400 to $300–$400 with Snowflake’s usage-based pricing. 
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Scalability:</strong> Eliminated the need for infrastructure upgrades with dynamic resource allocation.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Agility:</strong> Enabled seamless handling of ad hoc requests and new feature integration.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                            These gains positioned the firm for sustainable growth and operational excellence.
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                            Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            By harnessing Snowflake and Snowpark, Decimal Point Analytics transformed the client’s analytics framework, delivering speed, cost efficiency, and flexibility. This case study highlights how innovative technology solutions can drive business success in asset appraisal, liquidation, and beyond. 
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            Ready to unlock similar results for your business? Contact <Link to="/contact-us#contact_form" className="link">Decimal Point Analytics</Link> today to explore how our cutting-edge solutions can optimize your operations and fuel growth! 
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}