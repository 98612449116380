import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_enhancing_workflow_efficiency.webp"
import Infographics from "../../../../asset/images/insight/case_studies/enhancing_workflow_efficiency.webp"
import { Link } from 'react-router-dom';
import GetInTouch from '../../../../Component/Get_in_touch';



export default function EnhancingWorkflowEfficiency() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Boosting Efficiency, Reducing Costs: Streamline Your SBA Loan Process</title>
                <meta name="description" content="Discover how to optimize your SBA loan process, reduce late payment penalties, and improve compliance with expert guidance from DPA" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/enhancing-workflow-efficiency-and-reducing-costs-with-precision" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Enhancing Workflow Efficiency <span className='lower'>and</span> Reducing Costs <span className='lower'>with</span> Precision
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Client Profile
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Our client, a renowned real estate finance company specializing in SBA loans, struggled with timely and accurate SBA reporting. This led to frequent late payment penalties, inefficient resource allocation, and increased operational costs.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Challenges
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Substantial late payment penalties: </strong>  Frequent delays in meeting SBA deadlines resulted in significant financial penalties.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Process inefficiencies: </strong>  Bottlenecks and inefficiencies in the SBA reporting process caused delays and errors.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Diversion of resources: </strong>  The client's resources were diverted from core operations to manage SBA reporting, impacting their overall productivity.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                To address these challenges and improve their operational efficiency, DPA implemented the following solutions:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Solution: Streamlined Public Float Calculation" />
                                    </Box>
                                </Grid>
                            </Grid>


                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Revamped Experience
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                As a result of these solutions, the client experienced a significant improvement in their operational efficiency and cost savings:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Penalties sharply fell 80-90%: </strong> The streamlined workflows and clear prioritization of tasks enabled the client to meet SBA deadlines consistently, resulting in a significant reduction in late payment penalties.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Ensured all SBA deadlines were consistently met: </strong>  The improved processes and comprehensive compliance procedures ensured that the client met all SBA deadlines without fail.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Allowed client to concentrate on core operations: </strong>  The streamlined SBA reporting process freed up resources, allowing the client to focus on their core operations and business growth.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Provided better oversight and control over reporting processes:  </strong>  The implemented solutions provided better visibility and control over the SBA reporting process.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Successfully reclaimed funds from SBA for 63 loans in last quarter:  </strong>The client was able to successfully reclaim funds from the SBA for 63 loans in the last quarter due to improved compliance and documentation.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>

                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Success Metrics
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Savings (80-90% reduction in costs): </strong>  The significant reduction in late payment penalties resulted in substantial cost savings.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Consistent Compliance (on-time SBA reporting): </strong>  The client consistently met all SBA deadlines, demonstrating strong compliance.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Met SBA deadlines: </strong>  The streamlined workflows and clear prioritization of tasks enabled the client to meet all SBA deadlines.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                By identifying and addressing bottlenecks, streamlining workflows, and implementing comprehensive compliance procedures, DPA successfully improved the client's operational efficiency and reduced costs significantly. This enabled the client to focus on their core operations, enhance their compliance posture, and ultimately achieve greater business success.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <strong> Want to streamline your SBA loan process and reduce costs?   <Link to="/contact-us#contact_form" className="link">Contact us</Link>  today for a consultation.</strong>
                            </Typography>

                        </Box>

                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}