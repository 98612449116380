
import { Container, Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ESGDownload from '../../../../../../Component/forms/ESG_download';

import Preview from "../../../../../../asset/images/esg/esg_newsletter/january_newsletter.webp"
import ESGNewsletterBack from '../../../../../../Component/BackButtons/ESGNewsletterBack';
import { Helmet } from 'react-helmet';


export default function ESG_Newsletter_January() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/esg-newsletter-january-edition.pdf"


  return (
    <>

      <Helmet>
        <title>Decimal Point Analytics ESG Newsletter - January Edition  </title>
        <meta name="description" content="Stay updated on the latest ESG trends and insights for 2024 in our January edition newsletter. Explore analytical solutions for a sustainable future." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-january-edition" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top" justifyContent='center'>
            <Grid item lg={10} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Newsletter
                </Typography>
                <Typography variant='h4' className='blue al_center edition'>
                  January Edition
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Building Analytical Solutions for a Sustainable Future
                </Typography>

              </Box>
            </Grid>
          </Grid>


          <Box className='whitebx' mt={1}>

            <Grid container spacing={0} alignItems="flex-start">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='preview-box'>
                  <img loading="lazy"  src={Preview} className='preview-newsletter' alt='January Edition' />
                </Box>
              </Grid>
            </Grid>

            <Box mt={2} className='al_center'>
              <Typography variant="h4" className="black">
                Get Your Full Copy Now – Simply Fill Out the Form
              </Typography>
            </Box>

            <Box className='graybbx222' mt={2}>
              <ESGDownload reportby={"Building Analytical Solutions for a Sustainable Future [January Edition]"} link={link} />
            </Box>

          </Box>



          <ESGNewsletterBack />


        </Container>
      </Box>


    </>
  );
}

