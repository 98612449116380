import { Box, Container, Grid, Typography, } from '@material-ui/core'
import React from 'react';
import dots from '../../asset/images/crm/crm_success_stories/dots.webp'
import result from '../../asset/images/crm/crm_success_stories/result.png'
import crm from '../../asset/images/crm/crm_success_stories/crm.webp'
import star from '../../asset/images/crm/crm_success_stories/star.png'
import CRMDownloadCS from '../../Component/forms/CRMDownloadCS';


export const CRMSuccessStories = () => {
    return (
        <>
            <Box id='success-stories' className='section'>
                <Container>
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h2" className="black fw5 al_center">
                                    Our <span className='blue'> Success Stories</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='bl-bdr' mt={2}>

                        <Box>
                            <Typography variant="h3" className="black fw5 al_center" gutterBottom>
                                Advancing CRM Capabilities for a $41 Billion Investment Management Company
                            </Typography>
                        </Box>
                        <Grid container spacing={4} alignItems="top" justifyContent='center'>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                <Box className="mainbox">
                                    <img loading="lazy"  src={crm} className="parentimg" alt="Dots" />

                                    <img loading="lazy"  src={dots} className="childimg" alt="CRM" />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                {/* <Container maxWidth='md'> */}
                                    <Box className='storiesbx'>

                                        <Box className="line"></Box>

                                        <Box mt={3}>
                                            <Typography variant='h6' className='black'>
                                                A prominent asset management firm managing $41 billion sought to upgrade their CRM system to ensure superior data quality and smarter decision-making. We provided:
                                            </Typography>
                                        </Box>

                                        <Box className='bluebx'>
                                            <Box>
                                                <Box className='flx_fs'>
                                                    <Typography variant='h4' className='black fw6'>
                                                        What we are proud off
                                                    </Typography>
                                                    <Box ml={2}>
                                                        <img loading="lazy"  src={star} alt='star' />
                                                    </Box>

                                                </Box>

                                                <Grid container spacing={3} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <Box className='blinebx' mt={2}>
                                                            <Box className='bline1'></Box>
                                                            <Box className='bline2'></Box>
                                                            <Box ml={1}>
                                                                <Typography variant='h6' className='black'>
                                                                    <span className='blue'>Standardization and error correction</span> in their master database to boost data accuracy.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <Box className='blinebx' mt={2}>
                                                            <Box className='bline1'></Box>
                                                            <Box className='bline2'></Box>
                                                            <Box ml={1}>
                                                                <Typography variant='h6' className='black'>
                                                                    <span className='blue'>Development of interactive dashboards</span> for real-time insights into fundraising and investor portfolios.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <Box className='blinebx' mt={2}>
                                                            <Box className='bline1'></Box>
                                                            <Box className='bline2'></Box>
                                                            <Box ml={1}>
                                                                <Typography variant='h6' className='black'>
                                                                    <span className='blue'>Automation of workflows</span> to streamline data management and operational efficiency.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>

                                    </Box>
                                {/* </Container> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={4} alignItems="center" justifyContent='center'>
                            <Grid item lg={1} md={1} sm={2} xs={2}>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={result} alt='Result' className='result_img' />
                                </Box>
                            </Grid>

                            <Grid item lg={11} md={11} sm={10} xs={10}>
                                <Box>
                                    <Typography variant="h6" className="blue al_left">
                                        <span className='black'>Result: </span> The improvements have enabled the firm to leverage better data insights, enhance operational efficiency, and strengthen client relations, empowering them to make more confident business decisions.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>

                    <Grid container spacing={0} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center section2'>
                                {/* <Button
                                    variant="contained"
                                    className="dpabtn_2"
                                >
                                    Download Case Study
                                </Button> */}
                                <CRMDownloadCS  btn_name="Download Case Study"  className="dpabtn_2"/>
                            </Box>
                            
                        </Grid>
                    </Grid>

                </Container >
            </Box >

        </>
    )
}

