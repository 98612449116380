import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Banner1 from ".././../asset/images/newsroombanner/news_banner1.webp"
import Banner2 from ".././../asset/images/newsroombanner/news_banner2.webp"
import Banner3 from ".././../asset/images/newsroombanner/news_banner3.webp"

import logo1 from ".././../asset/images/newsroombanner/logo1.webp"
import logo2 from ".././../asset/images/newsroombanner/logo2.webp"
import logo3 from ".././../asset/images/newsroombanner/logo3.webp"




export default function NewsRoomBanner() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <h1>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research</h1>
        <title>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research </title>
        <meta name="description" content="Transform your business with Decimal Point Analytics. Our data analytics, AI consulting, and market research provide actionable insights for superior, data-driven decisions" />
        <meta name="keywords" content="data analytics, AI consulting, market research, data-driven decisions, customized solutions, actionable insights, Decimal Point Analytics" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "Decimal Point Analytics",
              "url": "https://www.decimalpointanalytics.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.decimalpointanalytics.com/search?q={search_term_string}https://www.decimalpointanalytics.com/insight",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>

      <Box id="new_landing_carousel" className="press-banner">
        <CarouselMulti
          responsive={responsive}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          autoPlay={true}
          pauseOnHover={false}
          keyBoardControl={true}
          // transitionDuration={1500}
          customTransition="opacity 200ms ease-in-out"
          autoPlaySpeed={9500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-0-px"
        >
   <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Banner3 + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={8} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design press-banner">

                    <Box>
                        <Typography variant="h6" className="black small-heading" gutterBottom>
                          FEATURED IN
                        </Typography>
                      </Box>

                      <Box>
                        <img loading="lazy"  src={logo3} alt="newsroom logo" />
                      </Box>

                      <Box className="fx_c">
                        <Box className="bltopBorder" my={'30px'} />
                      </Box>

                      <Typography variant="h1" className="blue fw7 mb0" gutterBottom>
                      Scarcity's Symphony
                      </Typography>

                      <Typography variant="h1" className="blue fw4 mb0" gutterBottom>
                      How Limitations Compose Innovation: Decimal Point Analytics
                      </Typography>

                      {/* <Box className="bottomBorder" mt={'40px'} /> */}
                      <Box mt={4}>
                        <Link to="/scarcitys-symphony-how-limitations-compose-innovation-decimal-point-analytics">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Read More
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>
          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Banner1 + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={7} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design press-banner">

                      <Box>
                        <Typography variant="h6" className="black small-heading" gutterBottom>
                          FEATURED IN
                        </Typography>
                      </Box>

                      <Box>
                        <img loading="lazy"  src={logo1} alt="newsroom logo" />
                      </Box>

                      <Box className="fx_c">
                        <Box className="bltopBorder" my={'30px'} />
                      </Box>

                      <Box>
                        <Typography variant="h1" className="blue fw4 mb0" gutterBottom>
                          <span className="fw7 "> Beyond Deepfakes:</span>
                        </Typography>
                      </Box>

                      <Typography variant="h1" className="blue fw4 mb0" gutterBottom>
                        How AI-Powered Cyber Threats Redefining Digital Warfare
                      </Typography>

                      {/* <Box className="bottomBorder" mt={'40px'} /> */}
                      <Box mt={4}>
                        <Link to="/beyond-deepfakes-how-ai-powered-cyber-threats-redefining-digital-warfare">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Read More
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Banner2 + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={7} md={9} sm={12} xs={12}>
                    <Box className="text_animated new-design press-banner">

                    <Box>
                        <Typography variant="h6" className="white small-heading" gutterBottom>
                          FEATURED IN
                        </Typography>
                      </Box>

                      <Box>
                        <img loading="lazy"  src={logo2} alt="newsroom logo" />
                      </Box>

                      <Box className="fx_c">
                        <Box className="topBorder" my={'30px'} />
                      </Box>

                      <Box>
                        <Typography variant="h1" className="white fw7 mb0" gutterBottom>
                          The Long Wave Ahead:
                        </Typography>
                      </Box>

                      <Typography variant="h1" className="white fw4 mb0" gutterBottom>
                        Re-<span className="lower">examining</span> Kondratieff’s Theory <span className="lower">in a</span> Multipolar World
                      </Typography>


                      {/* <Box className="bottomBorder" mt={'40px'} /> */}
                      <Box mt={4}>
                        <Link to="/the-long-wave-ahead-re-examining-kondratieffs-theory-in-a-multipolar-world">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Read More
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>
        </CarouselMulti>
      </Box>
    </>
  );
}