import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import award1 from "../../asset/images/landing_page/awards/award1.webp"
import award2 from "../../asset/images/aboutus/excellance_in_innovation.webp"
import award3 from "../../asset/images/landing_page/awards/award3.webp"
import db_2024 from "../../asset/images/landing_page/awards/db_2024.webp"

import ap1 from "../../asset/images/landing_page/awards/ap1.webp"
import ap2 from "../../asset/images/landing_page/awards/ap2.webp"
import ap3 from "../../asset/images/landing_page/awards/ap3.webp"

import RegTech from "../../asset/images/landing_page/awards/canadian-regtech.webp";


export default function HomeAwards() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Box className="section" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className="al_center">
                <Typography className="black" variant="h2">
                  Awards and Achievements
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Grid container spacing={0} alignItems="top" justifyContent="center">

              <Grid item className="w40">
                <Link to="/awards#listed-in-db">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={db_2024} alt="award1" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Leading SMEs of <br /> India 2024</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#canadian-regtech-award">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={RegTech} alt="RegTech" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Canadian RegTech <br /> 2023</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={award1} alt="award1" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">ISV of the Year <br />2023</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#excellence-in-innovation">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={award2} alt="award2" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Excellence in <br />Innovation 2023</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#featured-in-db">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={award3} alt="award1" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Leading SMEs of <br />India 2022</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#mark-tech-hackthon">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={ap1} alt="Appreciation" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Mark-Tech <br />Hackathon</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#graham-bell-awards">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={ap2} alt="Appreciation" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Top Innovative <br />Company</Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item className="w40">
                <Link to="/awards#indian-sme-of-the-year">
                  <Box className="al_center" pt={1} pb={1}>
                    <img loading="lazy"  src={ap3} alt="Appreciation" className="awards_img" />
                  </Box>
                  <Box>
                    <Typography className="al_center awd_ttl">Indian SME of the <br />Year -2021</Typography>
                  </Box>
                </Link>
              </Grid>

            </Grid>
          </Box>
        </Container>
      </Box>

    </>
  );
}
