import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
  import React, { useEffect, useState } from "react";
  import Aos from "aos";
  import "aos/dist/aos.css";
  import "owl.carousel/dist/assets/owl.carousel.css";
  import "owl.carousel/dist/assets/owl.theme.default.css";
  import { Link } from "react-router-dom";
  import LazyLoad from "react-lazyload"; // Make sure to install react-lazyload: npm install react-lazyload
  import Case_studies_Data from "./Case_studies_Data";
  import { Helmet } from "react-helmet";
  import { Autocomplete, ListItemButton } from "@mui/material";
  
  // Function to get unique categories from data
  const getUniqueCategories = (data) => {
    const categorySet = new Set();
    data.forEach((story) => {
      story.categories.forEach((category) => categorySet.add(category));
    });
    const categories = Array.from(categorySet)
      .sort((a, b) => {
        if (a === "Other") return 1;
        if (b === "Other") return -1;
        return a.localeCompare(b);
      })
      .map((category) => ({ label: category, value: category }));
    return [{ label: "All", value: "ALL" }, ...categories];
  };
  
  export default function Case_studies() {
    useEffect(() => {
      Aos.init({ duration: 1000 });
    }, []);
  
    const [selectedCategory, setSelectedCategory] = useState("ALL");
    const [visibleStories, setVisibleStories] = useState(6);
    const categories = getUniqueCategories(Case_studies_Data);
  
    // Filter stories based on selected category
    const filteredStories =
      selectedCategory === "ALL"
        ? Case_studies_Data
        : Case_studies_Data.filter((story) =>
            story.categories.includes(selectedCategory)
          );
  
    const handleLoadMore = () => {
      setVisibleStories((prev) =>
        Math.min(prev + 6, filteredStories.length)
      );
    };
  
    return (
      <>
        <Helmet>
          <title>Case Studies | Decimal Point Analytics </title>
          <meta
            name="description"
            content="Explore our case studies showcasing real world applications of innovative solutions. See how our expertise drives tangible business success."
          />
          <link
            rel="canonical"
            href="https://www.decimalpointanalytics.com/case-studies"
          />
        </Helmet>
  
        <Box
          className="sec_bg"
          component="section"
          style={{ paddingTop: "3%" }}
        >
          <Container maxWidth="lg" className="bdr">
            <Box>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center" mt={5}>
                    <Typography variant="h1" className="black newh1">
                      Success Stories
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center synopsic_bx" mb={3}>
                    <Typography variant="h6" className="black jst" gutterBottom>
                      At the heart of our success lie the triumphs of our clients,
                      where strategic brilliance meets and harmonizes with
                      forward-thinking progress.
                    </Typography>
                    <Typography variant="h6" className="black jst">
                      Our client success stories are not just tales of achievement;
                      they are blueprints for navigating the complexities of the
                      financial world and unlocking remarkable outcomes.
                    </Typography>
                    <Typography variant="h6" className="black jst">
                      Through meticulous analysis and an unwavering commitment to
                      excellence, we've empowered our clients to turn challenges
                      into actionable strategies, propelling their businesses to new
                      heights of success.
                    </Typography>
                    <Typography variant="h6" className="black jst">
                      Explore these compelling narratives and witness the
                      transformative power of collaboration & innovation.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
  
        <Box className="sticky_top_case_study">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box className="success_story alltxfieldlg w_bg">
                  <Autocomplete
                    disablePortal
                    options={categories}
                    value={categories.find(
                      (option) => option.value === selectedCategory
                    )}
                    onChange={(event, newValue) =>
                      setSelectedCategory(newValue ? newValue.value : "ALL")
                    }
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Solution" variant="outlined" />
                    )}
                    renderOption={(props, option) => (
                      <ListItemButton {...props}>{option.label}</ListItemButton>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
  
        <Box className="reportinfo sec_bg" pb={5} style={{ paddingBottom: "3%" }}>
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              {filteredStories.slice(0, visibleStories).map((item, index) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                  <LazyLoad height={300} offset={100} once>
                    {item.linking === "external" ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                      >
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                          </Box>
                          <Box className="line"></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              variant="h6"
                              className="black jst thr"
                              title={item.subheading}
                            >
                              {item.subheading}
                            </Typography>
                          </Box>
                        </Box>
                      </a>
                    ) : (
                      <Link to={item.link}>
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                          </Box>
                          <Box className="line"></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              variant="h6"
                              className="black jst thr"
                              title={item.subheading}
                            >
                              {item.subheading}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    )}
                  </LazyLoad>
                </Grid>
              ))}
            </Grid>
  
            {visibleStories < filteredStories.length && (
              <Box mt={4} textAlign="center">
                <Button
                  variant="contained"
                 className="dpabtn_1"
                  onClick={handleLoadMore}
                >
                  Load More
                </Button>
              </Box>
            )}
          </Container>
        </Box>
      </>
    );
  }
  