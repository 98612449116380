import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { Suspense, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import emp1 from "../asset/images/landing_page/emp1.webp"
import emp2 from "../asset/images/landing_page/emp2.webp"

import { IconButton } from "@mui/material";
// import GetInTouch from "../Component/Get_in_touch";
import ModalVideo from "react-modal-video";
// import HomeServices from "./LandingSections/HomeServices";
// import HomeResources from "./LandingSections/HomeResources";
// import HomeAwards from "./LandingSections/HomeAwards";
// import HomeOurStrength from "./LandingSections/HomeOurStrength";

// import HomeInnovationSecond from "./LandingSections/HomeInnovationSecond";
import AIFinance from "../asset/images/landing_page/banners/AIFinance.webp"
import Bridge from "../asset/images/landing_page/banners/bridge_lending.webp"
import BgMagazine from "../asset/images/landing_page/banners/new_era.webp"
import Finance from "../asset/images/landing_page/banners/finance.webp"


const HomeServices = React.lazy(() => import("./LandingSections/HomeServices"));
const HomeInnovationSecond = React.lazy(() => import("./LandingSections/HomeInnovationSecond"));
const HomeResources = React.lazy(() => import("./LandingSections/HomeResources"));
const HomeAwards = React.lazy(() => import("./LandingSections/HomeAwards"));
const HomeOurStrength = React.lazy(() => import("./LandingSections/HomeOurStrength"));
const GetInTouch = React.lazy(() => import("../Component/Get_in_touch"));


export default function Index_new() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  const [isOpenAbt, setIsOpenAbt] = useState(false);
  const openModalAbt = () => {
    setIsOpenAbt(true);
  };


  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <h1>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research</h1>
        <title>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research </title>
        <meta name="description" content="Transform your business with Decimal Point Analytics. Our data analytics, AI consulting, and market research provide actionable insights for superior, data-driven decisions" />
        <meta name="keywords" content="data analytics, AI consulting, market research, data-driven decisions, customized solutions, actionable insights, Decimal Point Analytics" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "Decimal Point Analytics",
              "url": "https://www.decimalpointanalytics.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.decimalpointanalytics.com/search?q={search_term_string}https://www.decimalpointanalytics.com/insight",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>

      <Box id="new_landing_carousel">
        <CarouselMulti
          responsive={responsive}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          autoPlay={true}
          pauseOnHover={false}
          keyBoardControl={true}
          customTransition="opacity 200ms ease-in-out"
          autoPlaySpeed={9500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-0-px"
        >

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + AIFinance + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design">
                      <Box className="topBorder" my={'30px'} />
                      <Typography variant="h2" className="white fw4 mb0" gutterBottom>
                        What’s Next<br />
                        <span className="fw7">
                          <span className="lower">for </span>
                          Agentic AI
                          <span className="lower"> in </span>
                          Finance?
                        </span>
                      </Typography>
                      <Box className="bottomBorder" mt={'40px'} />
                      <Box mt={4}>
                        <Link to="/whitepaper/agentic-ai-in-financial-services">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Discover the Insights
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + BgMagazine + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design">
                      <Box className="topBorder" my={'30px'} />
                      <Typography variant="h2" className="white fw4 mb0" gutterBottom>
                        A New Era of
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="white fw4" gutterBottom>
                          <span className="fw7">Bridge Lending with AI</span>
                        </Typography>
                      </Box>
                      <Box className="bottomBorder" mt={'40px'} />
                      <Box mt={4}>
                        <Link to="/whitepapers/technology-driven-bridge-lending">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Explore Now
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Finance + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design">
                      <Box className="topBorder" my={'30px'} />
                      <Typography variant="h2" className="white fw4 mb0" gutterBottom>
                        Future of <span className="fw7">Finance</span>:<br />
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="white fw4" gutterBottom>
                          Predictive <span className="fw7">Insights</span>
                        </Typography>
                      </Box>
                      <Box className="bottomBorder" mt={'40px'} />
                      <Box mt={4}>
                        <Link to="/whitepapers/predictive-intelligence-in-financial-services-preparing-for-the-future">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Access Here
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Bridge + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated new-design">
                      <Box className="topBorder" my={'30px'} />
                      <Typography variant="h2" className="white fw4 mb0" gutterBottom>
                        Simplify <br /> <span className="fw7">Bridge Lending</span>
                      </Typography>
                      <Box className="bottomBorder" mt={'40px'} />
                      <Box mt={4}>
                        <Link to="/bridge-lending-solutions">
                          <Button variant="contained" className='dpabtn_sm2'>
                            Get Started
                          </Button>
                        </Link>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

        </CarouselMulti>
      </Box>
      <Box id="mainpg">

        <Box id="emp" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={6} md={6} sm={4} xs={12} >

                <Box className="mainbox">
                  <img loading="lazy" src={emp1} className="fold21img" alt="Dash1" />

                  <Box className="image-container">
                    <img loading="lazy" src={emp2} className="slideimg childimg" alt="Dash2" />
                    <IconButton className="pulse-button playbtn" onClick={openModalAbt}></IconButton>
                  </Box>
                </Box>

              </Grid>

              <Grid item lg={6} md={6} sm={8} xs={12} >
                <Box className="content">
                  <Typography variant="h2" className="black">
                    Empowering Financial Success Through Advanced Analytics And Innovation
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    Welcome to Decimal Point Analytics, your trusted partner in innovative data analytics, AI, and technology solutions. Since 2003, we have been at the forefront of driving client success on a global scale. Our strength lies in crafting unique solutions that seamlessly integrate AI, Machine Learning, and Automation, ensuring an efficient and effective journey through diverse landscapes.
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    With a broad range of services – from full range of data management to detailed business intelligence dashboards, advanced data models to predictive analytics – we consistently anticipate and navigate market dynamics. Our commitment is to catalyze your enduring success by transforming challenges into opportunities through continuous innovation.
                  </Typography>
                  <Link to='/contact-us'>
                    <Typography variant="h6" className="blue jst fw5">
                      Discover how Decimal Point Analytics can fuel your financial trajectory today!
                    </Typography>
                  </Link>
                  <Box className="line"></Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className='section2 section_mob sec_bg' component='section' mt={4}>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">

                <Box className='al_center'>
                  <Typography variant='h4' className='skblue'>
                    DPA Mantras
                  </Typography>
                </Box>

                <Box className='al_center' mt={1}>
                  <Typography variant='h5' className='blue fw6'>
                    D<span className='lblue fw5'>eliver</span>P<span className='lblue fw5'>erformance</span>A<span className='lblue fw5'>dvantage</span>
                  </Typography>
                </Box>

                <Box className='al_center' mt={3}>
                  <Link to='/decimal-sutras'>
                    <Button variant="contained" className='dpabtn_sm1'>
                      Learn More
                    </Button>
                  </Link>
                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>


        <Suspense fallback={
          <>Loading...</>
        }>
          <HomeServices />
          <HomeInnovationSecond />
          <HomeResources />
          <HomeAwards />
          <HomeOurStrength />
          <Box component="section" >
            <GetInTouch />
          </Box>
        </Suspense>


      </Box>

      <ModalVideo
        channel="youtube"
        isOpen={isOpenAbt}
        videoId="bUs3X9dJNbo"
        onClose={() => setIsOpenAbt(false)}
      />
    </>
  );
}