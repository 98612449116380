import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function EconomyThatWorks() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Economic Insights from the Federal Reserve Bank of New York Briefing</title>
                <meta name="description" content="Decimal Point Analytics’ Nilanjan Das attended the Economic Press Briefing, gaining insights into key economic trends, labor market dynamics, and financial conditions shaping investment strategies." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/an-economy-that-works-for-all-measurement-matters" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    An Economy That Works for All: Measurement Matters
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>
                            Nilanjan Das, Executive VP of Business Development at Decimal Point Analytics, attended the <em>Economic Press Briefing: Regional Economic Outlook</em> hosted by the Federal Reserve Bank of New York.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event featured insights from leading economists, with opening remarks by President John C. Williams, addressing key economic trends, labor market dynamics, and financial conditions shaping both regional and national outlooks.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event aimed to explore critical economic indicators and their implications for financial services and investment strategies.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            At <Link to="/contact-us#contact_form" className='link'>Decimal Point Analytics</Link>, we are committed to harnessing data-driven insights to empower financial institutions with the clarity and confidence needed to navigate an ever-changing economic landscape.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
