import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import privacy from "../../../asset/images/new_banner/privacy_banner.webp";

export default function SmartCloudPolicy() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Privacy Policy
                                </Typography>


                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img loading="lazy"  src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

      <Box className="banner_bx">
        <Box
          style={{ backgroundImage: "url(" + privacy + ")" }}
          className="banner_img"
        >
          <Box className="img_over bl_overlay">
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={4} md={5} sm={6} xs={8}>
                <Box className="al_center">
                  <Typography className="white" variant="h1">
                    Privacy Policy
                  </Typography>
                  {/* <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">&ensp;</Typography>
                                    </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="section2 sec_bg" component="section">
        {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Privacy Policy Statement
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <Box>
                <Typography variant="h6" className="black jst">
                  Decimal Point Analytics Private Limited (Decimal Point)
                  respects and is committed to protecting your privacy. We have
                  created this Privacy Statement to disclose what happens with
                  the personal information you provide to us on the Decimal
                  Point web site{" "}
                  <a
                    href="http://decimalpointanalytics.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    www.decimalpointanalytics.com{" "}
                  </a>
                </Typography>

                <Typography variant="h6" className="black jst">
                  You can view our web site without divulging any personal
                  information about yourself. However certain content on our web
                  site requires the submission and the transmittal of personal
                  information such as your name, phone number, email address,
                  mailing address, research requirements, company name, country
                  and designation. Submitting your information on these online
                  forms is purely optional and you may choose to do so at your
                  discretion. In addition, Decimal Point may collect certain
                  information that is not visibly entered, like your site usage
                  pattern, routing and technical information to facilitate your
                  usage of this site.
                </Typography>

                <Typography variant="h6" className="black jst">
                  From time to time, we may use customer information for new,
                  unanticipated uses not previously disclosed in our privacy
                  notice. However, at no time, however, will Decimal Point,
                  sell, trade, and rent or distribute personal information to
                  any outside organization.
                </Typography>

                <Typography variant="h6" className="black jst">
                  With respect to cookies, we do not set any cookies.
                </Typography>

                <Typography variant="h6" className="black jst">
                  Decimal Point uses your information to provide you with the
                  services or information you have requested or we believe that
                  might be useful to you. We limit access to personal
                  information about you to employees who we believe reasonably
                  need to come into contact with that information to provide
                  products or services to you or in order to do their jobs. We
                  have appropriate security measures in place in our physical
                  facilities to protect against the loss, misuse or alteration
                  of information that we have collected from you at our site.
                </Typography>

                <Typography variant="h6" className="black jst">
                  Decimal Point claims trademark rights to all the trade marks
                  that may be listed by this website, also which may not be
                  explicitly listed. THESE MARKS ARE NOT TO BE USED WITHOUT THE
                  EXPRESS WRITTEN PERMISSION OF Decimal Point. Other trademarks
                  and service marks are the property of their respective owners.
                </Typography>

                <Typography variant="h6" className="black jst">
                  Decimal Point may update this policy. We will notify you about
                  significant changes in the way we treat personal information
                  by placing a prominent notice on our site.
                </Typography>

                <Typography variant="h6" className="black jst">
                  Please see our{" "}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    {" "}
                    Terms of Use
                  </Link>{" "}
                  to understand our policies on the usage of this web site.
                </Typography>

                <Typography variant="h6" className="black jst">
                  By using the Decimal Point Site, you agree to the collection
                  of information submitted by you, and its transmittal and
                  storage thereafter as per the terms described in this Privacy
                  Policy.
                </Typography>

                <Typography variant="h6" className="black jst">
                  For questions about copyrights, terms of use, privacy policy
                  or trademarks, please contact us at{" "}
                  <a
                    href="mailto:info@decimalpointanalytics.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@decimalpointanalytics.com
                  </a>
                </Typography>
              </Box> */}
            <Box id="eazydata">
              <Container maxWidth="lg">
                <Box className="jst">
                  <Typography className="pagecontent jst">
                    The Policy was last updated on ___18-04-2023___.
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    Introduction
                  </Typography>
                </Box>

                <Typography className="pagecontent jst">
                  This Privacy Policy is in continuation of the Terms of Service
                  on our Platform (as defined below) and shall be read with the
                  same. Please note that all the capitalized terms used in this
                  Policy (as defined herein-below) and not defined herein shall
                  have the same meaning as ascribed to it in Terms of Service
                  available at &nbsp;
                  <a
                    href="saas.decimalpointanalytics.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    saas.decimalpointanalytics.com/terms
                  </a>{" "}
                  <strong> (“Terms of Service”). </strong>
                </Typography>

                <Typography className="pagecontent jst">
                  Decimal Point Analytics Private Limited (Decimal Point), a
                  private company incorporated under provisions of the Companies
                  Act 2013 and having its registered office at 5A, B WING
                  TRADESTAR BUILDING, ANDHERI KURLA ROAD J.B NAGAR, ANDHERI EAST
                  MUMBAI Mumbai City MH IN 400059  ({" "}
                  <strong>Decimal Point Analytics, “We”, “Us” or “Our” </strong>{" "}
                  ) is a pioneering cloud-based web platform that enables its
                  customers and/or its authorised representatives and Affiliates
                  (collectively referred to as{" "}
                  <strong>
                    {" "}
                    “Clients”, “You”, “Your”, "Yourself" or “User”{" "}
                  </strong>{" "}
                  ), as the case may be, to use the services as provided in Our
                  Terms of Service. These Services are made available to You
                  through Our website{" "}
                  <a
                    href="https:// saas.decimalpointanalytics.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https:// saas.decimalpointanalytics.com
                  </a>{" "}
                  (<strong> “Platform”</strong>).
                </Typography>

                <Typography className="pagecontent jst">
                  This Privacy Policy (“Policy”) outlines Our practices and
                  policies, the kinds of information that We may collect from
                  You when You access or use the Platform, the reasons for and
                  methods for collection, how we use and disclose the
                  information that We gather from You for Your use of the
                  Platform, as well as security practices and procedures for
                  safeguarding Your privacy in relation to the information
                  shared with Us. You agree to be bound by the terms and
                  conditions of this Policy by accessing and using the Platform,
                  which will be deemed to create a legal contract between You
                  and Decimal Point Analytics.
                </Typography>

                <Typography className="pagecontent jst">
                  This Policy must also be read in conjunction with and
                  construed in accordance with the applicable laws of the
                  country from which You access and use the Platform, including
                  the provisions of the California Consumer Privacy Act of 2018,
                  EU General Data Protection Regulation 2016, the (Indian)
                  Information Technology Act, 2000 of India read with other
                  applicable rules and respective privacy laws of UAE, Qatar,
                  and Saudi Arabia. This Policy is officially recognised as an
                  electronic contract between Decimal Point Analytics and You.{" "}
                </Typography>

                <Typography className="pagecontent jst">
                  We reserve the right to amend, change or update this Policy at
                  our discretion. You agree to be bound by any updated policies
                  with Your continued access and/or use of Our Platform and
                  subsequently Our Services after any such changes have been
                  duly notified in any form to You. Any material changes to the
                  Policy will be notified to Your registered email address with
                  Us. We recommend that You shall update Your details with Your
                  active and correct email address with Us for smooth
                  communication. We will not be liable in case the registered
                  email is inactive, incorrect or not updated for any claims
                  regarding and not limited to communication gaps, liabilities
                  and legal claims with regard to this Policy.
                </Typography>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    1. Definations
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    In compliance with jurisdictional compliances, We have
                    provided an exhaustive list of information referenced in
                    this Policy.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    The term <strong> "Account" </strong> shall have the same
                    meaning as has been provided under the Terms of Service.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> “Affiliate”, </strong> shall mean any other Person
                    that, either directly or indirectly, is controlled by You,
                    or vice versa;
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> "Non-personal Identification Information" </strong>{" "}
                    shall include but is not limited to, browser name, the type
                    of computer and technical information about Your means of
                    connection to the Platform such as the operating system, the
                    internet service providers utilized and other similar
                    information used in conjecture with the access or use of the
                    Platform and subsequently the Services available thereof.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> “Person” </strong> shall mean and include any
                    natural person, limited or unlimited liability company,
                    corporation, general partnership, limited partnership,
                    proprietorship, trust, union, association, court, tribunal,
                    agency, governmental authority, ministry, department,
                    commission, self-regulatory organization, board, or other
                    entity, enterprise, authority, or business organization.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> “Personal Identifiable Information” </strong>{" "}
                     shall include such information which shall be voluntarily
                    shared by You, in any format whereby such information shall
                    be deemed to ascertain a Person’s identity, or can be
                    reasonably ascertained, from the information or opinion
                    including, but not limited to, when You visit the Platform
                    and conduct any such activity whereby You shall be required
                    to share such information, regardless of whether the
                    information provided is true or not. Further, the
                    information provided by You shall be inclusive of but not
                    limited to the information such as name, email address,
                    phone number, and residential address disclosed by You
                    pursuant to creation of an Account, use or access of the
                    Platform and further the Services. In the event that, You do
                    not consent to share Your Personal Identification
                    Information with Us, then You shall be freely allowed to do
                    so, but it may prevent Us from providing complete and full
                    access and/or use of the Platform and the Services thereof.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.6{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong>
                      {" "}
                      "Sensitive personal data or information (SPDI)"{" "}
                    </strong>{" "}
                    shall mean to include information relating to You, which
                    directly or indirectly, is capable of identifying You
                    including but not limited to:
                    <ol type="i">
                      <li> credentials for use of the Platform; </li>
                      <li> financial information; </li>
                      <li> any biometric information; and </li>
                      <li>
                        {" "}
                        any other information which is considered as a sensitive
                        personal data or information under the applicable law in
                        Your jurisdiction.{" "}
                      </li>
                    </ol>
                    Non-personal Identification Information, Personal
                    Identifiable Information and SPDI shall together be referred
                    to as <strong>"Information" </strong>.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    1.7{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> "Third Party" </strong> shall refer to any party
                    other than You and Decimal Point Analytics.{" "}
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    2. Information Collected
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    For the access and/or use of the Services, We have provided
                    a list of the Information which We shall collect from You:
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong>
                      {" "}
                      Information that You voluntarily provide to Us:{" "}
                    </strong>{" "}
                    his will include all the Information that You provide to Us
                    while You access and/or use the Platform, which will include
                    but shall not be limited to Information You might provide to
                    Us for the creation of Your Account, additional Information
                    provided during and/or for the provision of Services. It is
                    expressly clarified that We may also receive a confirmation
                    when You open an email from us.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong>
                      {" "}
                      Information collected from Third-Party Sites:{" "}
                    </strong>{" "}
                    You may also register for an Account using Your Microsoft or
                    Google or similar Third Party accounts and log-in
                    credentials (Your "Third-Party Site Account"). As part of
                    the functionality of the Platform, You may link Your Account
                    with Third Party Site Accounts, by either: (i) providing
                    Your Third Party Site Account login Information to Us
                    through the Platform or (ii) allowing Us to access Your
                    Third Party Site Account, as is permitted under the
                    applicable terms and conditions that govern Your use of each
                    Third Party Site Account. It shall be Your responsibility to
                    read and update Yourself with the policies of Third Party
                    Site Account from time to time.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Referrals: </strong> If any Person has referred any
                    of Our Services to You through any of Our referral programs,
                    that Person may have provided Us your name, email address
                    and other Information. You may contact Us at{" "}
                    <a
                      href="mailto:privacy@decimalpointanalytics.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      privacy@decimalpointanalytics.com{" "}
                    </a>{" "}
                    to request that We remove your Information from Our
                    database. If You provide Us Information about another
                    Person, or if another Person gives Us Your Information, We
                    will only use that Information for the specific reason for
                    which it was provided to Us.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong>
                      {" "}
                      Information from our reselling partners and service
                      providers:{" "}
                    </strong>{" "}
                    If You contact any of Our reselling partners, or otherwise
                    express interest in any of Our services to them, the
                    reselling partner may pass Your name, email address, company
                    name and other Information to Decimal Point Analytics. If
                    You register for or attend an event that is sponsored by
                    reselling partners, the event organizer may share Your
                    Information with Us. Decimal Point Analytics may also
                    receive Information about You from review sites if You
                    comment on any review of Our Services, and from other
                    Third-Party service providers that We engage for marketing
                    Our Services.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Cookie Policy: </strong> A comprehensive cookie
                    policy has been prepared by Decimal Point Analytics and the
                    same is available at{" "}
                    <a
                      href="https:// saas.decimalpointanalytics.com/cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      https:// saas.decimalpointanalytics.com/cookies
                    </a>
                    .
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.6{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Sensitive Personal Data: </strong> For the sake of
                    accuracy in providing services on the Platform, You may
                    additionally be asked to reveal or affirm certain SPDI.
                    Depending on how You engage with the Platform, different
                    types of Information may be gathered from You. Decimal Point
                    Analytics promises to protect the Information You supply
                    while adhering to reasonable security policies and
                    procedures and any relevant privacy laws.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    The following is the data that may be collected from You
                    based upon Your usage and access of the Platform:
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.7{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Aggregated Data: </strong> Decimal Point
                    Analytics may perform research on its customers'
                    demographics, interests, locations, and usage patterns of
                    the Services based on the SPDI and other Information
                    submitted to Us in a continuing attempt to better understand
                    and serve the Users of the Services. We may aggregate this
                    research and use it for any reason, including but not
                    limited to, distributing the results to Our affiliates,
                    agents, and business partners. Your personal Information is
                    not contained in this aggregate data. To describe Our
                    Services to existing and potential business partners as well
                    as to other Third Parties for other legitimate objectives,
                    We may also divulge aggregated user statistics.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.8{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Device and Usage Information: </strong>  While You
                    use the Service, We might gather data about Your device.
                    Device type, device family, operating system, carrier,
                    unique device id, time zones, browser type, version and
                    language are some examples of this data. We might keep track
                    of the things You do when using the Service. These details
                    may include the date and time of logins, the features used
                    or visited, latitude, longitude, and searches conducted
                    through the Service.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.9{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Push Notifications: </strong>  In relation to Your
                    Account or the Platform, We might ask if We can send You
                    push notifications. You can choose to either accept or
                    disable these communications in Your device's settings if
                    You prefer not to receive them.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    2.10{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Verification of Information: </strong>  We at
                    certain times require You to provide additional Information
                    for confirmation of Your identity for the access to Your own
                    Information as previously may have been provided to Us. Upon
                    the exercise of the right to remove any and all Information
                    provided to Us by You, We shall ask for additional
                    Information for verification purposes.
                  </Typography>
                </Box>

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    3. Use of SPDI and other Collected Information
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    3.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    We use the Information We collect in various ways, including
                    but not limited to:
                    <ol type="a">
                      <li>Provide, operate, and maintain Our Services;</li>

                      <li>Improve, personalize, and expand Our Services;</li>

                      <li>Understand and analyse how You use Our Services;</li>

                      <li>
                        {" "}
                        Develop new products, services, features, and
                        functionality;
                      </li>

                      <li>
                        {" "}
                        Communicate with You, either directly or through one of
                        Our partners, including for customer service, to provide
                        You with updates and other Information relating to the
                        Service, and for marketing and promotional purposes;
                      </li>

                      <li> Process Your transactions;</li>

                      <li> Send You text messages and push notifications;</li>

                      <li> Find and prevent fraud; </li>

                      <li>
                        {" "}
                        For compliance purposes, including enforcing Our Terms
                        of Service, or other legal rights, or as may be required
                        by applicable laws and regulations or requested by any
                        judicial process or governmental agency.
                      </li>
                    </ol>
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    3.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Decimal Point Analytics adheres to this Policy while using
                    the SPDI You supply. If You give Us any SPDI for a
                    particular reason, We will use it in connection with and
                    limited to such particular reason. For instance, if you
                    email Us any issue, we'll use the Personal Information
                    (i.e., your email address) to get back to you and see if we
                    can fix Your concern.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    3.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    In some cases, We may also have a legal obligation to
                    collect SPDI from You or may otherwise need the SPDI to
                    protect Your vital interests or those of another Person.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    3.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    If We ask You to provide SPDI to comply with a legal
                    requirement or to perform a contract with You, We will make
                    this clear at the relevant time and advise You whether the
                    provision of Your SPDI is mandatory or not (as well as of
                    the possible consequences if You do not provide Your SPDI).
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    3.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    From time to time for the provision of Services and
                    ancillary business objectives as mentioned under the Terms
                    of Service, We may engage certain Third-Party vendors and/or
                    partners. These Third-Party vendors and/or partners will
                    have access to Your Information and You hereby provide Us
                    consent to provide Your Information for the purposes as
                    mentioned hereinabove. You additionally agree and
                    acknowledge that it shall be Your responsibility to read and
                    update yourself with the term of use and privacy policy of
                    all Third-Party vendors and/or partners to understand how
                    they use and/or process Your Information.{" "}
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    4. Disclosure of Collected Information
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Decimal Point Analytics hereby represents that it does not
                    sell or involve itself in businesses related to selling any
                    Information. The exceptions are listed as follows:
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    4.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Business Transfers: </strong> Your Information may
                    be shared in the diligence process with counterparties and
                    others assisting with the transaction and transferred to a
                    potential acquirer, successor, or assignee as part of any
                    proposed merger, acquisition, debt financing, sale of
                    assets, or similar transaction, or in the event of
                    insolvency, bankruptcy, or receivership in which Information
                    is transferred to one or more Third Parties as one of our
                    business assets. SPDI may be included in the transferred
                    assets in the event of a corporate sale, merger,
                    reorganisation, dissolution, or another similar event.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    4.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Service Requirements: </strong> The employees,
                    business associates and any other Third Parties shall be
                    provided Information as provided by You for the fulfilment
                    of the Services as shall be required under the Terms of
                    Service.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    4.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> Requirement under applicable law: </strong> The
                    Information may be disclosed to any Person, if such
                    disclosure is required for a lawful purpose connected with a
                    function or activity of Platform. This may include but not
                    limited to -
                    <ol type="i">
                      <li>
                        satisfying any applicable law, regulation, legal
                        process, or governmental request;
                      </li>
                      <li>
                        enforce this Privacy Policy and our Terms of Service,
                        including investigation of potential violations hereof;
                      </li>
                      <li>
                        detect, prevent, or otherwise address fraud, security,
                        or technical issues;{" "}
                      </li>
                      <li>respond to Your requests; or </li>
                      <li>
                        protect Our rights, property or safety of Our users and
                        the public. The Personal Information may be disclosed,
                        where the disclosure is necessary for compliance of a
                        legal obligation under applicable law.
                      </li>
                    </ol>
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    4.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong> User Content: </strong> Any public post that would
                    have been posted from Your Account on the Platform shall be
                    visible and used by Third Party which We do not control.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    4.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <strong>
                      {" "}
                      Information We Share When You Sign Up Through a Referral:{" "}
                    </strong>{" "}
                    If You sign up for Our Services through a referral from a
                    Third Party, We may share Information with Your referrer to
                    let them know that You used their referral to sign up for
                    Our Services.
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    5. Security
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    We aim to protect Your Information against unauthorised
                    access, unlawful alteration, disclosure, or destruction. We
                    also aim to maintain its security, integrity, and privacy.
                    To protect data and to aid in preventing unauthorised
                    access, We put in place the necessary physical, electronic,
                    and managerial safeguards. As no method of data transmission
                    or protection is 100% safe, We cannot guarantee perfect
                    security. Additionally, We are not liable for any security
                    lapses or the deeds of any Third Parties who obtain Your
                    Information, due to uncontrollable internal and external
                    factors.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Password protection is a crucial component of the security
                    of Your Information. You must take care not to reveal Your
                    Account login information to any other parties and must keep
                    Your login information, including Your password, private.
                    You are ultimately in charge of protecting the
                    confidentiality of Your Account login details, even though
                    We will use our best efforts to ensure the highest level of
                    security for Your information. If You suspect that someone
                    else knows Your password, or You discover unauthorised use
                    of Your Account, or You become aware of any other security
                    lapses, kindly let Us know right away.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    We restrict access to Information to those:
                    <ol type="i">
                      <li>
                        Third Parties who We need to fulfil their obligations
                        under this Policy, the Terms of Service and any other
                        agreements concluded with Us;
                      </li>
                      <li>
                        who have received the necessary and ongoing training on
                        how to process, handle, and care for Information; and{" "}
                      </li>
                      <li>
                        who are subject to confidentiality obligations as
                        required by applicable law.{" "}
                      </li>
                    </ol>
                    Decimal Point Analytics takes measures to make sure that
                    entities who have access to SPDI are trustworthy, capable
                    and regularly trained. All Our rules and practices match the
                    applicable guidelines issued and in line with the control,
                    and adulteration of data as well as operational, managerial
                    and technical procedures.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Decimal Point Analytics cannot guarantee security in
                    transfers and transactions of Your Information to Us through
                    the means of the internet. We suggest that You shall ensure
                    to take adequate safety practices to protect Your
                    Information, as transfer of information on and through the
                    internet is not fully secured. Any unauthorised use of Your
                    personal Information or any suspicious activity on Your
                    Account is not Our responsibility and cannot be held against
                    Us. By utilising Our Platform, You acknowledge and accept
                    that We are not responsible for any disclosure of Your
                    Information caused by transmission mistakes or unauthorised
                    acts of Third Parties.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    We retain SPDI that We collect from You where We have an
                    ongoing legitimate business and the need to do so (for
                    example, to provide You with a service which You have
                    requested or to comply with applicable legal, tax, or
                    accounting requirements). After completion of ongoing
                    legitimate business and / or in circumstance when We do not
                    require to process Your SPDI, We will either delete or
                    anonymize it or, if this is not possible (for example,
                    because Your SPDI has been stored in backup archives), then
                    We will securely store Your SPDI and isolate it from any
                    further processing until deletion is possible.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    5.6{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    We conduct internal testing and audits of Our privacy
                    precautions on a periodic basis.
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    6. Exclusions
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    6.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    <em>Minors and Users below the age of majority: </em> e
                    reserve the right to terminate accounts of minors, as
                    understood pursuant to the laws of Your jurisdiction, who
                    are using Our Services, if We believe that the environment
                    around Our platform is not safe for a minor, at our
                    discretion. Please do not submit any SPDI through the
                    Services if You are under the age of 13, as You will not be
                    allowed to utilise the Services. Please get in touch with Us
                    if you think a child under the age of 13 may have
                    provided Decimal Point Analytics with SPDI through the
                    Services, and We will do Our best to remove that data from
                    Our records.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    6.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Any SPDI that We collect other than SPDI collected through
                    the Services is not covered by this Policy. Any Unsolicited
                    Information(as defined below) that You send to Us by any
                    unauthorised method, is not covered by this Policy. This
                    includes, but is not limited to, information submitted
                    informally (collectively,{" "}
                    <strong>"Unsolicited Information"</strong>) and posted in
                    any public parts of the Services, such as forums, as well as
                    suggestions for brand-new products or improvements to
                    current ones. All Unsolicited Information will be regarded
                    as non-confidential, and We may be allowed to distribute,
                    disclose, and utilise it without restriction or credit.{" "}
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    7. Link to third Parties
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    7.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    It is expressly stated that this Policy covers only Our
                    Services provided through the Platform. Our Services may
                    link to other Third-Party websites or mobile applications
                    (the <strong> "Third-Party Applications" </strong>) that are
                    not under Our control or operation. Such websites and
                    Third-Party Applications are not covered by this Policy. The
                    privacy policies of any Third-Party Websites or Mobile
                    Applications are not Our responsibility and You shall be
                    independently responsible for reading and updating Yourself
                    with the terms and condition and privacy policy of such
                    Third-Party platforms.{" "}
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    7.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    As previously mentioned, You have the option to login
                    through the Third-Party Site such as Google , Microsoft etc.
                    Please be informed that by choosing to access Our Platform
                    through this Third-Party Sites and widgets, You will be
                    provided an option to share Your Information as contained
                    under those Third Party’s Site accounts and You have an
                    option to opt out from sharing such information baring such
                    information which is essential for Us to allow You access to
                    use Our Services and access Our Platform. It shall be Your
                    responsibility to update Yourself with the term of service
                    and privacy policy of the Third Party Site for the
                    protection of Your data.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    7.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    There may be links on the Platform to other programmes or
                    websites. You accept and recognise that these applications
                    and websites, with the exception of other platforms owned by
                    Us, are run / operated by Third Parties and are not under
                    Our control, affiliated with, or associated with Us unless
                    clearly stated. As a result, We have no control over the
                    privacy practises or policies of the software and websites
                    that can be accessed via links from this Platform. You
                    should get in touch with the organisations in charge of
                    those websites directly if You have any questions about the
                    privacy practises and policies of those Third Party
                    platforms.{" "}
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    8. Amending or Updating User Information
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You have the right to request access to the Information
                    which We have about You. Even though there may be some
                    exceptions, We shall grant access to the Information in
                    compliance with the relevant legislation. For allowing
                    access to Information, We reserve the right to charge a fee.
                    Additionally, We will make a good-faith effort to accurately
                    capture the data You give us, including any modifications.
                    You have the right to review, modify and update the
                    Information which We have on file about You. You may also
                    ask Us to remove any of Your SPDI that is unreliable,
                    irrelevant, or processed unlawfully in light of any
                    applicable laws. Your ability to inspect, correct, and
                    delete Your Information is also governed by any statutory
                    retention obligations as well as any applicable legislation.
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    9. Rights of Users
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    9.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You fully accept and agree that Your Information may be
                    processed in United States of America (or any other local or
                    foreign jurisdiction as per Our requirements) if You are
                    using Decimal Point Analytics's Services. Laws governing
                    privacy and data security may differ from or be less strict
                    than those in force in Your jurisdiction. You hereby give
                    Your explicit approval for this Policy to be followed in the
                    processing of your Information.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    9.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Users have various data protection rights with respect to
                    the Information about them that businesses and organisations
                    may gather under United Kingdom and European Union
                    legislation. We value openness and are dedicated to
                    upholding all of Our User’s privacy rights. Each User of Our
                    Services has the following rights:
                    <ol type="a">
                      <li>
                        <strong> The Right to Access: </strong> You have the
                        right to request copies of Your Information, subject to
                        a small fee charged by Us.
                      </li>

                      <li>
                        {" "}
                        <strong> The Right to Rectification: </strong> In the
                        event of any inaccurate or incomplete Information, You
                        have the right to rectification or completion of the
                        Information, as the case may be.
                      </li>

                      <li>
                        <strong> The Right to Erasure: </strong> Subject to
                        certain conditions, upon Your request, We allow You to
                        erase any Information as provided by You.
                      </li>
                      <li>
                        <strong> The Right to Restrict Processing: </strong>{" "}
                        Upon fulfilment of certain conditions, You can
                        restrict Decimal Point Analytics from processing Your
                        Information. Please be advised that the same may result
                        in the restriction of the complete services and access
                        to Our Platform.
                      </li>
                      <li>
                        <strong> The Right to Notification: </strong> You have
                        the right to be notified regarding any rectification or
                        erasure of SPDI or restriction of processing unless this
                        proves impossible or involves disproportionate effort.
                      </li>
                      <li>
                        <strong> The Right to Data Portability: </strong> You
                        have the right to request that Decimal Point
                        Analytics transfer the data that We have collected from
                        another organization, or directly to You, under certain
                        conditions.
                      </li>
                      <li>
                        <strong> The Right to Object to Processing: </strong>{" "}
                        You have the right to object to Decimal Point
                        Analytics processing of Your SPDI, under certain
                        conditions.
                      </li>
                      <li>
                        <strong>
                          {" "}
                          The Right to Certain Protections related to Automated
                          Individual Decision-making, including Profiling:{" "}
                        </strong>{" "}
                        You have the right not to be the subject to a decision
                        based solely on automated processing, including
                        profiling. However, You agree and acknowledge that
                        aforesaid right shall not be applicable to the
                        processing: (i) which is necessary to fulfil Our
                        obligations under the Terms of Service or this Policy,
                        (ii) is authorised by the local jurisdiction from where
                        You access or use Our Platform and (iii) is based upon
                        Your explicit consent.
                      </li>
                      <li>
                        If You choose to exercise any of the above rights, we
                        will aim to respond to all legitimate requests relating
                        to Your rights above within one month of receipt of your
                        request. Occasionally it may take us longer than one
                        month if your request is particularly complex or if you
                        have made several requests. Please send any requests to{" "}
                        <a
                          href="mailto:privacy@decimalpointanalytics.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          privacy@decimalpointanalytics.com{" "}
                        </a>{" "}
                      </li>
                      <li>
                        <strong> Right against Discrimination: </strong> Decimal
                        Point Analytics as a strict rule is against the
                        discrimination of any kind of all its Users.
                      </li>
                      <li>
                        <strong> Right to Limit use or Disclosure: </strong> You
                        shall have the right to Opt-out of sharing Your
                        Information with other associates and third parties of
                        Decimal Point Analytic. Please be advised that, limiting
                        the disclosure of Your Information, shall also limit Our
                        ability to provide You with Services to Our fullest
                        extent.
                      </li>
                      <li>
                        You are recognized to exercise Your rights as per the
                        applicable law of Your respective jurisdiction.{" "}
                      </li>
                    </ol>
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    9.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    The aforesaid rights are exercisable upon sending an
                    intimation to the data protection officer specified in
                    clause 11 below.{" "}
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    10. User Declarations
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You understand and have the knowledge that Information is
                    being collected.{" "}
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You understand and have the knowledge of the purpose for
                    which Your Information is being collected.{" "}
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.3{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You understand and have the knowledge of the intended
                    recipients of the Information.{" "}
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.4{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You understand that You have the option not to provide the
                    data or Information sought to be collected by Decimal Point
                    Analytic
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.5{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You understand that You have an option (while availing the
                    services of Decimal Point Analytics or accessing the
                    Platform or otherwise) to withdraw Your consent given
                    earlier to Decimal Point Analytics and You understand and
                    accept that such withdrawal of Your consent shall be sent in
                    writing and in such an event Decimal Point Analytics shall
                    have the right, at its discretion, to not provide the
                    Services for which the information was derived / collected.{" "}
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    10.6{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    You have read and understood this Privacy Policy and all
                    other policies governing the usage of the Platform.
                  </Typography>
                </Box>

                <Divider />

                <Box className="jst" pt={1}>
                  <Typography variant="h6" className="pagesubhead col1">
                    11. Grievance Redressal
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    11.1{" "}
                  </Typography>
                  <Typography className="pagecontent jst">
                    Grievances in relation to this Policy can be sent to Our
                    Data Protection Officer Mr. Narendra Joshi.
                  </Typography>
                </Box>

                <Box className="flx_fs">
                  <Typography className="pagecontent jst subpoints">
                    {" "}
                    11.2{" "}
                  </Typography>
                  <Typography className="pagecontent jst mobviewallign">
                    Grievance letters can be mailed at{" "}
                    <a
                      href="mailto:narendra.joshi@decimalpointanalytics.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      narendra.joshi@decimalpointanalytics.com.
                    </a>
                  </Typography>
                </Box>

                <Box className="flx_fs btmsec">
                  <Typography className="pagecontent jst">
                    BY AGREEING TO THE TERMS OF THIS POLICY, YOU AGREE TO OUR
                    PROCESSING OF YOUR PERSONAL INFORMATION FOR THE PURPOSES
                    GIVEN HEREIN.{" "}
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
