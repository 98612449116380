import { AppBar,  Toolbar,  IconButton,  Button, Box, Drawer, withStyles, } from '@material-ui/core';
import React from 'react';
import Scroll from './Scroll'
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

 import dpalogo from "../asset/images/dpa_main_logo.svg";

import CloseIcon from '@mui/icons-material/Close';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'; 
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

 


export default function SBAHeader() {

 
    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
          const stickyItemsHeight = 70;
          const offset = element.getBoundingClientRect().top - stickyItemsHeight;


          setTimeout(() => {
              window.scrollBy({ top: offset, behavior: 'smooth' });
          }, 50);
      }
  };

 

  const [state, setState] = React.useState({
  });

  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'right' && (event.key === 'right')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


 



  const [expanded, setExpanded] = React.useState('false');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);

  };

  const list = (anchor) => (

    <Box className='mobilemenuview' sx={{ width: 300 }} role="presentation">


      <Box mb={1} mt={1} mr={2} className='al_right'>
        <IconButton className='Close_btn' variant="contained" onClick={toggleDrawer(anchor, false)}>
          <CloseIcon fontSize='small' color='primary' />
        </IconButton>
      </Box>

      <Box >

        <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
          <AccordionSummary component={Link} to='/' aria-controls="panel0d-content" id="panel0d-header" onClick={toggleDrawer(anchor, false)}>
          <Link to="/sba#sbaservices" onClick={() => scrollToElement('sbaservices')}>
            <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`}>SBA Solutions</Button>
            </Link>
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary component={Link} to='/' aria-controls="panel1d-content" id="panel1d-header" onClick={toggleDrawer(anchor, false)}>
          <Link to="/sba#sbabenefit" onClick={() => scrollToElement('sbabenefit')}>
            <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`}>Why DPA</Button>
            </Link>
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary component={Link} to='/' aria-controls="panel2d-content" id="panel2d-header" onClick={toggleDrawer(anchor, false)}>
          <Link to="/sba#sbacontactus" onClick={() => scrollToElement('sbacontactus')}>
            <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`}>Contact us</Button>
            </Link>
          </AccordionSummary>
        </Accordion>  


      </Box>
    </Box >
  );



  const location = useLocation();

  const isActive = (paths) => {
    return paths.some(path => location.pathname === (path));
  };

  
  return (
    <div className="grow">
      <Scroll showBelow={250} />
      <AppBar position="fixed" className="appnavbar" alignItems="center" data-aos="fade-down">
        <Toolbar>

          <Box className='loggo0'> 
            <img loading="lazy"  src={dpalogo} className='dpalogo' alt='Decimal Point Analytics' />
          </Box>

          <div className="grow" />
          <div className="sectionDesktop desktopviewmenu sba-header" >

          <Link to="/sba#sbaservices" onClick={() => scrollToElement('sbaservices')}>
              <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`} startIcon={<TipsAndUpdatesOutlinedIcon />} >SBA Solutions</Button>
            </Link>

            <Link to="/sba#sbabenefit" onClick={() => scrollToElement('sbabenefit')}>
              <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`} startIcon={<InfoOutlinedIcon />}>Why DPA</Button>
            </Link>

            <Link to="/sba#sbacontactus" onClick={() => scrollToElement('sbacontactus')}>
              <Button disableRipple className={`menutt ${isActive(['/']) ? 'active_menu' : ''}`} startIcon={<CallOutlinedIcon />} >Contact us</Button>
            </Link> 



          </div>


          <Box className='mobileviewmenu'>
            {['right'].map((anchor) => (
              <React.Fragment>  

                <IconButton aria-label="delete" onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon fontSize="small" color='primary' />
                </IconButton>
                <Drawer
                  className='drawer'
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>

        </Toolbar>
      </AppBar>

    </div >



  );
}
