import { Container, Grid, Typography, Box, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';


import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import D_Sutra from "../../asset/images/aboutus/esg/d_sutra.svg";
import D_Sutra1 from "../../asset/images/aboutus/esg/learn.svg";
import D_Sutra2 from "../../asset/images/aboutus/esg/wow.svg";
import D_Sutra3 from "../../asset/images/aboutus/esg/holistic.svg";
import D_Sutra4 from "../../asset/images/aboutus/esg/creative.svg";
import D_Sutra5 from "../../asset/images/aboutus/esg/support.svg";

import sutras from "../../asset/images/new_banner/sutras_banner.webp"
import { Helmet } from 'react-helmet';



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function Decimal_sutras() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const isMobile = useMediaQuery('(max-width: 600px)');

    // Decimal Mantra
    // const [anchorEl, setAnchorEl] = React.useState(null);

    const [open1, setOpen1] = React.useState(false);
    const handleClickOpen1 = () => { setOpen1(true); };
    const handleClose1 = () => { setOpen1(false); };


    const [open2, setOpen2] = React.useState(false);
    const handleClickOpen2 = () => { setOpen2(true); };
    const handleClose2 = () => { setOpen2(false); };


    const [open3, setOpen3] = React.useState(false);
    const handleClickOpen3 = () => { setOpen3(true); };
    const handleClose3 = () => { setOpen3(false); };


    const [open4, setOpen4] = React.useState(false);
    const handleClickOpen4 = () => { setOpen4(true); };
    const handleClose4 = () => { setOpen4(false); };


    const [open5, setOpen5] = React.useState(false);
    const handleClickOpen5 = () => { setOpen5(true); };
    const handleClose5 = () => { setOpen5(false); };

    const [open6, setOpen6] = React.useState(false);
    const handleClickOpen6 = () => { setOpen6(true); };
    const handleClose6 = () => { setOpen6(false); };



    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 170;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };
    // sctoll to sutra

    return (
        <>
            <Helmet>
                <title>Decimal Point Analytics Mantra & Sutras </title>
                <meta name="description" content="Explore Decimal Sutras, our powerful aphorisms guiding organizational values and culture, developed collaboratively by our dedicated team." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-sutras" />
            </Helmet>


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + sutras + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={6} sm={7} xs={10}>

                                <Box className="al_center desktop-only">
                                    <Typography className="white" variant="h1">
                                        Decimal’s{' '}
                                        {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                            <Tooltip arrow
                                                title={
                                                    <React.Fragment>
                                                        <Typography variant='h6' className='black al_center'>
                                                            At Decimal Point Analytics, we call our guiding principle a 'Mantra' - a word deeply rooted in ancient
                                                            wisdom and focus. Just like in its original Sanskrit meaning, our Mantra is not merely a word but a living
                                                            embodiment of purpose, reflecting our unwavering commitment to{' '}
                                                            <span className='blue fw6'>D</span>eliver<span className='blue fw6'>P</span>erformance<span className='blue fw6'>A</span>dvantage for our clients.
                                                        </Typography>
                                                        <Typography className="black  al_center" variant="h6">
                                                            Our Mantra is not just about what we do - it’s about who we are.
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                placement='bottom'
                                                classes={{ tooltip: 'tooltip-mantra' }}
                                            >
                                                <span className='mantra' style={{ cursor: 'pointer', }}>Mantra </span>
                                            </Tooltip>
                                        ) : ( // On mobile, render the content without the Tooltip
                                            <>
                                                <span className='mantra' style={{ cursor: 'pointer', }} onClick={handleClickOpen6}>Mantra </span>
                                            </>
                                        )}


                                        & <span className='mantra' onClick={() => scrollToElement('sutra')}>Sutras</span>
                                    </Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Our Operating Principle</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 section_mob sec_bg' component='section' style={{ paddingTop: "2%" }}>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">
                            <Box className='al_center'>
                                <Typography variant='h5' className='blue fw6'>
                                    D<span className='lblue fw5'>eliver</span>P<span className='lblue fw5'>erformance</span>A<span className='lblue fw5'>dvantage</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section section_mob' component='section' style={{ paddingTop: "3%" }}>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">
                            <Box className='al_center' mt={2}>
                                <Typography variant='h2' className='black'>
                                    Why do we call our values Decimal Sutra?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={10} xs={10} data-aos="fassde-up">
                            <Box mt={1}>
                                <Typography variant='h6' className='black jst'>
                                    Sutra is an ancient Sanskrit word that if you translate it into English would be called an “Aphorism”. It is a short powerful saying full of meaning and wisdom that is relevant to life. Here too our values convey a strong spirit of our culture and will be a guiding light for how we think, behave, and interact with each other internally and externally with clients and suppliers. It will also be touching every segment – clients, stakeholders, planet, and us – the decimals that are central to these principles.
                                </Typography>


                                <Typography variant='h6' className='black jst'>
                                    Our organizational values were developed through extensive exercises and workshops involving employees from Mumbai, Nasik, and Gift City. Our leaders contributed their insights, but also emphasized that values are about how individuals collaborate within teams, with clients, and with each other. Therefore, it was important for everyone to share their thoughts on what should be our values.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section ourstrength  sec_bg section_mob' component='section' style={{ paddingTop: "3%" }}>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={2} alignItems="top" justifyContent='center'>

                        <Grid item lg={4} md={6} sm={8} xs={10}>
                            <Box id='sutra' className='al_center ' mb={3}>
                                <img loading="lazy"  src={D_Sutra} className='topbanner' alt='Heading' draggable="false" />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={2}>
                        <Grid container spacing={2} alignItems="top" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                    <Tooltip
                                        arrow
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' className='black al_center'>
                                                    Decimals Believe that Client’s Success is our Success.<br />We Create Wow Experiences for our Clients.<br />Earning and Keeping our Client’s Trust is Valued Above all.
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        placement='bottom'
                                        classes={{ tooltip: 'tooltip-mantra' }}
                                    >
                                        <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen1 : undefined}>
                                            <img loading="lazy"  src={D_Sutra2} className='topbanner' alt='Deliver Wow' style={{ maxWidth: '72%' }} draggable="false" />
                                            <Typography variant='h4' className='sutratxt'>
                                                deliver <FiberManualRecordIcon /> wow
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : ( // On mobile, render the content without the Tooltip
                                    <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen1 : undefined}>
                                        <img loading="lazy"  src={D_Sutra2} className='topbanner' alt='Deliver Wow' style={{ maxWidth: '72%' }} draggable="false" />
                                        <Typography variant='h4' className='sutratxt'>
                                            deliver <FiberManualRecordIcon /> wow
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                    <Tooltip arrow
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' className='black al_center'>
                                                    Decimals Pride Themselves in Taking a Fresh<br />Look at Problems. We Boldly Challenge Ourselves<br />and Each Other to Discover New Possibilities</Typography>
                                            </React.Fragment>
                                        }
                                        placement='bottom'
                                        classes={{ tooltip: 'tooltip-mantra' }}
                                    >
                                        <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen2 : undefined}>
                                            <img loading="lazy"  src={D_Sutra4} className='topbanner' alt='creative bold' style={{ maxWidth: '72%' }} draggable="false" />
                                            <Typography variant='h4' className='sutratxt'> Creative <FiberManualRecordIcon /> bold </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : ( // On mobile, render the content without the Tooltip
                                    <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen2 : undefined}>
                                        <img loading="lazy"  src={D_Sutra4} className='topbanner' alt='creative bold' style={{ maxWidth: '72%' }} draggable="false" />
                                        <Typography variant='h4' className='sutratxt'> Creative <FiberManualRecordIcon /> bold </Typography>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                    <Tooltip arrow
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' className='black al_center'>
                                                    Decimals are Active Learners.<br /> They Keep Learning and Growing to keep <br /> DPA and Clients Ahead</Typography>
                                            </React.Fragment>
                                        }
                                        placement='bottom'
                                        classes={{ tooltip: 'tooltip-mantra' }}
                                    >
                                        <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen3 : undefined}>
                                            <img loading="lazy"  src={D_Sutra1} className='topbanner' alt='Learn Stay Ahead' draggable="false" />
                                            <Typography variant='h4' className='sutratxt'> Learn <FiberManualRecordIcon /> Stay Ahead </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : ( // On mobile, render the content without the Tooltip
                                    <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen3 : undefined}>
                                        <img loading="lazy"  src={D_Sutra1} className='topbanner' alt='Learn Stay Ahead' draggable="false" />
                                        <Typography variant='h4' className='sutratxt'> Learn <FiberManualRecordIcon /> Stay Ahead </Typography>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                    <Tooltip arrow
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' className='black al_center'>
                                                    We Value and Respect Each other’s Point of View<br />and have Each Other’s Back. Yet We Never Shy Away<br />from Expressing our Views and Challenging Each other </Typography>
                                            </React.Fragment>
                                        }
                                        placement='bottom'
                                        classes={{ tooltip: 'tooltip-mantra' }}
                                    >
                                        <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen4 : undefined}>
                                            <img loading="lazy"  src={D_Sutra5} className='topbanner' alt='support listen' style={{ maxWidth: '78%' }} draggable="false" />
                                            <Typography variant='h4' className='sutratxt'> support <FiberManualRecordIcon /> listen </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : ( // On mobile, render the content without the Tooltip
                                    <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen4 : undefined}>
                                        <img loading="lazy"  src={D_Sutra5} className='topbanner' alt='support listen' style={{ maxWidth: '78%' }} draggable="false" />
                                        <Typography variant='h4' className='sutratxt'> support <FiberManualRecordIcon /> listen </Typography>
                                    </Box>
                                )}

                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                {!isMobile ? ( // Show Tooltip only if it's not a mobile device
                                    <Tooltip arrow
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' className='black al_center'>
                                                    In all our Decisions Decimals Balance the Value Creation<br />for its Clients and Shareholders while Making a Positive<br />Impact on Communities and the Ecosystem</Typography>
                                            </React.Fragment>
                                        }
                                        placement='bottom'
                                        classes={{ tooltip: 'tooltip-mantra' }}
                                    >
                                        <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen5 : undefined}>
                                            <img loading="lazy"  src={D_Sutra3} className='topbanner' alt='Holistic Responsible' style={{ maxWidth: '96%' }} draggable="false" />
                                            <Typography variant='h4' className='sutratxt'> holistic <FiberManualRecordIcon /> responsible </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : ( // On mobile, render the content without the Tooltip
                                    <Box className='al_center sutrabox' onClick={isMobile ? handleClickOpen5 : undefined}>
                                        <img loading="lazy"  src={D_Sutra3} className='topbanner' alt='Holistic Responsible' style={{ maxWidth: '96%' }} draggable="false" />
                                        <Typography variant='h4' className='sutratxt'> holistic <FiberManualRecordIcon /> responsible </Typography>
                                    </Box>
                                )}
                            </Grid>


                        </Grid>
                    </Box>
                </Container>
            </Box>




            <Dialog onClose={handleClose1} aria-labelledby="customized-dialog-title" open={open1} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose1}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={10} xs={12}>
                            <Box className='al_center sutrabox'>
                                <img loading="lazy"  src={D_Sutra2} className='topbanner' alt='Deliver Wow' style={{ maxWidth: '72%' }} draggable="false" />
                                <Typography variant='h4' className='sutratxt' gutterBottom> deliver <FiberManualRecordIcon /> wow </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center'>
                                    Decimals Believe that Client’s Success is our Success. We Create Wow Experiences for our Clients. Earning and Keeping our Client’s Trust is Valued Above all
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title" open={open2} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose2}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={10} xs={12}>
                            <Box className='al_center sutrabox'  >
                                <img loading="lazy"  src={D_Sutra4} className='topbanner' alt='creative bold' style={{ maxWidth: '72%' }} draggable="false" />
                                <Typography variant='h4' className='sutratxt'> Creative <FiberManualRecordIcon /> bold </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center'>
                                    Decimals Pride Themselves in Taking a Fresh Look at Problems. We Boldly Challenge Ourselves and Each Other to Discover New Possibilities
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose3} aria-labelledby="customized-dialog-title" open={open3} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose3}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={10} xs={12}>

                            <Box className='al_center sutrabox'>
                                <img loading="lazy"  src={D_Sutra1} className='topbanner' alt='Learn Stay Ahead' draggable="false" />
                                <Typography variant='h4' className='sutratxt'> Learn <FiberManualRecordIcon /> Stay Ahead </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center'>
                                    Decimals are Active Learners. They Keep Learning and Growing to keep DPA and Clients Ahead
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose4} aria-labelledby="customized-dialog-title" open={open4} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose4}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={10} xs={12}>

                            <Box className='al_center sutrabox'>
                                <img loading="lazy"  src={D_Sutra5} className='topbanner' alt='support listen' style={{ maxWidth: '78%' }} draggable="false" />
                                <Typography variant='h4' className='sutratxt'> support <FiberManualRecordIcon /> listen </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center'>
                                    We Value and Respect Each other’s Point of View and have Each Other’s Back. Yet We Never Shy Away from Expressing our Views and Challenging Each other
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose5} aria-labelledby="customized-dialog-title" open={open5} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose5}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={6} md={8} sm={10} xs={12}>

                            <Box className='al_center sutrabox'>
                                <img loading="lazy"  src={D_Sutra3} className='topbanner' alt='Holistic Responsible' style={{ maxWidth: '96%' }} draggable="false" />
                                <Typography variant='h4' className='sutratxt'> holistic <FiberManualRecordIcon /> responsible </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black al_center'>
                                    In all our Decisions Decimals Balance the Value Creation for its Clients and Shareholders while Making a Positive Impact on Communities and the Ecosystem</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            <Dialog onClose={handleClose6} aria-labelledby="customized-dialog-title" open={open6} className="c_modal modal" maxWidth="sm">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose6}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h4' className='blue fw6 al_center'>
                                    Decimal Mantra
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_center'>
                                    At Decimal Point Analytics, we call our guiding principle a 'Mantra'—a word deeply rooted in ancient wisdom and focus. Just like in its original Sanskrit meaning, our Mantra is not merely a word but a living embodiment of purpose, reflecting our unwavering commitment to  <span className='blue fw6'>D</span>eliver<span className='blue fw6'>P</span>erformance<span className='blue fw6'>A</span>dvantage for our clients.
                                </Typography>

                                <Typography className="black  al_center" variant="h6">
                                    Our Mantra is not just about what we do - it’s about who we are.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


        </>
    );
}
