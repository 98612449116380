import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';


import Reg1 from "../../../asset/images/insight/insight_pages/regaining_inr_position/regaining1.webp";
import Reg2 from "../../../asset/images/insight/insight_pages/regaining_inr_position/regaining2.webp";

export default function Regaining_inr_position() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>INR's Role for Foreign Trade & Its Impact on Economy" </h1>
                <title>Regaining INR's Rightful Position In Regional Trade</title>
                <meta name="description" content="INR's Potential from Local to Global: Reclaiming its Rightful Position of pre-independence era & using it for foreign trade for financial & economical success. " />
                <meta name="keywords" content="indian rupee
indian currency
indian rupee to dollar" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/regaining-inr-rightful-position-in-regional-trade" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Regaining INR's Rightful Position in Regional Trade
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Box className='al_left ' mt={4} mb={4}>
                            <img loading="lazy"  src={Reg1} className='topbanner3' alt='Regaining INR ' />
                        </Box>

                        <Typography variant='h6' className='black jst '>The Indian National Rupee (INR) played a key role in foreign trade transactions of Indian subcontinent and gulf region countries in the pre-independence era. INR was the currency of foreign trade for Qatar, Saudi Arabia, Oman, Kuwait, Bahrain, and Yemen till the late 1950’s. After that, the rupee lost traction with inward looking stance of India forcing all of these countries adopting their own fiat currencies and devaluation in the post-independence era as a result of failure of economic policies. </Typography>

                        <Typography variant='h6' className='black jst' >Fast forward to the ongoing Russia-Ukraine war and the subsequent obstacles in trade settlement with Russia, India is now preparing to settle import/export transactions in INR. The Reserve Bank of India (RBI), in its circular dated July 11, 2022, has announced plans to put in place additional arrangements for this.  </Typography>

                        <br />
                        <br />

                        <Divider />

                        <br />

                        <Typography variant='h4' className='black'> The Central Bank has permitted authorized dealer (AD) banks to open Rupee Vostro accounts of correspondent bank/s of the partner trading country, which will act as the key in settling trade obligations in INR. The arrangement will work like this</Typography>



                        <Typography variant='h6' className='black jst'>
                            <ul>
                                <li>Indian importers undertaking imports through this mechanism shall make payment in INR which shall be credited into the Special Vostro account of the correspondent bank of the partner country, against the invoices for the supply of goods or services from the overseas seller /supplier. </li>
                                <li>Indian exporters, undertaking exports of goods and services through this mechanism, shall be paid the export proceeds in INR from the balances in the designated Special Vostro account of the correspondent bank of the partner country.  </li>

                            </ul>
                        </Typography>

                        <Box className='al_left ' mt={4} mb={4}>
                            <img loading="lazy"  src={Reg2} className='topbanner3' alt='Regaining INR ' />
                        </Box>


                        <Typography variant='h6' className='black jst'>The Central Bank in the said circular has mentioned that the move has been taken to promote the growth of global trade with emphasis on exports from India and to support the increasing interest of the global trading community in the Indian Rupee. So, the objective is loud and clear! RBI not only wants to settle India’s foreign trade transactions with INR, but it wants other countries to adopt the Indian currency as well, especially in their external asset, liability mix. To this regard, eventually, the progress at GIFT City is the key.   </Typography>


                        <Typography variant='h6' className='black jst'>It is in India’s interest that the INR is used in foreign trade transactions not only by India but by other countries as well. After all, acceptance as an international trade currency upholds the position and strengthens the value of the currency, which is widely evident in case of the US Dollar. Despite all the obvious negatives like low growth, hardening inflation, leveraged government finances, negative interest rates, and hardening racial conflict in US cities, dollar continues to hold its value because it is still an international reserve currency. The US currency is upheld mainly because most other countries hold their forex reserves in US treasuries with belief that its clout in the foreign trade will salvage the currency in any situation!  </Typography>

                        <br />



                        <Typography variant='h6' className='black jst'>So, the RBI move seems to be a welcome step in the right direction! In the initial stages, we will be able to cover exchange rate risk to the extent of our INR denominated foreign trade and this in turn will lend much needed stability and traction to the Indian currency. In the subsequent stages, the stability factor can be leveraged to promote it to MENA, Eastern Africa, South and Southeast Asia. It is a need of hour, as, due to cultural preferences of a civilizational state like India, INR will not be weaponized as USD and EUR has been.   </Typography>




                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>



                    <Box className='pages'>
                        <Writemessage3 pageVisited="Regaining INRs Rightful Position in Regional Trade" />
                    </Box>




                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
