import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function DPAatIFSCA() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>IFSCA Summit 2.0: Expanding Global Finance with GIFT IFSC</title>
                <meta name="description" content="Our CEO, Shailesh Dhuri, participated in the IFSCA Public Sector Enterprises Summit 2.0 in New Delhi, discussing GIFT IFSC’s impact on global finance. Explore key takeaways on PSE opportunities, policy innovation, and financial expansion." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/ifsca-summit-gift-ifsc-finance" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at IFSCA Public Sector Enterprises Summit 2.0
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>
                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics' CEO, <strong>Shailesh Dhuri,</strong> participated in the <strong>IFSCA Public Sector Enterprises Summit 2.0</strong> held in New Delhi. Organized by the <strong>International Financial Services Centres Authority (IFSCA),</strong> the summit brought together senior government officials, industry leaders, and public sector enterprises to discuss the evolving role of <strong>GIFT IFSC</strong> as a global financial hub.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Key Discussions and Insights </strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event focused on critical aspects of international finance and the opportunities available for public sector enterprises (PSEs). Key discussions included:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Opportunities for Public Sector Enterprises (PSEs) in International Finance:</strong> Exploring how PSEs can leverage GIFT IFSC to access global markets and expand their financial operations.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Case Studies from CPSEs Utilizing the IFSC Platform:</strong> Real-world examples of Central Public Sector Enterprises (CPSEs) benefiting from the regulatory and financial ecosystem provided by IFSC.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Policy Frameworks Driving Financial Innovation:</strong> Insights into the regulatory advancements and frameworks that are shaping the future of financial services in India and beyond.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Looking Ahead</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The summit provided a platform for in-depth discussions on the strategic importance of IFSC in enhancing India's global financial presence. With a focus on policy innovation and new financial opportunities, the event highlighted the immense potential for PSEs to expand their footprint in international finance.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Want to know more about our services in GIFT City at IFSCA? Connect with our experts by filling out our form</strong> <Link to="/contact-us#contact_form" className='link'>here</Link>.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
