import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Writemessage3 from '../../Component/Writemessage3'
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Experience from "../../asset/images/innovation/bridge_lending/experience.webp";
import Operations from "../../asset/images/innovation/bridge_lending/operations.webp";
import AutomatedProcesses from "../../asset/images/innovation/bridge_lending/automated_process.webp";

import GetInTouch from '../../Component/Get_in_touch';

import { Helmet } from 'react-helmet';

import Banner from "../../asset/images/new_banner/bridge_lending_solutions.webp"
import efficiency1 from "../../asset/images/innovation/sba/efficiency1.png";
import efficiency2 from "../../asset/images/innovation/sba/efficiency2.png";
import efficiency3 from "../../asset/images/innovation/sba/efficiency3.png";

export default function BridgeLendingSolutions() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <title>Bridge Lending Solutions | Streamline Operations with Decimal Point Analytics</title>
                <meta name="description" content="Optimize bridge lending operations with expert risk management, seamless processes, and proven efficiency to meet market demand and stay ahead of competition." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/bridge-lending-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box className="banner_bx" mb={"2px"} >
                <Box style={{ backgroundImage: "url(" + Banner + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Bridge Lending Solutions</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Writemessage3 pageVisited="Data Solution" />

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Navigate <span className='lower'>the</span> Complexities <span className='lower'>of</span> Bridge Lending <span className='lower'>with</span> Ease
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    In today's fast-paced real estate market, bridge loans are essential for securing short-term financing, whether for acquisition, development, or refinancing. As the demand for these loans grows, so do the challenges: navigating market volatility, tight deadlines, and intricate regulatory requirements. Precision and expertise are paramount.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    At <Link to="/" className='link'>Decimal Point Analytics</Link>, we transform challenges into opportunities. With a proven track record in bridge loan processing, we enable lenders to meet market demands with confidence and efficiency.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Experience Speaks <span className='lower'>for</span> Itself
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" my={3}>
                                <img loading="lazy"  src={Experience} className="w100" alt="Our Experience Speaks for Itself" />
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Streamline Lending Operations <span className='lower'>with</span> Decimal Point Analytics
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='al_center'>
                        <Typography variant='h6' className='black al_center' gutterBottom>
                            DPA offer expertise for streamlinedhandling of Bridge loans from application to closing
                        </Typography>
                    </Box>

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" my={3}>
                                <img loading="lazy"  src={Operations} className="w100" alt="Streamline Lending Operations with Decimal Point Analytics" />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='al_center'>
                        <Typography variant='h6' className='black al_center' gutterBottom>
                            Our tailored solutions address the critical factors for bridge loan success: swift risk assessment, detailed collateral evaluation, and seamless operational processes.
                        </Typography>
                    </Box>

                </Container>
            </Box>

            <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Choose DPA?
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={whyCP} className='topbanner5' alt='Why Choose DPA?' />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box>
                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Expert Handling:  </strong> Robust SOPs and checklists ensure high-quality outputs.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Cost Efficiency:  </strong> Achieve up to 50% reduction in processing costs.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Quick Turnaround:   </strong> Onboarding completed within 2-3 days.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Optimized Workflows:   </strong> Automated CRM processes enhance productivity.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Global Reach:   </strong> Leverage time zones for 24/7 operational efficiency.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </Box>

            <Box id='bridge-lending-solutions' className='section sec_bg' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                    Transform Your Bridge Lending Operations
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={0}>
                                <Typography variant='h6' className='black al_center'>
                                In a competitive market, every decision matters. By partnering with DPA, you gain industry-leading support to:
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Box className='boxbg align_c flx_fs'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={efficiency1} className='allic' alt='EE3' />
                                </Box>
                                <Box ml={2}>
                                    <Typography variant='h6' className='black mb0  '>
                                        <strong>Mitigate Risks </strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Box className='boxbg align_c flx_fs'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={efficiency2} className='allic' alt='EE3' />
                                </Box>
                                <Box ml={2}>
                                    <Typography variant='h6' className='black mb0  '>
                                        <strong> Optimize Operations</strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Box className='boxbg align_c flx_fs'>
                                <Box className='al_center'>
                                    <img loading="lazy"  src={efficiency3} className='allic' alt='EE3' />
                                </Box>
                                <Box ml={2}>
                                    <Typography variant='h6' className='black mb0'>
                                        <strong> Deliver Reliable and Timely Financing</strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={11} md={11} sm={11} xs={11}>
                        <Typography className="black al_center" variant="h6">
                                <Link className='link' to='/contact-us#contact_form'>Contact us</Link> to see how we can help you streamline your bridge lending process and stay ahead of the competition.
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Explore <span className='lower'>our</span> Resources
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Box className="reportm_bg">
                                    <Box
                                        className="reportimg_bg"
                                        style={{
                                            backgroundImage: 'url(' + AutomatedProcesses + ')',
                                        }}
                                    >
                                        <Button variant="contained" className='ora_title_btn'>
                                            Case Study
                                        </Button>
                                    </Box>

                                    <Box my={2}>
                                        <Typography className="reporttitle blue">
                                            Achieving 60% Faster Processing Times in Bridge Lending with Automated Processes
                                        </Typography>
                                    </Box>
                                    <Box className="line"></Box>
                                    <Box mt={2}>
                                        <Link to="/case-studies/achieving-60-faster-processing-times-in-bridge-lending-with-automated-processes">
                                            <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                Read More
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className='sec_bg'>
                <GetInTouch />
            </Box>

        </>
    );
}
