import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import React, { useEffect, useState } from "react";
import get_proposal_formbg from "../../asset/images/crm/get_proposal_formbg0.webp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { crmFormApi } from "../api/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import CustomPhoneNumber from "../CMR_Streamlined_CustomePhoneNumber_Not_Mandatory";

const validationSchema = yup.object({
  fullname: yup
    .string()
    .required("Name is required")
    .matches(/^[A-Za-z\s'-]+$/, "Enter valid name"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/,
      "Email is not valid"
    ),
});

const CRMStreamlineForm = () => {
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValueError, setPhoneValueError] = useState("");
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    if (phoneValue && !isValidPhoneNumber(phoneValue)) {
      setPhoneValueError("Enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const payload = {
        name: data.fullname,
        email: data.email,
        contact_no: phoneValue,
        form_type: "Get Proposal Request",
      };
      await crmFormApi(payload);
      history.push("/crm-form-submit");
    } catch (error) {
      toast.error("Failed to submit the form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="section2">
        <Box
          id="get_proposal_formbg"
          className="section2"
          style={{ backgroundImage: "url(" + get_proposal_formbg + ")" }}
        >
          <Container maxWidth="lg">
            <Typography variant="h3" className=" fw5 black mb0">
              Streamline Your Business Operations Efficiently
            </Typography>
            <Typography variant="h4" className="fw4 black">
              Drop us a note and we will get back to you
            </Typography>

            <Box className="small_form" mt={3}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className="alltxfieldlg textfield1 ">
                      <TextField
                        label="Name*"
                        variant="filled"
                        fullWidth
                        {...register("fullname")}
                        error={!!errors.fullname}
                        helperText={errors.fullname?.message}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className="alltxfieldlg textfield1 ">
                      <TextField
                        label="Email Address*"
                        variant="filled"
                        fullWidth
                        {...register("email")}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className="alltxfieldlg textfield1">
                      <Box className="textfield1">
                      <PhoneInput
                            flags={flags}
                            international
                            name="phoneInput"
                            value={phoneValue}
                            onChange={(value) => {
                              setPhoneValue(value);
                              if (value && !isValidPhoneNumber(value)) {
                                setPhoneValueError(
                                  "Enter a valid phone number"
                                );
                              } else {
                                setPhoneValueError("");
                              }
                            }}
                            inputComponent={CustomPhoneNumber}
                          />
                      </Box>
                      <Typography style={{ color: "#f00", fontSize: "13px" }}>{phoneValueError}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className="alltxfieldlg textfield1">
                      <Button
                        variant="contained"
                        className="crmbtn_2"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Get Proposal"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default CRMStreamlineForm;
