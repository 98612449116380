import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_achieving_faster_approvals.webp"
import Infographics from "../../../../asset/images/insight/case_studies/optimized_loan_underwriting.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function AchievingFasterApprovals() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Achieving 40% Faster Approvals and Scalable Growth in SBA Lending</title>
                <meta name="description" content="Discover how a finance firm improved loan processing efficiency by 40%, achieving faster approvals, higher throughput, and 99% compliance accuracy." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/achieving-40-faster-approvals-and-doubling-application-throughput" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Achieving 40% Faster Approvals <span className='lower'> and</span> Doubling Application Throughput
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Underwriting delays and scalability limitations affected processing speed and client satisfaction. Advanced workflows, optimized loan processing, and refined underwriting strategies led to 40% faster approvals, doubled application throughput, and maintained 99% compliance accuracy, enabling scalable growth and stronger competitiveness.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm encountered various issues in the underwriting process:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Delayed Underwriting: </strong>   Complex and slow processes led to prolonged approval times, hindering client growth.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Strained Operational Efficiency: </strong>  High loan volumes put pressure on existing workflows, affecting processing speed.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Client Satisfaction at Risk: </strong>   Lengthy turnaround times negatively impacted on borrower satisfaction and retention.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Limited Competitiveness: </strong>  Slow approvals diminished the firm’s competitive edge in the lending market.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Optimized Loan Underwriting
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="w100" alt="Solutions: Optimized Loan Underwriting" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>40% Faster Approvals: </strong>  Optimized workflows reduced underwriting times significantly.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>2x Increase in Application Throughput: </strong>  Streamlined processes enabled handling of double the loan volume.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>99% Compliance Accuracy: </strong>  Consistent quality checks ensured regulatory adherence.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Efficiency: </strong>  Advanced workflows improved processing speed and scalability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Higher Client Satisfaction: </strong>  Faster approvals enhanced borrower engagement and satisfaction.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Scalable Growth: </strong>    Streamlined processes supported higher application throughput and compliance accuracy.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Faster approvals and increased scalability are possible with streamlined underwriting processes. Focus on optimizing workflows to ensure both speed and compliance, setting the foundation for sustainable growth and improved client service.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Ready to enhance your lending efficiency? Explore solutions to accelerate underwriting and achieve scalable growth with faster approvals.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}