import React, { useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { writeToUsApi } from "./api/api";
import "../Style.css";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field, Form, } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert as MuiAlert } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ProductCTAA(props) {
 
  const [loader, setLoader] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const [clickAgree, setClickAgree] = React.useState(true);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [checkboxError, setCheckboxError] = React.useState("");
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s]+$/;
  const organizationReg = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;

  const history = useHistory();

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    organization: "",
    message: "",
    tandc: true,
    tandce: true,
  };
  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .typeError("Required field")
      .required("First name is required")
      .matches(nameValidation, "Enter valid first name"),
    lastname: yup
      .string()
      .typeError("Required field")
      .required("Last name is required")
      .matches(nameValidation, "Enter valid last name"),
    email: yup
      .string()
      .typeError("Required field")
      .required("Email is required")
      .matches(emailreg, "Enter valid email"),
    organization: yup
      .string()
      .typeError("Required field")
      .required("Organization is required")
      .matches(organizationReg, "Enter valid organization name"),
    tandc: yup.bool().oneOf([true], "You must accept the terms and conditions").nullable(),
    tandce: yup.bool(),
  });

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === 'firstname') {
        setFirstNameError('First name should not contain numbers');
      } else if (fieldName === 'lastname') {
        setLastNameError('Last name should not contain numbers');
      }
    } else {
      if (fieldName === 'firstname') {
        setFirstNameError('');
      } else if (fieldName === 'lastname') {
        setLastNameError('');
      }
    }
  };

  const onSubmit = (values) => {
    let tad = false;
    if (values.tandce) {
      tad = true;
    } else {
      tad = false;
    }
    const formData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      organization: values.organization,
      page_visited: props.pageVisited,
      message: values.message,
      obtain_email_flag: tad,
    };
    setCheckboxError("");
    handleFormSubmit(formData);
  };

  const handleFormSubmit = (values) => {
    post(values);
  };

  const onSucccessCall = () => {
    //localStorage.setItem("isOpen", 0);
    setCheckboxFlag(false);
    history.push("/form-submit");
  };

  const post = async (formData) => {
    setLoader(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await writeToUsApi(formData);
      if (status === 200) {
        if (data.status === true) {
          onSucccessCall()
        } else {
          toast.error(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }
      }

    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
    setLoader(false);
  };

  const handleClickOpen = () => {
    localStorage.setItem("isOpen", 1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //localStorage.setItem("isOpen", 0);
  };
  const handleCheckboxClick = () => {
    setClickAgree(false);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Box id='product_cta' className={props.allignment}>
        <Box mt={'6px'} mr={1}>
          <Typography variant='h6' className='black'> <strong> {props.cta_content} </strong> </Typography>
        </Box>

        <Box>
          <Button variant="contained" className='dpabtn_sm1' onClick={handleClickOpen}>
            {props.cta_btn_name}
          </Button>
        </Box>
      </Box>


      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="b_modal teammodal"
      >
        <DialogTitle
          className="feedname"
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent>
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange={true}
            >
              {({ values, handleChange, handleBlur, handleSubmit, setFieldError, isSubmitting, touched, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic1"
                          label="First Name *"
                          variant="standard"
                          fullWidth
                          name="firstname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyUp={(event) => handleKeyUp(event, 'firstname')}
                          error={touched.firstname && Boolean(errors.firstname)}
                          helperText={
                            (touched.firstname && errors.firstname) ? errors.firstname : firstNameError
                          }
                          FormHelperTextProps={{ style: { color: 'red' } }}
                        />
                      </Box>
                    </Grid>


                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic2"
                          label="Last Name *"
                          variant="standard"
                          fullWidth
                          name="lastname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyUp={(event) => handleKeyUp(event, 'lastname')}
                          error={touched.lastname && Boolean(errors.lastname)}
                          helperText={
                            (touched.lastname && errors.lastname) ? errors.lastname : lastNameError
                          }
                          FormHelperTextProps={{ style: { color: 'red' } }}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic3"
                          label="Email Address *"
                          variant="standard"
                          fullWidth
                          name="email"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic4"
                          label="Organization Name *"
                          variant="standard"
                          fullWidth
                          name="organization"
                          error={touched.organization && Boolean(errors.organization)}
                          helperText={touched.organization && errors.organization}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg">
                        <Field
                          as={TextField}
                          id="filled-basic5"
                          label="Write your message"
                          variant="standard"
                          fullWidth
                          name="message"
                          multiline
                          rows={4}
 
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg chkbx">
                        <FormControlLabel
                          control={
                            <Field
                              defaultChecked={true}
                              as={Checkbox}
                              name="tandce"
                              color="primary"
                              size="small"
                            />
                          }
                          label="Subscribe to our Newsletter"
                        />
                      </Box>
                      <Box className="alltxfieldlg chkbx">
                        <FormControlLabel
                          control={
                            <>
                              <Field
                                defaultChecked={true}
                                as={Checkbox}
                                name="tandc"
                                color="primary"
                                size="small"
                              />
                            </>
                          }
                          label={<Typography className="label2">
                            By clicking submit, I agree to Decimal Point Analytics{" "}
                            <Link to="/terms">Terms of Use</Link> and{" "}
                            <Link to="/policy">Privacy Policy</Link>
                          </Typography>}

                        />

                      </Box>
                      <Box className="checkbx-error">
                        {touched.tandc && errors.tandc && <MuiAlert severity="error">{errors.tandc}</MuiAlert>
                        }
                      </Box>

                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="al_center" mt={3}>
                        {loader ? (
                          <LoadingButton loading={true} variant="outlined" disabled>
                            disabled
                          </LoadingButton>
                        ) : (
                          <Button
                            onClick={handleSubmit}
                            variant="contained"
                            className="dpabtn_1"
                          >
                            Send Message
                          </Button>
                        )}
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg graybbx" p={2}>
                        <Typography variant="h6" className="jst">
                          Decimal Point Analytics (DPA) will process the information
                          in this form to share information as requested. By checking
                          the above box you confirm your acceptance to receive the
                          communication.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>

          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

