import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import TC from "../../../asset/images/media/tech_circle.webp";
import MediaNEWS from "../../../asset/images/media/media_news_4u.webp";
import CXO from "../../../asset/images/media/cxo.webp";

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import DS from '../../../asset/images/team/dilip_sah.webp'

import { Link } from 'react-router-dom';

export default function DilipSahJoinsAsCTO() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Dilip Sah Appointed Chief Technology Officer (CTO) at Decimal Point Analytics</title>
                <meta name="description" content="Meet Dilip Sah, newly appointed CTO at Decimal Point Analytics, leveraging over 20 years in data, AI, and digital product development to drive innovation in financial analytics" />
                <meta name="keywords" content="Dilip Sah, CTO appointment, Decimal Point Analytics, data analytics, AI, digital product development" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/dilip-sah-joins-decimal-point-analytics-as-chief-technology-officer" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h2' className='mainhead blue al_center'>
                                    Dilip Sah Joins Decimal Point Analytics as Chief Technology Officer
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* <Box >
                        <img loading="lazy"  src={DS} className='w100' alt='Dilip Sah' />
                    </Box> */}

                    
                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>


                                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                                        <Grid item lg={3} md={4} sm={5} xs={12}>
                                            <Box >
                                                <img loading="lazy"  src={DS} className='w100' alt='Dilip Sah' />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={9} md={8} sm={7} xs={12}>
                                            <Box >
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Mumbai, India – June 5, 2024 – </strong><Link to="/" className='link'>Decimal Point Analytics</Link>, a leading financial research and analytics company, strengthens its leadership team with the appointment of Dilip Sah as Chief Technology Officer (CTO).
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    Dilip, a seasoned technology leader with over 20 years of experience, brings a proven track record in driving innovation and building high-performing teams.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    Dilip brings a wealth of expertise in data, AI, Cloud Strategy and Digital Product Development. He holds an <strong>impressive academic background, having graduated from the Indian Institute of Technology (IIT) Kanpur and the Indian Institute of Management (IIM) Ahmedabad</strong>. This solid educational foundation, combined with his extensive experience with fintech companies and Generative AI, positions him uniquely to drive our technological vision forward.
                                                </Typography>

                                                {/* <Typography variant='h6' className='black jst' gutterBottom>
                                                    Prior to joining Decimal Point Analytics, he held leadership roles at MSMEx, eGain, Vuclip, FIS, and Entercoms, spearheading technology, product development, data analytics, and marketing technology initiatives. <strong>He also founded TechLadd, a company focused on digital transformation through custom Generative AI solutions for enterprises</strong>. His experience includes fundraising, scaling operations, and leading innovation in machine learning and advanced architectures.
                                                </Typography> */}
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    {/* <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Mumbai, India – June 5, 2024 – </strong> - <Link to="/" className='link'>Decimal Point Analytics</Link>, a leading financial research and analytics company, strengthens its leadership team with the appointment of Dilip Sah as Chief Technology Officer (CTO).
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Dilip, a seasoned technology leader with over 20 years of experience, brings a proven track record in driving innovation and building high-performing teams.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Dilip brings a wealth of expertise in data, AI, Cloud Strategy and Digital Product Development. He holds an <strong>impressive academic background, having graduated from the Indian Institute of Technology (IIT) Kanpur and the Indian Institute of Management (IIM) Ahmedabad</strong>. This solid educational foundation, combined with his extensive experience with fintech companies and Generative AI, positions him uniquely to drive our technological vision forward.
                                    </Typography> */}

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Prior to joining Decimal Point Analytics, he held leadership roles at MSMEx, eGain, Vuclip, FIS, and Entercoms, spearheading technology, product development, data analytics, and marketing technology initiatives. <strong>He also founded TechLadd, a company focused on digital transformation through custom Generative AI solutions for enterprises</strong>. His experience includes fundraising, scaling operations, and leading innovation in machine learning and advanced architectures.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>"We are thrilled to welcome Dilip Sah to Decimal Point Analytics," said Shailesh Dhuri, CEO.</strong> "His deep understanding of the financial technology landscape, combined with his proven leadership and innovative mindset, will be instrumental in propelling our company forward. We are confident that under his guidance, we will continue to deliver best-in-class financial analytics solutions and empower our clients with the insights they need to make informed decisions."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        "I am excited to join Decimal Point Analytics and contribute to its ongoing success," <strong>said Dilip Sah</strong>. "I look forward to collaborating with the talented team here to leverage cutting-edge technologies that will provide our clients with even deeper insights and enhanced decision-making capabilities."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        As CTO, Dilip will be responsible for overseeing the development and implementation of advanced technologies that will further strengthen Decimal Point Analytics' product offerings and streamline its operations. His leadership will be crucial in fostering a culture of innovation and excellence within the company's technology team.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        For more information about Decimal Point Analytics, please visit <Link to="/" className='link'>Decimalpointanalytics.com</Link>
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://cxotoday.com/press-release/dilip-sah-joins-decimal-point-analytics-as-chief-technology-officer/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CXO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.medianews4u.com/decimal-point-analytics-names-dilip-sah-as-chief-technology-officer/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + MediaNEWS + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.techcircle.in/2024/06/03/decimal-point-analytics-appoints-new-cto" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
