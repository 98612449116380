import React from "react"; 
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'; 

import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function EventBack() {
    const history = useHistory();
    const location = useLocation();

    return (
        <Box className="al_center" mt={3}>
            <Button
                variant="contained"
                className="dpabtn_gr"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push("/event", { state: { year: location.state?.year || 2025 } })}
            >
                Back to Events
            </Button>
        </Box>
    );
}