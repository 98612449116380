import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Singularity1 from "../../asset/images/insight/singularity1.webp";
import Singularity2 from "../../asset/images/insight/singularity2.webp";
import Singularity3 from "../../asset/images/insight/singularity3.webp";
import Singularity4 from "../../asset/images/insight/singularity4.webp";
import { Helmet } from "react-helmet";

export default function Singularity() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>

      <Helmet>
        <title>Singularity Pulse - Latest AI Implementation Across Industries</title>
        <meta name="description" content="Discover the latest AI implementations across various industries with Singularity Pulse by Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/singularity" />
      </Helmet> 

      <Box
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h1" className="black mb0 newh1">
                  Singularity Pulse
                </Typography>
                <Typography variant="h6" className="black ">
                  A weekly curation of AI implementation across industries and domains.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="reportinfo">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/singularity%2Fsingularity_pulse_15112022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Nov 15, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 15 Nov, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/nov_22/singularity_pulse_08112022_Nov 8.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Nov 08, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 08 Nov, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/nov_22/singularity_pulse_01112022_Nov 1.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Nov 01, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 01 Nov, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/oct_22/singularity_pulse_25102022_Oct 25.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Oct 25, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 25 Oct, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/oct_22/singularity_pulse_18102022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Oct 18, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 18 Oct, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/oct_22/singularity_pulse_11-October-2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Oct 11, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 11 Oct, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/oct_22/singularity_pulse_04-October-2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Oct 04, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 04 Oct, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/sept_22/singularity_pulse_27092022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Sep 27, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 27 Sep, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/sept_22/singularity_pulse_20092022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Sep 20, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 20 Sep, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/sept_22/singularity_pulse_13092022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Sep 13, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 13 Sep, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/sept_22/singularity_pulse_07092022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Sep 07, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 07 Sep, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/aug_22/singularity_pulse_30082022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Aug 30, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 30 Aug, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/aug_22/singularity_pulse_23082022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Aug 23, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 23 Aug, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/aug_22/singularity_pulse_16082022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Aug 16, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 16 Aug, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/aug_22/singularity_pulse_02082022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Aug 02, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 02 Aug, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              {/* ======================== */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jul_22/singularity_pulse_26072022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jul 26, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 26 Jul, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jul_22/singularity_pulse_19072022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jul 19, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 19 Jul, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jul_22/singularity_pulse_12072022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jul 12, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 12 Jul, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jul_22/singularity_pulse_05 July 2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jul 05, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 05 Jul, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jun_22/singularity_pulse_28062022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jun 28, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 28 Jun, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jun_22/singularity_pulse_21062022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jun 21, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 21 Jun, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jun_22/singularity_pulse_14 June 2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jun 14, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 14 Jun, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jun_22/singularity_pulse_08 June 2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jun 08, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 08 Jun, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/may_22/singularity_pulse_31_May_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        May 31, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 31 May, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/apr_22/singularity_pulse_12_April_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Apr 12, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 12 Apr, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/apr_22/singularity_pulse_06042022_S_Q.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Apr 06, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 06 Apr, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/mar_22/singularity_pulse_29_March_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Mar 29, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 29 Mar, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/mar_22/singularity_pulse_22_March_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Mar 22, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 22 Mar, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/mar_22/singularity_pulse_15_March_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Mar 15, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 15 Mar, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/mar_22/singularity_pulse_08th_March_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Mar 08, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 08 Mar, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/mar_22/singularity_pulse_01_March_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Mar 01, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 01 Mar, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/feb_22/singularity_pulse_22_February_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Feb 22, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 22 Feb, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/feb_22/singularity_pulse_15_February_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Feb 15, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 15 Feb, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/feb_22/singularity_pulse_08_February_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Feb 08, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 08 Feb, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/feb_22/singularity_pulse_01_February_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity4 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Feb 01, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 01 Feb, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jan_22/singularity_pulse_25_January_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity1 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jan 25, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 25 Jan, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jan_22/singularity_pulse_18_January_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity2 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jan 18, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 18 Jan, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <a
                  href="https://decimalpointanalytics.com/media/decimalreport/singularity_pulse/jan_22/singularity_pulse_11_January_2022.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + Singularity3 + ")" }}
                    ></Box>

                    <Box mt={1} mb={0}>
                      <Typography variant="h6" className="skblue">
                        Jan 11, 2022
                      </Typography>
                    </Box>

                    <Box mt={0} mb={2}>
                      <Typography className="reporttitle one">
                        Singularity Pulse | 11 Jan, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
