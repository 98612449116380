import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'

import excessive1 from "../../../asset/images/insight/blog_pages/1excessive_monetory.webp";
import excessive2 from "../../../asset/images/insight/blog_pages/2excessive_monetory.webp";
import excessive3 from "../../../asset/images/insight/blog_pages/3excessive_monetory.webp";
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';

export default function Excessive_monetory() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Excessive Monetary Tightening in India as compared to OECD </h1>
                <title>Excessive Monetary Tightening in India as compared to OECD</title>
                <meta name="description" content="Compare India's monetary tightening to OECD countries and its impact on economic growth. Explore the implications of policy disparities on India's economy." />
                <meta name="keywords" content="India, monetary tightening, OECD, GDP deflator, policy rates, real interest rates, economic growth, inflation, relative comparison" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/excessive-monetory" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Excessive Monetary Tightening  in India as  compared to OECD
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                July 17, 2017
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h4' className='blue jst'>Singularity is the biggest threat</Typography>

                        <Typography variant='h6' className='black jst'>In this note we will see how behavior of RBI in recent years is proving a relative impediment to economic growth.</Typography>

                        <Typography variant='h6' className='black jst'>In order to analyze inflation, this author prefers to study behavior of GDP deflator over a period of three years. The chart below shows the excess of three year CAGR of Indian GDP deflator over OECD GDP deflator.</Typography>

                        <Typography variant='h4' className='blue jst'>Chart 1: Excess Indian GDP Deflator over OECD Deflator</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={excessive1} className='topbanner2' alt='Excessive1' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>The above measure has many advantages. Firstly, three year CAGR envelops and hence smoothens out the effect of vagaries of Indian monsoons. Secondly, by subtracting OECD GDP deflator from Indian GDP deflator, we remove effects of global commodity and energy super cycles, which affects all of the countries to differing extents. Thus, what we are left with is only the structural constraints in Indian economy at given level growth of economic activity leading to inflation. As clearly shown by the chart, in last three years, as the new administration took over and removed obstacles for commerce, especially in farm sector, the excess GDP deflator has shown a clear downward trend in India, showing that, possibly, other things being equal, the Indian economy has moved to a permanent relative lower inflation trajectory as long as current administrative policies remain in force.</Typography>

                        <Typography variant='h6' className='black jst'>Now, let us see how the policy rates in India have responded, on relative basis to this long term, secular, structural (and possibly sustainable) softening of inflation in India. The chart below shows Indian Central Bank policy rates minus average OECD policy rates at the end of each year.</Typography>


                        <Typography variant='h4' className='blue jst'>Chart 2: Excess Indian Policy Rates over OECD</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={excessive2} className='topbanner2' alt='Excessive2' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>If we subtract Chart 1 from Chart 2, we will get relative real interest rates in India as compared to OECD, as shown in the chart below.</Typography>

                        <Typography variant='h4' className='blue jst'>Chart 3: Relative Real Interest Rates in India as compared to OECD</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img loading="lazy"  src={excessive3} className='topbanner2' alt='Excessive3' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>The last chart clearly shows that, on relative basis, Indian central bank was somewhat accommodative in 2010 with negative relative interest rates, and by the end of 2016, RBI had moved to extreme tight monetary policy with real rates nearing four percentage points on relative basis.</Typography>

                        <Typography variant='h6' className='black jst'>In our view, in normal, middle of business and economic cycle, there is no economic logic for the relative real rates between two countries (or groups of countries) to vary beyond +/-0.50%. In other words, it is possible that history will determine that current monetary policy in India is too tight by three to four percentage points as compared to OECD.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Excessive Monetary Tightening  in India as compared to OECD" />
                    </Box>



                    <Blogback />


                </Container>
            </Box>


        </>
    );
}
