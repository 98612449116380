import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  // Custom style for the first column
  indicatorColumn: {
    width: "40%", // Adjust the width as needed
  },
});

function createData(data) {
  return data;
}
const planIcon = (isIncluded) => {
  return isIncluded ? (
    <CheckIcon className="check-ico" />
  ) : (
    <HorizontalRuleIcon className="dash-ico" />
  );
};
const rows = [
  createData({
    indicator: "Monthly active rows (MAR)",
    free: "200,000",
    standard: "3,000,000",
    managed: "3,000,000",
  }),
  createData({
    indicator: "Per additional 1 million rows",
    free: false,
    standard: "₹849",
    managed: "₹4199",
  }),

  createData({
    indicator: "Workflows",
    free: "10",
    standard: "Unlimited",
    managed: "Unlimited",
  }),
  createData({
    indicator: "Rules",
    free: "Limited",
    standard: "Unlimited",
    managed: "Unlimited",
  }),
  createData({
    indicator: "Users",
    free: "1",
    standard: "Unlimited",
    managed: "Unlimited",
  }),
  createData({
    indicator: "Integrations",
    free: "All",
    standard: "All",
    managed: "All",
  }),
  createData({
    indicator: "Governance",
    free: "Error logs",
    standard: "Audit logs, error logs and role based access controls",
    managed: "Audit logs, error logs and role based access controls",
  }),
  createData({
    indicator: "Customer support",
    free: "Best efforts basis",
    standard: "4-hour response time + schedule a meet",
    managed: "1-hour response time + schedule a meet",
  }),
  createData({
    indicator: "Support coverage",
    free: "24*7 Mon-Fri ET",
    standard: "24*7 Mon-Fri ET",
    managed: "24*7 Mon-Fri ET",
  }),
  createData({
    indicator: "Guided automation and solution architecture support",
    free: "Self service",
    standard: "Self service",
    managed: "Yes, on-going basis",
  }),
  createData({
    indicator: "Account management",
    free: "Self service",
    standard: "Self service",
    managed: "Yes",
  }),
  createData({
    indicator: "Support channels available",
    free: "Email + Form",
    standard: "Live chat +Email + Form + Slack/Teams",
    managed: "Live chat +Email + Form + Slack/Teams",
  }),
];

export default function SmartCloudTerms() {
  const classes = useStyles();

  return (
    <>
      <Box id="mui-table">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.indicatorColumn}>
                  {" "}
                  Features{" "}
                </TableCell>
                <TableCell className="centered_data"> FREE TRAIL </TableCell>
                <TableCell className="centered_data">
                  {" "}
                  Do it Yourself{" "}
                </TableCell>
                <TableCell className="centered_data">
                  Eazy Collaboration
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.indicator}>
                  <TableCell component="th" scope="row">
                    {" "}
                    {row.indicator}{" "}
                  </TableCell>
                  <TableCell className="centered_data">
                    {typeof row.free !== "boolean"
                      ? row.free
                      : planIcon(row.free)}
                  </TableCell>
                  <TableCell className="centered_data">
                    {typeof row.standard !== "boolean"
                      ? row.standard
                      : planIcon(row.standard)}
                  </TableCell>
                  <TableCell className="centered_data">
                    {typeof row.managed !== "boolean"
                      ? row.managed
                      : planIcon(row.managed)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
