import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import cloudServices from "../../asset/images/landing_page/services/cloud_services.webp"
import data_analytics from "../../asset/images/landing_page/services/data_analytics.webp"
import ai_ml from "../../asset/images/landing_page/services/ai_ml.webp"
import fund_management from "../../asset/images/landing_page/services/fund_management.webp"
import esg_solutions from "../../asset/images/landing_page/services/esg_solutions.webp"
import SBA from "../../asset/images/landing_page/services/sba.webp"
import CMS from "../../asset/images/landing_page/services/cat_modeling_services.webp"
import investor from "../../asset/images/landing_page/services/investor_relations.webp"

import ProductCTAA from '../../Component/ProductCTAA'
import { Link } from "react-router-dom";


export default function HomeServices() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



  return (
    <>

      <Box id="services" className="section" component="section">
        <Container maxWidth="lg" className="bdr">

          <Box>
            <Grid container spacing={1} alignItems="top" justifyContent="center">
              <Grid item lg={9} md={10} sm={12} xs={12} >
                <Box className="al_center">
                  <Typography variant="h2" className="black mob_left">
                    Our Comprehensive Suite of Services
                  </Typography>
                  <Typography variant="h6" className="black mob_jst">
                    At DPA, we specialize in bespoke projects, ensuring every solution—from research to CRM—is perfectly aligned with your distinct business needs, enabling us to adapt to any project and customize it for optimal results.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={3} className="desk_view_only">
            <Grid container spacing={2} alignItems="top" justifyContent="center">

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/artificial-intelligence-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={ai_ml} className="service_img" alt="AI-ML and Automation Offerings" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        AI-ML and Automation
                        <br />
                        Offerings
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/catastrophe-modeling-services">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={CMS} className="service_img" alt="Catastrophe Modelling Services" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Catastrophe Modelling <br /> Services
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/cloud-services">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={cloudServices} className="service_img" alt="Cloud Services" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Cloud
                        <br />
                        Services
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/business-intelligence-solution">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={data_analytics} className="service_img" alt="Data Analytics & Business Intelligence" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Data Analytics &
                        <br />
                        Business Intelligence
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={esg_solutions} className="service_img" alt="ESG Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        ESG<br /> Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/fund-management-and-private-equity-support-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={fund_management} className="service_img" alt="Fund Management & PE Support Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Fund Management &
                        <br />
                        PE Support Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/investor-relations-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={investor} className="service_img" alt="Investor Relationship Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Investor Relationship <br />Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/small-business-administration">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={SBA} className="service_img" alt="Small Business Administration" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Small Business
                        <br />
                        Administration
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

            </Grid>
          </Box>


          <Box mt={2} className="mob_view_only">
            <Grid container spacing={2} alignItems="top" justifyContent="center">

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/artificial-intelligence-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={ai_ml} className="service_img" alt="AI-ML and Automation Offerings" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        AI-ML and Automation Offerings
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/catastrophe-modeling-services">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={CMS} className="service_img" alt="Catastrophe Modelling Services" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Catastrophe Modelling Services
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/cloud-services">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={cloudServices} className="service_img" alt="Cloud Services" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Cloud Services
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/business-intelligence-solution">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={data_analytics} className="service_img" alt="Data Analytics & Business Intelligence" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Data Analytics & Business Intelligence
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={esg_solutions} className="service_img" alt="ESG Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        ESG Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/fund-management-and-private-equity-support-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={fund_management} className="service_img" alt="Fund Management & PE Support Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Fund Management & PE Support Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/investor-relations-solutions">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={investor} className="service_img" alt="Investor Relationship Solutions" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Investor Relationship Solutions
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to="/small-business-administration">
                  <Box className="al_center services-card">
                    <Box className='img_box'>
                      <img loading="lazy"  src={SBA} className="service_img" alt="Small Business Administration" />
                    </Box>
                    <Box mt={2}>
                      <Typography className="blue fw5" variant="h6">
                        Small Business Administration
                      </Typography>
                    </Box>
                    <Box className="flx_center" mt={1}>
                      <Box className="line"></Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>

            </Grid>
          </Box>

          <Box className="mt40">
            <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today!" pageVisited="Artificial Intelligence" />
          </Box>

        </Container>
      </Box>

    </>
  );
}
