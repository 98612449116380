import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core';


import FAQ from "../../../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function RobopulseFaq() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>


      <Container maxWidth='lg' className='bdr'>


        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image'  mt={4}>
              <img loading="lazy"  src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box className='al_left'>
              <Typography variant='h2' className='black'>
                <strong>  FAQ </strong>
              </Typography>
            </Box>

            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> What is RoboPulse? </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    RoboPulse's API-driven Robo-advisory engine automates financial planning with tailored portfolios and intelligent recommendations, helping users reach their financial goals effortlessly.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How does RoboPulse work? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    RoboPulse utilizes advanced algorithms to analyze various asset classes, risk profiles, and historical performance data. It then generates precise investment recommendations and constructs portfolios tailored to each user's specific needs and goals.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Is RoboPulse suitable for businesses of all sizes? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, RoboPulse caters to businesses of all sizes, offering customized solutions that align with their unique investment requirements. Whether you're a small startup or a large corporation, RoboPulse can help optimize your investment strategy. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How does RoboPulse handle market volatility? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    RoboPulse is designed to manage market volatility effectively. It automatically rebalances portfolios based on predefined goals and risk appetite, ensuring investments stay aligned with the desired targets, even in turbulent market conditions. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Can I track the performance of my portfolio? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, RoboPulse provides regular performance monitoring. It reviews the progress of your recommended portfolio over different timeframes and compares it to relevant benchmarks, providing you with valuable insights for informed decision-making.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How can I get started with RoboPulse? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Getting started with RoboPulse is easy. Simply sign up on our platform and provide the necessary information to personalize your investment strategy. Our intuitive interface will guide you through the onboarding process, enabling you to begin your intelligent investing journey.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Can I manage my portfolio without meeting financial advisors? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Absolutely! RoboPulse offers hassle-free portfolio management, allowing you to conveniently track and manage your investments directly through our user-friendly platform. You have the flexibility to adjust and stay in control without the need for frequent meetings with financial advisors.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Is my personal and financial information secure with RoboPulse? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    At RoboPulse, we prioritize the security and privacy of our users. We employ robust encryption protocols and industry-standard security measures to safeguard your personal and financial information, ensuring a secure and trustworthy experience. </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How can I stay updated on my investment goals? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    RoboPulse provides prompt goal updates through proactive notifications. You will receive regular email and SMS alerts, keeping you informed about the progress of your investment goals and any necessary adjustments recommended by the platform.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10bh-content"
                    id="panel10bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Can I switch my investment strategy or make changes to my portfolio? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Yes, RoboPulse allows you to make changes to your investment strategy and portfolio. You have the flexibility to adjust your risk tolerance, update financial goals, and make modifications as needed to ensure your investments align with your evolving requirements. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>

 


              </Box>
            </Box>
          </Grid>
        </Grid>




      </Container>




    </>


  );
}
