import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function PrivateCredit() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Private Credit Trends Discussed at CFA New York Event</title>
                <meta name="description" content="Nilanjan Das joined industry experts at CFA New York’s event on private credit and market transformation, discussing risk assessment and opportunities in a changing regulatory landscape." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/private-credit-and-the-credit-markets-transformation" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Private Credit and the Credit Markets Transformation
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>
                            Nilanjan Das, Executive VP Business Development at Decimal Point Analytics, attended the Private Credit and the Credit Markets Transformation event hosted by <a href='https://cfany.org/' target='_blank' rel="noopener noreferrer">CFA New York</a>.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The discussion brought together industry experts to explore the evolving landscape of private credit, the impact of macroeconomic trends, and the shifting dynamics in credit markets.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Key insights included
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Risk assessment strategies
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Opportunities in a changing regulatory environment
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            As credit markets continue to transform, <Link to="/contact-us#contact_form" className='link'>Decimal Point Analytics</Link> remains dedicated to delivering data-driven insights that help financial institutions stay ahead of emerging trends with clarity and confidence.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
