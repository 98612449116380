import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack.js';
import { Helmet } from 'react-helmet';

import Banner from '../../../asset/images/media/press_release/banner_comprehensive-investor.webp'
import AP from "../../../asset/images/media/apnews.webp";
import MM from "../../../asset/images/media/marketers_media.webp";
import { Link } from 'react-router-dom';


export default function ComprehensiveInvestor() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics Expands Investor Relationship Management Solutions</title>
                <meta name="description" content="Decimal Point Analytics expands its Investor Relationship Management solutions, using AI and automation to improve communications and reduce costs." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-launches-comprehensive-investor-relationship-management-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h2' className='mainhead blue al_center'>
                                    Decimal Point Analytics Launches Comprehensive Investor Relationship Management Solutions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <Link to="/" className='link'>Decimal Point Analytics (DPA),</Link> a global leader in data analytics and technology, has announced the expansion of its Investor Relationship Management offerings. These solutions are designed to elevate client engagement, streamline investor communications, and support financial firms in building long-term investor trust.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        These services provide clients with faster turnaround times, enhanced accuracy, and reduced operational costs by integrating cutting-edge technology, AI, and Large Language Models (LLM) with a human-in-the-loop approach.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Shailesh Dhuri, CEO of Decimal Point Analytics, said the company’s enhanced Investor Relationship Management offerings cater to the growing need for reliable, efficient tools that simplify communication and streamline engagement for enterprises. “By combining automation and centralized data, we empower our clients to deliver seamless, transparent investor experiences, which are crucial for growth in today’s competitive landscape,” he said.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The expanded Investor Relationship Management solutions suite includes Due Diligence Questionnaire (DDQ) Management, RFP (Request for Proposal) Support, CRM Services, and Consultant Database Management. These tools simplify complex processes, ensure compliance, and strengthen client relationships across financial sectors.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The key offerings include:
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Investor Communication and Engagement: </strong>  Custom solutions that enable consistent, effective communication to foster long-term investor relationships.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>DDQ (Due Diligence Questionnaire) Management: </strong> A centralized, automated system supporting timely, precise responses that enhance investor confidence and facilitate compliance.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>RFP (Request for Proposal) Support:</strong> Tailored RFP services to improve firms’ success rates in acquiring high-value clients.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Customer Relationship Management (CRM) Services: </strong>  Integrated solutions for enhanced client management, lead tracking, and sales processes, allowing firms to optimize every client interaction.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Consultant Database Management: </strong>  Tools for efficient consultant data management, performance monitoring, and industry-standard compliance assurance.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <Link to="/" className='link'>Decimal Point Analytics</Link> is committed to delivering data-driven solutions that support seamless investor interactions, empowering financial firms to achieve operational efficiency and build stronger, more trusted client relationships.
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>


                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://apnews.com/press-release/marketersmedia/digital-rights-management-bf79d2e06eabd6abe8957cab9f4f0600" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + AP + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://news.marketersmedia.com/decimal-point-analytics-launches-comprehensive-investor-relationship-management-solutions/89146245" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + MM + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
