import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_standardizing_benchmarks.webp"
import Infographics from "../../../../asset/images/insight/case_studies/standardizing_benchmarks.webp"
import { Link } from 'react-router-dom';



export default function StandardizingBenchmarks() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Efficient Portfolio Performance Reporting & Benchmark Optimization</title>
                <meta name="description" content="Discover how benchmark optimization and portfolio performance reporting improve accuracy, efficiency, and liquidity for asset managers. Learn how to enhance fund management." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/standardizing-benchmarks-across-6700-funds-elevating-investment-precision" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Standardizing Benchmarks Across 6,700 Funds, Elevating Investment Precision
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A prominent global asset management company managing over 6,700 funds across 26 regions needed to optimize benchmark tracking, standardization, and ETF mapping for improved investment decisions. By addressing inconsistencies in benchmark formats and missing assignments, the organization significantly enhanced its investment precision.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Key challenges included:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Non-Standard Benchmark Formats: </strong>  Approximately 20% of funds had inconsistent benchmark formats, leading to confusion and inefficiencies.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Missing or Undefined Benchmarks: </strong> 53% of funds lacked clear benchmark assignments, impacting portfolio performance reporting.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Limited Investability: </strong> Non-investable benchmarks increased tracking errors, reducing investment efficiency.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Enhancing Benchmark Efficiency
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="topbanner" alt="Solution: Enhancing Benchmark Efficiency" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Enhanced accuracy in benchmark tracking for 6,700+ funds.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Improved alpha measurement through standardized benchmarks, supporting informed trading decisions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Increased portfolio liquidity by converting non-investable benchmarks into investable ETFs.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Effective benchmark standardization reduces manual tracking efforts by up to 75%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Integrating ETFs improves investment efficiency and accuracy.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            A tailored approach enhances resource utilization and focus on core investment activities.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    <strong>Interested in optimizing your benchmark processes?</strong>
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Let’s connect</Link> and explore how we can help your organization achieve similar results!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}