import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Adhering from "../../../asset/images/media/press_release/adhering_to_green_practices.webp"

import Banner from '../../../asset/images/media/press_release/banner_green_practice.webp'

export default function AdheringtoGreenPractices() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Embracing Sustainability in Business: Integration of Green Practices</title>
                <meta name="description" content="Explore Shailesh Dhuri's insights on global business sustainability and environmentally friendly operations amidst investments in green technologies." />
                <meta name="keywords" content="sustainability practices, green operations, environmentally friendly practices, eco-friendly infrastructure, business sustainability" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/does-adhering-to-green-practices-add-costs-for-businesses" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Does adhering to green practices add costs for businesses?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Shailesh Dhuri emphasizes the widespread adoption of sustainability practices in global business, highlighting the integration of environmentally friendly operations. He recognizes the enduring importance of sustainability despite the initial substantial capital investments required for implementing green technologies, such as eco-friendly infrastructure.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <a href='https://economictimes.indiatimes.com/small-biz/sustainability/does-adhering-to-green-practices-add-costs-for-businesses/articleshow/109391491.cms' target='_blank' rel="noopener noreferrer">Click here</a> to Read the full coverage
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={7} md={8} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={Adhering} className='fullimg' alt='Quote' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
