import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Helmet } from "react-helmet";
import Newsletter_Data from "./Newsletter_data";
import PropTypes from "prop-types";
import NewsletterBannerCarousel from "./NewsletterBannerCarousel";
import NewsletterCarousel2025 from "./NewsletterCarousel2025";
import NewsletterCarousel2024 from "./NewsletterCarousel2024";
import NewsletterCarousel2023 from "./NewsletterCarousel2023";
import NewsletterContact from "./NewsletterContact";
import NewsletterCarousel2022 from "./NewsletterCarousel2022";
import NewsletterResourecs from "./NewsletterResourecs";
import Get_in_touch from "../../Component/Get_in_touch";
import banner1 from "../.././asset/images/newsletter/newsletter_banner/nl_april_banner.webp"
import { Link } from "react-router-dom/cjs/react-router-dom.min";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function NewsletterNew() {

  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <title>Newsletter Updates | Decimal Point Analytics</title>
        <meta name="description" content="Read the latest newsletter from Decimal Point Analytics. Get valuable insights into the world of business and technology. " />
        <meta name="keywords" content="ESG Disclosures, Data Science, AI, Banking Operations, Financial Ratings Firm, Data management, Research" />
        <meta name="author" content="Newsletter by Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/newsletter" />
      </Helmet>

      <Box id="new_landing_carousel" className="press-banner">
        <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + banner1 + ")" }} pb={2}>
          <Container maxWidth="lg">
            <Grid container spacing={1} alignItems="center" justifyContent='center'>
              <Grid item lg={12} md={9} sm={12} xs={12}>
                <Box className="text_animated new-design press-banner">

                  <Box className="topBorder" my={'30px'} />
                  <Box>
                    <Typography variant="h1" className="white fw7 " gutterBottom>
                      NEWSLETTER April 2025
                    </Typography>
                  </Box>

                  <Typography variant="h1" className="white fw4 mb0" gutterBottom>
                  Lending, CRM & Beyond
                  </Typography>

                  <Box className="bottomBorder" mt={'40px'} />
 
                  <Box mt={4}> 
                    <a  href='/newsletter/monthly-newsletter-apr-2025.html' target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" className='dpabtn_sm2'>
                        Read More
                      </Button>
                    </a>
                  </Box>
                </Box>

              </Grid>

            </Grid>
          </Container>
        </Box>
      </Box>

      <Box className="sec_bg section">
        <NewsletterContact />
      </Box>

      <Box className="section">
        <NewsletterCarousel2025 />
      </Box>

      <Box className="sec_bg section">
        <NewsletterCarousel2024 />
      </Box>

      <Box className="section">
        <NewsletterCarousel2023 />
      </Box>

      {/* <Box className="section">
        <NewsletterCarousel2022 />
      </Box> */}

      <Box className="sec_bg">
        <NewsletterResourecs />
      </Box>

      <Box>
        <Get_in_touch />
      </Box>
    </>
  );
}
