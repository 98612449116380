import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core';


import FAQ from "../../../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function DDQFaq() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>


      <Container maxWidth='lg' className='bdr'>


        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image'  mt={4}>
              <img loading="lazy"  src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box className='al_left'>
              <Typography variant='h2' className='black'>
                <strong>  FAQ </strong>
              </Typography>
            </Box>

            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>  How does automation enhance DDQ management? </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Automation streamlines processes, minimizes errors, and ensures consistency and accuracy in DDQ responses.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Why is tailored customization important for DDQs?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    Customized responses address specific investor requirements, showcasing a commitment to transparency and governance.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>What are the key benefits of using DPA’s DDQ solutions?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                    DPA offers improved accuracy, efficient management, tailored solutions, and compliance-driven processes to enhance investor relationships.
                    </Typography>
                  </AccordionDetails>
                </Accordion>  


              </Box>
            </Box>
          </Grid>
        </Grid>




      </Container>




    </>


  );
}
