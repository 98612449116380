import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_data_cre_lending.webp'

import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

export default function DataAConfidenceBooster() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Data%2C%20a%20confidence%20booster%20in%20commercial%20real%20estate%20CRE%20lending%20decisions.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Optimizing CRE Lending Decisions with Data: Strategies and Solutions</title>
                <meta name="description" content="Discover how data-driven strategies can improve CRE lending decisions and enhance underwriting accuracy. Learn from Decimal Point Analytics' solutions to navigate market challenges effectively" />
                <meta name="keywords" content="data visualization, Power BI, personalized banking, customer relationships, AI in banking, machine learning in banking" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/data-a-confidence-booster-in-commercial-real-estate-cre-lending-decisions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id='article-page' className='section sec_bg'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>


                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h3' className='subhead skblue al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            Data, a confidence-booster in commercial real estate CRE lending decisions
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                    Navigating the commercial real estate (CRE) lending landscape is increasingly complex due to economic shifts and market volatility. Our whitepaper, “Data: A Confidence-Booster in CRE Lending Decisions,” explains how leveraging data can enhance lending strategies.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    This whitepaper covers:
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Market Challenges and Data Solutions:</strong> Insights into how hybrid work, rising vacancies, and economic stress impact CRE financing, and how robust data strategies can enhance underwriting, credit monitoring, and risk management.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Real-World Example: </strong> A case study of a leading U.S. CRE lender optimizing their process through advanced data management.
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Unlock effective strategies for navigating today’s CRE lending landscape. Don’t miss out on these essential insights for smarter decision-making.
                                                </Typography>

                                            </Box>
                                            <Box className='graybbx2222' mt={2}>
                                                <ViewFullWhitepaper reportby={"Data, a confidence-booster in commercial real estate CRE lending decisions"} link={link} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>



                        </Grid>

                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box >
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
