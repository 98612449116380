import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_transformative_potential.webp'

import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

export default function AgenticFinancialServices() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/The%20transformative%20potential%20of%20agentic%20AI%20in%20the%20financial%20services%20industry%201.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Agentic AI Financial Services with Intelligent Automation | Decimal Point Analytics</title>
                <meta name="description" content="Discover how Agentic AI is revolutionizing financial services. Learn how AI-driven automation enhances customer support, streamlines operations, and strengthens risk management. Download the whitepaper now!" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepaper/agentic-ai-in-financial-services" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>


                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h2' className='subhead skblue  al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                         The Transformative Potential of Agentic AI for the Financial Services Industry
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img loading="lazy"  src={Banner} className='w100' alt='Banner Of The Transformative Potential' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                    Financial Services Institutions are under constant pressure to improve customer-centricity, streamline operations, and navigate evolving regulations. Our latest whitepaper, <strong>"The Transformative Potential of Agentic AI for the Financial Services Industry,"</strong> explores how agentic AI empowers FSIs to overcome these challenges and achieve unprecedented levels of efficiency and growth.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Key Takeaways:</strong>
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Smarter Customer Support : </strong> AI-driven agents consolidate data, understand context, and operate autonomously within set limits.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Efficient Data Management : </strong>Automate data collection, cleansing, and integration, freeing teams for high-value tasks.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Stronger Fraud & Risk Control : </strong> AI solutions detect threats with real-time behavioral insights.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                <strong>Higher Operational Efficiency : </strong> Automate workflows, reduce errors, and streamline banking, insurance, and asset management.
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Download the whitepaper to explore how the transformative potential of agentic AI for your financial services institution.
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    knowledge.
                                                </Typography>

                                            </Box>

                                            <Box className='graybbx2222' mt={2}>
                                                <ViewFullWhitepaper reportby={"The Transformative Potential of Agentic AI for the Financial Services Industry"} link={link} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>


                        </Grid>


                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box >
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
