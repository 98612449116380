import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";




export default function Sitemap() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>

      <Helmet>
        <title>Sitemap | Decimal Point Analytics </title>
        <meta name="description" content="Explore Decimal Point Analytics' sitemap for easy navigation. Find all our web pages listed conveniently. Find what you're looking for effortlessly!" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/sitemap" />
      </Helmet>

      <Box id="sitemap" className="section" component="section">
        <Container maxWidth>

          <Box>



            <Box id="top-3-boxes" mb={1}>
              <Grid container spacing={2} alignItems="top">

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box id="solution" className="whitebx">
                    <Box>
                      <Typography variant="body1" className="sitemaphdd al_center"> Solutions </Typography>
                      <Divider />
                    </Box>
                    <Box mt={1}><Typography className="menuheading">Services</Typography></Box>

                    <Grid container spacing={1} alignItems="top" >

                      {/* Solutions Section Started */}
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="brdbx smscroll">

                          <Link to="/artificial-intelligence-solutions" className="smitem">Artificial Intelligence  </Link>
                          <Divider className="itemdiv" />

                          <Link to="/bridge-lending-solutions" className="smitem">Bridge Lending Solutions</Link>
                          <Divider className="itemdiv" />

                          <Link to="/catastrophe-modeling-services" className="smitem"> Catastrophe Modeling Services </Link>
                          <Divider className="itemdiv" />

                          <Link to="/cloud-services" className="smitem"> Cloud Services </Link>
                          <Divider className="itemdiv" />

                          <Link to="/data-solution-in-us" className="smitem"> Data Solution  </Link>
                          <Divider className="itemdiv" />

                          <Link to="/business-intelligence-solution" className="smitem">  Data Visualization & BI Dashboards </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="brdbx smscroll">

                          <Link to="/investor-relations-solutions" className="smitem"> Investor Relations Solutions  </Link>
                          <Divider className="itemdiv" />

                          <Link to="/request-for-proposals-solutions" className="smitem"> RFP Management Solutions </Link>
                          <Divider className="itemdiv" />

                          <Link to="/due-diligence-questionnaires-solutions" className="smitem"> Due Diligence Questionnaires </Link>
                          <Divider className="itemdiv" />

                          <Link to="/consultant-database-management-solutions" className="smitem"> Consultant Database Management </Link>
                          <Divider className="itemdiv" />

                          <Link to="/customer-relationship-management-solutions" className="smitem"> Customer Relationship Management </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>

                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box className="brdbx smscroll">

                          <Link to="/leading-research-solution-company" className="smitem"> Research Solution </Link>
                          <Divider className="itemdiv" />

                          <Link to="/risk-management-company-in-us" className="smitem"> Risk Management </Link>
                          <Divider className="itemdiv" />

                          <Link to="/small-business-administration" className="smitem"> Small Business Administration </Link>
                          <Divider className="itemdiv" />
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} alignItems="top">
                          <Grid item lg={2} md={4} sm={12} xs={12}>
                            <Box>

                              <Box>
                                <Typography variant="body1" className="sitemaphdd al_center"> ESG </Typography>
                                <Divider />
                              </Box>


                              <Box mt={1}><Typography className="menuheading">&nbsp;</Typography></Box>
                              <Box className="brdbx smscroll" >

                                <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses" className="smitem"> ESG </Link>
                                <Divider className="itemdiv" />

                                <Link to="/esg-insights/esg-whitepaper" className="smitem">ESG Insight</Link>
                                <Divider className="itemdiv" />

                                <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable" className="smitem">ESG Roundtable</Link>
                                <Divider className="itemdiv" />

                                <Link to="/empowering-esg-investment-with-real-time-analytics" className="smitem">ESG Products</Link>
                                <Divider className="itemdiv" />

                              </Box>
                            </Box>
                          </Grid>

                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box className="whitebxxx">
                              <Box>
                                <Typography variant="body1" className="sitemaphdd al_center">
                                  ESG Insights
                                </Typography>
                                <Divider />
                              </Box>

                              <Grid container spacing={1} alignItems="top">

                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                  <Box mt={1} mb={1}>
                                    <Link to="/esg-insights/esg-whitepaper" className="smitem">
                                      <Typography className="menuheading">ESG Whitepaper</Typography>
                                    </Link>
                                  </Box>
                                  <Box className="brdbx smscroll">

                                    <Link to="/esg-insights/esg-whitepaper/navigating-sustainability-usa-esg-landscape-in-2023" className="smitem"> Navigating Sustainability: USA's ESG Landscape in 2023 </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-whitepaper/esg-south-africa-review-2023-whitepaper" className="smitem"> ESG South Africa Review 2023 Whitepaper  </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-whitepaper/esg-canada-review-2023-whitepaper" className="smitem">  ESG Canada Review 2023 Whitepaper </Link>
                                    <Divider className="itemdiv" />

                                  </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                  <Box mt={1} mb={1}>
                                    <Link to="/esg-insights/esg-newsletter" className="smitem">
                                      <Typography className="menuheading">ESG Newsletter</Typography>
                                    </Link>
                                  </Box>
                                  <Box className="brdbx smscroll">

                                    <Link to="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-july-edition" className="smitem"> July Edition  </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-june-edition" className="smitem"> June Edition  </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-january-edition" className="smitem"> January Edition  </Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-october-edition" className="smitem"> October Edition  </Link>
                                    <Divider className="itemdiv" />



                                    <Link to="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-august-edition" className="smitem"> August Edition  </Link>
                                    <Divider className="itemdiv" />

                                    <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fuploaded_imgs%2Fesgnewsletter%2Fnewsletter%2Fbuilding-analytical-solutions-for-a-sustainable-future.html" className="smitem" target="_blank" rel="noopener noreferrer">  May Edition </a>
                                    <Divider className="itemdiv" />

                                  </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                  <Box mt={1} mb={1}>
                                    <Link to="/esg-insights/esg-podcast" className="smitem">
                                      <Typography className="menuheading">ESG Podcast</Typography>
                                    </Link>
                                  </Box>
                                  <Box className="brdbx smscroll">

                                    <a href="https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI" className="smitem" target="_blank" rel="noopener noreferrer">
                                      Unlocking Success: 2024's Top Five ESG Trends for Asset Managers</a>
                                    <Divider className="itemdiv" />

                                    <a href="https://open.spotify.com/episode/3hBMTuRrENqKYBeYz3pcnG?si=PPONZwUiSie6Futo1XsBIg&nd=1" className="smitem" target="_blank" rel="noopener noreferrer"> Ajay Jindal in Conversation with CRTA on Canada ESG Review 2023 </a>
                                    <Divider className="itemdiv" />

                                    <a href="https://www.youtube.com/watch?v=wsSgZ22V-Tw" className="smitem" target="_blank" rel="noopener noreferrer"> Exploring Canada’s ESG Landscape: Insights from DPA’s ESG Review
                                    </a>
                                    <Divider className="itemdiv" />

                                  </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                  <Box mt={1} mb={1}>
                                    <Link to="/esg-insights/esg-blogs" className="smitem">
                                      <Typography className="menuheading">ESG BLogs</Typography>
                                    </Link>
                                  </Box>
                                  <Box className="brdbx smscroll">

                                    <Link to="/esg-insights/esg-blogs/indian-asset-managers-need-to-take-the-lead-in-net-zero-targeting" className="smitem"> Indian Asset Managers need to take the lead in Net Zero targeting </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/giving-the-green-signalfor-esg-investing-with-the-right-insights" className="smitem"> Giving the Green Signal for ESG Investing with the Right Insights </Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-blogs/unlocking-success-2024-top-five-esg-trends-for-asset-managers" className="smitem"> Unlocking Success: 2024's Top Five ESG Trends for Asset Managers</Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-blogs/navigating-climate-change-how-the-financial-services-industry-can-adapt-and-thrive" className="smitem"> Navigating Climate Change: How the Financial Services Industry Can Adapt and Thrive </Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-blogs/what-is-esg-and-its-impact-on-investing" className="smitem"> What is ESG & Its Impact on Investing? </Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-blogs/how-portfolio-alignment-tools-implement-esg-strategies-for-investors" className="smitem"> How Portfolio Alignment Tools Implement ESG Strategies for Investors </Link>
                                    <Divider className="itemdiv" />


                                    <Link to="/esg-insights/esg-blogs/how-to-avoid-repenting-your-article-8-declarations" className="smitem"> How to avoid repenting your Article-8 declarations  </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/indian-regulator-wants-to-regulate-esg-ratings" className="smitem">  Indian regulator wants to regulate ESG ratings </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/esg-ratings-muddle-created-by-needless-complexity-in-rating-methodologies" className="smitem"> ESG Ratings muddle created by needless complexity in rating methodologies </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/masterplan-to-accelerate-decarbonisation-of-five-major-sectors-planned" className="smitem"> Masterplan to accelerate decarbonisation of five major sectors planned </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/implementation-commitments-loss-and-damage-to-be-the-focus-at-cop27" className="smitem"> Implementation commitments, Loss and Damage to be the focus at COP27 </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/unep-emissions-gap-report-for-2022-paints-a-grim-picture" className="smitem">  UNEP Emissions Gap Report for 2022 paints a grim picture </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/denmark-sets-a-precedent-on-loss-and-damage-funding" className="smitem">  Denmark sets a precedent on loss and damage funding </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/emission-performance-of-global-auto-a-mixed-bag-in-2021" className="smitem">  Emission Performance of Global Auto a mixed bag in 2021 </Link>
                                    <Divider className="itemdiv" />

                                    <Link to="/esg-insights/esg-blogs/sebastian-vettel-and-net-zero" className="smitem">  Sebastian Vettel and Net Zero </Link>
                                    <Divider className="itemdiv" />

                                  </Box>
                                </Grid>

                              </Grid>

                            </Box>
                          </Grid>

                          <Grid item lg={2} md={4} sm={12} xs={12}>
                            <Box className="whitebxxx">

                              <Box>
                                <Typography variant="body1" className="sitemaphdd al_center"> ESG Product </Typography>
                                <Divider />
                              </Box>


                              <Box mt={1}><Typography className="menuheading">&nbsp;</Typography></Box>
                              <Box className="brdbx smscroll">

                                <Link to="/empowering-esg-investment-with-real-time-analytics" className="smitem">
                                  News Senti-Meter
                                </Link>
                                <Divider className="itemdiv" />

                                <Link to="/elevating-private-equity-performance-with-strategic-esg-solutions" className="smitem">
                                  Private Equity
                                </Link>
                                <Divider className="itemdiv" />

                                <Link to="/align-your-investments-with-global-sustainability-goals" className="smitem">
                                  GreenInvest Pulse
                                </Link>
                                <Divider className="itemdiv" />

                              </Box>
                            </Box>
                          </Grid>

                        </Grid>
                      </Grid>

                    </Grid>
                  </Box>
                </Grid>



              </Grid>


            </Box>


            {/* Insight Dropdown Content Section Started */}
            <Box id="insights-section" mt={1} mb={1}>
              <Grid container spacing={2} alignItems="top" >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx">
                    <Box>
                      <Typography variant="body1" className="sitemaphdd al_center"> Insight </Typography>
                      <Divider />
                    </Box>

                    <Grid container spacing={1} alignItems="top" justifyContent="space-between">

                      {/* Case-Studies Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={1}><Typography className="menuheading">Success Stories </Typography></Box>
                        <Box className="brdbx lgscroll">

                          <Link to="/case-study/transforming-data-insights-investor-engagement" className='smitem'>
                            Transforming Data into Granular Insights for Enhanced Investor Engagement
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/predicting-index-rebalancing-with-100-accuracy" className='smitem'>
                            Achieving 100% Accuracy in Index Rebalancing Predictions, Optimizing Trading Strategies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/unified-database-financial-operations" className='smitem'>
                            Unified Database for Enhancing Financial Operations Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/automated-record-type-matching-salesforce-contact-management" className='smitem'>
                            Optimizing Contact Management with Automated Record Type Matching in Salesforce CRM
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/automated-real-time-email-alerts-salesforce-financial-firms" className='smitem'>
                            Automating Real-Time Email Alerts For A Financial Services Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/crm-optimization-investor-data-automation" className='smitem'>
                            Optimizing CRM & Investor Engagement for a $27B Asset Manager
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/data-driven-investor-acquisition-asset-manager" className='smitem'>
                            How a $76B Asset Manager Used AI & CRM Insights to Secure 24 New Investors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/global-asset-manager-crm-automation-efficiency" className='smitem'>
                            How a $27B Asset Manager Achieved 70% Faster Data Processing & Near-Zero Errors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/accelerating-analytics-with-snowflake" className='smitem'>
                            Transforming Analytics Efficiency with Snowflake & Snowpark
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-study/optimizing-crm-database-management-for-investor-relations" className='smitem'>
                            Optimizing CRM Database Extraction & Management for Efficient Investor Relations
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/ai-driven-insurance-claims-processing" className='smitem'>
                            AI-Driven Insurance Claims Processing: Faster, Smarter, and More Accurate
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/transforming-workforce-management-automation" className='smitem'>
                            Transforming Workforce Management Through Automation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/predicting-index-rebalancing" className='smitem'>
                            Predicting Index Rebalancing with 100% Accuracy
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/driving-fundraising-success-with-accurate-data-management" className='smitem'>
                            Driving Fundraising Success with Accurate Data Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/dpas-innovative-approach-enhances-oracle-autonomous-database-implementation" className='smitem'>
                            DPA's Innovative Approach Enhances Oracle Autonomous Database Implementation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/preventing-5-million-in-fraud-and-increasing-efficiency-by-40-with-compliance-excellence" className='smitem'>
                            Preventing $5 Million in Fraud and Increasing Efficiency by 40% with Compliance Excellence
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-40-faster-approvals-and-doubling-application-throughput" className='smitem'>
                            Achieving 40% Faster Approvals and Doubling Application Throughout
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/improving-customer-acquisition-by-25-faster-screening-and-40-increased-efficiency" className='smitem'>
                            Improving Customer Acquisition by 25% Faster Screening and 40% Increased Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-100-backlog-clearance-in-less-than-2-months-for-sba-audit-readiness" className='smitem'>
                            Achieving 100% Backlog Clearance in Less Than 2 Months for SBA Audit Readiness
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/reducing-penalty-payments-by-80-90-with-efficient-sba-reporting" className='smitem'>
                            Reducing Penalty Payments by 80-90% with Efficient SBA Reporting
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/boosting-sba-loan-approvals-by-30-with-99-documentation-accuracy" className='smitem'>
                            Boosting SBA Loan Approvals by 30% with 99% Documentation Accuracy
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/saving-60-in-overheads-while-enhancing-sba-loan-quality-and-speed-by-40" className='smitem'>
                            Saving 60% in Overheads While Enhancing SBA Loan Quality and Speed by 40%
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/doubling-productivity-achieving-2x-faster-loan-processing-with-50-fewer-errors" className='smitem'>
                            Doubling Productivity Achieving 2x Faster Loan Processing with 50% Fewer Errors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality" className='smitem'>
                            Cost-Effective Excellence – Saving Lenders 60% in Overheads While Enhancing SBA Loan Quality
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhancing-workflow-efficiency-and-reducing-costs-with-precision" className='smitem'>
                            Enhancing Workflow Efficiency and Reducing Costs with Precision
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhancing-sba-loan-approval-rates-through-precise-documentation-and-ensuring-sba-compliance" className='smitem'>
                            Enhancing SBA Loan Approval Rates Through Precise Documentation and Ensuring SBA Compliance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/how-to-prevent-fraud-in-real-eEstate-finance-a-case-study-on-compliance-excellence" className='smitem'>
                            How to Prevent Fraud in Real Estate Finance: A Case Study on Compliance Excellence
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/how-automation-doubled-loan-processing-speed-and-cut-errors-by-50-for-real-estate-finance-firms" className='smitem'>
                            How Automation Doubled Loan Processing Speed and Cut Errors by 50% for Real Estate Finance Firms
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/transforming-ipo-data-management-with-6-hour-lead-for-stock-market-research" className='smitem'>
                            Transforming IPO Data Management with 6-Hour Lead for Stock Market Research
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-99-data-accuracy-in-contact-management-for-global-alternatives-firm" className='smitem'>
                            Achieving 99% Data Accuracy in Contact Management for Global Alternatives Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/streamlining-invoice-processing-by-85-for-efficient-transaction-management" className='smitem'>
                            Streamlining Invoice Processing by 85% for Efficient Transaction Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/reducing-maintenance-downtime-by-90-with-ai-driven-solutions" className='smitem'>
                            Reducing Maintenance Downtime by 90% with AI-Driven Solutions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-50-improvement-in-currency-risk-model-accuracy" className='smitem'>
                            Achieving 50% Improvement in Currency Risk Model Accuracy
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/crafting-a-dynamic-industry-classification-system-enabling-real-time-insights-for-investment-strategies" className='smitem'>
                            Crafting a Dynamic Industry Classification System, Enabling Real-Time Insights for Investment Strategies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/standardizing-benchmarks-across-6700-funds-elevating-investment-precision" className='smitem'>
                            Standardizing Benchmarks Across 6,700 Funds, Elevating Investment Precision
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/reducing-client-query-time-by-29-ensuring-100-support-coverage-with-an-offshore-team" className='smitem'>
                            Reducing Client Query Time by 29%, Ensuring 100% Support Coverage with an Offshore Team
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/automating-fund-classification-for-2600-funds-enhancing-insights-and-efficiency" className='smitem'>
                            Automating Fund Classification for 2,600+ Funds, Enhancing Insights and Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/delivering-public-float-data-50-hours-faster-empowering-smarter-investment-decisions" className='smitem'>
                            Delivering Public Float Data 50 Hours Faster, Empowering Smarter Investment Decisions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-60-faster-processing-times-in-bridge-lending-with-automated-processes" className='smitem'>
                            Achieving 60% Faster Processing Times in Bridge Lending with Automated Processes
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/us-asset-management-firm-boosts-security-with-30-faster-operations-and-zero-downtime" className='smitem'>
                            US Asset Management Firm Boosts Security with 30% Faster Operations and Zero Downtime
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/streamlining-operations-with-oracle-apex-and-40-cost-reduction-in-inventory-management" className='smitem'>
                            Streamlining Operations with Oracle APEX and 40% Cost Reduction in Inventory Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/improving-oas-bond-pricing-accuracy-by-25-for-a-global-investment-firm" className='smitem'>
                            Improving OAS Bond Pricing Accuracy by 25% for a Global Investment Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/boosting-forecast-accuracy-by-30-with-advanced-inflation-models-for-a-global-hedge-fund" className='smitem'>
                            Boosting Forecast Accuracy by 30% with Advanced Inflation Models for a Global Hedge Fund
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/achieving-90-accuracy-in-industry-classification-for-a-leading-us-financial-data-provider" className='smitem'>
                            Achieving 90% Accuracy in Industry Classification for a Leading US Financial Data Provider
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/driving-1.5m-monthly-profit-increase-with-product-portfolio-optimization-for-a-mena-fmcg" className='smitem'>
                            Driving $1.5M Monthly Profit Increase with Product Portfolio Optimization for a MENA FMCG
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhancing-catastrophe-modeling-accuracy-for-a-uk-based-reinsurer-with-4000-models" className='smitem'>
                            Enhancing Catastrophe Modeling Accuracy for a UK-Based Reinsurer with 4,000+ Models
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/optimizing-portfolio-investment-decisions-for-a-leading-middle-eastern-asset-management-firm" className='smitem'>
                            Optimizing Portfolio Investment Decisions for a Leading Middle Eastern Asset Management Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/the-impact-of-predictive-analytics-on-strategic-growth-in-a-leading-indian-bank" className='smitem'>
                            The Impact of Predictive Analytics on Strategic Growth in a Leading Indian Bank
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/dpas-implementation-expertise-enhances-clickups-full-potential" className='smitem'>
                            DPA’s Implementation Expertise Enhances ClickUp’s Full Potential
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/delivering-efficiency-gains-through-predictive-analytics-for-a-us-asset-advisory-firm" className='smitem'>
                            Delivering 8x Efficiency Gains through Predictive Analytics for a US Asset Advisory Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/cutting-loan-pool-acquisition-time-by-for-a-leading-us-asset-management-firm" className='smitem'>
                            Cutting Loan Pool Acquisition Time by 75% for a Leading US Asset Management Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/reducing-fund-processing-time-by-for-a-mena-asset-management-firm" className='smitem'>
                            Reducing Fund Processing Time by 50% for a MENA Asset Management Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhanced-risk-management-capabilities-for-a-leading-us-based-asset-management-firm" className='smitem'>
                            Enhanced Risk Management Capabilities for a Leading US-based Asset Management Firm
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/streamlining-consultant-database-management-for-enhanced-efficiency" className='smitem'>
                            Streamlining Consultant Database Management for Enhanced Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhancing-portfolio-management-through-advanced-analytics-and-machine-learning" className='smitem'>
                            Enhancing Portfolio Management through Advanced Analytics and Machine Learning
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/transforming-rent-roll-data-management-efficiency-and-automation-with-dpas-data-ingestion-platform" className='smitem'>
                            Transforming Rent Roll Data Management: Efficiency and Automation with DPA’s Data Ingestion Platform
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/overcoming-data-challenges-to-build-a-credible-esg-index" className='smitem'>
                            Overcoming Data Challenges to Build a Credible ESG Index
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/a-lending-company-transforms-data-management-a-case-study-in-efficiency" className='smitem'>
                            A Lending Company Transforms Data Management: A Case Study in Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/automating-data-and-news-updation-for-enhanced-index-management" className='smitem'>
                            Automating Data and News Updation for Enhanced Index Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/enhancing-asset-forecasting-capabilities-for-a-leading-financial-institution" className='smitem'>
                            Enhancing Asset Forecasting Capabilities for a Leading Financial Institution
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/wealth-management-solution" className='smitem'>
                            Optimizing Asset Allocation: A Tailored Wealth Management Solution
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/financial-data-extraction-global-index-provider" className='smitem'>
                            Enhancing Business Intelligence: Transforming Data Extraction for a Leading Financial Institution
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/revolutionising-machinery-and-equipment-data-management-with-web-scraping-and-advanced-analytics" className='smitem'>
                            Revolutionizing Machinery and Equipment Data Management with DPA: A Case Study in Efficiency
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/revolutionizing-financial-data-analysis-for-global-indices" className='smitem'>
                            Revolutionizing Financial Data Analysis for Global Indices
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/optimizing-supplier-risk-management-unleashing-the-power-of-advanced-analytics-for-seamless-operation" className='smitem'>
                            Optimizing Supplier Risk Management: Unleashing the Power of Advanced Analytics for Seamless Operation
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/investment-leader-automates-credit-and-loan-operations-achieving-remarkable-efficiency-and-precision" className='smitem'>
                            Investment Leader Automates Credit and Loan Operations, Achieving Remarkable Efficiency and Precision
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/advanced-fund-accounting-solutions-for-top-financial-services-group-in-mena-region" className='smitem'>
                            Advanced Fund Accounting Solutions for Top Financial Services Group in MENA Region
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/enhanced-esg-reporting-from-voluminous-data-to-clear-insights" className='smitem'>
                            Enhanced ESG Reporting: From Voluminous Data to Clear Insights
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company" className='smitem'>
                            How AI-Driven Automation Revolutionizes a Leading European Industrial Company
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/driving-investment-banking-success-decimals-comprehensive-solutions-for-valuation-research-and-trade-recommendations" className='smitem'>
                            Driving Investment Banking Success: Decimal's Comprehensive Solutions for Valuation, Research, and Trade Recommendations
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/improving-database-management-for-enhanced-decision-making-in-the-alternate-fixed-income-industry" className='smitem'>
                            Improving Database Management for Enhanced Decision-Making in the Alternate Fixed Income Industry
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/streamlining-technical-support-and-enhancing-efficiency-through-process-automation" className='smitem'>
                            Streamlining Technical Support and Enhancing Efficiency through Process Automation
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/transforming-banking-operations-with-advanced-data-analytics" className='smitem'>
                            Transforming Banking Operations with Advanced Data Analytics
                          </Link>
                          <Divider className="itemdiv" />



                          <Link to="/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits" className='smitem'>
                            Automated Data Warehouse & Back-Testing Solutions That Helped a Global Index Provider Achieve Financial Benefits </Link>
                          <Divider className="itemdiv" />



                          <Link to="/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports" className='smitem'>
                            Extraction of GHG Emission Data from ESG Disclosure Reports </Link>
                          <Divider className="itemdiv" />



                          <Link to="/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider" className='smitem'>
                            Delivering Comprehensive Data Solutions for a Leading Financial Data
                            Aggregator and Provider </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm" className='smitem'>
                            Tailoring Process Automation Solutions to Provide Modernised Quality
                            Data in Salesforce CRM </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer" className='smitem'>
                            Fraud Detection & Analytics for a Leading German
                            Reinsurer </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions" className='smitem'>
                            Process & Cost Efficiency through DPA's Structured
                            Data Management Solutions</Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/salesforce-automation-to-simplify-your -complex-processes" className='smitem'>
                            Salesforce Automation to Simplify Your Complex
                            Processes </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/salesforce-automation-to-simplify-your -complex-processes" className='smitem'>
                            Cutting Edge Data Solutions for a Corporate Client </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/offering-solutions-for-every-asset-managers" className='smitem'>
                            Offering solutions for every Asset Managers </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/nav-calculations-for-portfolio-management" className='smitem'>
                            NAV Calculations for Portfolio Management </Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager" className='smitem'>
                            Intelligent document processing of Rent Rolls for an
                            Asset Manager</Link>
                          <Divider className="itemdiv" />


                          <Link to="/case-studies/data-managment-for-efficient-crm-usage" className='smitem'>
                            Data Management for Efficient CRM Usage </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/response-management-system-for-fund-raising" className='smitem'>
                            Response Management System for Fund Raising </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm" className='smitem'>
                            Automated Portfolio Monitoring System for a Private
                            Equity firm </Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/robotic-process-automation-for-credit-funds" className='smitem'>
                            Robotics Process Automation (RPA) for Credit Funds</Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/customized-credit-platform-for-financial-institution" className='smitem'>
                            Customized Credit Platform for Financial Institution</Link>
                          <Divider className="itemdiv" />

                          <Link to="/case-studies/intelligent-dashboard-solutions-for-banks" className='smitem'>
                            Intelligent Dashboard Solution for Banks </Link>
                          <Divider className="itemdiv" />



                        </Box>
                      </Grid>
                      {/* Case-Studies Section Ended */}


                      {/* Articles Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={1}><Typography className="menuheading">Articles</Typography></Box>
                        <Box className="brdbx lgscroll">
                          <Link
                            to="/articles/ai-llms-software-testing" className="smitem">
                            Leveraging AI and Large Language Models in Software Testing: Enhancing Quality and Productivity
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/leveraging-agile-scrum-and-critical-path-method-cpm-for-enhanced-software-project-management" className="smitem">
                            Leveraging Agile Scrum and Critical Path Method (CPM) for Enhanced Software Project Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/india-modern-day-arthashastra-a-ministry-of-economic-security" className="smitem">
                            India’s Modern Day Arthashastra - A Ministry Of Economic Security
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot" className="smitem">
                            The Holographic Future of Regulation: Why We Need a Radical New Approach Unravelling the Regulatory Knot
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/digital-assets-can-look-forward-to-a-brighter-future-in-2024" className="smitem">
                            Digital Assets can look forward to a brighter future in 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/is-it-time-to-write-esg-requiem-not-quite" className="smitem">
                            Is it time to write ESG’s Requiem? Not quite</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot" className="smitem">
                            LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/inclusion-indian-sovereign-bonds-jpmorgan-index" className="smitem">
                            Is the inclusion of Indian Sovereign Bonds in the JP Morgan Index a positive move? </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/implementing-machine-readable-regulation-for-competitive-advantage" className="smitem">
                            Implementing Machine Readable Regulation for Competitive Advantage </Link>
                          <Divider className="itemdiv" />

                          <a href="https://www.oracle.com/customers/dpa-case-study/" className="smitem" target="_blank" rel="noopener noreferrer">
                            DPA overcomes project complexity, improves performance with Autonomous Database </a>
                          <Divider className="itemdiv" />


                          <Link
                            to="/articles/tokenization-of-assets-a-new-paradigm-for-businesses" className="smitem">
                            Tokenization of assets a new paradigm for businesses </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/regaining-inr-rightful-position-in-regional-trade" className="smitem">
                            Regaining INR's Rightful Position in Regional Trade</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero" className="smitem">
                            How can Asset managers engage with businesses to
                            accelerate drive towards Net Zero</Link>
                          <Divider className="itemdiv" />

                          <Link to="/articles/pe-funds-and-esg-challenges" className="smitem">
                            PE Funds and the ESG challenge </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/challenges-in-operationalizing-esg-norms-in-asset-management" className="smitem">
                            Challenges in operationalizing ESG norms in Asset
                            Management </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/solving-challenges-in-esg-investing" className="smitem">
                            Solving Challenges in ESG Investing</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/data-visualization-and-the-art-of-effective-storytelling" className="smitem">
                            Data Visualization and the Art of Effective
                            Storytelling </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques" className="smitem">
                            How Asset Managers are Leveraging Natural Language Processing (NLP) Techniques </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/private-equity-adopting-a-data-first-approach" className="smitem">
                            Private Equity - Adopting a Data First Approach</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation" className="smitem">
                            Adopting changes in Investment Research Methodologies - Roadmap to Institutional Transformation </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/big-data-and-analytics-the-way-forward-for-asset-managers" className="smitem">
                            Big Data and Analytics, the way forward for Asset Managers! </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/disrupting-investment-research-process" className="smitem">
                            Disrupting Investment Research Process </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/data-analytics-in-asset-backed-securities" className="smitem">
                            Data Analytics in Asset-Backed Securities (ABS) </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/role-of-advanced-analytics-in-redefining-retail-banking" className="smitem">
                            Role of Advanced Analytics in Redefining Retail
                            Banking </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/real-interest-rates-burder-on-indian-businesses" className="smitem">
                            Real Interest Rates Burden On Indian Businesses </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/evolution-of-the-new-age-avatars-of-asset-managers" className="smitem">
                            Evolution of the New Age Avatars of Asset Managers</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa" className="smitem">
                            How can India benefit from being on the currency watchlist of USA? </Link>
                          <Divider className="itemdiv" />

                          <Link to="/articles/non-traditional-to-access-credit-risk" className="smitem">
                            Exploring Non-traditional Ways to Assess Company Credit Risk
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/india-leverage" className="smitem">
                            How can India leverage rising crude oil prices to help the domestic economy? </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/rethinking-corporate-governance" className="smitem">
                            Rethinking Corporate Governance Framework in the Digital World </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/robo-advisors" className="smitem">
                            Will Robo-advisors disrupt the dynamics in the financial services industry </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/independant-research" className="smitem">
                            Independent Research </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/indian-budget-17-18" className="smitem">
                            Impact of India Budget 2017-18 on Global Institutional Investor </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/trumps-presidency" className="smitem">
                            Donald Trump'S Presidency</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/germany-and-upcoming-elections" className="smitem">
                            Germany & The Upcoming Elections </Link>
                          <Divider className="itemdiv" />

                          <Link to="/articles/fork-wars" className="smitem">
                            Fork Wars A Tale of two cryptocurrencies</Link>
                          <Divider className="itemdiv" />


                          <Link
                            to="/articles/pursuit-of-alpha" className="smitem">
                            In pursuit of Alpha: The rise of data-driven decision making with help of AI/ ML technologies. </Link>
                          <Divider className="itemdiv" />


                          <Link
                            to="/articles/research-redux" className="smitem">
                            Research Redux</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/articles/assessing-quality-of-research" className="smitem">
                            MIFID II Assessing Quality of Research! </Link>
                          <Divider className="itemdiv" />



                        </Box>
                      </Grid>
                      {/* Articles Section Ended */}


                      {/* Blog Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={1}><Typography className="menuheading">Blog</Typography></Box>
                        <Box className="brdbx lgscroll">

                          <Link to="/blog/data-driven-loan-portfolio-strategies" className="smitem">
                            Optimizing Loan Portfolio Performance: Data-Driven Strategies for Lenders
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/ai-risk-compliance-asset-management" className="smitem">
                            AI: Empowering Asset Managers with Real-Time Risk Mitigation and Compliance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/tech-led-focus-areas-for-asset-managers-2025" className="smitem">
                            Tech-led focus areas for asset managers preparing for opportunities and challenges in 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/future-of-fund-management-data-analytics" className="smitem">
                            The Future of Fund Management: Leveraging Data Analytics for Superior Performance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-technology-is-transforming-private-credit-lending" className="smitem">
                            How Technology is Transforming Private Credit Lending: Key Trends and Challenges
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/financial-strategy-investment-cxos" className="smitem">
                            Navigating the Evolving Financial Landscape: Strategic Investment Management for CXOs
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/fund-management-expertise" className="smitem">
                            The Future of Fund Management: AI, Data, and Smarter Strategies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/ddqs-vs-rfps" className="smitem">
                            DDQs vs. RFPs: DPA’s Solutions for Compliance and Vendor Selection
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/bridge-lending-solutions-overcoming-challenges-in-a-competitive-landscape" className="smitem">
                            Bridge lending solutions: Overcoming challenges in a competitive landscape
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-agentic-ai-can-help-small-businesses-hold-onto-their-spot-as-the-us-sunshine-sector" className="smitem">
                            How Agentic AI can help small businesses hold onto their spot as the US’ sunshine sector
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/the-importance-of-due-diligence-in-asset-management" className="smitem">
                            The Importance of Due Diligence in Asset Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/investor-relations-the-bridge-between-markets-and-value-creation" className="smitem">
                            Investor Relations: The Bridge Between Markets and Value Creation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-automation-speeds-up-sba-loan-approvals-without-compromising-accuracy" className="smitem">
                            How Automation Speeds Up SBA Loan Approvals Without Compromising Accuracy
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/rfp-trends-2025" className="smitem">
                            What to Look for in RFP Management Trends Heading into 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/strengthening-investor-relations-with-reliable-consultant-database-management" className="smitem">
                            Strengthening Investor Relations with Reliable Consultant Database Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/the-future-of-investor-relations-integrating-large-language-models-for-smarter-reporting" className="smitem">
                            The Future of Investor Relations: Integrating Large Language Models for Smarter Reporting
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/the-2025-guide-to-scaling-sba-loan-processing-without-increasing-overheads" className="smitem">
                            The 2025 Guide to Scaling SBA Loan Processing Without Increasing Overheads
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/streamline-rfp-ddq-management-asset-wealth-managers" className="smitem">
                            Simplifying RFP and DDQ Management for Asset and Wealth Managers
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/data-extraction-complex-documents" className="smitem">
                            Data Extraction from Complex Documents: Enhancing Efficiency with Decimal Point Analytics
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/making-crm-work-for-financial-services" className="smitem">
                            Making CRM work for financial services
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/revolutionizing-industry-classification-how-decimal-point-analytics-leverages-artificial-intelligence-to-transform-industry-classification" className="smitem">
                            Revolutionizing Industry Classification: How Decimal Point Analytics Leverages Artificial Intelligence to Transform Industry Classification
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-a-360-degree-view-of-customer-data-can-help-companies-anticipate-changing-preferences" className="smitem">
                            How a 360-degree view of customer data can help companies anticipate changing preferences
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/what-is-rent-roll-and-outsourcing-for-decision-making" className="smitem">
                            Leveraging Artificial Intelligence for Rent Roll Management with Strong Business Processes and Automation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/transforming-financial-services-the-role-of-ai-and-ml-in-data-automation" className="smitem">
                            Transforming Financial Services: The Role of AI and ML in Data Automation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/artificial-intelligence-in-finance-transforming-the-future-of-financial-industry" className="smitem">
                            Artificial Intelligence in Finance: Transforming the Future of Financial Industry
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/top-5-trends-transforming-asset-management" className="smitem">
                            Top 5 Trends Transforming Asset Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/data-driven-catastrophe-modeling" className="smitem">
                            Building a superior data-driven catastrophe modeling practice for insurance underwriters
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/the-power-of-catastrophe-modeling-in-modern-risk-management" className="smitem">
                            The Power of Catastrophe Modeling in Modern Risk Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-ai-drives-investment-success-a-game-changer-strategy" className="smitem">
                            How AI Drives Investment Success: A Game-Changer Strategy
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/unlock-alpha-investment-decisions-powered-by-alternative-data" className="smitem">
                            Unlock Alpha: Investment Decisions Powered by Alternative Data
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/ai-and-machine-learning-in-asset-management-unlocking-hidden-value" className="smitem">
                            AI and Machine Learning in Asset Management: Unlocking Hidden Value
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/the-future-of-wealth-management-how-ai-is-changing-the-game" className="smitem">
                            The Future of Wealth Management: How AI is Changing the Game
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/navigating-public-float-crucial-insights-for-investors" className="smitem">
                            Navigating Public Float: Crucial Insights for Investors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/llms-beyond-chatbots-exploring-new-frontiers" className="smitem">
                            LLMs Beyond Chatbots - Exploring New Frontiers
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/unlocking-success-key-trends-for-asset-management-in-2024" className="smitem">
                            Unlocking Success: Key Trends for Asset Management in 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/power-up-your-finances-why-migrating-to-power-bi-is-a-game-changer-for-financial-institutions" className="smitem">
                            Power Up Your Finances: Why Migrating to Power BI is a Game-Changer for Financial Institutions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/enhancing-operational-efficiency-applying-operations-management-theories-in-data-analytics-and-research" className="smitem">
                            Enhancing Operational Efficiency: Applying Operations Management Theories in Data Analytics and Research
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/self-correcting-llms-their-potential-to-transform-the-future-of-ai" className="smitem">
                            Self-Correcting LLMs: Their Potential to Transform the Future of AI
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/overview-us-small-business-loans" className="smitem">
                            Overview of US Small Business Loans
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/navigating-credit-risks-with-creditpulse-an-insightful-analysis-for-Q2-2023" className="smitem">
                            Navigating Credit Risks with CreditPulse: An Insightful Analysis for Q2 2023
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/blog/ai-in-esg-analysis-how-ai-is-transforming-the-way-we-assess-environmental-social-and-governance-factors" className="smitem">
                            AI in ESG Analysis: How AI is Transforming the Way We Assess Environmental, Social, and Governance Factors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-analytics-is-changing-the-face-of-personalization-in-finance" className="smitem">
                            How Analytics is Changing the Face of Personalization in Finance
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/blog/south-africas-remarkable-rise-in-responsible-investing-insights-from-dpa-south-africa-esg-review-2023" className="smitem">
                            South Africa's Remarkable Rise in Responsible Investing: Insights from DPA South Africa ESG Review 2023
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/blog/navigating-market-volatility-how-data-analytics-empowers-asset-managers" className="smitem">
                            Navigating Market Volatility: How Data Analytics Empowers Asset Managers
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/blog/safeguarding-data-privacy-and-security-in-financial-analytics-best-practices-and-technologies" className="smitem">
                            Safeguarding Data Privacy and Security in Financial Analytics: Best Practices and Technologies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/navigating-climate-change-how-the-financial-services-industry-can-adapt-and-thrive" className="smitem">
                            Navigating Climate Change: How the Financial Services Industry Can Adapt and Thrive
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/transforming-financial-services-with-ai-reshaping-risk-management-personalizing-experiences-and-strengthening-fraud-detection" className="smitem">
                            Transforming Financial Services with AI: Reshaping Risk Management, Personalizing Experiences, and Strengthening Fraud Detection
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/enhancing-fraud-protection-for-asset-managers-through-effective-data-management" className="smitem">
                            Enhancing Fraud Protection for Asset Managers through Effective Data Management
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/blog/using-machine-learning-to-detect-fraud-a-step-forward-in-financial-security" className="smitem">
                            Using Machine Learning to Detect Fraud: A Step Forward in Financial Security
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/what-are-sba-loans" className="smitem">
                            What are Small Business Administration (SBA) Loans?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/emerging-trends-in-artificial-intelligence-ai-and-machine-learning-ml-whats-next-for-financial-services" className="smitem">
                            Emerging Trends in Artificial Intelligence (AI) and Machine Learning (ML): What's Next for Financial Services?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/why-creditpulse-is-a-must-have-for-risk-management-professionals" className="smitem">
                            Why CreditPulse is a Must-Have for Risk Management Professionals?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-portfolio-alignment-tools-implement-esg-strategies-for-investors" className="smitem">
                            How Portfolio Alignment Tools Implement ESG Strategies for Investors
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/harnessing-generative-ai-for-innovative-saas-products" className="smitem">
                            Harnessing Generative AI (Artificial Intelligence) for Innovative SaaS Products
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-to-leverage-data-analytics-for-improved-investment-decision-making" className="smitem">
                            How to Leverage Data Analytics for Improved Investment Decision Making
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-artificial-intelligence-is-changing-the-game-in-finance" className="smitem">
                            How Artificial Intelligence is Changing the Game in Finance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/driving-growth-and-decision-making-unleashing-the-power-of-financial-business-intelligence" className="smitem">
                            Driving Growth and Decision-Making: Unleashing the Power of Financial Business Intelligence
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/data-automation-optimizing-financial-operations-with-ai-driven-solutions" className="smitem">
                            Data Automation: Optimizing Financial Operations with AI-Driven Solutions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/digital-security-tokens-a-better-way-of-raising-capital" className="smitem">
                            Digital Security Tokens – A Better Way of Raising Capital
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/how-ai-is-enabling-real-time-data-analysis-for-businesses" className="smitem">
                            How AI is Enabling Real-Time Data Analysis for Businesses
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/revolutionizing-equity-research-with-artificial-intelligence-the-future-is-here" className="smitem">
                            Revolutionizing Equity Research with Artificial Intelligence: The Future is Here
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/unlocking-the-potential-of-data-visualization-the-key-to-business-growth-and-optimization" className="smitem">
                            Unlocking the Potential of Data Visualization: The Key to Business Growth and Optimization
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/scope-of-ai-and-ml-in-finance" className="smitem">
                            Scope of AI & ML in Finance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/trends-and-advancement-in-data-science" className="smitem">
                            Trends And Advancements In Data  Science And AI In 2023 </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/bank-runs-in-balancesheet" className="smitem">
                            How to Value “Bank Runs” in Balance Sheet? </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/building-process-efficiency" className="smitem">
                            Building process efficiency for US Lenders to offer ERC/SBA loans </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/advances-in-ai" className="smitem">
                            Advances in AI – An opportunity for India’s IT/ITES sector to advance in
                            productivity. </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/the-great-skills" className="smitem">
                            The Great Skills Shortage and BhagvatGita </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/a-cautionary-tale" className="smitem">
                            A Cautionary Tale for Bitcoin Maximalists</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/guiding-policy-making" className="smitem">Guiding
                            Policymaking through the Fog of Covid19
                            induced Lockdowns</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/private-equity-global-pandamic" className="smitem">Private
                            Equity - Navigating the Global Pandemic
                            Environment </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/forging-ahead" className="smitem">
                            Forging Ahead in Troubled Times </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/laplace-demon" className="smitem">
                            Laplace’s Demon and Limits to Central Banks’ Ability
                            to Stabilize Economy </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/latrogenesis-of-social-distancing" className="smitem">Iatrogenesis
                            of Social Distancing: Thoughts on Flaws
                            in Deeper Structure of Society. </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/beginning-of-etf-shock" className="smitem">
                            Beginning of ETF Shock </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/extreme-funding-stress" className="smitem">
                            Extreme Funding Stress in Dollar Market </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blog/sba-underwriting-efficiency-erc-sba-loans" className="smitem">
                            The Impact of COVID-19 on Small Business Lending </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/research-operations" className="smitem">
                            Customized, Optimized and Enhanced research operations </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/from-us-to-yes-bank" className="smitem">
                            From Bank of United States to Yes Bank: Why Bank
                            Stocks should be ineligible as loan security</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/do-we-really-need-trade-wars" className="smitem">
                            Do we really need trade wars to shrink international
                            trade-flows in Physical Goods?</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/is-demonstration-respo" className="smitem">
                            Is Demonetization Responsible for Lower GDP Growth
                            Rate? </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/analyzing-gdp-nos" className="smitem">
                            Analyzing The GDP numbers </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/indias-fiscal-contraction" className="smitem">
                            India’s coming fiscal contraction – effect on growth
                            and inflation </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/does-rbi-need-to-revise" className="smitem">
                            Does RBI need to revise its models? </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/india-deflation" className="smitem">
                            India Deflation: Strategic, Tactical and Operational
                            Options for RBI </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/why-will-ai-emerge" className="smitem">
                            Why Will AI Emerge Spontaneously? </Link>
                          <Divider className="itemdiv" />


                          <Link
                            to="/blog/india-heading-toward-deflation" className="smitem">
                            India Heading Towards Deflation</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/excessive-monetory" className="smitem">
                            Excessive Monetary Tightening in India as compared to
                            OECD </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/emergence-of-singularity" className="smitem">
                            Emergence of Singularity: Building Anti Fragile Human
                            Race </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/the-fallacy-of-paradox" className="smitem">
                            The Fallacy of the Repugnant Paradox </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/ai-heuristics-and-bio" className="smitem">
                            Artificial Intelligence, Heuristics and Biomimicry </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/incentive-structure-for-ai" className="smitem">
                            Incentive Structure for building Artificial
                            Intelligence with a sense of morality</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/towards-collective-cons" className="smitem">
                            Towards Collective Consciousness of Human Race </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/blog/need-trade-wars-two" className="smitem">
                            Do we really need trade wars to shrink international
                            trade-flows in Physical Goods? </Link>
                          <Divider className="itemdiv" />



                        </Box>
                      </Grid>
                      {/* Blog Section Ended */}


                      {/* Newsletter Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={1}><Typography className="menuheading"> Newsletter </Typography></Box>
                        <Box className="brdbx lgscroll" mb={2}>

                          <Link to="/newsletter/monthly-newsletter-mar-2025.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | March, 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-feb-2025.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | February, 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jan-2025.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | January, 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-nov-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | November, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-oct-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | October, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-sep-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | September, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-aug-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | August, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jul-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | July, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jun-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | June, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-may-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | May, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-apr-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | April, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-mar-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | March, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-feb-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | February, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jan-2024.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | January, 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-dec-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | December, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-nov-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | November, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-oct-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | October, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-sep-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | September, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-aug-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | August, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jul-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | July, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jun-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | June, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-may-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | May, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-apr-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | April, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-mar-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | March, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-feb-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | February, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-jan-2023.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | January, 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-dec-2022.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | December, 2022
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-nov-2022.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | November, 2022
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-oct-2022.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | October, 2022
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/newsletter/monthly-newsletter-sep-2022.html" className="smitem" target="_blank" rel="noopener noreferrer">
                            Newsletter | September, 2022
                          </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>
                      {/* Newsletter Section Ended */}


                      {/* Podacast Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={2}><Typography className="menuheading"> Podcast </Typography></Box>
                        <Box className="brdbx smscroll" mb={2}>

                          <a href="https://youtu.be/O26q8yg8t1s" className="smitem" target="_blank" rel="noopener noreferrer">
                            ESG at a Crossroads with Ajay Jindal on Policy Shifts Market Trends and Investor Strategies</a>
                          <Divider className="itemdiv" />

                          <a href="https://youtu.be/eMPK_l4P3nM" className="smitem" target="_blank" rel="noopener noreferrer">
                            Unpacking Fund Accounting - Insights from Adarshjeet Panikar</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=Pst4_LK3r1U" className="smitem" target="_blank" rel="noopener noreferrer">
                            Decoding Capital Market Expectations: How Quants Navigate Trends</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=IPeLMamwmII" className="smitem" target="_blank" rel="noopener noreferrer">
                            Redefining Index Operations with Automation</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=WJdhdt-m9ZU" className="smitem" target="_blank" rel="noopener noreferrer">
                            Transforming Financial Operations Through Advanced Technology</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=2JHxnD7DUX0&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB" className="smitem" target="_blank" rel="noopener noreferrer">
                            Exploring Equity Markets and the Impact of AI on Research & Investment Strategies</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=MKdenjzeWk0&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=2" className="smitem" target="_blank" rel="noopener noreferrer">
                            Maximing succes in SBA Lending:Comprehensive strategic insights</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=paI241kKWEM&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=5" className="smitem" target="_blank" rel="noopener noreferrer">
                            Streamlining RFP & DDQ Management for Global Fund Managers</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=exWEn6MR36k" className="smitem" target="_blank" rel="noopener noreferrer">
                            Leveraging Salesforce Automation for Financial Reporting & Analysis</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=X4DCAKyLuEY" className="smitem" target="_blank" rel="noopener noreferrer">
                            SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=eSfGBubb7gk&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=3" className="smitem" target="_blank" rel="noopener noreferrer">
                            Transforming PDF Interactions with Sanika Pandit</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=Tyyvw4WAdjo&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=9" className="smitem" target="_blank" rel="noopener noreferrer">
                            Key Trends for Asset Management In 2024</a>
                          <Divider className="itemdiv" />

                          <a href="https://open.spotify.com/episode/0YWQLh7HA7z8QvV5AbAvTm" className="smitem" target="_blank" rel="noopener noreferrer">
                            CEO Shailesh Dhuri in an Exclusive Podcast with Entrepreneur Lounge of India</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI" className="smitem" target="_blank" rel="noopener noreferrer">
                            Unlocking Success: 2024's Top Five ESG Trends for Asset Managers</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=L_W3oLcUQw8" className="smitem" target="_blank" rel="noopener noreferrer">
                            Navigating Challenges for Small Businesses in 2024: Rising Interest Rates</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=3E-QBcE2GI0" className="smitem" target="_blank" rel="noopener noreferrer">
                            The Evolution of APIs: How They Work and Why Are They Important?</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=3Pu2weDavYE" className="smitem" target="_blank" rel="noopener noreferrer">
                            DPA USA ESG Review 2023: USA's Path to Net-Zero and Responsible Investing</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=Dtuna7KnjUQ" className="smitem" target="_blank" rel="noopener noreferrer">
                            Navigating the ESG Landscape: Insights from Prasad Nawathe | Decimal Point Analytics Unplugged</a>
                          <Divider className="itemdiv" />

                          <a href="https://youtu.be/GcI0eq-OQHs?si=yQjQx3W__VPI-Clz" className="smitem" target="_blank" rel="noopener noreferrer">
                            How Business Intelligence is Changing Businesses in Today's Ever-Changing</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=tg7y2BCDqdM" className="smitem" target="_blank" rel="noopener noreferrer">
                            Strategic Insights into Finance & Innovation with Pravas Sahoo</a>
                          <Divider className="itemdiv" />

                          <a href="https://open.spotify.com/episode/3hBMTuRrENqKYBeYz3pcnG?si=PPONZwUiSie6Futo1XsBIg&nd=1" className="smitem" target="_blank" rel="noopener noreferrer">
                            Ajay Jindal in Conversation with CRTA on Canada ESG Review 2023</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=xgVNbqLzm7E&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=17" className="smitem" target="_blank" rel="noopener noreferrer">
                            Creating Efficiencies with Technology</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=wsSgZ22V-Tw" className="smitem" target="_blank" rel="noopener noreferrer">
                            Exploring Canada’s ESG Landscape: Insights from DPA’s ESG Review</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=WRyLIRguWMw&t=2s" className="smitem" target="_blank" rel="noopener noreferrer">
                            Data warehousing Cloud and Beyond: Exploring the Future with Suyash</a>
                          <Divider className="itemdiv" />


                          <a href="https://www.youtube.com/watch?v=FDO5ldmEfX4" className="smitem" target="_blank" rel="noopener noreferrer">
                            How will GPTs change the Analytics Landscape?</a>
                          <Divider className="itemdiv" />


                          <a href="https://podcasters.spotify.com/pod/show/canadianregtech/episodes/Is-Machine-Readable-Regulation-the-Answer-to-Regulatory-Burden-e25ej7v/a-a9vh8gh" className="smitem" target="_blank" rel="noopener noreferrer">
                            Is Machine Readable Regulation the Answer to Regulatory Burden?</a>
                          <Divider className="itemdiv" />

                          <a href="https://www.youtube.com/watch?v=iTmO6qbip9Q" className="smitem" target="_blank" rel="noopener noreferrer">
                            Exploring the ESG landscape and Net Zero possibilities!</a>
                          <Divider className="itemdiv" />

                          <a href="https://youtu.be/M_-gONwkGbY" className="smitem" target="_blank" rel="noopener noreferrer">
                            A Zero possibilities! HR Insights: Expert Analysis & Trends</a>
                          <Divider className="itemdiv" />


                          <a href="https://www.youtube.com/watch?v=49h8lRYKCis" className="smitem" target="_blank" rel="noopener noreferrer">
                            CEO Shailesh Dhuri in an Exclusive interview with Start-up Story Media</a>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>
                      {/* Podacast Section Ended */}


                      {/* Infographics Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={2}><Typography className="menuheading">Infographics</Typography></Box>
                        <Box className="brdbx smscroll">


                          <Link
                            to="/infographics/china-auto-finance-market" className="smitem">
                            China Auto Finance Market</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/infographics/taxi-aggregator-market" className="smitem">Taxi
                            Aggregator Market</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/infographics/laser-therapy" className="smitem">
                            Laser Therapy</Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/infographics/white-label-atm" className="smitem">
                            White Label ATMs</Link>
                          <Divider className="itemdiv" />



                        </Box>
                      </Grid>
                      {/* Infographics Section Ended */}


                      {/* Whitepaper Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={2}><Typography className="menuheading">Whitepapers</Typography></Box>
                        <Box className="brdbx smscroll">

                          <Link
                            to="/whitepaper/agentic-ai-in-financial-services" className="smitem">
                            The Transformative Potential of Agentic AI for the Financial Services Industry
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/technology-driven-bridge-lending" className="smitem">
                            The Future of Bridge Lending: How Technology is Reshaping Real Estate Financing
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/predictive-intelligence-in-financial-services-preparing-for-the-future" className="smitem">
                            Predictive Intelligence in Financial Services: Preparing for the Future
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/data-a-confidence-booster-in-commercial-real-estate-cre-lending-decisions" className="smitem">
                            Data, a confidence-booster in commercial real estate CRE lending decisions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/getting-visual-with-data-for-personalized-banking" className="smitem">
                            Getting Visual with Data for Personalized Banking
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/the-amplified-need-for-tech-led-intelligence-in-insurance" className="smitem">
                            The Amplified Need for Tech-Led Intelligence in Insurance
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/alternative-data-that-can-power-successful-alternative-investments" className="smitem">
                            Alternative Data that can Power Successful Alternative Investments
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds" className="smitem">
                            Turning Data into Dollars: Combining Advanced Analytics and Tech to Unlock Intel for Hedge Funds
                          </Link>
                          <Divider className="itemdiv" />

                          <Link
                            to="/whitepapers/leveraging-large-language-models-to-minimize-technical-debt" className="smitem">
                            Leveraging Large Language Models to Minimize Technical Debt
                          </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>
                      {/* Whitepaper Section Ended */}


                      {/* Magazine Section Started */}
                      <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Box mt={2}><Typography className="menuheading">Magazine</Typography></Box>
                        <Box className="brdbx smscroll">

                          <Link
                            to="/magazine" className="smitem">
                            Magazine
                          </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>
                      {/* Magazine Section Ended */}

                    </Grid>

                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Insight Dropdown Content Section Ended */}

            <Box id="bottom-2-boxes" mt={1}>
              <Grid container spacing={2} alignItems="top" >

                {/* About Us Section Started */}
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Box className="whitebx">
                    <Box>
                      <Typography variant="body1" className="sitemaphdd al_center"> About Us</Typography>
                      <Divider />
                    </Box>

                    <Box className="brdbx smscroll" mt={2}>

                      <Link to="/who-we-are" className='smitem'>
                        Our Story & Offerings
                      </Link>
                      <Divider className="itemdiv" />

                      <Link to="/decimal-sutras" className='smitem'>
                        Decimal Sutras
                      </Link>
                      <Divider className="itemdiv" />

                      <Link to="/awards" className='smitem'>
                        Awards and Accolades
                      </Link>
                      <Divider className="itemdiv" />

                      {/* <Link to="/technologyedge" className='smitem'>
                        Technology Edge
                      </Link>
                      <Divider className="itemdiv" />

                      <Link to="/valueproposition" className='smitem'>
                        Value Proposition
                      </Link>
                      <Divider className="itemdiv" /> */}

                      <Link to="/management" className='smitem'>
                        Management Team
                      </Link>
                      <Divider className="itemdiv" />

                      {/* <Link to="/structure-innovation" className='smitem'>
                        Structure of Innovation
                      </Link>
                      <Divider className="itemdiv" /> */}

                      <Link to="/csr" className='smitem'>CSR</Link>
                      <Divider className="itemdiv" />

                      <Link to="/energy-efficiency" className='smitem'>
                        Energy Efficiency
                      </Link>
                      <Divider className="itemdiv" />

                      <Link to="/event" className="smitem">Events</Link>
                      <Divider className="itemdiv" />

                      <Link to="/pressreleases" className="smitem">News Room</Link>
                      <Divider className="itemdiv" />


                    </Box>
                  </Box>
                </Grid>
                {/* About Us Section Ended */}


                {/* Latest Events Section Started */}
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Box className="whitebx">
                    <Box>
                      <Typography variant="body1" className="sitemaphdd al_center">Latest Events</Typography>
                      <Divider />
                    </Box>

                    <Grid container spacing={1} alignItems="top" >
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className="brdbx smscroll" mt={2}>

                          <Link to='/gcc-policy-2025-launch' className='smitem'>
                            Launch of the Global Capability Center (GCC) Policy 2025-30
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to='/ifsca-summit-gift-ifsc-finance' className='smitem'>
                            Decimal Point Analytics at IFSCA Public Sector Enterprises Summit 2.0
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/oracle-cloudworld-tour-insightss' className='smitem'>
                            Decimal Point Analytics at Oracle CloudWorld Tour India
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/11th-annual-sustainable-investing-symposium' className='smitem'>
                            11th Annual Sustainable Investing Symposium
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/private-credit-and-the-credit-markets-transformation' className='smitem'>
                            Private Credit and the Credit Markets Transformation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-paniit-world-of-technology-2025' className='smitem'>
                            Decimal Point Analytics at PANIIT - World of Technology 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/an-economy-that-works-for-all-measurement-matters' className='smitem'>
                            An Economy That Works for All: Measurement Matters
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/8th-annual-data-science-in-finance-conference' className='smitem'>
                            8th Annual Data Science in Finance Conference
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/roundtable-discussion-on-unleash-innovation-build-the-next-big-thing' className='smitem'>
                            Roundtable Discussion on Unleash Innovation: Build the Next Big Thing
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/where-innovation-meets-strategy-an-exclusive-boardroom-session' className='smitem'>
                            Where Innovation Meets Strategy: An Exclusive Boardroom Session
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-ifsca-official-talent-vista-2024' className='smitem'>
                            Decimal Point Analytics at IFSCA Official Talent Vista 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/roundtable-discussion-on-smart-strategies-for-multi-cloud-environments-and-data-integration' className='smitem'>
                            Roundtable Discussion on Smart Strategies for Multi-Cloud Environments and Data Integration
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-ifsca-san-francisco-conference-2024' className='smitem'>
                            Decimal Point Analytics at IFSCA San Francisco Conference 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/harvesting-opportunities-funds-in-gift-city-scaling-new-heights' className='smitem'>
                            Harvesting Opportunities: Funds in GIFT City Scaling New Heights
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-the-gift-ifsc-conference-2024-in-new-york' className='smitem'>
                            Decimal Point Analytics at the GIFT IFSC Conference 2024 in New York
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-gen-ai-conclave-awards-2024' className='smitem'>
                            Decimal Point Analytics at Gen.ai Conclave & Awards 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/oracle-cloud-world-ai-powered-data-management-with-dilip-sah' className='smitem'>
                            Oracle Cloud World: AI-Powered Data Management with Dilip Sah
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/finovate-fall-2024-connecting-with-industry-leaders' className='smitem'>
                            Finovate Fall 2024: Connecting with Industry Leaders
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/executive-roundtable-on-innovating-finance-with-ai' className='smitem'>
                            Executive Roundtable on Innovating Finance with AI
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/global-fintech-fest-2024-insights-from-the-panel-discussion-on-gift-city' className='smitem'>
                            Global Fintech Fest 2024: Insights from the Panel Discussion on GIFT City
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/arun-singh-at-avantika-universitys-student-orientation-ceremony-aarambh' className='smitem'>
                            Arun Singh at Avantika University’s Student Orientation Ceremony "Aarambh"
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-america-east-meet-nyc-2024-advancing-data-driven-insights' className='smitem'>
                            Decimal Point Analytics at America East Meet NYC 2024: Advancing Data-Driven Insights
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference' className='smitem'>
                            Paresh Sharma Represents Decimal Point Analytics at the 5th PICUP Fintech Conference
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/shailesh-dhuris-talk-at-thakur-global-business-school-navigating-emerging-markets' className='smitem'>
                            Shailesh Dhuri's Talk at Thakur Global Business School: Navigating Emerging Markets
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-proudly-supported-the-annual-feinstein-summer-concert-featuring-gwen-stefani' className='smitem'>
                            Decimal Point Analytics Proudly Supported the 19th Annual Feinstein Summer Concert Featuring Gwen Stefani
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/decimal-point-analytics-at-altsny-june-2024' className='smitem'>
                            Decimal Point Analytics at ALTSNY June 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/exploring-ais-impact-on-the-insurance-sector-insights-from-the-mena-insurTech-association-summit-2024' className='smitem'>
                            Exploring AI's Impact on the Insurance Sector: Insights from the MENA InsurTech Association Summit 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference' className='smitem'>
                            Unveiling the Transformative Power of Tokenization: Insights from the 6th CFA India Fintech Conference
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/executive-roundtable-exploring-generative-ai-for-enterprises' className='smitem'>
                            Executive Roundtable: Exploring Generative AI for Enterprises
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/innovation-summit-2023-at-sda-bocconi-asia-center' className='smitem'>
                            Innovation Summit 2023 at SDA Bocconi Asia Center
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes' className='smitem'>
                            Yin & Yang of Web3 & AI to Transform Insurance Processes
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers' className='smitem'>
                            Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to='/quantifying-esg-risk' className='smitem'>
                            Quantifying ESG Risk
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to='/executive-roundtable-on-portfolio-alignment-tools' className='smitem'>
                            Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to='/convergence-of-ai-and-iot' className='smitem'>
                            Convergence of AI & IoT for a safer and equal society
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/application-of-machine-learning-in-catastrophe-modeling" className='smitem'>
                            Application of Machine Learning in Catastrophe Modeling
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/data-analytics-making-it-work" className='smitem'>
                            Data Analytics: Making it work in the real world
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/round-table-by-dpa" className='smitem'>
                            Round Table by DPA - Is Net zero still a myth?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/techniche-2022" className='smitem'>
                            Decimal point analytics a sponsorer in “Techniche 2022”
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/are-developed-markets-riskier" className='smitem'>
                            Are Developed Markets Riskier Than Developing Markets?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/indo-french-chamber-of-commerce" className='smitem'>
                            Indo-French Chamber of Commerce and Industry (IFCCI)
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/chetana-institutes-of-management" className='smitem'>
                            Chetana’s Institutes of Management
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/mipm-ies-mcrc-convention" className='smitem'>
                            NIPM - IES MCRC Convention
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/weschool-mumbai" className='smitem'>
                            WeSchool Mumbai
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/professional-risk-managers-international-association" className='smitem'>
                            Professional Risk Managers' International Association
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/nl-dalmia-college" className='smitem'>
                            NL Dalmia College
                          </Link>
                          <Divider className="itemdiv" />


                        </Box>
                      </Grid>
                    </Grid>

                  </Box>
                </Grid>
                {/* Latest Events Section Ended */}


                {/* News Room Section Started */}
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Box className="whitebx">
                    <Box>
                      <Typography variant="body1" className="sitemaphdd al_center">News Room</Typography>
                      <Divider />
                    </Box>

                    <Grid container spacing={1} alignItems="top" >
                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <Box className="brdbx smscroll" mt={2}>

                          <Link to="/scarcitys-symphony-how-limitations-compose-innovation-decimal-point-analytics" className='smitem'>
                            Scarcity's Symphony – How Limitations Compose Innovation: Decimal Point Analytics
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/beyond-deepfakes-how-ai-powered-cyber-threats-redefining-digital-warfare" className='smitem'>
                            Beyond Deepfakes: How AI-Powered Cyber Threats Redefining Digital Warfare
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/the-long-wave-ahead-re-examining-kondratieffs-theory-in-a-multipolar-world" className='smitem'>
                            The Long Wave Ahead: Re-examining Kondratieff’s Theory in a Multipolar World
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/the-digital-renaissance-of-americas-small-business-administration-a-potential-tech-revolution" className='smitem'>
                            The Digital Renaissance of America's Small Business Administration: A Potential Tech Revolution
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/budget-expectation-2025" className='smitem'>
                            Budget Expectation 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/shailesh-dhuri-ceo-of-decimal-point-analytics-discusses-genai-and-sustainability-in-exclusive-interviews-with-cxo-news-and-apac-news-network" className='smitem'>
                            Shailesh Dhuri, CEO of Decimal Point Analytics, Discusses GenAI and Sustainability in Exclusive Interviews with CXO News and APAC News Network
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/navigating-the-ai-landscape-key-trends-shaping-2025-and-beyond" className='smitem'>
                            Navigating the AI Landscape: Key Trends Shaping 2025 and Beyond
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/decimal-point-analytics-hosts-hackathon-2025" className='smitem'>
                            Decimal Point Analytics (DPA) Hosts Hackathon 2025
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/contemplating-cyberspace-and-beyond" className='smitem'>
                            Contemplating Cyberspace and Beyond
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/the-new-economics-when-ai-meets-multi-agent-networks" className='smitem'>
                            The new economics: When AI meets multi-agent networks
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/decimal-point-analytics-launches-comprehensive-investor-relationship-management-solutions" className='smitem'>
                            Decimal Point Analytics Launches Comprehensive Investor Relationship Management Solutions
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/indias-modern-day-arthashastra-a-ministry-of-economic-security" className='smitem'>
                            India’s Modern Day Arthashastra - A Ministry Of Economic Security
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/reshaping-discovery-ais-transformative-impact-on-scientific-research" className='smitem'>
                            Reshaping Discovery: AI's Transformative Impact on Scientific Research
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot" className='smitem'>
                            The Holographic Future of Regulation: Why We Need a Radical New Approach Unravelling the Regulatory Knot
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/global-capability-centers-strategic-catalysts-of-digital-transformation" className='smitem'>
                            Global Capability Centers: Strategic Catalysts of Digital Transformation
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing" className='smitem'>
                            Global and instant magnification of stupidity as we watch the dream of the Internet failing
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/budget-expectation-2024" className='smitem'>
                            Budget Expectation 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/technical-debt-crisis-a-ticking-time-bomb-in-the-digital-age" className='smitem'>
                            Technical debt crisis: A ticking time bomb in the digital age
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/beyond-the-eiffel-tower-building-frances-tech-undersea-wealth" className='smitem'>
                            Beyond the Eiffel Tower: Building France’s Tech Undersea Wealth
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/decimal-point-analytics-empowers-underprivileged-students-through-dnyanodapi-dhanodapi-program" className='smitem'>
                            Decimal Point Analytics Empowers Underprivileged Students Through Dnyanodapi Dhanodapi Program
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/pixels-and-bullets-the-case-for-digital-defence-strategies" className='smitem'>
                            Pixels and Bullets: The Case for Digital Defence Strategies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/dilip-sah-joins-decimal-point-analytics-as-chief-technology-officer" className='smitem'>
                            Dilip Sah Joins Decimal Point Analytics as Chief Technology Officer
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/industry-experts-highlights-impact-of-saas-on-business-landscapes" className='smitem'>
                            Industry experts highlights impact of SaaS on business landscapes
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/does-adhering-to-green-practices-add-costs-for-businesses" className='smitem'>
                            Does adhering to green practices add costs for businesses?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/global-vaults-for-a-connected-world-the-promise-of-data-embassies" className='smitem'>
                            Global Vaults for a Connected World: The Promise of Data Embassies
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/digital-assets-can-look-forward-to-a-brighter-future-in-2024" className='smitem'>
                            Digital Assets Can Look Forward to A Brighter Future in 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/blockchain-beyond-bitcoin-a-journey-towards-true-financial-inclusion" className='smitem'>
                            Blockchain beyond Bitcoin – A journey towards true financial inclusion
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/rollercoaster-ride-of-bitcoin-what-lies-ahead" className='smitem'>
                            Rollercoaster Ride Of Bitcoin: What Lies Ahead?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/global-transformations-the-impact-of-ai-trends-across-industries" className='smitem'>
                            Global Transformations: The Impact of AI Trends Across Industries
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/shaping-a-new-growth-mindset-through-innovation-ideas-at-the-growth-summit" className='smitem'>
                            Shaping a New Growth Mindset through Innovation & Ideas at The Growth Summit.
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability" className='smitem'>
                            Unveiling Net Zero: Decoding Decimal Point Analytics' Purposeful Journey Towards Sustainability
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity" className='smitem'>
                            Cybersecurity in Fintech: Ensuring Data Protection and Privacy in a World of Connectivity
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/llms-beyond-chatbots-new-frontiers" className='smitem'>
                            LLMs Beyond Chatbots - New Frontiers
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/unlocking-success-key-trends-for-asset-management-in-2024" className='smitem'>
                            Unlocking Success: Key Trends For Asset Management In 2024
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/decimal-point-analytics-plans-overseas-expansion" className='smitem'>
                            Decimal Point Analytics plans overseas expansion
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/central-bank-digital-currencies-the-new-era-of-money" className='smitem'>
                            Central Bank Digital Currencies (CBDCs): The New Era of Money
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/gautam-naidu-joins-decimal-point-analytics-as-chief-revenue-officer-in-mumbai" className='smitem'>
                            Gautam Naidu joins Decimal Point Analytics as Chief Revenue Officer in Mumbai
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/ai-versus-ai-how-ai-can-fight-ai-to-solve-cyberthreat" className='smitem'>
                            AI versus AI: How AI can fight AI to solve cyberthreat
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/is-it-time-to-write-esg-requiem-not-quite" className='smitem'>
                            Is it time to write ESG’s Requiem? Not quite
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot" className='smitem'>
                            LLMs, The Drama Around OpenAI: Is Q* The Death* of Co-pilot?
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023" className='smitem'>
                            Decimal Point Analytics Wins Canadian Reg Tech Award for Best ESG Solution 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/central-bank-digital-currency-enabled-fx-transactions" className='smitem'>
                            Central Bank Digital Currency (CBDC) Enabled FX Transactions: An Opportunity to Facilitate Currency Movement
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/india-has-an-enviable-place-in-global-esg-disclosure-list" className='smitem'>
                            India has an enviable place in global ESG disclosure list
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/india-emerging-as-a-global-trendsetter-in-esg-disclosures" className='smitem'>
                            India emerging as a global trendsetter in ESG disclosures
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023" className='smitem'>
                            Oracle Global Leaders Recognizes Decimal Point Analytics as ISV of the Year 2023
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/transformative-impact-of-gpt-on-the-analytics-landscape" className='smitem'>
                            Transformative Impact of GPTs on the Analytics Landscape
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/implementing-machine-readable-regulation-for-competitive-advantage" className='smitem'>
                            Implementing Machine Readable Regulation for competitve advantage
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/decimal-pointa-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event" className='smitem'>
                            Decimal Point Analytics and PRMIA Collaborate for Successful "Quantifying ESG Risk" Event
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/decimal-point-analytics-inaugurated-its-green-energy-office-in-nashik" className='smitem'>
                            Decimal Point Analytics inaugurated its 100% green energy office in Nashik
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions" className='smitem'>
                            Decimal Point Analytics receives grant funding to develop Tokenization solutions
                          </Link>
                          <Divider className="itemdiv" />



                          <Link to="/fintechentity" className='smitem'>
                            DPA SmartCloud recognized as a “Fintech Entity” by International Financial Services Centres Authority (IFSCA).
                          </Link>
                          <Divider className="itemdiv" />



                          <a href='https://www.youtube.com/watch?v=49h8lRYKCis&t=1006s' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Mr. Shailesh Dhuri, CEO of Decimal Point Analytics shared about his entrepreneurial journey and more in an exclusive interview with Startup Story Media
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.business-standard.com/content/press-releases-ani/malware-and-ransomware-attacks-increased-by-358-per-cent-and-435-per-cent-respectively-the-global-risks-report-2022-122090100993_1.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            A “perfect storm” awaiting the financial sector due to deepened global divisions!
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.thehindu.com/business/Industry/decimal-point-analytics-raises-35-crore/article65467387.ece' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics announces a fund raise of Rs 35 crore (USD 4.7m) by a large global institutional investor
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.prfree.org/@decimalp/decimal-point-analytics-announces-the-launchdate-of-its-asset-allocation-platform-powered-by-quantum-computing-in-a-world-first-implementation-6rm5rnyjbkw4' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics announces the launchdate of its Asset Allocation platform powered by Quantum Computing in a world first implementation
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.prfree.org/@decimalp/prosper-with-knowledge-and-wealth-program-by-decimal-point-analytics-yrkd54qqrm5x' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Prosper With Knowledge and Wealth Program by Decimal Point Analytics
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.openpr.com/news/2384101/decimal-point-analytics-achieves-zero-carbon-emissions-at-their' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics achieves zero carbon emissions at their analytics centers
                          </a>
                          <Divider className="itemdiv" />



                          <a href='http://www.prfree.org/@decimalp/decimal-point-analytics-urges-all-its-employees-to-fightback-corona-eqmje54q6kr6' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics urges all its employees to fightback Corona
                          </a>
                          <Divider className="itemdiv" />




                          <a href='https://www.pr.com/press-release/835244' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Hiring Figures Are Through the Roof at Decimal Point Analytics During the Pandemic Season
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.pr.com/press-release/833602' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics Pvt. Ltd. Wins "Indian SME of the Year Award"
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.pr.com/press-release/820383' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics CEO, Mr. Shailesh Dhuri Says India Needs a Rate Cut of 250 BPS in the Next Seven Months
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.marketpressrelease.com/Decimal-Point-Analytics-employees-contribute-prize-money-to-feed-the-less-privileged-1596541649.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics employees contribute prize money to feed the less privileged
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.1888pressrelease.com/laplace-s-demon-and-limits-to-central-banks-ability-to-stab-pr-673098.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.prlog.org/12816128-iatrogenesis-of-social-distancing-thoughts-on-flaws-in-the-deeper-structure-of-society.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Iatrogenesis of Social Distancing: Thoughts on flaws in the deeper structure of society
                          </a>
                          <Divider className="itemdiv" />


                          <a href='http://www.prfree.org/@decimalp/beginning-of-etf-shock-p7kea7jprkwx' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Beginning of ETF Shock
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.openpr.com/news/1975128/funding-stress-in-dollar-market-at-a-record-high-in-the-last-two' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Funding Stress in Dollar Market at a record high in the last two decade
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.marketpressrelease.com/Shailesh-Dhuri-CEO-of-Decimal-Point-Analytics-discusses-about-COVID-19-and-the-Folly-of-ETFs-1584513466.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Shailesh Dhuri, CEO of Decimal Point Analytics discusses about 'COVID-19 and the Folly of ETFs'
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.openpr.com/news/1927571/institute-of-actuaries-of-india-has-announced-weather' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Institute of Actuaries of India has announced "Weather Hackathon January 2020" powered by Decimal Point Analytics
                          </a>
                          <Divider className="itemdiv" />


                          <a href='http://www.prfree.org/@decimalp/decimal-point-analytics-announces-the-top-five-contestants-of-technopulse-its-online-machine-learning-challenge-3rmwydr8amn4' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics announces the top five contestants of ‘TechnoPulse’, its online Machine Learning challenge
                          </a>
                          <Divider className="itemdiv" />

                          <a href='https://www.prlog.org/12653914-weekly-digest-india-heading-towards-deflation.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            India Heading Towards Deflation
                          </a>
                          <Divider className="itemdiv" />

                          <a href='https://etradewire.com/news/decimal-point-analytics-launches-singularitypulse-an-ai-focused-newsletter' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics launches SingularityPulse – An AI focused newsletter
                          </a>
                          <Divider className="itemdiv" />

                          <a href='https://www.prdistribution.com/nbherard.com/decimal-point-analytics-has-launched-a-research-management-portal' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics has launched a ‘Research Management Portal’
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.openpr.com/news/866247/bitcoin-market-an-apology-for-blockchain.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Bitcoin Market, An Apology for Blockchain
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.openpr.com/news/1030797/how-can-india-leverage-rising-crude-oil-prices-to-help-the-domestic-economy.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            How can India leverage rising crude oil prices to help the domestic economy?
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.prlog.org/12706613-rethinking-corporate-governance-framework-in-the-digital-world.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Rethinking Corporate Governance Framework in the Digital World
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.prlog.org/12716242-decimal-point-analytics-launches-the-beta-of-imagepulse-for-predicting-the-financial-status.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics launches the beta of ImagePulse for predicting the financial status
                          </a>
                          <Divider className="itemdiv" />

                          <a href='https://www.prlog.org/12726317-decimal-point-analytics-unveils-new-credit-risk-management-framework-at-the-prmia-event-in-mumbai.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point Analytics unveils new credit risk management framework at the PRMIA event in Mumbai
                          </a>
                          <Divider className="itemdiv" />


                          <a href='https://www.openpr.com/news/1238419/decimal-point-releases-an-infographic-on-chinese-auto-finance-market.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point releases an Infographic on Chinese Auto Finance Market
                          </a>
                          <Divider className="itemdiv" />



                          <a href='https://www.openpr.com/news/1743666/decimal-point-releases-the-latest-economic-dashboard-for-india.html' target="_blank" rel="noopener noreferrer" className='smitem'>
                            Decimal Point releases the latest Economic Dashboard for India
                          </a>
                          <Divider className="itemdiv" />




                        </Box>
                      </Grid>
                    </Grid>

                  </Box>
                </Grid>
                {/* News Room Section Started */}


                {/* Partners Section Started */}
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Box className="whitebx">

                    <Grid container spacing={1} alignItems="top" >
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Box>
                          <Typography variant="body1" className="sitemaphdd al_center">Partners</Typography>
                          <Divider />
                        </Box>

                        <Box className="brdbx smscroll" mt={2}>
                          <Link to="/oracle" className='smitem'>
                            Oracle
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/ibm" className='smitem'>
                            IBM
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/clickup-partnership" className='smitem'>
                            ClickUp
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/salesforce-crm-solutions" className='smitem'>
                            Salesforce
                          </Link>
                          <Divider className="itemdiv" />

                        </Box>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box>
                          <Typography variant="body1" className="sitemaphdd al_center"> Careers </Typography>
                          <Divider />
                        </Box>

                        <Box className="brdbx smscroll" mt={2}>
                          <Link to="/life-as-a-decimal" className='smitem'>
                            Life as a Decimal
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/our-culture" className='smitem'>
                            Our Culture
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/journey-of-a-decimal" className='smitem'>
                            Journey of a Decimal
                          </Link>
                          <Divider className="itemdiv" />


                          <Link to="/dnyanodapi-dhanodapi" className='smitem'>
                            <Box pt={"1px"}>
                              ज्ञानोदपि धनोदपि
                            </Box>
                          </Link>

                          <Divider className="itemdiv" />


                          <Link to="/current-openings" className='smitem'>
                            Current Openings
                          </Link>
                          <Divider className="itemdiv" />

                          <Link to="/contact-us">
                            <Typography className="sitemaphdd">Contact Us</Typography>
                          </Link>
                          <Divider className="itemdiv" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {/* Partners Section Started */}


              </Grid>
            </Box>

          </Box >
        </Container >
      </Box >
    </>
  );
}
