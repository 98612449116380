import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Casestudyback from '../../../../Component/Casestudyback'
import Banner from "../../../../asset/images/insight/case_studies/banner_investor_engagement.webp"
import { Link } from 'react-router-dom';

export default function InvestorEngagement() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>CRM Optimization & Data Automation for a Asset Manager | Decimal Point Analytics</title>
                <meta name="description" content="Optimized CRM workflows, automated investor tracking & provided real-time fundraising insights for a $27B asset manager using Salesforce CRM solutions." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-study/crm-optimization-investor-data-automation" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Optimizing CRM & Investor Engagement <span className='lower'>for a</span> $27B Asset Manager
                                </Typography>
                            </Box> 
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A US-based alternative asset manager with <strong>$27 billion in AUM</strong> sought a scalable <strong>data management and CRM solution</strong> to streamline investor interactions, fundraising insights, and meeting preparations. The firm aimed to improve <strong>efficiency, automation, and data security</strong> to enhance decision-making and operational effectiveness.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Challenges
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm faced key challenges in managing CRM data efficiently:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Unstructured and inconsistent investor data,</strong> making engagement tracking difficult.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Limited visibility into fundraising trends,</strong> delaying strategic decisions.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Manual investor interaction logging,</strong> leading to data inconsistencies.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Time-intensive meeting preparation,</strong> reducing productivity.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data security concerns, requiring a structured,</strong> automation-driven approach.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                To address these issues, the firm partnered with <strong>Decimal Point Analytics (DPA)</strong> to implement a  <Link to="/customer-relationship-management-solutions" className="link">comprehensive CRM optimization and data automation strategy.</Link>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Solution
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                DPA deployed a <strong>customized Salesforce CRM framework</strong> with <strong>advanced data automation</strong> to improve data accuracy, investor engagement, and operational efficiency.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Data Standardization & Accuracy Enhancement</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Applied <strong>fuzzy logic matching</strong> to clean and standardize investor data.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Enabled <strong>bulk data uploads,</strong> eliminating manual errors and inefficiencies.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Fundraising Insights via Interactive Dashboards</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Designed <strong>real-time, interactive dashboards</strong> in Salesforce to track investor activity, fund sources, and regional trends.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Provided <strong>granular analytics,</strong> enabling smarter, data-driven fundraising strategies.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Seamless Outlook & Salesforce Integration</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Developed an <strong>Outlook plugin for automated investor interaction logging</strong> into Salesforce.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Enabled direct <strong>record creation, search, and updates</strong> from Outlook, reducing manual effort.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated Meeting Preparation & Alerts</strong>
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Implemented <strong>automated meeting notifications</strong> and preparation workflows for timely access to key investor data.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    Streamlined <strong>multi-investor handling,</strong> allowing the team to focus on high-value engagements.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Impact & Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                DPA’s <strong>CRM automation and data optimization strategy</strong> delivered measurable improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Standardized CRM data,</strong> ensuring accurate investor interactions.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster access to fundraising insights,</strong> improving strategic planning.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Seamless Salesforce-Outlook integration,</strong> eliminating manual data entry.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated meeting alerts,</strong> boosting engagement efficiency.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Stronger data security & compliance,</strong> ensuring data integrity.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Key Takeaway
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By automating CRM processes, enhancing investor analytics, and integrating seamless workflows, asset managers can <strong>increase efficiency, reduce errors, and drive smarter fundraising strategies.</strong>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h2' className='blue h2h4' gutterBottom>
                                Unlock Efficiency & Data-Driven Decision-Making
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Struggling with <strong>manual CRM inefficiencies, investor tracking, or fundraising insights?</strong>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <Link to="/contact-us#contact_form" className="link">Partner with Decimal Point Analytics</Link> to implement intelligent data automation and CRM solutions that drive <strong>higher engagement, faster decision-making, and improved operational efficiency.</strong>
                            </Typography>
                        </Box>

                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}