import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_leading_data_provider.webp"
import Infographics from "../../../../asset/images/insight/case_studies/achieving_90__accuracy.webp"
import { Link } from 'react-router-dom';



export default function LeadingDataProvider() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>US Financial Data Provider Revamps Classification - Learn How</title>
                <meta name="description" content="See how a top US financial data provider updated its industry classification to boost accuracy and client trust." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/achieving-90-accuracy-in-industry-classification-for-a-leading-us-financial-data-provider" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Achieving 90% Accuracy <span className='lower'> in</span> Industry Classification <span className='lower'>for a</span> Leading US Financial Data Provider
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading US financial data provider faced challenges with maintaining an up-to-date and accurate industry classification system. The existing models were outdated, leading to misclassifications and inaccuracies in sector-based analyses. By re-engineering the classification system, the provider achieved more accurate industry categorizations, improved data reliability, and enhanced client satisfaction.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The financial data provider faced several challenges in managing their industry classification system:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Outdated Classification Models:</strong> Existing models were outdated, resulting in inaccurate industry and sector categorization.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Misclassification Issues: </strong>  Inconsistent classifications impacted the accuracy of sector-based analysis.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Limited Data Insights:  </strong>  Outdated models limited the ability to provide detailed sector insights to clients.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                            Solution: Industry Classification Re-engineering
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img loading="lazy"  src={Infographics} className="w100" alt="Solution: Industry Classification Re-engineering" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>   Improved Accuracy:  </strong> The new classification system reduced misclassifications by 90%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Real-Time Updates:  </strong> Automated updates ensured data accuracy and up-to-date industry classifications.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Client Satisfaction:  </strong>  Accurate classifications improved the reliability of sector-based analyses for clients.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Accurate Classification Models: </strong>  Re-engineered classification models provided real-time insights and enhanced data reliability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Improved Client Confidence:  </strong> Enhanced accuracy led to greater client satisfaction and trust in data.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Streamlined Data Processes: </strong> Automated updates reduced manual work and improved operational efficiency.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today to learn how we can help you enhance your data classification systems and improve client satisfaction!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}