import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ic1 from "../../asset/images/smartpipe/pricing/free.svg";
import ic2 from "../../asset/images/smartpipe/pricing/personal.svg";
import ic3 from "../../asset/images/smartpipe/pricing/business.svg";
import "react-modal-video/scss/modal-video.scss";

import ecopulse from "../../asset/images/new_banner/eco_pulse_banner.webp";
import SmartPipeTable from "./SmartCloud/SmartPipeTable";
import { Link } from "react-router-dom";

export default function Smartpipe() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // const handleClickOpen = () => {
  //   window.location.href = "https://eazydata.ai";
  // };
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>EAZYDATA - Revolutionizing Data Management</title>
        <meta
          name="description"
          content="At EAZYDATA , we understand the difficulties businesses encounter when managing their data. That's why we offer a comprehensive, end-to-end solution that boasts enterprise-grade quality, eliminating the downsides of existing tools such as long setup times, difficult set up and maintenance, and technical skill requirements."
        />
        <meta name="author" content="Decimal Point Analytics" />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/eazydata"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "EAZYDATA",
              "image": "https://www.decimalpointanalytics.com/static/media/ecopulsebanner.10a7a396c742d59ca936.webp",
              "description": "At EAZYDATA , we understand the difficulties businesses encounter when managing their data. That's why we offer a comprehensive, end-to-end solution that boasts enterprise-grade quality, eliminating the downsides of existing tools such as long setup times, difficult set up and maintenance, and technical skill requirements.",
              "brand": {
                "@type": "Brand",
                "name": "Decimal Point Analytics"
              }
            }
          `}
        </script>
      </Helmet>

      <Box id="product-page"> 
        <Box className="banner_bx">
          <Box
            style={{ backgroundImage: "url(" + ecopulse + ")" }}
            className="banner_img"
          >
            <Box className="img_over bl_overlay">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item lg={4} md={5} sm={7} xs={10}>
                  <Box className="al_center">
                    <Typography className="white" variant="h1">
                      EAZYDATA
                    </Typography>
                    <Box mt={"10px"}>
                      <Typography className="white" variant="h4">
                        Revolutionizing Data Management
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box id="ecopulse" className="section2 sec_bg" component="section">
          <Container maxWidth="lg" className="bdr"> 

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx">
                  <Typography variant="h6" className="black jst">
                    Welcome to EAZYDATA, the world's first collaborative support
                    platform for your data workflows. Our team combines
                    expertise in data management and machine learning with a
                    commitment to excellent customer service to simplify how
                    companies manage their data.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    At EAZYDATA, we understand the difficulties businesses
                    encounter when managing their data. That's why we offer a
                    comprehensive, end-to-end solution that boasts
                    enterprise-grade quality, eliminating the downsides of
                    existing tools such as long setup times, difficult set up
                    and maintenance, and technical skill requirements.
                    Additionally, we cut the costs and complexity associated
                    with building it in-house. Our collaborative option is a
                    unique and valuable proposition, where we take on the
                    responsibility of managing your data workflows from start to
                    finish. We can handle the minutia of changes and validations
                    in your workflows. This enables you to focus on more
                    critical areas of your business. For those who prefer a
                    self-service option, our Do-it-yourself solution includes
                    advanced features to manage your data yourself. Our pipes
                    are engineered to be highly automated, minimizing set-up
                    time and effort and with no coding required. We use
                    intelligent algorithms and machine learning to resolve
                    complex data issues, giving you peace of mind and confidence
                    in your data.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box id="ecopulse" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2">
                    {" "}
                    Pricing
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container spacing={2} alignItems="top">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img loading="lazy"  src={ic1} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Free Trail
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography
                          variant="h6"
                          className="black al_center"
                        ></Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img loading="lazy"  src={ic2} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Do it Yourself
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant="h6" className="black al_center">
                          <strong style={{ display: "block" }}>
                            ₹20799/month
                          </strong>
                          <strong style={{}}>₹18699/month</strong>( for annual
                          commitment)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img loading="lazy"  src={ic3} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Eazy Collaboration
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant="h6" className="black al_center">
                          <strong style={{ display: "block" }}>
                            ₹49999/month
                          </strong>
                          <strong style={{}}>₹44999/month</strong>( for annual
                          commitment)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Box id="ecopulse" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Box mt={3}>
              <SmartPipeTable />
            </Box>
          </Container>
        </Box>

        <Box id="eazydata" className="maintabbedsection stiky2">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={7}>
                <Typography variant="h6">
                  <Link to="/eazydata/privacy" className="black">
                    Privacy Policy
                  </Link>{" "}
                  |{"  "}
                  <Link to="/eazydata/terms" className="black">
                    Terms and Condition
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
