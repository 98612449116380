import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Articles_Data from "./Articles_Data";
import LazyLoad from "react-lazyload"; // npm install react-lazyload

export default function Articles() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // State to manage visible articles
  const [visibleArticles, setVisibleArticles] = useState(6);

  // Increment the number of visible articles by 6, not exceeding the total
  const handleLoadMore = () => {
    setVisibleArticles((prev) => Math.min(prev + 6, Articles_Data.length));
  };

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>
          Finance & Businesses Industries Articles | Decimal Point Analytics
        </title>
        <meta
          name="description"
          content="Articles from leading provider of Data Analytics and Financial Research Solutions, we offer comprehensive and reliable data-driven insights to empower your businesses & make informed data-driven decisions."
        />
        <meta
          name="keywords"
          content="big data analytics, business analytics, data analytics, ai solution"
        />
        <meta name="author" content="Decimal Point Analytics" />
        <link
          rel="canonical"
          href="https://www.decimalpointanalytics.com/articles"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Box
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h1" className="black newh1">
                  Articles
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="reportinfo">
            <Grid container spacing={1} alignItems="top">
              {Articles_Data.slice(0, visibleArticles).map((item) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={item.link}>
                  <LazyLoad height={300} offset={100} once>
                    {item.linking === "external" ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                      >
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                          </Box>
                          <Box className="line"></Box>
                        </Box>
                      </a>
                    ) : (
                      <Link to={item.link}>
                        <Box className="reportm_bg">
                          <Box
                            className="reportimg_bg"
                            style={{
                              backgroundImage: `url(${item.imgsrc})`,
                            }}
                          ></Box>
                          <Box className="reporttitle_box">
                            <Typography
                              className="reporttitle two"
                              title={item.heading}
                            >
                              {item.heading}
                            </Typography>
                          </Box>
                          <Box className="line"></Box>
                        </Box>
                      </Link>
                    )}
                  </LazyLoad>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Load More Button */}
          {visibleArticles < Articles_Data.length && (
            <Box mt={4} textAlign="center">
              <Button
                variant="contained"
               className="dpabtn_1"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
}