import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3' 
import Intro from "../../asset/images/services/fund_management/fund_intro.webp";

import CO1 from "../../asset/images/services/fund_management/co-1.svg";
import CO2 from "../../asset/images/services/fund_management/co-2.svg";
import CO3 from "../../asset/images/services/fund_management/co-3.svg";
import CO4 from "../../asset/images/services/fund_management/co-4.svg";
import CO5 from "../../asset/images/services/fund_management/co-5.svg";
import CO6 from "../../asset/images/services/fund_management/co-6.svg";

import DPACore from "../../asset/images/services/fund_management/fund_why_choose.webp";

import { Helmet } from 'react-helmet';

import FundMngt from "../../asset/images/services/fund_management/banner_fund_mngt.webp";
import { Link } from "react-router-dom";
import GetInTouch from '../../Component/Get_in_touch';
import ProductCTAA from '../../Component/ProductCTAA';

export default function FundManagement() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Fund Management & Private Equity Solutions | Optimize Performance & Maximize Returns</title>
                <meta name="description" content="Enhance fund management with our all-in-one solutions. From portfolio analytics to automated reporting, we streamline operations and optimize investment strategies." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/fund-management-and-private-equity-support-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id='fund_mngt'>

                <Box className="banner_bx">
                    <Box style={{ backgroundImage: "url(" + FundMngt + ")" }} className="banner_img">
                        <Box className="img_over">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={6} md={7} sm={8} xs={8}>
                                    <Box className="al_center">
                                        <Typography className="blue" variant="h1">Fund Management & Private Equity Support Solutions</Typography>

                                    </Box>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='flx_center' mt={4}>
                                            <ProductCTAA allignment="flx_fs" cta_btn_name="Contact us today!" pageVisited="Fund Management & Private Equity Support Solutions" />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Writemessage3 pageVisited="Fund Management & Private Equity Support Solutions" />

                <Box className='section2 sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems='center' justifyContent="center">
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                <Box className="al_center">
                                    <img loading="lazy"  src={Intro} className="topbanner5" alt="Enjoy" />
                                </Box>
                            </Grid>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In today’s fast-paced financial landscape, effective fund management is crucial for achieving sustainable growth and maximizing investor returns. Our Fund Management & Private Equity Support Solutions are designed to provide fund managers and private equity professionals with the tools, insights, and services they need to manage, monitor, and optimize their portfolios efficiently. Whether you're overseeing complex portfolios or seeking to improve operational efficiency, our solutions offer comprehensive support every step of the way.
                                    </Typography>
                                </Box>
                                <Box className='flx_fs mob_center' mt={2}>
                                    <ProductCTAA allignment="flx_fs" cta_btn_name="Contact us today!" pageVisited="Fund Management & Private Equity Support Solutions" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box className='section mob_card' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} direction="row" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Comprehensive Offering
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="center">
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO1} className='allic dblue' alt='Fund Administration & Accounting' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={7} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Fund Administration & Accounting
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Efficient fund administration is essential for successful fund management. We streamline accounting, NAV calculations, investor transactions, and compliance, ensuring transparency, accuracy, and timely reporting—so you can focus on strategic decisions while we manage the complexities.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO2} className='allic sblue' alt='Fund & Portfolio Analytics' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={7} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Fund & Portfolio Analytics

                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Gain deeper investment insights with our advanced fund and portfolio analytics. Our tools provide real-time performance monitoring, risk assessment, and trend analysis, empowering fund managers to make data-driven decisions, optimize portfolios, and manage risk with confidence.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO3} className='allic dblue' alt='Fund Level Performance Evaluation & Reporting' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={9} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Fund Level Performance Evaluation & Reporting
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Our performance evaluation services provide a clear view of your fund’s progress, highlighting key metrics and trends. With customized reports on fund returns, asset performance, and critical indicators, you can make informed decisions, communicate with investors, and showcase your fund’s value.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO4} className='allic sblue' alt='Automated Factsheets & Reports' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={8} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Automated Factsheets & Reports
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Our automated factsheet and reporting solutions simplify the creation of timely, accurate, and visually appealing reports. By reducing manual effort, they ensure consistency and accuracy in investor communications, providing stakeholders with reliable performance data for better decision-making and enhanced trust.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO5} className='allic dblue' alt='Portfolio Construction & Monitoring' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={8} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Portfolio Construction & Monitoring
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Construct and maintain a diversified portfolio tailored to your fund’s objectives. Our portfolio construction services ensure the right asset mix based on strategy, risk profile, and market conditions, while our monitoring services track performance against benchmarks, providing real-time insights for proactive adjustments.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='boxbg mnhg5'>
                                    <Box className='al_left'>
                                        <img loading="lazy"  src={CO6} className='allic sblue' alt='Investment Teasers & Investor Reporting' />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item lg={7} md={9} sm={8} xs={12}>
                                                <Box mb={2}>
                                                    <Typography variant='h4' className='blue'>
                                                        Investment Teasers & Investor Reporting
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='h6' className='black jst'>
                                            Attract and engage investors with compelling teasers and detailed reporting. Our services present investment opportunities effectively, providing investors with the insights needed for informed decisions. With clear and consistent updates, you can keep investors informed on fund performance and progress.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center' mt={4}>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Contact us today!" pageVisited="Fund Management & Private Equity Support Solutions" />
                            </Box>
                        </Grid>

                    </Container>
                </Box>

                <Box className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                        <Box className='mob_card' mt={3}>
                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center">
                                        <Typography className="black" variant="h2">
                                            Why Choose Us?
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    We provide a unique blend of technical expertise, industry knowledge, and cutting-edge technology to ensure that your fund management operations are streamlined and optimized. Here’s why our clients trust us:
                                </Typography> 

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box mt={3}>
                                        <img loading="lazy"  src={DPACore} className='topbanner1' alt='Why Choose Us?' />
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" mt={5}>
                                        <Typography className="black" variant="h6">
                                            <Link className='link' to='/contact-us#contact_form'>Partner with us</Link>  today and streamline your fund management operations for enhanced efficiency and investor satisfaction.
                                        </Typography>
                                    </Box>
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='flx_center' mt={4}>
                                        <ProductCTAA allignment="flx_fs" cta_btn_name="Contact us today!" pageVisited="Fund Management & Private Equity Support Solutions" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>



                    </Container>
                </Box>

                <Box>
                    <GetInTouch />
                </Box>

            </Box>
        </>
    );
}