import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Budget from "../../../asset/images/media/press_release/budget_2024.webp"

import Banner from '../../../asset/images/media/press_release/banner_budget_2024.webp'

import ET from "../../../asset/images/media/eco_time.webp";
import CXO from "../../../asset/images/media/cxo.webp";
import MB from "../../../asset/images/media/media_brief.webp";
import BT from "../../../asset/images/media/business_today.webp";

export default function BudgetExpectations2024() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Shailesh Dhuri's Insights on the Upcoming Budget: Growth, Innovation, and Reforms</title>
                <meta name="description" content="Explore Shailesh Dhuri's take on the upcoming Budget and its potential to drive economic growth, innovation, and reform." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/budget-expectation-2024" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Budget Expectation 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO, Shailesh Dhuri, expects the upcoming Budget to drive economic growth by cutting red tape, expanding trade, and reforming state enterprises. It will simplify taxes, boost infrastructure, and enhance education and healthcare, while also supporting vulnerable populations and fostering innovation.
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={8} md={8} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img loading="lazy"  src={Budget} className='fullimg' alt='Quote' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://economictimes.indiatimes.com/wealth/tax/income-tax-budget-2024-expectations-live-updates-standard-deduction-hike-new-income-tax-slab-changes-finance-minister-nirmala-sitharaman-latest-new-july-22-2024/liveblog/111916925.cms?from=mdr" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + ET + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://cxotoday.com/specials/industries-voice-their-budget-desires-a-look-at-sectoral-expectations-for-union-budget-2024-25/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CXO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://mediabrief.com/budget-2024-expectations-across-sectors/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + MB + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://www.businesstoday.in/union-budget/story/union-budget-2024-whats-in-store-for-aam-aadmi-taxpayers-investors-this-budget-key-expectations-here-finance-minister-nirmala-sitharaman-438227-2024-07-23" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + BT + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
