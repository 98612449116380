import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import "../Style.css";
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';


export default function WritemessageSBA(props) {


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Box className="maintabbedsection stiky2">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={7}>
              <Typography className="stay1 white">
                For more details contact us
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={5}>
              <Box className="al_right">
                <Link to='/contact-us#contact_form' className='link'>
                  <Button
                    variant="contained"
                    className="dpabtn_2 mobbtn"
                  >
                    Contact Us Today
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
