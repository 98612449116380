// import JAN_2025 from "../../asset/images/newsletter/newsletter_banner/jan_2025_newsletter.webp";
// import FEB_2025 from "../../asset/images/newsletter/newsletter_banner/feb_2025_newsletter.webp";
// import MAR_2025 from "../../asset/images/newsletter/newsletter_banner/march_2025_newsletter.webp";

import JAN_2025 from "../../asset/images/newsletter/newsletter_banner/1jan_2025_newsletter.webp";
import FEB_2025 from "../../asset/images/newsletter/newsletter_banner/1feb_2025_newsletter.webp";
import MAR_2025 from "../../asset/images/newsletter/newsletter_banner/1march_2025_newsletter.webp";
import APR_2025 from "../../asset/images/newsletter/newsletter_banner/1april_2025_newsletter.webp";
// import MAR_2025 from "../../asset/images/newsletter/newsletter_banner/2march_2025_newsletter.jpg";


import NOV_2024 from "../../asset/images/newsletter/nov_2024.webp"
import OCT_2024 from "../../asset/images/newsletter/oct_2024.webp"
import SEP_2024 from "../../asset/images/newsletter/sep_2024.webp"
import AUG_2024 from "../../asset/images/newsletter/aug_2024.webp";
import JUL_2024 from "../../asset/images/newsletter/july_2024.webp";
import JUN_2024 from "../../asset/images/newsletter/jun_2024.webp";
import MAY_2024 from "../../asset/images/newsletter/may_2024.webp";
import APR_2024 from "../../asset/images/newsletter/apr_2024.webp";
import MAR_2024 from "../../asset/images/newsletter/mar_2024.webp";
import FEB_2024 from "../../asset/images/newsletter/feb_2024.webp";
import JAN_2024 from "../../asset/images/newsletter/jan_2024.webp";

import DEC_2023 from "../../asset/images/newsletter/dec_2023.webp"
import NOV_2023 from "../../asset/images/newsletter/nov_2023.webp"
import OCT_2023 from "../../asset/images/newsletter/oct_2023.webp"
import SEP_2023 from "../../asset/images/newsletter/sep_2023.webp"
import AUG_2023 from "../../asset/images/newsletter/aug_2023.webp";
import JUL_2023 from "../../asset/images/newsletter/july_2023.webp";
import JUN_2023 from "../../asset/images/newsletter/jun_2023.webp";
import MAY_2023 from "../../asset/images/newsletter/may_2023.webp";
import APR_2023 from "../../asset/images/newsletter/apr_2023.webp";
import MAR_2023 from "../../asset/images/newsletter/mar_2023.webp";
import FEB_2023 from "../../asset/images/newsletter/feb_2023.webp";
import JAN_2023 from "../../asset/images/newsletter/jan_2023.webp";

import DEC_2022 from "../../asset/images/newsletter/dec_2022.webp";
import NOV_2022 from "../../asset/images/newsletter/nov_2022.webp";
import OCT_2022 from "../../asset/images/newsletter/oct_2022.webp";
import SEP_2022 from "../../asset/images/newsletter/sep_2022.webp";


const Newsletter_Data = [ 
    {
        year: 2025,
        link: "/newsletter/monthly-newsletter-apr-2025.html",
        date: "April, 2025",
        imgsrc: APR_2025,
        heading2025:"Lending, CRM & Beyond, 2025"
    },
    {
        year: 2025,
        link: "/newsletter/monthly-newsletter-mar-2025.html",
        date: "March, 2025",
        imgsrc: MAR_2025,
        heading2025:"Strategic Insights for Financial Leaders"
    },
    {
        year: 2025,
        link: "/newsletter/monthly-newsletter-feb-2025.html",
        date: "February, 2025",
        imgsrc: FEB_2025,
        heading2025:"Agentic AI Boosts Small Businesses"
    },
    {
        year: 2025,
        link: "/newsletter/monthly-newsletter-jan-2025.html",
        date: "January, 2025",
        imgsrc: JAN_2025,
        heading2025:"Sustainable Investments: Future Insights"
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-nov-2024.html",
        date: "November, 2024",
        imgsrc: NOV_2024,
        heading: "Decimal Chronicles",
        subheading: "AI in compliance, underwriting, and innovation."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-oct-2024.html",
        date: "October, 2024",
        imgsrc: OCT_2024,
        heading: "Decimal Chronicles",
        subheading: "Data analytics & emerging technologies."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-sep-2024.html",
        date: "September, 2024",
        imgsrc: SEP_2024,
        heading: "Decimal Chronicles",
        subheading: "Advancements in AI & data solutions"
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-aug-2024.html",
        date: "August, 2024",
        imgsrc: AUG_2024,
        heading: "Decimal Chronicles",
        subheading: "Data reshaping services & decision-making."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-jul-2024.html",
        date: "July, 2024",
        imgsrc: JUL_2024,
        heading: "Decimal Chronicles",
        subheading: "Analytics & transformative solutions."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-jun-2024.html",
        date: "June, 2024",
        imgsrc: JUN_2024,
        heading: "Decimal Chronicles",
        subheading: "AI innovations & data management insights."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-may-2024.html",
        date: "May, 2024",
        imgsrc: MAY_2024,
        heading: "Decimal Chronicles",
        subheading: "Advanced analytics driving impact."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-apr-2024.html",
        date: "April, 2024",
        imgsrc: APR_2024,
        heading: "Decimal Chronicles",
        subheading: "AI advancements & data-driven strategies."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-mar-2024.html",
        date: "March, 2024",
        imgsrc: MAR_2024,
        heading: "Decimal Chronicles",
        subheading: "AI solutions, compliance, & key updates."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-feb-2024.html",
        date: "February, 2024",
        imgsrc: FEB_2024,
        heading: "Decimal Chronicles",
        subheading: "Compliance & operational optimization."
    },
    {
        year: 2024,
        link: "/newsletter/monthly-newsletter-jan-2024.html",
        date: "January, 2024",
        imgsrc: JAN_2024,
        heading: "Decimal Chronicles",
        subheading: "AI trends & strategic advancements."
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-dec-2023.html",
        imgsrc: DEC_2023,
        date: "Newsletter | December, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-nov-2023.html",
        imgsrc: NOV_2023,
        date: "Newsletter | November, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-oct-2023.html",
        imgsrc: OCT_2023,
        date: "Newsletter | October, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-sep-2023.html",
        imgsrc: SEP_2023,
        date: "Newsletter | September, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-aug-2023.html",
        imgsrc: AUG_2023,
        date: "Newsletter | August, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-jul-2023.html",
        imgsrc: JUL_2023,
        date: "Newsletter | July, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-jun-2023.html",
        imgsrc: JUN_2023,
        date: "Newsletter | June, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-may-2023.html",
        imgsrc: MAY_2023,
        date: "Newsletter | May, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-apr-2023.html",
        imgsrc: APR_2023,
        date: "Newsletter | April, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-mar-2023.html",
        imgsrc: MAR_2023,
        date: "Newsletter | March, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-feb-2023.html",
        imgsrc: FEB_2023,
        date: "Newsletter | February, 2023",
    },
    {
        year: 2023,
        link: "/newsletter/monthly-newsletter-jan-2023.html",
        imgsrc: JAN_2023,
        date: "Newsletter | January, 2023",
    },
    {
        year: 2022,
        link: "/newsletter/monthly-newsletter-dec-2022.html",
        imgsrc: DEC_2022,
        date: "Newsletter | December, 2022",
    },
    {
        year: 2022,
        link: "/newsletter/monthly-newsletter-nov-2022.html",
        imgsrc: NOV_2022,
        date: "Newsletter | November, 2022",
    },
    {
        year: 2022,
        link: "/newsletter/monthly-newsletter-oct-2022.html",
        imgsrc: OCT_2022,
        date: "Newsletter | October, 2022",
    },
    {
        year: 2022,
        link: "/newsletter/monthly-newsletter-sep-2022.html",
        imgsrc: SEP_2022,
        date: "Newsletter | September, 2022",
    },

];

export default Newsletter_Data;