import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack.js';
import { Helmet } from 'react-helmet';

import Banner from '../../../asset/images/media/press_release/banner_global_instant.webp'
import ET from "../../../asset/images/media/et_insights.webp";
import CXO from "../../../asset/images/media/cxo.webp";

export default function GlobalAndInstant() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>CEO Shailesh Dhuri Reflects on Global Internet Disruption and Centralization</title>
                <meta name="description" content="CEO Shailesh Dhuri explores the impact of a global Internet disruption and questions if we can return to its decentralized roots." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h2' className='mainhead blue al_center'>
                                    Global and instant magnification of stupidity as we watch the dream of the Internet failing
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img loading="lazy"  src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Internet was conceptualized as a fail-safe system of communication in 1960s to keep lines of communication open in case of catastrophic warfare. The design of the Internet, and the subsequent WWW protocols developed in early 1990s dreamt of a distributed system with no single point of failure.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        However, yesterday, on July 19, 2024, everything from airports to hospitals to banks across the world came to standstill. The human civilization came to standstill again due to a null file of microscopic size, for a few hours, reliving the memory of Covid-19 virus induced standstill that we experienced four years ago.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This was not a cyber-attack, not a malware, not a computer virus, not a computer worm, not a ransomware. This was result of design flaw in the architecture of the subsequent layers of the Internet, caused by deliberate choices made by technology corporations, by governments, by telecom companies, and by venture capitalists. The additional layers of the Internet laid in late 1990s’ dot com boom to web 2.0 of 2000s to web 3.0 and now GenAI layer of 2020s deliberately created centralized powers of control to accumulate wealth, and more importantly to accumulate information, and to disseminate disinformation from centralized control points.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        When one creates global centers of concentration for whatever reasons, those very centers become single point of failure for the Internet and now since the Internet is backbone of humanity, this transmits into single point of failure for human society.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This centralized power allowed global and instant magnification of stupidity of one developer team in one company with political connections to bring human society to its knees for one day.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This is scary concentration of power. Will it change? Will we ever go back to the original design of the Internet? I guess never.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://etedge-insights.com/in-focus/opinion/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + ET + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://cxotoday.com/specials/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CXO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
